import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput,SelectGroup } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';
import CurrencyInput from './../../components/moneyInput';
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'

import { addDays, format } from 'date-fns'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalBillsToPay({showModal, handleCloseModal, handleReload}) {
    const [loading, setLoading]                           = useState(false)

    const [idpeople,setIdPeople]                          = useState('')
    const [amount,setAmount]                              = useState(0.00)
    const [idpaymentcondition,setIdPaymentCondition]      = useState('')
    const [idcostcenter,setIdCostCenter]                  = useState('')
    const [idchartofaccount,setIdCharTofAccount]          = useState('')
    const [idkindofpayment,setIdKindOfPayment]            = useState('')
    const [comments ,setComments]                         = useState('')
    const [invoice ,setInvoice]                           = useState('')
    const [quantity, setQuantity]                         = useState(0)
    const [parcels, setParcels]                           = useState([])

    const [dataPeoples, setDataPeoples]                   = useState([])
    const [dataPaymentCondition, setDataPaymentCondition] = useState([])
    const [dataChartOfAccount, setDataChartOfAccount]     = useState([])
    const [dataCostCenter, setDataCostCenter]             = useState([])
    const [dataKindOfPayment, setDataKindOfPayment]       = useState([])
    const [valueType, setValueType]                       = useState(0)

    const [allowEdit, setAllowEdit]                       = useState(false)

    const setData = ()=> {
        setIdPeople('')
        setAmount(0.00)
        setIdPaymentCondition('')
        setIdCostCenter('')
        setIdCharTofAccount('')
        setIdKindOfPayment('')
        setComments('')
        setInvoice('')
        setQuantity(0)
        setParcels([])
    }
    const getData = async() => {
        await api.get('/getBillsToPayData')
                 .then((response) => { 
                    setDataPeoples(response.data.peoples)
                    setDataPaymentCondition(response.data.paymentsCondition)
                    setDataChartOfAccount(response.data.chartOfAccount)
                    setDataCostCenter(response.data.costcenter)
                    setDataKindOfPayment(response.data.kindofpayment)
                 }).catch(error => {
                    ShowMessages(error)
                 })  
    }

    const editParcelAmount = (_value,_parcel) => {
        let _parcels = []

        parcels.map((item, key) => {
            if (item.parcel === _parcel)
                _parcels.push({parcel  : item.parcel,
                               duedate : item.duedate,
                               amount  : _value})
            else
                _parcels.push({parcel  : item.parcel,
                               duedate : item.duedate,
                               amount  : item.amount})
        })    
        setParcels(_parcels)
    }

    const editParcelDate = (_value,_parcel) => {
        console.log(new Date(_value))
        let _parcels = []

        parcels.map((item, key) => {
            if (item.parcel === _parcel)
                _parcels.push({parcel  : item.parcel,
                               duedate : new Date(_value),
                               amount  : item.amount})
            else
                _parcels.push({parcel  : item.parcel,
                               duedate : item.duedate,
                               amount  : item.amount})
        })    
        setParcels(_parcels)
    }

    const handleKind = (_value) => {
        if (_value === -1)
            return 

        let _paymentCondition = dataPaymentCondition.filter((_item) => {
            return(_item.value === _value)
        })

        if (_paymentCondition.length > 0)
            _paymentCondition = _paymentCondition[0]
        
        setIdKindOfPayment(_paymentCondition.idkindofpayment)
        setAllowEdit(_paymentCondition.allowedit)
    }

    const handlePayment = (_idpaymentCondition, _value) => {
        if ((_idpaymentCondition === -1) || (_value <= 0))
            return

        let _paymentCondition = dataPaymentCondition.filter((_item) => {
            return(_item.value === _idpaymentCondition)
        })
        
        if (_paymentCondition.length > 0)
            _paymentCondition = _paymentCondition[0]

        let _parcel = _value.replace('.','').replace('.','').replace('.','').replace(',','.')  

        let _qtdParcel = _paymentCondition.installments.length
        setQuantity(_qtdParcel)
        _parcel = valueType  === 0 ? _parcel / _qtdParcel : _parcel

        let _duedate = new Date()
        let _parcels = []

        _paymentCondition.installments.map((item, key) => {
            _duedate = addDays(_duedate,item.day)
            
            _parcels.push({parcel  : item.installment,
                           duedate : _duedate,
                           amount  : (_parcel + (_parcel * item.addition / 100)).toLocaleString('pt-br', {minimumFractionDigits: 2})})
        })    
        setParcels(_parcels)
        
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-billstopay'),document.querySelector('#submit-billstopay').children[0].classList,document.querySelector('#submit-billstopay').lastChild.nodeValue)
        try {
            let _data = {idpeople,
                         amount,
                         idpaymentcondition,
                         idcostcenter,
                         idchartofaccount,
                         idkindofpayment,
                         comments,
                         quantity,
                         invoice,
                         parcels }

            await api.post('/bills-to-pay', _data)
                     .then(response => {
                          ShowMessages(response)
                          if (response.ok) {   
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-billstopay'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-billstopay'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-billstopay'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-billstopay'))
            ShowMessages(response)
        }
    }
       
    useEffect(() => {
        getData()
        setStatistic('modals\\modal-billstopay')
    } ,[])

    useEffect(() => {
        setData()
    } ,[showModal])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-money-bill-wave"></i> Propriedades de Contas a Pagar
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading}/>
                    )}
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>Fornecedor</label>
                            <div>
                                <SelectGroup 
                                    name="idpeople" 
                                    id="idpeople" 
                                    autoFocus={true}
                                    placeholder="Selecione" 
                                    required
                                    errorMessage={{ required: "Por favor, informe um fornecedor!" }}
                                    className="form-select"
                                    value={idpeople} 
                                    onChange={e => setIdPeople(parseInt(e.target.value))}>
                                        <option value="">Selecione</option>
                                        {dataPeoples.map((data, id) => {
                                        return (<option key={id} value={data.value}>{data.label}</option>)
                                    })} 
                                </SelectGroup> 
                            </div>
                        </div>  
                        <div style={{display:"flex"}}>
                            <div style={{width:"50%"}}>
                                <label>Centro de Custo</label>
                                <div>
                                    <SelectGroup 
                                        name="idcostcenter" 
                                        id="idcostcenter" 
                                        placeholder="Selecione" 
                                        required
                                        errorMessage={{ required: "Por favor, informe um centro de custo!" }}
                                        className="form-select"
                                        value={idcostcenter} 
                                        onChange={e => setIdCostCenter(parseInt(e.target.value))}>
                                            <option value="">Selecione</option>
                                            {dataCostCenter.map((data, id) => {
                                            return (<option key={id} value={data.value}>{data.label}</option>)
                                        })} 
                                    </SelectGroup> 
                                </div>
                            </div>   
                            <div style={{marginLeft:"15px",width:"50%"}}>
                                <label>Plano de Contas</label>
                                <div>
                                    <SelectGroup 
                                        name="idchartofaccount" 
                                        id="idchartofaccount" 
                                        placeholder="Selecione" 
                                        required
                                        errorMessage={{ required: "Por favor, informe um plano de contas!" }}
                                        className="form-select"
                                        value={idchartofaccount} 
                                        onChange={e => setIdCharTofAccount(parseInt(e.target.value))}>
                                            <option value="">Selecione</option>
                                            {dataChartOfAccount.map((data, id) => {
                                            return (<option key={id} value={data.value}>{data.label}</option>)
                                        })} 
                                    </SelectGroup> 
                                </div>
                            </div>                               
                        </div>
                        <div style={{display:"flex"}}>
                            <div style={{width:"50%"}}>
                                <label>Condições de Pagamento</label>
                                <div>
                                    <SelectGroup 
                                        name="idpaymentcondition" 
                                        id="idpaymentcondition" 
                                        placeholder="Selecione" 
                                        required
                                        errorMessage={{ required: "Por favor, informe uma condição de pagamento!" }}
                                        className="form-select"
                                        value={idpaymentcondition} 
                                        onChange={e => {setIdPaymentCondition(parseInt(e.target.value));
                                                        handleKind(parseInt(e.target.value || -1));
                                                        handlePayment(parseInt(e.target.value || -1), amount || 0)}}>
                                            <option value="">Selecione</option>
                                            {dataPaymentCondition.map((data, id) => {
                                            return (<option key={id} value={data.value}>{data.label}</option>)
                                        })} 
                                    </SelectGroup> 
                                </div>
                            </div> 
                            <div style={{marginLeft:"15px",width:"30%"}}>
                                <label>Espécie de Pagamento</label>
                                <div>
                                    <SelectGroup 
                                        name="idKindOfPayment" 
                                        id="idKindOfPayment" 
                                        placeholder="Selecione" 
                                        required
                                        errorMessage={{ required: "Por favor, informe uma espécie de pagamento!" }}
                                        className="form-select"
                                        value={idkindofpayment} 
                                        onChange={e => {setIdKindOfPayment(parseInt(e.target.value))}}>
                                            <option value="">Selecione</option>
                                            {dataKindOfPayment.map((data, id) => {
                                            return (<option key={id} value={data.value}>{data.label}</option>)
                                        })} 
                                    </SelectGroup> 
                                </div>
                            </div> 
                            <div style={{marginLeft:"15px", width:"20%"}}>
                                <label>Nº Documento</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="invoice"
                                        id="invoice"
                                        type="text"
                                        required
                                        errorMessage={{ required: "Por favor, informe o número do documento!" }}
                                        maxLength={25}
                                        value={invoice}
                                        className="form-control "
                                        onChange={e => setInvoice(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex"}}>
                            <div style={{display:"flex", padding:"7px", justifyContent:"center", flexDirection:"column", width:"30%"}}>
                                <div style={{width:"100%", display:"flex", justifyContent:"start"}}>
                                    <input style={{marginLeft:"-5px"}} type='radio' className="form-check-input" checked={valueType === 0} value={valueType}  onChange={e => {setValueType(0);
                                                                                                                                                                              handlePayment(idpaymentcondition || -1, amount || 0)}}/>
                                    <span style={{marginLeft:"15px"}}>Valor Total</span>
                                </div>
                                <div style={{width:"100%", display:"flex", justifyContent:"start"}}>
                                    <input style={{marginLeft:"-5px"}} type='radio' className="form-check-input" checked={valueType === 1} value={valueType}  onChange={e => {setValueType(1);
                                                                                                                                                                              handlePayment(idpaymentcondition || -1, amount || 0)}}/>
                                    <span style={{marginLeft:"15px"}}>Valor Parcela</span>
                               </div>    
                            </div> 
                            <div style={{marginLeft:'15px',width:"30%"}}>
                                <label>Valor Total</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="saleprice"
                                        id="saleprice"
                                        type="text"
                                        required
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe um valor!" }}
                                        value={amount}
                                        className="form-control text-right"
                                        onChange={e => {setAmount(e.target.value);
                                                        handlePayment(idpaymentcondition || -1, e.target.value || 0)}}
                                        autoComplete="off"/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label>Observações</label>
                            <div className="input-group">
                                <TextInput  
                                    name="comments"
                                    id="comments"
                                    type="text"
                                    maxLength={100}
                                    multiline
                                    value={comments}
                                    className="form-control "
                                    onChange={e => setComments(e.target.value)}
                                     autoComplete="off"/>
                             </div>
                        </div>  
                        <div style={{border:"1px dashed #cacaca", marginTop:"15px", padding:"5px", borderRadius:"5px"}}>
                            <div style={{ overflow:"auto", height:"150px"}}>       
                                <table className="table table-striped w-100">
                                    <thead className="thead-theme">
                                        <tr className="head-th">
                                            <td className="head-th" style={{width:"85px", minWidth:"85px", maxWidth:"85px"}}>Parcela</td>
                                            <td className="head-th text-right" style={{width:"70px", minWidth:"70px", maxWidth:"70px"}}>Vencimento</td>
                                            <td className="head-th text-right" style={{width:"80px", minWidth:"80px", maxWidth:"75px"}}>Valor</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {parcels.map((item, key) => {
                                            return (
                                                <tr className="head-th" key={key} >
                                                    <td>{item.parcel}</td>
                                                    <td className="text-right">{allowEdit ? <input type="date" value={format(item.duedate,'yyyy-MM-dd')} onChange={e => editParcelDate(e.target.value,item.parcel)}className="form-control"/> : format(item.duedate,'dd/MM/yyyy')}</td>
                                                    <td className="text-right">{allowEdit ? <CurrencyInput  
                                                                                                name="saleprice"
                                                                                                id="saleprice"
                                                                                                type="text"
                                                                                                required
                                                                                                errorMessage={{ required: "Por favor, informe um valor!" }}
                                                                                                value={item.amount}
                                                                                                className="form-control text-right"
                                                                                                onChange={e => editParcelAmount(e.target.value,item.parcel)}
                                                                                                autoComplete="off"/> : item.amount}</td>
                                                </tr>)
                                                    })}         
                                    </tbody>
                                </table>
                                <div className={`box-sem-dados alert alert-warning ${parcels.length > 0 ? "hidden" : ""}`} style={{padding:"15px"}}>Ainda não foi gerado parcelas.</div>
                            </div>            
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-billstopay" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}