import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import MaskWithValidation from '../../components/maskInput';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { isValid } from 'date-fns';

const initialState = {
  banco: '',
  agencia: '',
  conta: '',
  kindofperson: '0',
  // cnpj: '',
  // cpf: '',
  name: '',
  pix: '',
};

export default function ModalAfiliateRegistration({
  showModalAfiliateRegistration,
  toggleModalAfiliateRegistration,
}) {
  const [bankData, setBankData] = useState([
    { idbank: 1, description: '001 - Banco do Brasil' },
    { idbank: 2, description: '341 - Banco Itau' },
    { idbank: 3, description: '104 - Caixa Economica Federal' },
  ]);
  const [accountRegistration, setAccountRegistration] = useState({
    ...initialState,
    banco: bankData[0].idbank,
  });
  const [formErrors, setFormErrors] = useState({
    ...initialState,
    kindofperson: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setAccountRegistration((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    handleValidation(name, value);
  };

  const handleCNPJCPF = (e) => {
    const { value } = e.target;
    accountRegistration.kindofperson === '0'
      ? handleChange('cpf', value)
      : handleChange('cnpj', value);
  };

  const handleValidation = (name, value) => {
    const newErrors = { ...formErrors };

    // Validação do campo 'banco'
    if (name === 'banco' && !value) {
      newErrors.banco = 'Campo obrigatório';
    } else {
      newErrors.banco = '';
    }

    // Validação do campo 'agencia'
    if (name === 'agencia' && !value) {
      newErrors.agencia = 'Campo obrigatório';
    } else {
      newErrors.agencia = '';
    }

    // Validação do campo 'conta'
    if (name === 'conta' && !value) {
      newErrors.conta = 'Campo obrigatório';
    } else {
      newErrors.conta = '';
    }

    // Adicione outras validações conforme necessário

    setFormErrors(newErrors);
  };

  const handleSave = () => {
    // Realize a validação antes de salvar
    if (validateForm()) {
      // Lógica de salvamento aqui
      // ...

      // Simulando a lógica de salvamento com um log
      console.log('Dados salvos:', accountRegistration);

      // Feche o modal, se necessário
      toggleModal();
    }
  };

  const validateForm = () => {
    const newErrors = { ...formErrors };

    // Validação do campo 'banco'
    if (!accountRegistration.banco) {
      newErrors.banco = 'Campo obrigatório';
    } else {
      newErrors.banco = '';
    }

    // Validação do campo 'agencia'
    if (!accountRegistration.agencia) {
      newErrors.agencia = 'Campo obrigatório';
    } else {
      newErrors.agencia = '';
    }

    // Validação do campo 'conta'
    if (!accountRegistration.conta) {
      newErrors.conta = 'Campo obrigatório';
    } else {
      newErrors.conta = '';
    }

    // Validação do campo 'cpf'
    // if (accountRegistration.cpf && !cpf.isValid(accountRegistration.cpf)) {
    //   newErrors.cpf = 'CPF inválido';
    // } else {
    //   newErrors.cpf = '';
    // }

    // // Validação do campo 'cnpj'
    // if (accountRegistration.cnpj && !cnpj.isValid(accountRegistration.cnpj)) {
    //   newErrors.cnpj = 'CNPJ inválido';
    // } else {
    //   newErrors.cnpj = '';
    // }

    // Validação do campo 'name'
    if (!accountRegistration.name) {
      newErrors.name = 'Campo obrigatório';
    } else {
      newErrors.name = '';
    }

    // Validação do campo 'pix'
    if (!accountRegistration.pix) {
      newErrors.pix = 'Campo obrigatório';
    } else {
      newErrors.pix = '';
    }

    const isValid = Object.values(newErrors).every((value) => !value);
    console.log(newErrors);
    setFormErrors(newErrors);
    return isValid;
  };

  const toggleModal = () => {
    toggleModalAfiliateRegistration();
    setAccountRegistration({
      ...initialState,
      banco: bankData[0].idbank,
    });
    setFormErrors({
      ...initialState,
      kindofperson: '',
    });
  };

  return (
    <Modal isOpen={showModalAfiliateRegistration} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        <i
          className="fa-solid fa-address-card"
          style={{ marginRight: '8px' }}
        ></i>
        Cadastro de Conta Recebimento
      </ModalHeader>
      <ModalBody>
        <div className="box-shadow">
          <div className="mb-1">
            <Label htmlFor="banco">Banco</Label>
            <Input
              onChange={handleChange}
              type="select"
              name="banco"
              id="banco"
            >
              {bankData.map((value) => {
                return (
                  <option value={value.idbank}>{value.description}</option>
                );
              })}
            </Input>
            <span className="text-danger">{formErrors.banco}</span>
          </div>
          <div className="d-flex gap-3 mb-1">
            <div>
              <Label htmlFor="agencia">Agência</Label>
              <Input
                onChange={handleChange}
                maxLength={7}
                required
                type="text"
                name="agencia"
                id="agencia"
                value={accountRegistration.agencia}
              />
            </div>
            <span className="text-danger">{formErrors.agencia}</span>
            <div>
              <Label htmlFor="conta">Conta</Label>
              <Input
                onChange={handleChange}
                maxLength={15}
                required
                type="text"
                name="conta"
                id="conta"
                value={accountRegistration.conta}
              />
            </div>
            <span className="text-danger">{formErrors.conta}</span>
          </div>
          <div className="mb-1">
            <Label htmlFor="kindofperson">Tipo de Pessoa</Label>
            <select
              name="kindofperson"
              className="form-select"
              value={accountRegistration.kindofperson}
              onChange={handleChange}
            >
              <option value="0">Física</option>
              <option value="1">Jurídica</option>
            </select>
          </div>
          {/* <div className="mb-1">

          <Label htmlFor="cpfcnpj">CPF | CNPJ Titular</Label>
          <Input type="text" name="cpfcnpj" id="cpfcnpj" />
          <label>CPF do Usuário</label>
          <MaskWithValidation
            name="cpf"
            id="cpf"
            inputmode="numeric"
            type="text"
            required
            validator={(value) => 
              accountRegistration.kindofperson === 0
                ? cpf.isValid(value)
                : cnpj.isValid(value)
            }
            errorMessage={{
              required: `Por favor, informe um ${
                accountRegistration.kindofperson === 0 ? 'CPF' : 'CNPJ'
              }!`,
              validator: `${
                accountRegistration.kindofperson === 0 ? 'CPF' : 'CNPJ'
              } inválido!`,
            }}
            className="form-control"
            value={
              accountRegistration.kindofperson === 0
                ? accountRegistration.cpf
                : accountRegistration.cnpj
            }
            onChange={handleCNPJCPF}
            autoComplete="off"
            mask={
              accountRegistration.kindofperson === 0
                ? [
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    '.',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    '.',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    '-',
                    /[0-9]/,
                    /[0-9]/,
                  ]
                : [
                    /[0-9]/,
                    /[0-9]/,
                    '.',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    '.',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    '/',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    '-',
                    /[0-9]/,
                    /[0-9]/,
                  ]
            }
          />
            Apenas será feito o pagamento, caso o CPF do titular seja o mesmo do
            cadastro.
          
        </div> */}
          <div className="mb-1">
            <Label htmlFor="name">Nome do Titular</Label>
            <Input
              value={accountRegistration.name}
              onChange={handleChange}
              maxLength={150}
              required
              type="text"
              name="name"
              id="name"
            />
            <span className="text-danger">{formErrors.name}</span>
          </div>
          <div className="mb-1">
            <Label htmlFor="pix">Chave Pix</Label>
            <Input
              value={accountRegistration.pix}
              onChange={handleChange}
              maxLength={150}
              required
              type="text"
              name="pix"
              id="pix"
            />
            <span className="text-danger">{formErrors.pix}</span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          style={{ width: '80px', marginRight: '5px' }}
          className={`btn-theme`}
          onClick={handleSave}
        >
          <em className="fa fa-save"></em> Salvar
        </Button>
        <Button
          style={{ width: '80px' }}
          className="btn-ligth-gray"
          onClick={toggleModal}
        >
          <em className="fa fa-door-closed"></em> Sair
        </Button>
      </ModalFooter>
    </Modal>
  );
}
