import React, {useState, useEffect} from 'react'

import { Button, } from 'reactstrap';
import Draggable from 'react-draggable';

import { Modal, ModalDialog } from 'react-bootstrap';
import setStatistic from '../../services/apiStatistic'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalCoordenates({showModal, handleCloseModal, data}) {
    const [latitude, setLatitude]   = useState('')
    const [longitude, setLongitude] = useState('')

    useEffect(() => {
        setStatistic('modals\\modal-coordanates')
    },[])
   
    useEffect(() => {
        if (data) {
            setLatitude(data.latitude)
            setLongitude(data.longitude)
        }
    },[data])

    return (
      
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-map-pin"></i> Coordenadas da Mensagem
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div className={`box-shadow`} style={{padding:"15px"}}>            
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <span>Latitude</span>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}>{latitude}</span>
                        </div>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <span>Longitude</span>
                            <span  style={{fontSize:"16px", fontWeight:"bold"}}>{longitude}</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>        
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
        </Modal>
       
    )
}