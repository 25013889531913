import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button, } from 'reactstrap';
import Draggable from 'react-draggable';


import { Modal, ModalDialog ,Accordion} from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'

import api from '../../services/api';

import loadingButton  from '../layouts/Buttons'
import { subMinutes, format } from 'date-fns';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalCampaignGroup({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                     = useState(false)
    const [showButtonGroup, setShowButtonGroup]     = useState(false)
    const [showButtonGroupAll, setShowButtonGroupAll] = useState(false)
    const [campaign, setCampaign]                   = useState('')
    const [idautomationchat, seIdAutomationChat]    = useState(0)
    const [numberAutomation, setNumberAutomation]   = useState('')
    const [chatData, setChatData]                   = useState([])
    const [group, setGroup]                         = useState('')
    const [groupName, setGroupName]                 = useState('')
    const [groups, setGroups]                       = useState([])
    const [inviteCode, setInvideCode]               = useState('')
    const [groupData, setGroupData]                 = useState([])
    const [executetype, setExecuteType]             = useState(-1)
    const [week, setWeek]                           = useState(-1)
    const [time, setTime]                           = useState('')
    const [schedule, setSchedule]                   = useState([])
    const [listHours, setListHours]                 = useState('')
    const [reduceHours, setReduceHours]             = useState('')
    const [disableGroup, setDisableGroup]           = useState(true)

    const executeDate = [
        {label : 'Selecione',
         value : -1},

        {label : 'Diaramente',
         value : 0},

        {label : 'Semanalmente',
         value : 1},

        {label : 'Horário Programado',
         value : 2} 
    ]

    const dayWeek = [
        {label : 'Selecione',
         value : -1},

        {label : 'Domingo',
         value : 0},

        {label : 'Segunda-Feira',
         value : 1},

        {label : 'Terça-Feira',
         value : 2},

        {label : 'Quarta-Feira',
         value : 3},

        {label : 'Quinta-Feira',
         value : 4},

        {label : 'Sexta-Feira',
         value : 5},

         {label : 'Sábado',
         value : 6}
    ]

    const handleGroup = async(item,value) => {
        setGroup(item)
        setGroupName(value)
    }

    const addGroup = async() => {
        if (parseInt(idautomationchat) === 0) {
            ShowMessages({status : 500, message : "Automação não informada1"})
            return
        }

        if (group === '') {
            ShowMessages({status : 500, message : "Link do grupo não informado"})
            return
        }

        let _data = groups.filter((_item) => {return(_item.group === group)})

        if (_data.length > 0) {
            ShowMessages({status : 500, message : "Grupo já incluido"})
            return
        }

        
        setGroups(groups => groups.concat({numberAutomation,group,groupName,inviteCode}))

        setGroup('')
        setInvideCode('')
    }

    const addAllGroup = async() => {
        if (parseInt(idautomationchat) === 0) {
            ShowMessages({status : 500, message : "Automação não informada2"})
            return
        }

        let _groups = []
        
        setShowButtonGroupAll(true)
        for(let i = 0; i < groupData.length; i++){
            let _data = {
                numberAutomation,
                group : groupData[i].id.user,
            }

            await api.post('/invitegroupcode',_data)
                     .then(response => {
                        _groups.push({numberAutomation,group : groupData[i].id.user,groupName : groupData[i].name,inviteCode : response.data?.invite || ''})
                        
                        setShowButtonGroup(true)
                     })
                     .catch( error => {      
                        ShowMessages(error) 
                     })
        }
        setShowButtonGroupAll(false)
        setGroups(_groups)
        ShowMessages({status : 200, message : "Operação realizada com sucesso!"})
    }

    const removeGroup = (_group) => {
        const _remove = groups.filter((t, i) => {
            if (t.group !== _group)
                return true;
        
            return false;
        });

        setGroups(_remove)
    }

    const editGroup = (_group) => {
        let _edit = groups.filter((t, i) => {
            if (t.group === _group)
                return true;
        
            return false;
        })[0];

        setGroup(_edit.group)
        setInvideCode(_edit.inviteCode)
        removeGroup(_edit.id)
    }

    const addSchedule = async(e) => {
        e.preventDefault()
        if (executetype === -1) {
            ShowMessages({status : 500, message : "'Executar quando' não foi informado"})
            return
        }

        if ((time === '') && ([0,1].includes(executetype))){
            ShowMessages({status : 500, message : "Horário não foi informado"})
            return
        }

        let _data = schedule.filter((_item) => {return(((_item.executetype === executetype) && (_item.time === time) && (_item.week === week)))})

        if (_data.length > 0) {
            ShowMessages({status : 500, message : "Agendamento já cadastrado"})
            return
        }
        let _weekname = dayWeek.filter(_data => _data.value === week)[0]?.label || ''
        if (executetype === 2) {
            let _hours = listHours.match(/\b\d{1,2}:\d{2}\b/g)
            if (_hours.length <= 0) {
                ShowMessages({status : 500, message : "Nenhum horário encontrado"})
                return   
            }

            _hours.map((_hour) => {
                setSchedule(schedule => schedule.concat({executetype,
                                                         time, 
                                                         week, 
                                                         hour : format(subMinutes(new Date('2023-01-01T' + _hour + ':00'),reduceHours),'HH:mm'),
                                                         executeName : executeDate.filter(_data => _data.value === executetype)[0].label , weekName : _weekname})) 
            })
        } else {
            if (week === -1)
                _weekname = ''
            setSchedule(schedule => schedule.concat({executetype,time, week, executeName : executeDate.filter(_data => _data.value === executetype)[0].label , weekName : _weekname}))
        }

        setExecuteType(-1)
        setTime('')
    }

    const removeAllSchedule = async(e) => {
        setSchedule([])
    }

    const removeSchedule = (_time, _executetype) => {
        const _remove = schedule.filter((t, i) => {
            if ((t.time !== _time) || (t.executetype !== _executetype))
                return true;
        
            return false;
        });
        setSchedule(_remove)
    }

    const editSchedule = (_time, _executetype) => {
        let _edit = schedule.filter((t, i) => {
            if ((t.time === _time) && (t.executetype === _executetype))
                return true;
        
            return false;
        })[0];

        setTime(_edit.time)
        setExecuteType(_edit.executetype)
        removeSchedule(_time, _executetype)
    }

    const setData = (obj,clear) => {
        setCampaign(clear ? '' : obj.campaign)
        seIdAutomationChat(clear ? 0 : obj.idautomationchat)
        setGroups(clear ? [] : obj.groups)
        setSchedule(clear ? [] : obj.schedule)
    }

    const getData = async() => {
        setLoading(true)
        await api.get('/getChatBotGroup')
                 .then(async response => {
                    setChatData(response.data)
                    setLoading(false) 
                 })
                 .catch( error => {
                    ShowMessages(error)
                    setLoading(false) 
                 })
    }

    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-campaign-group'),document.querySelector('#submit-campaign-group').children[0].classList,document.querySelector('#submit-campaign-group').lastChild.nodeValue)
        event.preventDefault()

        try {
            let _data = {campaign,
                         idautomationchat,
                         groups,
                         schedule }
                     
                     
            if (editState)
                await api.put('/campaign-group/' + data.idcampaigngroup, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-campaign-group'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-campaign-group'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-campaign-group'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/campaign-group', _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-campaign-group'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-campaign-group')) 
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-campaign-group'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-campaign-group'))
            ShowMessages(response)
        }
    }

    const getGroups = async() => {
        try {
            setDisableGroup(true)
            await api.get('/chatbot/' + idautomationchat)
                     .then(async response => {       
                        if (response.data?.automation?.number)             
                            await api.get('/leads/groups/' + response.data.automation.number)
                                     .then(response1 => {
                                        setNumberAutomation(response.data.automation.number)
                                        if (response1.data) {
                                            setGroupData(response1.data.data || []) 
                                            setDisableGroup(false)
                                        }
                                    })
                                    .catch( error => {      
                                        ShowMessages(error) 
                                    })

                    }).catch( error => {      
                       ShowMessages(error) 
                    })
        } catch (response) { 
            console.log(response) 
            ShowMessages(response)
        }
    }

    const getInviteCode = async() => {
        setShowButtonGroup(false)
        if (parseInt(idautomationchat) === 0) {
            ShowMessages({status : 500, message : "Automação não informada4"})
        //    return
        }

        let _data = {
            numberAutomation,
            group
        }

        try {
            await api.post('/invitegroupcode',_data)
                    .then(response => {
                        setInvideCode(response.data?.invite || '')
                        
                        setShowButtonGroup(true)
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            console.log(response) 
            ShowMessages(response)
        }
    }

    useEffect(() => {
        if (group !== '')
            getInviteCode()
    },[group])

    useEffect(() => {
        setShowButtonGroup(false)
        setShowButtonGroupAll(false)
        if (idautomationchat > 0)
            getGroups()
    },[idautomationchat])

    useEffect(() => {
        if (executetype === 0)
            setWeek(-1)
    },[executetype])

    useEffect(() => {
        setShowButtonGroup(false)
        setShowButtonGroupAll(false)
        setStatistic('modals\\modal-campaign-group')
    },[])

    useEffect(() => {
        if (showModal)
            getData()
        
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    return (
      
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" > 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-users"></i> Propriedades do Campanha de Grupo
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth330px"/> 
                    )}
                    <div className={`box-shadow ${loading ? "hidden" : ""}`}>      
                        <div style={{display:"flex", gap:"5px"}}>
                            <div style={{width:"50%"}}>
                                <label>Campanha</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="street"
                                        id="street"
                                        type="text"
                                        required
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe um departamento!" }}
                                        value={campaign}
                                        className="form-control "
                                        autoFocus
                                        onChange={e => setCampaign(e.target.value)}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div style={{width:"50%"}}>
                                <label>Fluxo de Automação(Chatbot)</label>
                                <SelectGroup className="form-select" value={idautomationchat} defaultValue={idautomationchat} required 
                                   errorMessage={{ required: "Por favor, informe um chatbot!" }}  onChange={e => seIdAutomationChat(e.target.value)}>
                                    <option value="0">Selecione</option>
                                    {chatData.map((field, key) => {
                                        return (<option key={key} value={field.idautomationchat}>{field.title}</option>)
                                    })}
                                </SelectGroup>
                            </div>
                        </div>
                        <div>
                            <div style={{display:"flex", gap:"5px"}}>
                                <div style={{width:"100%"}}>
                                    <span>Grupo</span><label className="text-required">*</label>
                                    <select className="form-select" value={group} onChange={e => handleGroup(e.target.value,e.target.options[e.target.selectedIndex].text)} disabled={disableGroup}>
                                        <option value="">Selecione</option>
                                        {groupData.map((item) => {
                                            return(<option value={item.id.user}>{item.name}</option>)
                                        })}
                                    </select>
                                </div>
                                <div style={{display:"flex",alignItems:"end", gap:"5px"}}>
                                    <button type="button" title="Adiciona o grupo selecionado" disabled={!showButtonGroup || showButtonGroupAll} className="btn btn-success" onClick={e => addGroup(e)}><i className="fa fa-plus"/></button>
                                    <button type="button" title="Adicionar todos os grupos" disabled={!showButtonGroup || showButtonGroupAll} className="btn btn-primary" onClick={e => addAllGroup(e)}><i className={`fa ${showButtonGroupAll ? 'fa-spin fa-spinner' : 'fa-list-check'}`}/></button>
                                </div>
                            </div>
                            <div style={{marginTop:"15px"}}>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className="own-acordion">
                                            <span className="w-100">Grupos</span>
                                        </Accordion.Header>
                                        <Accordion.Body className="p-0" style={{height:"150px", overflow:"auto"}}>
                                            <table className="table table-striped w-100" style={{maxHeight:"150px", overflow:"auto"}}>
                                                <tbody>
                                                    {groups.map((_actions, id) => {
                                                        return (
                                                            <tr className="gradeX" key={id}>
                                                                <td style={{width:"100%"}}>
                                                                    {_actions.groupName}
                                                                </td>
                                                                <td style={{width:"100%"}}>
                                                                    {_actions.number}
                                                                </td>
                                                                <td style={{width:"100%"}}>
                                                                    {_actions.inviteCode}
                                                                </td>
                                                                <td>
                                                                    <div style={{display:"flex", gap:"2px"}}>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger btn-sm"
                                                                            onClick={(e) =>
                                                                                removeGroup(_actions.group)
                                                                            }>
                                                                            <i className="fa fa-trash" />
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-warning btn-sm"
                                                                            onClick={(e) =>
                                                                                editGroup(_actions.group)
                                                                            }>
                                                                            <i className="fa fa-edit" />
                                                                        </button>
                                                                    </div>
                            
                                                                </td>
                                                             </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div>
                            <div style={{display:"flex", gap:"5px"}}>
                                <div style={{width:"250px"}}>
                                    <span>Executar quando</span><label className="text-required">*</label>
                                    <select className="form-select" value={executetype} onChange={e => setExecuteType(parseInt(e.target.value))} >
                                        {executeDate.map((item) => {
                                            return(<option value={item.value}>{item.label}</option>)
                                        })}
                                    </select>
                                </div> 

                                {(executetype === 1) && (
                                    <div>
                                       <span>Dia da Semana</span><label className="text-required">*</label>
                                       <select className="form-select" value={week} onChange={e => setWeek(parseInt(e.target.value))} >
                                            {dayWeek.map((item) => {
                                                return(<option value={item.value}>{item.label}</option>)
                                            })}
                                        </select> 
                                    </div>
                                )}
                                 {(executetype === 2) && (
                                    <div style={{display:"flex", gap:"5px"}}>
                                        <div>
                                            <span>Lista de Horários</span><label className="text-required">*</label>
                                            <TextInput
                                                multiline={true}
                                                rows="6"
                                                cols="50"
                                                name="list"
                                                id="list"
                                                type="text"
                                                autoFocus={true}
                                                maxLength={5000}
                                                value={listHours}
                                                onChange={e => setListHours(e.target.value)}
                                                className="form-control "
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <span>Reduzir</span>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"column"}}>
                                                <div style={{width:"120px"}}>
                                                    <TextInput
                                                        name="message"
                                                        id="message"
                                                        type="number"
                                                        value={reduceHours}
                                                        onChange={e => setReduceHours(parseInt(e.target.value))}
                                                        className="form-control "
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div style={{display:"flex", alignItems:"center"}}>
                                                    <span>minutos do tempo informado</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {(executetype !== 2) && (
                                <div style={{width:"120px"}}>
                                    <span>às</span>
                                    <input type="time" value={time} onChange={e => setTime(e.target.value)} className="form-control"/>
                                </div>  
                                )}

                                <div style={{display:"flex", alignItems:`${executetype === 2 ? 'center' : 'end'}`}}>
                                    <button type="button" className="btn btn-success" onClick={e => addSchedule(e)}><i className='fa fa-plus'></i></button>
                                </div>
                                <div style={{display:"flex", alignItems:`${executetype === 2 ? 'center' : 'end'}`}}>
                                    <button type="button" title="Apagar todos os registros de agendamento" className="btn btn-danger" onClick={e => removeAllSchedule(e)}><i className='fa fa-trash'></i></button>
                                </div>
                            </div>
                            <div style={{marginTop:"15px"}}>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className="own-acordion">
                                            <span className="w-100">Quando será executado</span>
                                        </Accordion.Header>
                                        <Accordion.Body className="p-0" style={{height:"150px", overflow:"auto"}}>
                                            <table className="table table-striped w-100" style={{maxHeight:"150px", overflow:"auto"}}>
                                                <tbody>
                                                    {schedule.map((_actions, id) => {
                                                        return (
                                                            <tr className="gradeX" key={id}>
                                                                <td style={{width:"25%"}}>
                                                                    {_actions.executeName}
                                                                </td>
                                                                <td style={{width:"25%"}}>
                                                                    {_actions.week > -1 ? _actions.week : ''}
                                                                </td>
                                                                <td style={{width:"25%"}}>
                                                                    {_actions.time}{_actions.hour}
                                                                </td>
                                                                <td>
                                                                    <div style={{display:"flex", gap:"2px"}}>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger btn-sm"
                                                                            onClick={(e) =>
                                                                                removeSchedule(_actions.time, _actions.executetype)
                                                                            }>
                                                                            <i className="fa fa-trash" />
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-warning btn-sm"
                                                                            onClick={(e) =>
                                                                                editSchedule(_actions.time, _actions.executetype)
                                                                            }>
                                                                            <i className="fa fa-edit" />
                                                                        </button>
                                                                    </div>
                            
                                                                </td>
                                                             </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-campaign-group' className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}