import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';
import loadingButton  from '../layouts/Buttons'
import { create } from 'apisauce';
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalTelgram({showModal, handleCloseModal, data, handleReload,editState}) {
    const [loadingBtn, setLoadingBtn]       = useState(false)
    const [token, setToken]                 = useState('')
    const [description, setDescription]     = useState('')
    const [appkey, setAppKey]               = useState('')
    const [appid, setAppId]                 = useState('')

    const setData = (obj,clear) => {
        setToken(clear ? '' : obj.number) 
        setDescription(clear ? '' : obj.description) 
        setAppKey(clear ? '' : obj.appkey) 
        setAppId(clear ? '' : obj.appid) 
    }
      
    const testTelegram = async() => {
        try {
            const apiTelegram = create({
                baseURL: 'https://api.telegram.org', 
            });

            const response = await apiTelegram.get(`/bot${token}/getMe`);
            if (response.status === 200 && response.data && response.data.ok) {
                ShowMessages({status : 200, message : "Token válido"})
            } else {
                ShowMessages({status : 500, message : "Token inválido"})
            }
        } catch (error) {
            console.error('Erro ao verificar o token:', error.message);
        }
    }

    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-telegram'),document.querySelector('#submit-telegram').children[0].classList,document.querySelector('#submit-telegram').lastChild.nodeValue)
        event.preventDefault()

        try {
            let _data = {token,
                         description,
                         appkey,
                         appid}
              
            if (editState)
                await api.put('/telegram/' + data.idnumber, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-telegram'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-telegram'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-telegram'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/telegram', _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-telegram'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-telegram')) 
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-telegram'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-telegram'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        setData(data,false)
    },[data,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-telegram')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fab fa-telegram"></i> Conexão com Telegram
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div className='box-shadow'>
                        <div className="alert alert-warning" style={{padding:"10px", borderRadius:"5px", display:"flex"}}>
                            <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                                <i className="fab fa-telegram" style={{fontSize:"55px"}}/>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", gap:"20px"}}>
                                <div style={{marginLeft:"15px", display:"flex", flexDirection:"column"}}>
                                    <p style={{fontWeight:"bold", fontSize:"16px"}}>Como obter o token no telegram</p>
                                    <span>1. Abra o telegram, no canto superior direito, clique em <span style={{fontWeight:"bold"}}>nova mensagem</span></span>
                                    <span>2. Na caixa de busca, digite <span style={{fontWeight:"bold"}}>botFather</span></span>
                                    <span>3. Clique no Menu e vá a opção <span style={{fontWeight:"bold"}}>Create a new bot</span></span>
                                    <span>4. Siga as instruções para criar o bot</span>
                                    <span>5. Copie o <span style={{fontWeight:"bold"}}></span>token gerado e cole na área abaixo</span>
                                </div>

                                <div style={{marginLeft:"15px", display:"flex", flexDirection:"column"}}>
                                    <p style={{fontWeight:"bold", fontSize:"16px"}}>Como obter o AppId e o AppHash</p>
                                    <span>1. Clique <a href="https://my.telegram.org/auth" target="_new" style={{textDecoration:"none", cursor:"pointer", fontWeight:"bold"}}>AQUI</a> para se autenticar. </span>
                                    <span>2. Informe os dados solicitados pelo telegram</span>
                                    <span>3. Clique em <span style={{fontWeight:"bold"}}>API development tools</span></span>
                                    <span>4. Copie os campos <span style={{fontWeight:"bold"}}>App app_id</span> e <span style={{fontWeight:"bold"}}>App app_hash</span> e cole abaixo</span>
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex", marginTop:"15px", gap:"5px", flexDirection:"column"}}>
                            <div style={{width:"100%"}}>
                                <label>Descrição</label>
                                <TextInput
                                    name="description"
                                    id="description"
                                    type="text"
                                    maxLength={100}
                                    value={description}
                                    required
                                    errorMessage={{ required: "Por favor, informe uma descrição!" }}
                                    className="form-control"
                                    onChange={e =>  setDescription(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                            <div style={{display:"flex", gap:"5px", flexDirection:"column"}}>
                                <div style={{display:"flex", gap:"5px"}}>
                                    <div style={{width:"100%"}}>
                                        <label>Token</label>
                                        <TextInput
                                            name="token"
                                            id="token"
                                            type="text"
                                            maxLength={300}
                                            value={token}
                                            required
                                            errorMessage={{ required: "Por favor, informe o token!" }}
                                            className="form-control"
                                            onChange={e =>  setToken(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}} title>
                                        <button type="button" className="btn btn-success" style={{marginTop:"17px"}} onClick={e => testTelegram(e)}>{loadingBtn ? <i className="fa fa-spin fa-spinner"/> : <i className="fa fa-link"/>}</button>
                                    </div>
                                </div>
                                <div style={{width:"100%"}}>
                                    <label>AppID</label>
                                    <TextInput
                                        name="appid"
                                        id="appid"
                                        type="text"
                                        maxLength={300}
                                        value={appid}
                                        required
                                        errorMessage={{ required: "Por favor, informe o appID!" }}
                                        className="form-control"
                                        onChange={e =>  setAppId(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                                <div style={{width:"100%"}}>
                                    <label>AppHash</label>
                                    <TextInput
                                        name="appkey"
                                        id="appkey"
                                        type="text"
                                        maxLength={300}
                                        value={appkey}
                                        required
                                        errorMessage={{ required: "Por favor, informe o apphash!" }}
                                        className="form-control"
                                        onChange={e =>  setAppKey(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    <Button type="submit" id="submit-telegram" className={`btn-theme`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button> 
                    <Button type="button" className="btn-ligth-gray" onClick={e => handleCloseModal()}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    )
}