import React, { useState, useRef, useEffect } from 'react';
import ShowMessages from '../../components/Exceptions';
import Swal from 'sweetalert2'
import api from '../../services/api'
import { ReadDataStorage, IDCOMPANY} from '../../services/auth'
import Loading from '../../components/Loading';

const AffiliatePage = () => {
    const [loading, setLoading]             = useState(false)
    const [loadingButton, setLoadingButton] = useState(false)
    const [link, setLink]                   = useState('')
    const [isAffiliate, setIsAffiliate]     = useState(false)
    const affiliateLinkRef                  = useRef(null);

    const handleCopyToClipboard = () => {
        if (affiliateLinkRef.current) {
            affiliateLinkRef.current.select();
            document.execCommand('copy');
            ShowMessages({ status: 200, message: 'Link copiado com sucesso!' });
        }
    };

    const handleAffiliate = () => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente se gerar o seu link de afiliado?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                setLoadingButton(true)
                await api.post('/affiliate/') 
                         .then((response) => {  
                            ShowMessages(response)
                            setLink(response.data.link)
                            setIsAffiliate(true)
                            setLoadingButton(false)
                        }).catch(error => {
                            ShowMessages(error)
                        })  
            }
            
        })
    }

    useEffect(() => {
        const getCompany = async() => {
            setLoading(true)
            await api.get('/company/' + ReadDataStorage(IDCOMPANY))
                     .then((response) => {
                        setLink(response.data.linkaffiliate)
                        setIsAffiliate(response.data.isaffiliate) 
                        setLoading(false)
                     }).catch(error => {
                        ShowMessages(error)
                      })  
        }
        getCompany()
    },[])

    return (
        <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"80vh"}}>
            {loading && (
                <Loading loading={loading} /> 
            )}
            <div className={`box-shadow ${(loading ? "hidden" : "")}`} style={{ marginTop: '15px', width: '522px', padding: '30px 20px', paddingBottom: '40px' }}>
                {!isAffiliate && (
                    <div style={{borderRadius:"5px", border:"1px dashed #cacaca", padding:"10px", fontSize:"16px", marginBottom:"15px"}} className='alert-primary'>
                        Afilie-se <b>agora mesmo </b> na plataforma de automação que mais
                        cresce no Brasil. Para afiliar-se é muito fácil, basta clicar no botão
                        abaixo e <b>gerar o seu link de afiliado</b>.
                    </div>
                )}
   
                <div className="d-flex justify-content-center align-items-center flex-column">
                    {isAffiliate ? 
                        (<div style={{display:"flex", width:"100%", flexDirection:"column"}}>
                            <span>Link de Afiliado</span>
                            <div style={{display:"flex", gap:"2px",width:"100%"}}>
                                <input ref={affiliateLinkRef} type="text" className="form-control form-control-sm" value={link} readOnly />
                                <button className="btn btn-success btn-sm" type="button" onClick={handleCopyToClipboard} title="Click para copiar o link">
                                    <i className="fa fa-copy"></i>
                                </button>
                            </div>
                        </div>)
                    : 
                        (<button className="btn btn-success" type="button" onClick={e => handleAffiliate()} disabled={loadingButton}>
                            <i className={loadingButton ? 'fa fa-spin fa-spinner' : 'fa fa-handshake'} style={{ marginRight: '3px' }}></i>
                            AFILIAR-SE AGORA
                        </button>)
                    }
                </div>
                <p className="card-text mt-3" style={{gap:"10px", display:"flex", flexDirection:"column"}}>
                    {!isAffiliate && (
                        <div style={{borderRadius:"5px", border:"1px dashed #cacaca", padding:"10px", fontSize:"16px"}} className='alert-primary'>
                            Após a solicitação, você receberá um <b>link de divulgação</b>, e
                            terá uma área exclusiva em seu painel, onde estara todo o controle
                            sobre suas indicações, extrato de pagamentos, solicitações de saque
                            e muito mais... 
                        </div>
                    )}
                    <div style={{borderRadius:"5px", border:"1px dashed #cacaca", padding:"10px", fontSize:"16px", display:"flex", flexDirection:"column"}} className='alert alert-success'>
                        <span style={{fontWeight:"bold"}}>O que recebo por indicar?</span>
                        <span>Nosso modelo de negócio é pago por
                        recorrência, sempre que o seu indicado efetua um pagamento em nossa
                        plataforma, o valor de <b>10% é pago pela indicação</b>, caso o
                        cliente fique conosco por um período de <b>12 meses</b>, você também
                        receberá sua comissão recorrente por 12 meses.</span>
                    </div>
                    <div style={{borderRadius:"5px", border:"1px dashed #cacaca", padding:"10px", fontSize:"16px"}} className='alert-primary'>
                        Após o valor creditado em seu usuário, basta <b>solicitar o saque</b>, e em até <b>24 horas úteis </b>o valor solicitado estará em sua conta cadastrada.
                    </div>
                </p>
            </div>
        </div>
    );
};

export default AffiliatePage;
