import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';
import loadingButton  from '../layouts/Buttons'
import { create } from 'apisauce';
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalEmail({showModal, handleCloseModal, data, handleReload,editState}) {
    const [loadingBtn, setLoadingBtn]     = useState(false)
    const [description, setDescription]   = useState('')
    const [smtp, setSMTP]                 = useState('')
    const [imap, setIMAP]                 = useState('')
    const [password, setPassword]         = useState('')
    const [user, setUser]                 = useState('')
    const [portSMTP, setPortSMTP]         = useState('')
    const [portIMAP, setPortIMAP]         = useState('')
    const [tls, setTLS]                   = useState('')

    const setData = (obj,clear) => {
        setDescription(clear ? '' : obj.description)
        setSMTP(clear ? '' : obj.servername) 
        setIMAP(clear ? '' : obj.imap) 
        setPassword(clear ? '' : obj.password) 
        setUser(clear ? '' : obj.number) 
        setPortSMTP(clear ? '' : obj.port || '') 
        setPortIMAP(clear ? '' : obj.portimap || '') 
        setTLS(clear ? '' : obj.tls || false)
    }
      
    const testEmail = async() => {
        try {
            let _data = {
                smtp,
                password,
                user,
                portSMTP,
                portIMAP,
                imap,
                tls
            }
            setLoadingBtn(true)
            await api.post('/api/v1/getStatusEmail', _data)
                     .then(response => {
                        ShowMessages(response)
                        setLoadingBtn(false)
                     })
                     .catch( error => {
                        setLoadingBtn(false)
                        ShowMessages(error) 
                     })
        } catch (error) {
            console.error('Erro ao verificar o token:', error.message);
        }
    }

    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-email'),document.querySelector('#submit-email').children[0].classList,document.querySelector('#submit-email').lastChild.nodeValue)
        event.preventDefault()

        try {
            let _data = {smtp,
                         description,
                         user,
                         password,
                         portSMTP,
                         portIMAP,
                         imap,
                         tls}
              
            if (editState)
                await api.put('/email/' + data.idnumber, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-email'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-email'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-email'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/email', _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-email'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-email')) 
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-email'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-email'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        setData(data,false)
    },[data,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-email')
    } ,[])

    return (
        <Modal size='md' dialogClassName="width600" show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-envelope"></i> Conexão com Email
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div className='box-shadow'>
                        <div style={{display:"flex", gap:"5px", flexDirection:"column"}}>
                            <div style={{width:"100%"}}>
                                <label>Descrição</label>
                                <TextInput
                                    name="description"
                                    id="description"
                                    type="text"
                                    maxLength={100}
                                    value={description}
                                    required
                                    errorMessage={{ required: "Por favor, informe uma descrição!" }}
                                    className="form-control"
                                    onChange={e =>  setDescription(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                            <div style={{display:"flex", gap:"5px", flexDirection:"column"}}>
                                <div style={{display:"flex", gap:"5px"}}>
                                    <div style={{width:"100%"}}>
                                        <label>SMTP</label>
                                        <TextInput
                                            name="smtp"
                                            id="smtp"
                                            type="text"
                                            maxLength={300}
                                            value={smtp}
                                            className="form-control"
                                            onChange={e =>  setSMTP(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                    <div style={{width:"100%"}}>
                                        <label>IMAP</label>
                                        <TextInput
                                            name="imap"
                                            id="imap"
                                            type="text"
                                            maxLength={300}
                                            value={imap}
                                            className="form-control"
                                            onChange={e =>  setIMAP(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                    <div style={{width:"200px"}}>
                                        <label>Porta SMTP</label>
                                        <TextInput
                                            name="port"
                                            id="port"
                                            type="number"
                                            maxLength={5}
                                            value={portSMTP}
                                            className="form-control"
                                            onChange={e =>  setPortSMTP(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                    <div style={{width:"200px"}}>
                                        <label>Porta IMAP</label>
                                        <TextInput
                                            name="port"
                                            id="port"
                                            type="number"
                                            maxLength={5}
                                            value={portIMAP}
                                            className="form-control"
                                            onChange={e =>  setPortIMAP(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div>
                                <div style={{display:"flex", gap:"5px"}}>
                                    <div style={{width:"50%"}}>
                                        <label>Usuário</label>
                                        <TextInput
                                            name="user"
                                            id="user"
                                            type="text"
                                            maxLength={300}
                                            value={user}
                                            required
                                            errorMessage={{ required: "Por favor, informe o apphash!" }}
                                            className="form-control"
                                            onChange={e =>  setUser(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                    <div style={{width:"25%"}}>
                                        <label>Senha</label>
                                        <TextInput
                                            name="password"
                                            id="password"
                                            type="password"
                                            maxLength={300}
                                            value={password}
                                            required
                                            errorMessage={{ required: "Por favor, informe a senha!" }}
                                            className="form-control"
                                            onChange={e =>  setPassword(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center",marginTop:"10px"}}> 
                                        <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                            <input type="checkbox" checked={tls} value={tls} onChange={(e) => setTLS(!tls)}/>
                                            <span style={{ marginTop: "5px" }}></span>
                                        </label>
                                        <span style={{marginLeft:"5px",marginTop:"5px"}}>TLS?</span>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}} title="Testar Conexao">
                                        <button disabled={loadingBtn} type="button" className="btn btn-success" style={{marginTop:"17px"}} onClick={e => testEmail(e)}>{loadingBtn ? <i className="fa fa-spin fa-spinner"/> : <i className="fa fa-link"/>}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop:"10px"}}>
                            <div className="alert alert-warning">
                                <div style={{display:"flex", flexDirection:"column", gap:"2px"}}>
                                    <span>Para Emails do <span style={{fontWeight:"bold"}}>GMAIL</span>, acesse o link abaixo para ativar as opção de SMTP e IMAP.</span>
                                    <a href="https://mail.google.com/mail/u/1/#settings/general">Configurações de Conta do GMail</a>
                                    <span>Após autorizar o uso do SMTP e IMAP, acesse o link abaixo para ativar a opção de <span style={{fontWeight:"bold"}}>VERIFICAÇÃO DE 2 ETAPAS</span></span>
                                    <a href="https://myaccount.google.com/u/1/security?hl=pt_BR">Configurações de Segurança</a>
                                    <span>Feito isso, acesse novamente a opção de <span style={{fontWeight:"bold"}}>VERIFICAÇÃO DE 2 ETAPAS</span>, vá até o final da página e acesse <span style={{fontWeight:"bold"}}>SENHAS DE APP</span></span>
                                    <span>Informe o nome do App(Qualquer nome a sua escolha) e clique em <span style={{fontWeight:"bold"}}>CRIAR</span></span>
                                    <span>Irá aparecer uma tela com a senha gerada, essa senha e a usada para conectar o seu email.</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    <Button type="submit" id="submit-email" className={`btn-theme`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button> 
                    <Button type="button" className="btn-ligth-gray" onClick={e => handleCloseModal()}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    )
}