import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalCampaignNumbers({showModal, handleCloseModal, data, handleReload}) {
    const [loading, setLoading]      = useState(false)

    const [selectNumbers,setSelectNumbers] = useState([])
    const [dataNumbers, setDataNumbers]    = useState([])
    const [number, setNumber]              = useState('')

    const getData = async() => {
        try {
            await api.get('/getAllMyWhatsAppNumbers')
                    .then(response => {
                        setDataNumbers(response.data)     
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            console.log(response) 
            ShowMessages(response)
        }
    }

    const addNumber = async() => {
        if (number === '') {
            ShowMessages({status : 500, message : "Número não informado"})
            return
        }

        let _data = selectNumbers.filter((_item) => {return(_item.number === number)})

        if (_data.length > 0) {
            ShowMessages({status : 500, message : "Número já informado"})
            return
        }

        setSelectNumbers(selectNumbers => selectNumbers.concat({number})) 

        setNumber('')
    }

    const setData = (obj) => {
        setSelectNumbers(obj.numbers || []) 
    }

    const removeNumber = (_number) => {
        const _remove = selectNumbers.filter((t, i) => {
            if (t.number !== _number)
                return true;
        
            return false;
        });

        setSelectNumbers(_remove)
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-campaign-numbers'),document.querySelector('#submit-campaign-numbers').children[0].classList,document.querySelector('#submit-campaign-numbers').lastChild.nodeValue)
        try {
            let _data = {selectNumbers}

            await api.put('/campaign-number/' + data.idcampaign, _data)
                     .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else
                                 loadingButton(false,document.querySelector('#submit-campaign-numbers'))
                         } else
                             loadingButton(false,document.querySelector('#submit-campaign-numbers'))
                     })
                     .catch( error => {
                         loadingButton(false,document.querySelector('#submit-campaign-numbers'))
                         ShowMessages(error) 
                     })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-campaign-numbers'))
            ShowMessages(response)
        }
    }
    
    useEffect(() => {
        setData(data)
    },[data,showModal])
   
    useEffect(() => {
        getData()
        setStatistic('modals\\modal-campaign-numbers')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-user-tie"></i> Propriedades de Números
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading}/>
                    )}
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div style={{display:"flex"}}>
                            <div>
                                <div style={{display:"flex"}}>
                                    <div style={{width:"200px"}}>
                                        <span>Número</span>
                                        <select className="form-select" value={number} onChange={e => setNumber(e.target.value)}>
                                            <option value="">Selecione</option>
                                            {dataNumbers.map((item) => {
                                                return(<option value={item.number}>{item.phone_format}</option>)
                                            })}
                                        </select>
                                    </div>
                                                
                                    <div style={{display:"flex",alignItems:"center", marginLeft:"10px", width:"40px"}}>
                                        <button type="button" className="btn btn-success" style={{marginTop:"18px"}} onClick={e => addNumber()}><i className="fa fa-plus"/></button>
                                    </div>
                                </div>
                                <div style={{marginTop:"5px", width:"247px"}}>
                                    <table className="table table-striped w-100">
                                        <thead className="thead-theme">
                                            <tr>
                                                <td>Números</td>
                                                <td style={{textAlign:"center"}}>...</td>
                                            </tr>
                                        </thead>  
                                        <tbody>                        
                                            {selectNumbers.map((_actions,id) => {
                                                return(<tr className="gradeX" key={id}>
                                                            <td style={{verticalAlign: "middle"}}>{_actions.number}</td>
                                                            <td style={{textAlign:"center",verticalAlign: "middle"}}>
                                                                <button type='button' className="btn btn-danger btn-sm" onClick={e => removeNumber(_actions.number)}><i className="fa fa-trash"/></button>
                                                            </td>
                                                        </tr>)
                                                        }) }
                                        </tbody> 
                                    </table>
                                </div>
                            </div>
                        </div>                              
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-campaign-numbers" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}