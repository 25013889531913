import api from './api'

export const checkPlain = async(idcompany) =>{
    try {
        return await api.post('/checkPlain')
                        .then((response) =>{
                            return response.data
                        })
                        .catch((error) => {
                            return { accept : false}
                        })
    } catch (error) {
        return { accept : false}
    }
}