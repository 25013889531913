import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'
import { getFields } from "./../../components/Utils";

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalChatBotWebHook({showModal, handleCloseModal,data, handleReload}) {
    const [loading, setLoading]   = useState(false)

    const [URL, setURL]           = useState('')

    const [fields, setFields]     = useState([])

    const [tag, setTag]           = useState('')
    const [tagMatch, setTagMatch] = useState('')
    const [tags, setTags]         = useState([])

    const setData = (obj,clear) => {
        setURL(obj.webhook || '')
        setFields(getFields())
        //setTags([obj?.webhook_fields] || [])
        setTags(obj?.webhook_fields || [])
    }

    const handleSubmit = async(event) => {
        event.preventDefault()

        let check = tags.filter(_item => {
            return _item.tag === '[PHONE]'
        })

        if (check.length <= 0) {
            ShowMessages({status : 500, message : "A tag PHONE e obrigatório no uso do webhook"})
            return
        }

        loadingButton(true,document.querySelector('#submit-chatbot-webhook'),document.querySelector('#submit-chatbot-webhook').children[0].classList,document.querySelector('#submit-chatbot-webhook').lastChild.nodeValue)
        try {
            let _data = {tags}

            await api.put('/chatbot/' + data.idautomationchat, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else
                                    loadingButton(false,document.querySelector('#submit-chatbot-webhook'))
                            } else
                                loadingButton(false,document.querySelector('#submit-chatbot-webhook'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-chatbot-webhook'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-chatbot-webhook'))
            ShowMessages(response)
        }
    }
    
    const handleCopyCode = () => {
        navigator.clipboard.writeText(URL);
        ShowMessages({status : 200,message : "URL copiada com sucesso!"})
    }

    const removeTag = (_tag) => {
        const _remove = tags.filter((t, i) => {
            if (t.tag !== _tag)
                return true;
        
            return false;
        });
        setTags(_remove)
    }

    const addTag = (e) => {
        e.preventDefault()

        if (tag === '') {
            ShowMessages({status : 500, message : "Tag não informada"})
            return
        }

        setTags(tags =>  tags.concat({
            tag,
            tagMatch
        }))
        setTag('')
        setTagMatch('')
    }

    useEffect(() => {
        setData(data,false)
    },[data,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-chatbot-webhook')
    } ,[])

    return (
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="width650" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} className="modal-content" setFocusOnError={true}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-robot"></i> Webhook
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div className={`box-shadow ${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>URL</label>
                            <div style={{display:"flex", gap:"5px"}}>
                                <div className="input-group">
                                    <TextInput  
                                        name="URL"
                                        id="URL"
                                        type="text"
                                        autoFocus={true}
                                        readonly
                                        value={URL}
                                        className="form-control "
                                        autoComplete="off"/>
                                </div>
                                <button type="button" className='btn btn-success' onClick={e => handleCopyCode()}>
                                    <i className='fa fa-copy'/>
                                </button>
                            </div>
                        </div>  
                        <div style={{display:"flex", marginTop:"15px", borderRadius:"5px", border:"1px dashed #cacaca", padding:"10px", flexDirection:"column"}}>
                            <div style={{marginTop:"-20px"}}>
                                <span style={{fontWeight:"bold", background:"#fff"}}>Opções de vínculo de campos</span>
                            </div>
                            <div style={{display:"flex", gap:"5px", marginTop:"15px"}}>
                                <div>
                                    <label>TAG</label> 
                                    <select className="form-select" value={tag} onChange={e => setTag(e.target.value)}>
                                        {
                                            fields.map(_item => {
                                                return (
                                                    <option value={_item.value}>{_item.label}</option>
                                                )
                                            })
                                       }
                                    </select>   
                                </div>
                                <div className="input-group">
                                    <label>TAG Correspondente</label> 
                                    <TextInput  
                                        name="name1"
                                        id="name1"
                                        type="text"
                                        value={tagMatch}
                                        onChange={e => setTagMatch(e.target.value)}
                                        className="form-control "
                                        autoComplete="off"/>
                                </div>
                                <div style={{display:"flex",alignItems:"end"}}>
                                    <button type="button" className="btn btn-success" onClick={e => addTag(e)}>
                                        <i className='fa fa-plus'/>
                                    </button>
                                </div>
                            </div>
                            <div style={{marginTop:"5px"}}>
                                <table className="table table-striped w-100">
                                    <thead className="thead-theme">
                                        <tr className='head-th'>
                                            <td>TAG</td>
                                            <td>TAG Correspondente</td>
                                            <td style={{textAlign:"center"}}>...</td>
                                        </tr>
                                    </thead>  
                                    <tbody>                   
                                        {tags.map((_tag,id) => {
                                                    return(<tr className="gradeX" key={id}>
                                                                <td style={{verticalAlign: "middle"}}>{_tag?.tag}</td>
                                                                <td style={{verticalAlign: "middle"}}>{_tag?.tagMatch}</td>
                                                                <td style={{textAlign:"center",verticalAlign: "middle"}}>
                                                                    <button type='button' className="btn btn-danger btn-sm" onClick={e => removeTag(_tag.tag)} style={{marginLeft:"5px"}}><i className="fa fa-trash"/></button>
                                                                </td>
                                                        </tr>)
                                                    }) }
                                            </tbody> 
                                        </table>
                            </div>
                        </div>                                 
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-chatbot-webhook" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}