import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog, Accordion } from "react-bootstrap";
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";
import api from '../../../services/api'
import { IDCOMPANY, ReadDataStorage } from './../../../services/auth'
import Loading from '../../../components/Loading';
import EmojiPicker from 'emoji-picker-react';
import EMOJI from './../../../assets/Emoji.png'

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalButtonNode({ nodeId, showModal, handleCloseModal, nodeData }) {
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [footer, setFooter] = useState('');
    const [buttons, setButtons] = useState([])
    const [settings, setSettings] = useState({})
    const [closeInteraction, setCloseInteration] = useState(30)
    const [contentButton, setContentButton] = useState('')
    const [redirectLead, setRedirectLead] = useState(3)
    const [showEmojiTitle, setShowEmojiTitle] = useState(false)
    const [showEmojiText, setShowEmojiText] = useState(false)
    const [showEmojiClose, setShowEmojiClose] = useState(false)

    const [messageNotInteraction, setMessageNotInteraction] = useState('Por falta de interação, iremos encerrar a nossa conversa... Até logo 👋')
    const reactFlowInstance = useReactFlow();

    useEffect(() => {
        const getSettings = async () => {
            setLoading(true)
            await api.get('/company/' + ReadDataStorage(IDCOMPANY))
                .then(async (response) => {
                    setSettings(response.data.settings)
                    setTitle(nodeData.title);
                    setBody(nodeData.body)
                    setFooter(nodeData.footer)
                    setButtons((nodeData.buttons || []).sort((a, b) => a.order - b.order))
                    setCloseInteration(nodeData.closeInteraction || 30)
                    setRedirectLead(nodeData.redirectLead || 3)
                    setMessageNotInteraction(nodeData.messageNotInteraction || 'Por falta de interação, iremos encerrar a nossa conversa... Até logo 👋')
                    setLoading(false)
                }).catch((error) => {
                    ShowMessages(error)
                })
        }

        getSettings()
    }, [nodeData]);

    const getID = (arr, chave) => {
        arr.sort(function (a, b) { return a[chave] - b[chave]; }); // ordena o array em ordem crescente

        let proximoNumero = 4;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][chave] === proximoNumero) {
                proximoNumero++;
            } else if (arr[i][chave] > proximoNumero) {
                return proximoNumero;
            }
        }
        return proximoNumero; // se não houver sequência vazia, retorna o próximo número na sequência
    }

    const addItemText = () => {

        const _list = buttons.some((item) => {
            if (item.body === contentButton) {
                return true;
            }
            return false;
        });

        if (contentButton === '') {
            ShowMessages({ status: 500, message: "Descrição não informada" });
            return;
        }

        if (buttons.length >= 10) {
            ShowMessages({
                status: 500,
                message: "Permitido apenas 10 botões",
            });
            return;
        }

        if (_list) {
            ShowMessages({ status: 500, message: "Botão já existe" });
            return;
        }

        let _buttons = buttons.filter(_button => {
            return _button?.isRedirect === true
        })

        if (_buttons.length <= 0) {
            let buttonError = { body: 'Em caso de erro ir para...', id: getID(buttons, 'id'), order: buttons.length, isRedirect: true }
            setButtons([...buttons, buttonError, { body: contentButton, id: getID([...buttons, buttonError], 'id'), order: buttons.length + 1, isRedirect: false }])
        } else
            setButtons([...buttons, { body: contentButton, id: getID(buttons, 'id'), order: buttons.length, isRedirect: false }]);
        setContentButton('')
    };

    const removeItemText = (_word) => {
        let _list = buttons.filter((item) => {
            return item.body !== _word;
        });

        setButtons(_list);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (redirectLead < 0) {
            ShowMessages({ status: 500, message: 'O número de tentativas não pode ser menor que 0.' })
            return
        }

        if (redirectLead > 30) {
            ShowMessages({ status: 500, message: 'O número de tentativas não pode ser maior que 30.' })
            return
        }

        if (closeInteraction < 10) {
            ShowMessages({ status: 500, message: 'O encerramento da interação não pode ser menor que 10 minutos.' })
            return
        }

        if (closeInteraction > 10080) {
            ShowMessages({ status: 500, message: 'O encerramento da interação não pode ser maior que 7 dias.' })
            return
        }

        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node: parseInt(nodeData.node),
                            parentId: parseInt(nodeData.parentId),
                            targetId: parseInt(nodeData.targetId),
                            closeInteraction,
                            body,
                            footer,
                            title,
                            buttons,
                            messageNotInteraction,
                            redirectLead,
                            objectType: 5
                        }
                    }
                }

                return node;
            })
        )

        handleCloseModal();
    };

    const upBottom = (order) => {
        let _buttonsActual = buttons.find(item => item.order === order)
        let _buttonsPior = buttons.find(item => item.order === order - 1)

        _buttonsActual.order--
        _buttonsPior.order++

        setButtons([...buttons.sort((a, b) => a.order - b.order)])
    }

    const downBottom = (order) => {
        let _buttonsActual = buttons.find(item => item.order === order)
        let _buttonsNext = buttons.find(item => item.order === order + 1)

        _buttonsActual.order++
        _buttonsNext.order--

        setButtons([...buttons.sort((a, b) => a.order - b.order)])
    }

    return (
        <Modal size="md" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width500" animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-mobile-screen"></i> Botões
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {loading ? (
                            <Loading loading={loading} addClass="heigth390px" />
                        ) : (
                            <div className="box-shadow" style={{ padding: "10px" }}>
                                <div className="mb-1">
                                    <div style={{ width: "100%" }}>
                                        <span>Título do botão</span>
                                        <label className="text-required">*</label>
                                        <div className="input-group">
                                            <textarea
                                                name="title"
                                                id="title"
                                                type="text"
                                                autoFocus={true}
                                                maxLength={100}
                                                value={title}
                                                onFocus={e => {
                                                    setShowEmojiText(false)
                                                    setShowEmojiTitle(false)
                                                    setShowEmojiClose(false)
                                                }}
                                                className="form-control "
                                                onChange={(e) => setTitle(e.target.value)}
                                                autoComplete="off" />
                                        </div>
                                        <div>
                                            <div className="emoji-button" onClick={e => setShowEmojiTitle(!showEmojiTitle)}>
                                                <div>
                                                    <img src={EMOJI} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        {showEmojiTitle && (
                                            <div style={{ position: "fixed", top: "155px", left: "500px" }}>
                                                <EmojiPicker onEmojiClick={e => setTitle(title + ' ' + e.emoji)} searchPlaceholder="Pesquisar" previewConfig={{
                                                    showPreview: false
                                                }} categories={[
                                                    {
                                                        category: 'smileys_people',
                                                        name: 'Pessoas e Rostos',
                                                    },
                                                    {
                                                        category: 'animals_nature',
                                                        name: 'Animais e Natureza',
                                                    },
                                                    {
                                                        category: 'food_drink',
                                                        name: 'Comida e Bebida',
                                                    },
                                                    {
                                                        category: 'travel_places',
                                                        name: 'Viagem e Lugares',
                                                    },
                                                    {
                                                        category: 'activities',
                                                        name: 'Atividades',
                                                    },
                                                    {
                                                        category: 'objects',
                                                        name: 'Objetos',
                                                    },
                                                    {
                                                        category: 'symbols',
                                                        name: 'Símbolos',
                                                    },
                                                    {
                                                        category: 'flags',
                                                        name: 'Bandeiras',
                                                    }
                                                ]} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {settings?.officialAPI && (
                                    <>
                                        <div className="mb-1">
                                            <div style={{ width: "100%" }}>
                                                <span>Descrição do Botão</span>
                                                <div className="input-group">
                                                    <textarea
                                                        name="body"
                                                        id="body"
                                                        type="text"
                                                        onFocus={e => {
                                                            setShowEmojiText(false)
                                                            setShowEmojiTitle(false)
                                                            setShowEmojiClose(false)
                                                        }}
                                                        maxLength={150}
                                                        value={body}
                                                        className="form-control "
                                                        onChange={(e) => setBody(e.target.value)}
                                                        autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div style={{ width: "100%" }}>
                                                <span>Rodapé</span>
                                                <div className="input-group">
                                                    <textarea
                                                        name="footer"
                                                        id="footer"
                                                        type="text"
                                                        maxLength={150}
                                                        value={footer}
                                                        className="form-control "
                                                        onChange={(e) => setFooter(e.target.value)}
                                                        autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div>
                                    <div style={{ padding: "5px", border: "1px dashed #cacaca", marginTop: "5px", borderRadius: "5px" }}>
                                        <div className="my-1 d-flex flex-direction-row " style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ width: "100%" }}>
                                                    <span>Texto do botão</span>
                                                    <label className="text-required">*</label>
                                                    <div className="input-group">
                                                        <textarea
                                                            name="itemText"
                                                            id="itemText"
                                                            type="text"
                                                            onFocus={e => {
                                                                setShowEmojiText(false)
                                                                setShowEmojiTitle(false)
                                                                setShowEmojiClose(false)
                                                            }}
                                                            rows={settings?.officialAPI ? 0 : 2}
                                                            maxLength={settings?.officialAPI ? 50 : 500}
                                                            value={contentButton}
                                                            className="form-control "
                                                            onChange={(e) => setContentButton(e.target.value)}
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center" style={{ marginLeft: "5px", alignItems: "center", display:"flex", flexDirection:"column" }}>
                                                    <button
                                                        type="button"
                                                        title="Clique para inserir a palavra"
                                                        className="btn btn-success"
                                                        onClick={(e) => addItemText()}
                                                    >
                                                        <i className="fa fa-plus" />
                                                    </button>
                                                    <div>
                                                        <div className="emoji-button" onClick={e => setShowEmojiText(!showEmojiText)}>
                                                            <div>
                                                                <img src={EMOJI} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {showEmojiText && (
                                                        <div style={{ position: "fixed", top: "155px", left: "500px" }}>
                                                            <EmojiPicker onEmojiClick={e => setContentButton(contentButton + ' ' + e.emoji)} searchPlaceholder="Pesquisar" previewConfig={{
                                                                showPreview: false
                                                            }} categories={[
                                                                {
                                                                    category: 'smileys_people',
                                                                    name: 'Pessoas e Rostos',
                                                                },
                                                                {
                                                                    category: 'animals_nature',
                                                                    name: 'Animais e Natureza',
                                                                },
                                                                {
                                                                    category: 'food_drink',
                                                                    name: 'Comida e Bebida',
                                                                },
                                                                {
                                                                    category: 'travel_places',
                                                                    name: 'Viagem e Lugares',
                                                                },
                                                                {
                                                                    category: 'activities',
                                                                    name: 'Atividades',
                                                                },
                                                                {
                                                                    category: 'objects',
                                                                    name: 'Objetos',
                                                                },
                                                                {
                                                                    category: 'symbols',
                                                                    name: 'Símbolos',
                                                                },
                                                                {
                                                                    category: 'flags',
                                                                    name: 'Bandeiras',
                                                                }
                                                            ]} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: "15px" }}>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className="own-acordion">
                                                        <span className="w-100">Itens</span>
                                                    </Accordion.Header>
                                                    <Accordion.Body className="p-0" style={{ height: "150px", overflow: "auto" }}>
                                                        <table className="table table-striped w-100" style={{ maxHeight: "150px", overflow: "auto" }}>
                                                            <tbody>
                                                                {buttons.map((_actions, id) => {
                                                                    if (!_actions?.isRedirect) {
                                                                        return (
                                                                            <tr className="gradeX" key={id}>
                                                                                <td style={{ width: "100%" }}>
                                                                                    {_actions.body}
                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ display: "flex", gap: "3px" }}>
                                                                                        {id > 0 && (
                                                                                            <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { upBottom(_actions.order) }}>
                                                                                                <i className="fa fa-arrow-up" />
                                                                                            </button>
                                                                                        )}
                                                                                        {id < buttons.length - 1 && (
                                                                                            <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { downBottom(_actions.order) }}>
                                                                                                <i className="fa fa-arrow-down" />
                                                                                            </button>
                                                                                        )}
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-danger btn-sm"
                                                                                            onClick={(e) =>
                                                                                                removeItemText(_actions.body)
                                                                                            }
                                                                                        >
                                                                                            <i className="fa fa-trash" />
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }

                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ display: "flex", gap: "5px", alignItems: "center", marginTop: "15px" }}>
                                        <span>Redirecionar o Lead após</span>
                                        <div style={{ width: "80px" }}>
                                            <input value={redirectLead} onChange={e => setRedirectLead(e.target.value)} className="form-control" type="number" />
                                        </div>

                                        <span> erros na escolha da opção</span>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ display: "flex", gap: "5px", alignItems: "center", marginTop: "15px" }}>
                                        <span>Encerrar o atendimento após</span>
                                        <div style={{ width: "80px" }}>
                                            <input value={closeInteraction} onChange={e => setCloseInteration(e.target.value)} className="form-control" type="number" />
                                        </div>

                                        <span> minutos(s) sem interação</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "5px", marginTop: "15px" }}>
                                        <span>Mensagem de encerramento por falta de interação</span>
                                        <textarea className="form-control" onFocus={e => {
                                                    setShowEmojiText(false)
                                                    setShowEmojiTitle(false)
                                                    setShowEmojiClose(false)
                                                }} onChange={e => setMessageNotInteraction(e.target.value)} value={messageNotInteraction}></textarea>
                                                <div>
                                                        <div className="emoji-button" onClick={e => setShowEmojiClose(!showEmojiClose)}>
                                                            <div>
                                                                <img src={EMOJI} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {showEmojiClose && (
                                                        <div style={{ position: "fixed", top: "260px", left: "500px" }}>
                                                            <EmojiPicker onEmojiClick={e => setMessageNotInteraction(messageNotInteraction + ' ' + e.emoji)} searchPlaceholder="Pesquisar" previewConfig={{
                                                                showPreview: false
                                                            }} categories={[
                                                                {
                                                                    category: 'smileys_people',
                                                                    name: 'Pessoas e Rostos',
                                                                },
                                                                {
                                                                    category: 'animals_nature',
                                                                    name: 'Animais e Natureza',
                                                                },
                                                                {
                                                                    category: 'food_drink',
                                                                    name: 'Comida e Bebida',
                                                                },
                                                                {
                                                                    category: 'travel_places',
                                                                    name: 'Viagem e Lugares',
                                                                },
                                                                {
                                                                    category: 'activities',
                                                                    name: 'Atividades',
                                                                },
                                                                {
                                                                    category: 'objects',
                                                                    name: 'Objetos',
                                                                },
                                                                {
                                                                    category: 'symbols',
                                                                    name: 'Símbolos',
                                                                },
                                                                {
                                                                    category: 'flags',
                                                                    name: 'Bandeiras',
                                                                }
                                                            ]} />
                                                        </div>
                                                    )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-action-info">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    );
}
