import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";
import FileVisualizer from "../../../components/FileVisualizer";
import { convertToBase64 } from "../../../components/Utils";
import Dropzone from "../../../components/dropzone";
import PhoneInput from 'react-phone-number-input'

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalContactNode({ nodeId, showModal, handleCloseModal, nodeData, editData }) {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [origin, setOrigin] = useState(0)
    const [name, setName] = useState('')
    const [whatsapp, setWhatsapp] = useState('')

    const reactFlowInstance = useReactFlow();

    const handleFiles = async (arrSelected) => {
        if (arrSelected.length !== 0) {
            let files = [...selectedFiles];

            for (let i = 0; i < arrSelected.length; i++) {
                console.log(arrSelected[i])
                files.push({
                    src: URL.createObjectURL(arrSelected[i]),
                    altText: `${URL.createObjectURL(arrSelected[i])}`,
                    file: URL.createObjectURL(arrSelected[i]),
                    name: arrSelected[i].name,
                    size: arrSelected[i].size,
                    type: arrSelected[i].type,
                    base64: await convertToBase64(arrSelected[i])
                });
            }

            setSelectedFiles(files);
        }
    };

    useEffect(() => {
        setSelectedFiles(nodeData.files)
        setOrigin(nodeData.origin || 0)
        setName(nodeData.name)
        setWhatsapp(nodeData.whatsapp)
    }, [nodeData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(selectedFiles.length)
        if (selectedFiles.length > 5) {
            ShowMessages({
                status: 500,
                message: "Quantidade de arquivos deve ser até 5, para inserir um número maior, crie um outro objeto de arquivos!",
            });
            return;
        }

        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node: parseInt(nodeData.node),
                            parentId: parseInt(nodeData.parentId),
                            targetId: parseInt(nodeData.targetId),
                            files: selectedFiles,
                            origin,
                            whatsapp,
                            name,
                            objectType: 4
                        },
                    };
                }

                return node;
            })
        );

        handleCloseModal();
    };

    return (
        <Modal size="md" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width500" animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-address-card"></i> Contato
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box-shadow" style={{ padding: "10px" }}>
                        <div className="mb-3">
                            <div style={{ borderRadius: "5px", border: "1px dashed #cacaca", padding: "10px", marginTop: "15px", display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", gap: "5px", cursor: "pointer" }} onClick={e => setOrigin(0)}>
                                    <input type="radio" className="form-check-input" checked={origin === 0} value={origin} />
                                    <label>Arquivo de Contato</label>
                                </div>
                                <div style={{ display: "flex", gap: "5px", cursor: "pointer" }} onClick={e => setOrigin(1)}>
                                    <input type="radio" className="form-check-input" checked={origin === 1} value={origin} />
                                    <label>Informar o Número de Contato</label>
                                </div>
                            </div>
                            {origin === 0 && (
                                <div style={{ marginTop: "15px" }}>
                                    <div className="w-100 rounded" style={{ overflow: "auto", maxHeight: "200px" }}>
                                        <FileVisualizer imagesArray={selectedFiles} setSelectedFiles={setSelectedFiles} parentType="modal" />
                                    </div>
                                    <div>
                                        <label>Arquivo de Contato</label>
                                        <div className="mb-2">
                                            <Dropzone onChange={handleFiles} multiple={true} accept={{
                                                'text/vcard': ['.vcf'],
                                                'text/x-vcard': ['.vcard']
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {origin === 1 && (
                                <div style={{ marginTop: "15px" }}>
                                    <div>
                                        <label>Nome do Contato</label>
                                        <input type='text' className="form-control" value={name} onChange={e => setName(e.target.value)}/>
                                    </div>
                                    <div>
                                        <label>Whatsapp</label>
                                        <PhoneInput
                                            placeholder=""
                                            initialValueFormat="national"
                                            style={{ display: 'flex', padding: "0.295rem 0.75rem" }}
                                            value={whatsapp}
                                            defaultCountry="BR"
                                            className='form-control'
                                            onChange={setWhatsapp} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-modal-file">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    );
}
