import { memo, useCallback, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import ModalRandomizeNode from "../../../pages/modals/workflow/modal-randomize-node";

const PercentNodeItem = ({ handleId, value }) => {
    return (
        <div className="custom-node__options" style={{minWidth:"110px"}}>
          <li className="list-item d-flex align-items-center" style={{minWidth:"110px"}}>
            <div className="list-marker me-1">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            <div style={{textOverflow: "ellipsis", maxWidth:"110px",whiteSpace: "nowrap",overflow: "hidden" }} title={value}>{value}%</div>
          </li>
          <Handle type="source" position={Position.Right} id={`node-randomize${handleId}`}/>
        </div>
      );
};


const RandomizeNode = (props) => {
    const [showModal, setShowModal]     = useState(false);
    const [percents, setPercents]       = useState(0);
    const [id,setID]                    = useState(0)
    const [statistic, setStatistic]     = useState(0)

    const reactFlowInstance = useReactFlow();

    const constas = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            setPercents(nodeInfo.percents || []);
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
        };
        handleNodeProps();
    }, [props.data]);

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node         : _id,
                    parentId     : -1, 
                    targetId     : -1,
                    percents     : props.data.nodeInfo.percents,
                    objectType   : 13
                }
            }
        }))
    }


    const handleShowModal = (e) => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <ModalRandomizeNode nodeId={props.id} showModal={showModal} handleCloseModal={handleCloseModal} nodeData={props.data.nodeInfo}/>
            <div className="rfnode rfnode-randomize d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) => handleShowModal(e)}>
                <div className="rfnode-title">#{id} Randomizador</div>
                <div  style={{ maxWidth: "200px", borderRadius: "5px", padding: "4px" }}>
                    {percents.length > 0 ?
                        <div style={{padding:"5px"}}>
                            {percents.map((item,optionId) => {
                                return (
                                    <div style={{background:"rgba(1, 88, 95, 0.1098039216)", borderRadius:"5px", padding:"5px", display:"flex", height:"27px", marginTop:"2px" ,fontSize:"8px", alignItems:"center"}}>
                                        <PercentNodeItem
                                            key={optionId}
                                            value={item.percent}
                                            handleId={optionId + 4}
                                        ></PercentNodeItem>
                                    </div>
                                )   
                            })} 
                        </div>              
                    : 
                        <div style={{fontSize:"6px", padding:"10px", background:"rgba(1, 88, 95, 0.1098039216)", borderRadius:"5px", margin:"5px 5px"}}>
                            <span style={{color:"#01585F"}}>Aqui você adiciona os percentuais a randomizar </span>
                        </div>
                    }
                    <div style={{display:"flex", fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #01585F"}}>
                        <div style={{display:"flex", gap:"5px"}}>
                            <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                                <i className="fa fa-copy text-primary"></i>
                            </div>
                            <div onClick={() => constas()} title="Excluir Mensagem" className="workflow-delete" >
                                <i className="fa fa-trash text-danger"></i>
                            </div>
                        </div>

                        <div title="Número de vezes que o lead passou pela ação">
                            <span style={{fontSize:"10px", color:"#01585F"}}>{statistic || ''}</span>
                        </div>
                    </div>
                </div>
            </div>

            <Handle type="target" position={Position.Bottom} id='node-randomize1'/>
            <Handle type="target" position={Position.Left} id='node-randomize2' />
            <Handle type="target" position={Position.Top} id='node-randomize3' />
        </>
    );
};

export default memo(RandomizeNode);
