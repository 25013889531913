import React, { createContext, useContext, useEffect, useState } from 'react'

const ChatDataContext = createContext(null)

export default function ChatDataProvider({ children }) {   
    const [loadingChat, setLoadingChat]                                 = useState(false) 
    const [startHuman, setStartHuman]                                   = useState(null)
    const [isGroup, setIsGroup]                                         = useState(false)
    const [lastPage, setLastPage]                                       = useState(1)
    const [unread, setUnread]                                           = useState(0)
    const [idAutomationChatHeadHistory, setIdAutomationChatHeadHistory] = useState(0)
    const [endHuman, setEndHuman]                                       = useState(null)
    const [isOpened, setIsOpened]                                       = useState(false)
    const [settings, setSettings]                                       = useState({})
    const [chatList, setChatList]                                       = useState([])
    const [conversation, setConversation]                               = useState([])
    const [nameUser, setNameUser ]                                      = useState('')
    const [photoUser, setPhotoUser]                                     = useState('')
    const [userNumber, setUserNumber]                                   = useState('')
    const [to, setTo]                                                   = useState('')
    const [idPeople, setIDPeople]                                       = useState('')
    const [number, setNumber]                                           = useState('')
    const [idAttendant, setIdAttendant]                                 = useState(0)
    const [isNotRead, setIsNotRead]                                     = useState(false)
    const [isUnanswered, setIsUnanswered]                               = useState(false)
    const [showPreview, setShowPreview]                                 = useState(false)
    const [selectedFiles, setSelectedFiles]                             = useState([])
    const [filename, setFilename]                                       = useState({})
    const [messageType, setMessageType]                                 = useState(1)

    const handleReload = (_chatList) => {
        setChatList(_chatList)
    }

    return (
        <ChatDataContext.Provider
            value={{
                handleReload,
                startHuman,
                setStartHuman,
                endHuman,
                setEndHuman,
                isOpened,
                setIsOpened,
                settings,
                setSettings,
                chatList,
                setChatList,
                conversation,
                setConversation,
                nameUser, 
                setNameUser,
                photoUser, 
                setPhotoUser,
                userNumber, 
                setUserNumber,
                to, 
                setTo,
                idPeople, 
                setIDPeople,
                loadingChat,
                setLoadingChat,
                lastPage,
                setLastPage,
                unread,
                setUnread,
                idAutomationChatHeadHistory,
                setIdAutomationChatHeadHistory,
                number, 
                setNumber,
                idAttendant, 
                setIdAttendant,
                isNotRead, 
                setIsNotRead,
                isUnanswered, 
                setIsUnanswered,
                isGroup,
                setIsGroup,
                showPreview,
                setShowPreview,
                setSelectedFiles,
                selectedFiles,
                filename, 
                setFilename,
                messageType,
                setMessageType,

            }}
        >
            {children}
        </ChatDataContext.Provider>
    )


}

export function useChatData() {
    const context = useContext(ChatDataContext)
    if (!context)
        throw new Error('useChatData data precisa está inserida em um ChatDataProvider')

    return context
}
