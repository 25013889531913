import React, {useState} from "react";
import { useDropzone } from "react-dropzone";

function Dropzone({ onChange,accept, multiple, text }) {
    const [errorMessage, setErrorMessage] = useState('')
    const { getRootProps, getInputProps } = useDropzone({accept,
                                                         onDrop:onChange,
                                                         multiple,
                                                         onDropRejected: (rejectedFiles) => {
                                                            setErrorMessage(rejectedFiles.length > 0 ? 'O arquivo informado e inválido' : '');
                                                         }});


    return (
        <div {...getRootProps({ className: "dropzone" })}>
            <input className="input-zone" {...getInputProps()} />
            <div className="text-center" style={{border:"1px dashed #009ef7", background:"#f1faff", borderRadius:"5px", padding:"15px", display:"flex", gap:"5px", justifyContent:"center"}}>
                <i className="fa fa-upload" style={{fontSize:"24px",color:"#009ef7"}}/>
                <span style={{color:"#009ef7", display:"flex", alignItems:"center"}}>{text ? text : 'Clique ou arraste o arquivo aqui.'}</span>
            </div>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    );
}

export default Dropzone;