import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button} from 'reactstrap';
import Draggable from 'react-draggable';
import Swal from 'sweetalert2'
import Dropzone from "./../../components/dropzone";
import { Modal,  ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'

import api from '../../services/api';
import loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalGroupEntry({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]           = useState(false)
    const [invites, setInvites]           = useState([])
    const [description, setDescription]   = useState('')
    const [startTime, setStartTime]       = useState(15)
    const [endTime, setEndTime]           = useState(60)
    const [number, setNumber]             = useState('')
    const [numbersData, setNumbersData]   = useState([])

    const setData = async (obj,clear) => {
        setInvites(clear ? [] : obj.invites)
        setDescription(clear ? '' : obj.description)
        setStartTime(clear ? '' : obj.starttime)
        setEndTime(clear ? '' : obj.endtime)
        setNumber(clear ? '' : obj.number)
      
        await api.get('/getAllMyWhatsAppNumbers')
            .then(response => {
                setNumbersData(response.data)
            })
            .catch(error => {
                ShowMessages(error)
            })
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        if (description === '') {
            ShowMessages({status : 500, message : "Informe uma descrição para importação"})
            return
        }

        if (number === '') {
            ShowMessages({status : 500, message : "Informe o número para importação"})
            return
        }

        if (startTime === '') {
            ShowMessages({status : 500, message : "Informe o tempo inicial"})
            return
        }

        if (endTime === '') {
            ShowMessages({status : 500, message : "Informe o tempo final"})
            return
        }

        if (startTime < 15) {
            ShowMessages({status : 500, message : "O tempo inicial não pode ser menor que 15 segundos"})
            return
        }

        if (startTime > 10800) {
            ShowMessages({status : 500, message : "O tempo inicial não pode ser maior que 3 horas(10800 segundos)"})
            return
        }
        
        if (startTime > endTime) {
            ShowMessages({status : 500, message : "O tempo inicial não pode ser maior que o tempo final"})
            return
        }

        if (endTime > 86400) {
            ShowMessages({status : 500, message : "O tempo final não pode ser maior que 24 horas(86400 segundos)"})
            return
        }

        if (endTime < startTime) {
            ShowMessages({status : 500, message : "O tempo final não pode ser menor que o tempo inicial"})
            return
        }

        loadingButton(true,document.querySelector('#submit-group-entry'),document.querySelector('#submit-group-entry').children[0].classList,document.querySelector('#submit-group-entry').lastChild.nodeValue)

        try {
            let _data = {description,
                         invites,
                         startTime,
                         number,
                         endTime}
                     
                     
            if (editState)
                await api.put('/entrygrouplink/' + data.identrygrouplink, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-group-entry'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-group-entry'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-group-entry'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/entrygrouplink', _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-group-entry'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-group-entry')) 
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-group-entry'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-group-entry'))
            ShowMessages(response)
        }
    }

    const handleClear = async(e) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir os grupos de importação?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                setInvites([])
            }
            
          })
    }

    useEffect(() => {
        setData()
        setStatistic('modals\\modal-group-entry')
    },[])

    const processGroups = (file) => {
        const reader = new FileReader();
        reader.onload = function(event) {
          const lines = event.target.result.split('\n');
          lines.forEach((line, index) => {
            let _group = line.toString().split('/invite/')
            if (_group.length  === 2) {
                if (!invites.includes(_group[1]))
                    setInvites(invites => invites.concat(_group[1]))
            }
          });
        };
        
        reader.readAsText(file);
    }

    const handleFiles = async(files) => {
        if (files.length !== 0) {
            for (let i = 0; i < files.length; i++) {
                if (files[i].size > 20971520) {
                    ShowMessages({status : 500, message : "O arquivo informado não pode ser maior que 20Mb"})
                } else {
                    processGroups(files[i])
                }
            }
        }
    };

    
    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    return (
      
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" > 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-person-through-window"></i> Propriedades de Entradas em Grupos
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth330px"/>
                    )}
                    <div className={`box-shadow ${loading ? "hidden" : ""}`}>               
                        <div style={{display:"flex",gap:"15px", flexDirection:"column"}}>
                            <div>
                                <label>Descrição</label>
                                <TextInput type="text" 
                                    errorMessage={{ required: "Por favor, informe um descrição!" }}
                                    className='form-control' required value={description} onChange={e => setDescription(e.target.value)}/>
                            </div>
                            <div style={{width:"100%"}}>
                                <span>WhatsApp</span>
                                <select className="form-select" value={number} onChange={e => setNumber(e.target.value)} >
                                    <option value="">Selecione</option>
                                    {numbersData.map((item) => {
                                        return(<option value={item.number}>{item.phone_format}</option>)
                                    })}
                                </select>
                            </div>
                            <div style={{width:"310px"}}>
                                <label>Entrar de</label>
                                <div style={{display:'flex', gap:"5px"}}>
                                    <div>
                                        <TextInput 
                                            required 
                                            errorMessage={{ required: "Por favor, informe o tempo inical!" }}
                                            type="number" className='form-control' value={startTime} maxValue={10800} minValue={15} onChange={e => setStartTime(e.target.value)}/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                        <span>à</span>
                                    </div>
                                    <div>
                                        <TextInput 
                                            required 
                                            errorMessage={{ required: "Por favor, informe o tempo final!" }}
                                            type="number" className='form-control' value={endTime} maxValue={86400} onChange={e => setEndTime(e.target.value)}/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                        <span>segundos</span>
                                    </div>
                                    
                                </div>

                            </div>
                            {invites.length > 0 && (
                                <div style={{display:"flex",  gap:"10px"}}>
                                    <div className='alert alert-success' style={{marginBottom:"0px", display:"flex", justifyContent:"center"}}>
                                        <span>Foram encontrados <span style={{fontWeight:"bold"}}>{invites.length}</span> grupos para inserir!</span>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                        <button className='btn btn-danger' type="button" onClick={e => handleClear(e)}>
                                            <i className='fa fa-trash'/>
                                        </button>
                                    </div>
                                </div>
                            )}

                            <div>
                                <Dropzone text='Importe o seu arquivo de grupos aqui' onChange={handleFiles} multiple={true} accept= {{'text/plain': ['.txt']}} />
                            </div>      
                        </div>                
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-group-entry' className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}