import { memo, useCallback, useState, useEffect } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import ModalListNode from "../../../pages/modals/workflow/modal-list-node";

const ListNodeItem = ({ handleId,  value }) => {
  return (
    <div className="custom-node__options" style={{minWidth:"170px"}}>
      <li className="list-item d-flex align-items-center" style={{minWidth:"170px"}}>
        <div className="list-marker me-1">
          <i className="fa-solid fa-circle-check"></i>
        </div>
        <div style={{textOverflow: "ellipsis", maxWidth:"170px",whiteSpace: "nowrap",overflow: "hidden" }} title={value}>{value}</div>
      </li>
      <Handle type="source" position={Position.Right} id={`node-item${handleId}`}/>
    </div>
  );
};

const ListNode = (props) => {
    const [showModal, setShowModal]         = useState(false);
    const [list, setList]                   = useState([])
    const [id, setID]                       = useState(-1)
    const [statistic, setStatistic]         = useState(0)

    const reactFlowInstance                 = useReactFlow();

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            setList(nodeInfo.list);
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
        };
        handleNodeProps();
    }, [props.data]);

    const constas = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node        : _id,
                    parentId    : -1, 
                    targetId    : -1,
                    body        : props.data.nodeInfo.body,
                    footer      : props.data.nodeInfo.footer,
                    description : props.data.nodeInfo.description,
                    title       : props.data.nodeInfo.title,
                    list        : props.data.nodeInfo.list,
                    objectType  : 4                    
                }
            }
        }))
    }

    if (showModal) {
        return (
            <>
                <ModalListNode
                    nodeId={props.id}
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    nodeData={props.data.nodeInfo}
                ></ModalListNode>
            </>
        );
    }

    return (
        <>
            <div className="rfnode rfnode-list d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) => setShowModal(true)}>
                <div className="rfnode-title">#{id} Lista</div>
                <div  style={{ maxWidth: "200px", borderRadius: "5px", padding: "4px" }}>
                    {list.length > 0 ?
                        <div style={{padding:"5px"}}>
                            {list.map((item,optionId) => {
                                return (
                                    <div style={{background:"#f532e51c", borderRadius:"5px", padding:"5px", display:"flex", height:"27px", marginTop:"2px" ,fontSize:"8px", alignItems:"center"}}>
                                        <ListNodeItem
                                            key={optionId}
                                            value={item.title}
                                            handleId={item.id}
                                        ></ListNodeItem>
                                    </div>
                                )   
                            })} 
                        </div>
                    : 
                        <div style={{fontSize:"6px", padding:"10px", background:"#f532e5c1", borderRadius:"5px", margin:"5px 5px"}}>
                            <span>Aqui você adiciona os itens da sua lista </span>
                        </div>
                    }
               
                    <div style={{display:"flex",fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #f532e5"}}>
                        <div style={{display:"flex", gap:"5px"}} >
                            <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                                <i className="fa fa-copy text-primary"></i>
                            </div>
                            <div onClick={() => constas()} title="Excluir Mensagem" className="workflow-delete" >
                                <i className="fa fa-trash text-danger"></i>
                            </div>
                        </div>
                        <div title="Número de vezes que o lead passou pela ação">
                            <span style={{fontSize:"10px", color:"#f532e5"}}>{statistic || ''}</span>
                        </div>
                    </div>
                </div>
            </div>
            <Handle type="source" position={Position.Bottom} id='node-list1' />
            <Handle type="target" position={Position.Top}    id='node-list2' />
            <Handle type="target" position={Position.Left}   id='node-list3' />
        </>
    );
};

export default memo(ListNode);
