import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Loading from '../../components/Loading';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'

import {  Button } from 'reactstrap';
import { IDCOMPANY, ReadDataStorage } from '../../services/auth';

export default function WebHook(props) {
    const [loading, setLoading]             = useState(false)
    const [activeHook, setActiveHook]       = useState(false)
    const [idmethod, setIDMethod]           = useState(-1)
    const [authorization, setAuthorization] = useState('')
    const [help, setHelp]                   = useState([{
        title       : "Obter Ambiente pelo Número",
        description : 'Método utilizado para obter o ambiente pelo número informado.',
        method      : '/POST',
        route       : '/getEnvironmentForNumber',
        header      : 'authorization={CHAVE PRIVADA}',
        params      : '{"number" : "5519996798219"}',
        fields      : [{
            fieldName : 'number',
            value     : 'Parâmetro OBRIGATÓRIO que deverá ser informado para obter qual o ambiente ele está alocado.'
        }]
    }, {
        title       : "Obter Número pelo Ambiente",
        description : 'Método utilizado para obter o número pelo número ambiente.',
        method      : '/POST',
        route       : '/getNumberForEnvironment',
        header      : 'authorization={CHAVE PRIVADA}',
        params      : '{"environment" : "30-c4afb3c1-5a4d-498c-adac-5441d9fcdfb3"}',
        fields      : [{
            fieldName : 'environment',
            value     : 'Parâmetro OBRIGATÓRIO que deverá ser informado para obter qual o número ele está alocado no ambiente.'
        }]
    }, {
        title       : "Obter Números Registrados na API",
        description : 'Método utilizado para obter todos os números registrados na API com a respectiva CHAVE PRIVADA.',
        method      : '/POST',
        route       : '/getNumbers',
        header      : 'authorization={CHAVE PRIVADA}',
        params      : '',
        fields      : []
    }, {
        title       : "Obter Status do Número",
        description : 'Método utilizado para obter o status do número informado pelo ambiente, se ele está ou não logado.',
        method      : '/POST',
        route       : '/getStatus',
        header      : 'authorization={CHAVE PRIVADA}',
        params      : '{"number" : "5519996798219"}',
        fields      : [{
            fieldName : 'number',
            value     : 'Parâmetro OBRIGATÓRIO que deverá ser informado para obter se o número está ou não logado.'
        }]
    }, {
        title       : "Autenticar | Obter QRCode",
        description : 'Método utilizado para obter o QRCode do WhatsApp e realizar a autenticação.',
        method      : '/POST',
        route       : '/auth',
        header      : 'authorization={CHAVE PRIVADA}',
        params      : '{"environment" : "30-c4afb3c1-5a4d-498c-adac-5441d9fcdfb3"}',
        fields      : [{
            fieldName : 'environment',
            value     : 'Parâmetro OBRIGATÓRIO que deverá ser informado para apontar em qual ambiente o número será alocado. Esse campo deve ser único, e salvo no sistema, pois a cada requisição no WEBHOOK o mesmo deverá ser informado.'
        }]
    }, {
        title       : "Deslogar",
        description : 'Método utilizado para realizar logoff no sistema.',
        method      : '/POST',
        route       : '/logoff',
        header      : 'authorization={CHAVE PRIVADA}',
        params      : '{"number" : "5519996798219"}',
        fields      : [{
            fieldName : 'number',
            value     : 'Parâmetro OBRIGATÓRIO que deverá ser informado para identificar qual número deverá ser deslogado.'
        }]
    }, {
        title       : "Setar URL de Callback",
        description : 'Método utilizado para informar o WEBHOOK em qual URL deverá ser feito um POST com as mensagens recebidas.',
        method      : '/POST',
        route       : '/setURLCallback',
        header      : 'authorization={CHAVE PRIVADA}',
        params      : '{"url" : "https://www.google.com"}',
        fields      : [{
            fieldName : 'url',
            value     : 'Parâmetro OBRIGATÓRIO que deverá ser informado para setar em qual URL o sistema irá fazer o POST das mensagens recebidas.'
        }]
    }, {
        title       : "Obter URL de Callback",
        description : 'Método utilizado para receber qual URL está cadastrada no sistema.',
        method      : '/POST',
        route       : '/getURLCallback',
        header      : 'authorization={CHAVE PRIVADA}',
        params      : '',
        fields      : []
    }, {
        title       : "Criar Contato",
        description : 'Método utilizado para cadastrar um contato no sistema.',
        method      : '/POST',
        route       : '/createContact',
        header      : 'authorization={CHAVE PRIVADA}',
        params      : '{"name" : "Teste","phone" : "62958778487","email" : "teste@teste.com.br","facebook" : "/facebook","instagram" : "@instagram","telegram" : "telegram","linkedin" : "linkedin","cep" : 74000000,"street" : "Rua 01","number" : "SN", "complement" : "", "neighborhood" : "Bairro", "city" : "Goiânia", "uf" : "GO", "country" : "Brasil"}',
        fields      : [{
            fieldName : 'name',
            value     : 'Parâmetro utilizado para informar o contato do número cadastrado.'
        },{
            fieldName : 'phone',
            value     : 'Parâmetro OBRIGATÓRIO que deverá ser informado o número de telefone do contato.'
        },{
            fieldName : 'email',
            value     : 'Parâmetro utilizado para informar o email do contato.'
        },{
            fieldName : 'facebook',
            value     : 'Parâmetro utilizado para informar o facebook do contato.'
        },{
            fieldName : 'instagram',
            value     : 'Parâmetro utilizado para informar o instagram do contato.'
        },{
            fieldName : 'telegram',
            value     : 'Parâmetro utilizado para informar o telegram do contato.'
        },{
            fieldName : 'linkedin',
            value     : 'Parâmetro utilizado para informar o linkedin do contato.'
        },{
            fieldName : 'cep',
            value     : 'Parâmetro utilizado para informar o cep do endereço do contato.'
        },{
            fieldName : 'street',
            value     : 'Parâmetro utilizado para informar a rua do endereço do contato.'
        },{
            fieldName : 'number',
            value     : 'Parâmetro utilizado para informar o número do endereço do contato.'
        },{
            fieldName : 'complement',
            value     : 'Parâmetro utilizado para informar o complemento do endereço do contato.'
        },{
            fieldName : 'neighborhood',
            value     : 'Parâmetro utilizado para informar o bairro do endereço do contato'
        },{
            fieldName : 'city',
            value     : 'Parâmetro utilizado para informar a cidade do endereço do contato.'
        },{
            fieldName : 'uf',
            value     : 'Parâmetro utilizado para informar o estado do endereço do contato.'
        },{
            fieldName : 'country',
            value     : 'Parâmetro utilizado para informar o país do endereço do contato.'
        }]
    },{
        title       : "Enviar Mensagem de Texto",
        description : 'Método utilizado para enviar uma mensagem de texto para um contato informado.',
        method      : '/POST',
        route       : '/sendMessage',
        header      : 'authorization={CHAVE PRIVADA}',
        params      : '{"number" : "5519996798219", "bodyMessage" : "Olá tudo bem?", "to" : "5562955225554"}',
        fields      : [{
            fieldName : 'number',
            value     : 'Parâmetro OBRIGATÓRIO que deverá ser informado para identificar qual remetende deverá enviar a mensagem.'
        },{
            fieldName : 'bodyMessage',
            value     : 'Parâmetro OBRIGATÓRIO utilizado para informar o corpo da mensagem a ser enviada.'
        },{
            fieldName : 'to',
            value     : 'Parâmetro OBRIGATÓRIO utilizado para informar qual e o destinatário da mensagem.'
        }]
    },{
        title       : "Enviar Mensagem de Áudio",
        description : 'Método utilizado para enviar uma mensagem de áudio gravado para um contato informado.',
        method      : '/POST',
        route       : '/sendAudio',
        header      : 'authorization={CHAVE PRIVADA}',
        params      : '{"number" : "5519996798219", "fileBase64" : "T2dnUwACAAAAAAAAAAB...", "to" : "5562955225554","extension" : ".ogg"}',
        fields      : [{
            fieldName : 'number',
            value     : 'Parâmetro OBRIGATÓRIO que deverá ser informado para identificar qual remetende deverá enviar a mensagem.'
        },{
            fieldName : 'fileBase64',
            value     : 'Parâmetro OBRIGATÓRIO utilizado para enviar o audio, o formato do arquivo deve ser .mp3 ou .ogg e convertido para base64.'
        },{
            fieldName : 'extension',
            value     : 'Parâmetro OBRIGATÓRIO utilizado para informar em qual extensão está o áudio informado.'
        },{
            fieldName : 'to',
            value     : 'Parâmetro OBRIGATÓRIO utilizado para informar qual e o destinatário da mensagem.'
        }]
    },{
        title       : "Enviar Mensagem com Arquivo",
        description : 'Método utilizado para enviar uma mensagem com um arquivo em qualquer formato.',
        method      : '/POST',
        route       : '/sendAudio',
        header      : 'authorization={CHAVE PRIVADA}',
        params      : '{"number" : "5519996798219", "filename" : "nome do arquivo", "bodyMessage" : "Mensagem que acompanha o arquivo", "fileBase64" : "data:image/bmp;base64,Qk2KBAAAAAAAAIoAAAB8AAAA...", "to" : "5562955225554","extension" : ".bmp"}',
        fields      : [{
            fieldName : 'number',
            value     : 'Parâmetro OBRIGATÓRIO que deverá ser informado para identificar qual remetende deverá enviar a mensagem.'
        },{
            fieldName : 'fileBase64',
            value     : 'Parâmetro OBRIGATÓRIO utilizado para enviar o arquivo, e permitido o envio em qualquer formato convertido para base64.'
        },{
            fieldName : 'extension',
            value     : 'Parâmetro OBRIGATÓRIO utilizado para informar em qual extensão está o arquivo informado.'
        },{
            fieldName : 'to',
            value     : 'Parâmetro OBRIGATÓRIO utilizado para informar qual e o destinatário da mensagem.'
        },{
            fieldName : 'bodyMessage',
            value     : 'Parâmetro utilizado para informar uma mensagem juntamente com o arquivo informado.'
        }]
    },{
        title       : "Enviar Mensagem de Contato",
        description : 'Método utilizado para enviar uma mensagem com um contato.',
        method      : '/POST',
        route       : '/sendContact',
        header      : 'authorization={CHAVE PRIVADA}',
        params      : '{"number" : "5519996798219", "fileBase64" : "T2dnUwACAAAAAAAAAAB...", "to" : "5562955225554"}',
        fields      : [{
            fieldName : 'number',
            value     : 'Parâmetro OBRIGATÓRIO que deverá ser informado para identificar qual remetende deverá enviar a mensagem.'
        },{
            fieldName : 'fileBase64',
            value     : 'Parâmetro OBRIGATÓRIO utilizado para enviar o contato.'
        },{
            fieldName : 'to',
            value     : 'Parâmetro OBRIGATÓRIO utilizado para informar qual e o destinatário da mensagem.'
        }]
    },{
        title       : "Enviar Mensagem de Botões",
        description : 'Método utilizado para enviar uma mensagem com botões de interação.',
        method      : '/POST',
        route       : '/sendButtons',
        header      : 'authorization={CHAVE PRIVADA}',
        params      : '{"number" : "5519996798219","buttons" : {"titleButton" : "Titulo do botão","titleFooter" : "Rodapé do botão","textButton"  : "Texto do botão","buttons" : [{"text" : "Botão 01"}, {"text" : "Botão 02"}, {"text" : "Botão 03"},{"text" : "Botão 04"},{"text" : "Botão 05"}]}, "to" : "5562955225554"}',
        fields      : [{
            fieldName : 'number',
            value     : 'Parâmetro OBRIGATÓRIO que deverá ser informado para identificar qual remetende deverá enviar a mensagem.'
        },{
            fieldName : 'buttons',
            value     : 'Parâmetro OBRIGATÓRIO utilizado para informar as configurações dos 5 possiveis botões a serem enviados'
        },{
            fieldName : 'to',
            value     : 'Parâmetro OBRIGATÓRIO utilizado para informar qual e o destinatário da mensagem.'
        }]
    },{
        title       : "Iniciar um fluxo de chatbot",
        description : 'Método utilizado para iniciar um fluxo de chatbot',
        method      : '/POST',
        route       : '',
        header      : '',
        params      : '',
        fields      : [],
        comments    : `<p style='font-weight:bold'>Para obter a URL do fluxo, você deve seguir os passos abaixo.</p>
        <ul>
            <li>Tenha o webhook ativo</li>
            <li>Tenha o ChatBot criado e ativo no menu ChatBot > Configurações</li>
            <li>Obtenha a URL do webhook pelo botão AZUL na lista de fluxos criados</li>
            <li>Configure os parâmetros que irá receber na URL do webhook</li>
            <li>Cole a URL onde irá ser utilizado a chamado do webhook</li>
        </ul>
                        `
    }


    ])
   
    const getYAML = async(e) => {
        await api.post('/downloads/webhook/yaml')
                 .then(response => {
                    var link      = document.createElement('a');
                    link.download = '55Zap - WebHook - Insomnia.yaml';
                    link.href     = 'data:text/plain;base64,' + response.data;
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                 .catch( error => { 
                    ShowMessages(error)
                 })
    }

    const handleWebHook = async(e,activeHook) => {
        e.preventDefault()
        setActiveHook(activeHook)
        try {
            let _data = { activeHook }

            await api.post('/webhook' , _data)
                     .then(response => {
                        if (response.ok) 
                            ShowMessages(response.data)
                            getData()
                        })
        } catch (response) {
            ShowMessages(response)
        }
    }

    const getData = async() => {
        try {
            setLoading(true)
            await api.get('/webhook/' + ReadDataStorage(IDCOMPANY))
                     .then(response => {
                        if (response.ok) 
                            setActiveHook(response.data.idwebhookstatus === 1 ? true : false)
                            setAuthorization(response.data.authorization)
                        })
                        setLoading(false)
        } catch (response) {
            ShowMessages(response)
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
        setStatistic('maintenance\\webhook')
    },[])
                                                        
    const handleCopyCode = () => {
        navigator.clipboard.writeText(authorization);
        ShowMessages({status : 200,message : "Chave Privada copiada com sucesso!"})
    }

    return (    
        <ContentWrapper className="content-wrapper-bottom81 ">
            {loading && (
                <Loading loading={loading}/>
            )}
            <div style={{display:"flex"}} className={` ${(loading ? "hidden" : "")}`}>
                <div style={{border:"1px solid #f6f7f9", margin:"5px", display:"flex", flexDirection:"column", width:"420px",borderRadius:"5px", boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px", padding:"15px"}} className={` content-box-cart-page ${(loading ? "hidden" : "")}`}>
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                        <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                            <input type="checkbox" checked={activeHook} value={activeHook} onClick={e => handleWebHook(e,!activeHook)} />
                            <span style={{ marginTop: "5px" }}></span>
                        </label>
                        <label style={{marginLeft:"5px"}}>{!activeHook ? "Ativar" : "Desativar"} WebHook</label>
                    </div>
                    <div style={{marginTop:"10px"}}>
                        <label>
                            Chave Privada
                        </label>
                        <div style={{display:"flex"}}>
                            <input type="text" readOnly className='form-control' value={authorization}></input>
                            <button className='btn btn-success' style={{marginLeft:"5px"}} onClick={e => handleCopyCode()}><em className="fa fa-copy"></em></button>
                        </div>
                    </div>
                    <div style={{display:"flex", marginTop:"10px", flexDirection:"column"}}>
                        <span>URL de Comunicação</span>
                        <span>https://webhook.55zap.com.br/webhook/</span>
                    </div>
                    <div style={{display:"flex", marginTop:"10px", flexDirection:"column"}}>
                        <span>Primeiros Passos</span>
                        <div className='bg alert alert-success' style={{borderRadius:"5px", padding:"5px"}}>
                            <div>
                                <div style={{display:"flex"}}><div className='list text-success' >1</div>Ativar o WebHook.</div>
                                <div style={{display:"flex", marginTop:"5px"}}><div className='list text-success' >2</div>Copiar a chava privada e incluir no header da sua aplicação.</div>
                                <div style={{display:"flex", marginTop:"5px", flexWrap:"wrap"}}><div className='list text-success' >3</div>Inicie primeiramente fazendo o login do seu &nbsp;<span style={{fontWeight:"bold"}}> WhatsApp,</span>diretamente no 55Zap, ou pode usar o método&nbsp;<span style={{fontWeight:"bold"}}>/auth</span> para obter o QRCode.</div>
                                <div style={{display:"flex", marginTop:"5px", flexWrap:"wrap"}}><div className='list text-success' >4</div><span></span>Após estar logado no sistema com o &nbsp;<span style={{fontWeight:"bold"}}> WhatsApp,</span> você poderá utilizar qualquer um dos métodos abaixo.</div>
                            </div>
                        </div>
                    </div>
                    <div style={{display:"flex", marginTop:"10px"}}> 
                        <button className='btn btn-primary' onClick={e => getYAML()}><em className="fa fa-file-code"></em> Download do YAML do Insomnia</button>
                        <a className='btn btn-primary' style={{marginLeft:"12px"}} href="https://insomnia.rest/download" target="_new"><em className="fa fa-file-download"></em> Download Insomnia</a>
                    </div>
                    <div style={{ marginTop:"10px"}}>
                        <label>Métodos</label>
                        <select className='form-select' value={idmethod} onChange={e => setIDMethod(parseInt(e.target.value))}>
                            <option value={-1}>Selecione</option>
                            <option value={0}>Obter Ambiente pelo Número</option>
                            <option value={1}>Obter Número pelo Ambiente</option>
                            <option value={2}>Obter Números Registrados</option>
                            <option value={3}>Obter Status de um Número</option>
                            <option value={4}>Autenticar</option>
                            <option value={5}>Deslogar</option>
                            <option value={6}>Setar URL de Callback</option>
                            <option value={7}>Obter URL de Callback</option>
                            <option value={8}>Criar Contato</option>
                            <option value={9}>Enviar Texto</option>
                            <option value={10}>Enviar Áudio</option>
                            <option value={11}>Enviar Documento</option>
                            <option value={12}>Enviar Contato</option>
                            <option value={13}>Enviar Botões</option>        
                            <option value={14}>Iniciar Fluxo de Chatbot</option>  
                        </select>
                    </div>
                </div>
                <div className={`${idmethod === -1 ? 'hidden' : ''}`} style={{border:"1px solid #f6f7f9", margin:"5px", display:"flex", flexDirection:"column", width:"650px",borderRadius:"5px", boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px", padding:"15px"}}>
                    <div>
                        <div style={{background:'#f6f7f9', padding:"5px", justifyContent:"center", display:"flex", fontWeight:"bold", fontSize:"14px", borderRadius:"5px"}}>
                            <span>{help[idmethod < 0 ? 0 : idmethod]?.title}</span>
                        </div>
                        <div style={{marginTop:"5px"}}>
                            <span>{help[idmethod < 0 ? 0 : idmethod]?.description}</span>
                        </div>
                        <div style={{marginTop:"5px"}}>
                            <span style={{fontWeight:"bold"}}>Método: </span><span className='text-success'>{help[idmethod < 0 ? 0 : idmethod]?.method}</span>
                        </div>
                        {help[idmethod < 0 ? 0 : idmethod]?.route && (
                            <div style={{marginTop:"5px"}}>
                                <span style={{fontWeight:"bold"}}>Rota: </span><span>{help[idmethod < 0 ? 0 : idmethod]?.route}</span>
                            </div>
                        )}
                        {help[idmethod < 0 ? 0 : idmethod]?.header && (
                            <div style={{marginTop:"5px"}}>
                                <span style={{fontWeight:"bold"}}>Header: </span><span>{help[idmethod < 0 ? 0 : idmethod]?.header}</span>
                            </div>
                        )}
                        <div style={{marginTop:"5px"}} className={help[idmethod < 0 ? 0 : idmethod]?.params === '' ? 'hidden' : ''}>
                            <span style={{fontWeight:"bold"}}>Parâmetros: </span>
                            <pre>
                                <code>{JSON.stringify(JSON.parse(help[idmethod < 0 ? 0 : idmethod]?.params || '{}'), null, 4)}</code>
                            </pre>
                        </div>
                        <div style={{marginTop:"5px"}} className={help[idmethod < 0 ? 0 : idmethod]?.fields.length <= 0 ? 'hidden' : ''}>
                            <div>
                                <span style={{fontWeight:"bold"}}>Campos: </span>
                            </div>
                            {help[idmethod < 0 ? 0 : idmethod]?.fields.map((_item) => {
                                return <div><span style={{fontStyle:"italic"}}>{_item.fieldName}: </span><span>{_item.value}</span></div>
                            })}
                            
                        </div>
                        {help[idmethod < 0 ? 0 : idmethod]?.comments && (
                            <div style={{marginTop:"5px"}}>
                                <div>
                                    <span style={{fontWeight:"bold"}}>Observações</span>
                                </div>
                                <div style={{marginTop:"5px", padding:"5px", borderRadius:"5px"}} className='bg alert alert-warning'>
                                    <div style={{color:"#000"}} dangerouslySetInnerHTML={{__html: help[idmethod < 0 ? 0 : idmethod]?.comments }}>
                                    </div>
                                </div>
                            </div>
                        )}                            
                        
                    </div>

                </div>
            </div>

            <div className="modal-footer footer-fixed modal-footer-bottom-10">
                <Button className="btn-ligth-gray" onClick={e => window.removeTab(`tab_${props.id}`,e)}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </div>
        </ContentWrapper>
    )
}