import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../../components/Exceptions'
import Loading from '../../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../../services/api';
import setStatistic from '../../../services/apiStatistic'
import  loadingButton  from '../../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalInstagram({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]       = useState(false)

    const [user,setUser]           = useState('')
    const [password,setPassword]   = useState('')
    const [isprivate,setIsPrivate] = useState(false)

    const setData = (obj,clear) => {
        setUser(clear ? '' : obj.user) 
        setPassword(clear ? '' : obj.password) 
        setIsPrivate(clear ? '' : obj.isprivate || false) 
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-instagram'),document.querySelector('#submit-instagram').children[0].classList,document.querySelector('#submit-instagram').lastChild.nodeValue)

        try {
            let _data = {user,
                         password,
                         isprivate }

            if (editState)
                await api.put('/admin/instagram/' + data.idinstagram, _data)
                      .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else
                                 loadingButton(false,document.querySelector('#submit-instagram'))
                         } else
                             loadingButton(false,document.querySelector('#submit-instagram'))
                     })
                     .catch( error => {
                         loadingButton(false,document.querySelector('#submit-instagram'))
                         ShowMessages(error) 
                     })
            else
                await api.post('/admin/instagram', _data)
                      .then(response => {
                          ShowMessages(response)
                          if (response.ok) {   
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-instagram'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-instagram'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-instagram'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-instagram'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    useEffect(() => {
        setStatistic('modals\\admin\\modal-instagram')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-project-diagram"></i> Propriedades do Instagram
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>    
                        <div>
                            <label>Usuário</label>
                            <div className="input-group">
                                <TextInput  
                                    name="user"
                                    id="user"
                                    type="text"
                                    required
                                    maxLength={150}
                                    errorMessage={{ required: "Por favor, informe um usuário!" }}
                                    value={user}
                                    className="form-control "
                                    onChange={e => setUser(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                        </div>   
                        <div>
                            <label>Senha</label>
                            <div className="input-group">
                                <TextInput  
                                    name="password"
                                    id="password"
                                    type="password"
                                    required
                                    maxLength={150}
                                    errorMessage={{ required: "Por favor, informe uma senha!" }}
                                    value={password}
                                    className="form-control "
                                    onChange={e => setPassword(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                        </div>     
                        <div>
                                            <div style={{display:"flex",background:"#fff", width:"fit-content"}}>
                                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                    <input type="checkbox" checked={isprivate} value={isprivate} onClick={e => setIsPrivate(!isprivate)} />
                                                    <span style={{ marginTop: "5px" }}></span>
                                                </label>
                                                <span style={{marginLeft:"5px",marginTop:"5px"}}>Privado?</span>
                                            </div>  
                                        </div>                             
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-instagram" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}