import React, {useState, useEffect} from 'react'
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button, } from 'reactstrap';
import Draggable from 'react-draggable';
import { Modal,  ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'
import api from '../../services/api';

import loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalECommerce({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                                   = useState(false)
    const [idecommercecompany, setIdECommerceCompany]             = useState('')
    const [idecommerceevent, setIdeCommerceEvent]                 = useState('')
    const [idecommerceorderstatus, setIdeCommerceOrderStatus]     = useState('')
    const [appkey, setAppKey]                                     = useState('')
    const [interval, setInteval]                                  = useState(5)
    const [idautomationchat, setIDAutomationChat]                 = useState('')

    const [eCommerceData, setECommerceData]                       = useState([])
    const [eCommerceEventData, setECommerceEventData]             = useState([])
    const [eCommerceOrderStatusData, seteCommerceOrderStatusData] = useState([])
    const [automationChatData, setAutomationChatData]             = useState([])
    
    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-ecommerce-integration'),document.querySelector('#submit-ecommerce-integration').children[0].classList,document.querySelector('#submit-ecommerce-integration').lastChild.nodeValue)
        event.preventDefault()
     
        try {
            let _data = {idecommercecompany,
                         idecommerceevent,
                         idecommerceorderstatus,
                         appkey,
                         interval,
                         idautomationchat
                        }
            
            if (editState)
                await api.put('/ecommerce-integration/' + data.idecommerce_integration, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-ecommerce-integration'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-ecommerce-integration'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-ecommerce-integration'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/ecommerce-integration', _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-ecommerce-integration'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-ecommerce-integration')) 
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-ecommerce-integration'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-ecommerce-integration'))
            ShowMessages(response)
        }
    }

    const setData = (obj,clear) => {
        setIdECommerceCompany(clear ? '' : obj.idecommercecompany)
        setIdeCommerceEvent(clear ? '' : obj.idecommerceevent)
        setIdeCommerceOrderStatus(clear ? '' : obj.idecommerceorderstatus)
        setAppKey(clear ? '' : obj.appkey)
        setInteval(clear ? 5 : obj.interval || 5)
        setIDAutomationChat(clear ? '' : obj.idautomationchat )
    }

    useEffect(() => {
        setStatistic('modals\\modal-ecommerce')
    },[])

    useEffect(() => {
        const getData = async() => {
            setLoading(true)
            await api.get('/getecommerce-integration')
                     .then(async response => {
                        setECommerceData(response.data?.eCommerceCompany || [])
                        setECommerceEventData(response.data?.eCommerceEvent || [])
                        seteCommerceOrderStatusData(response.data?.eCommerceOrderStatus || [])
                        setAutomationChatData(response.data?.AutomationChat)
                        if (editState) 
                            setData(data,false)
                        else 
                            setData({},true)
                        setLoading(false)
                     })
                     .catch( error => {
                        ShowMessages(error)
                        setLoading(false) 
                     })
        }
        getData()
    },[data,editState,showModal])
   
    return (
      
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" > 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-at"></i> Integração com E-Commerce
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth330px"/>
                    )}
                    <div className={`box-shadow ${loading ? "hidden" : ""}`}>               
                        <div style={{display:"flex", gap:5}}>
                            <div style={{width:"210px"}}>
                                <label>E-Commerce</label>
                                <select className='form-select' value={idecommercecompany} onChange={e => setIdECommerceCompany(e.target.value)} 
                                    required errorMessage={{ required: "Por favor, informe o E-Commerce!" }}>
                                    <option value="">Selecione</option>
                                    {eCommerceData.map((_item) => {
                                        return (<option value={_item.idecommercecompany}>{_item.ecommercecompany}</option>)
                                    })}
                                    
                                </select>
                            </div>
                            <div style={{width:"250px"}}>
                                <label>Chave da API</label>
                                <TextInput  
                                    name="apikey"
                                    id="apikey"
                                    type="text"
                                    required
                                    value={appkey}
                                    className="form-control"
                                    errorMessage={{ required: "Por favor, informe a chave da API!" }}
                                    onChange={e => {setAppKey(e.target.value)}}
                                    autoComplete="off"/>
                            </div>
                        </div>
                        <div style={{display:"flex", gap:5}}>
                            <div style={{width:"210px"}}>
                                <label>Evento</label>
                                <select className='form-select' value={idecommerceevent} onChange={e => setIdeCommerceEvent(e.target.value)} required>
                                    <option value="">Selecione</option>
                                    {eCommerceEventData.filter(_item => {
                                        return _item.idecommercecompany === parseInt(idecommercecompany)
                                    }).map((_item) => {
                                        return (<option value={_item.idecommerceevent}>{_item.ecommerceevent}</option>)
                                    })}
                                </select>
                            </div>
                            <div style={{width:"150px"}}>
                                <label>Enviar depois de </label>
                                <div style={{display:"flex", gap:"5px", alignItems:"center"}}>
                                    <TextInput  
                                        name="minutes"
                                        id="minutes"
                                        type="number"
                                        required
                                        value={interval}
                                        className="form-control text-right"
                                        errorMessage={{ required: "Por favor, informe um intervalo!" }}
                                        onChange={e => {setInteval(e.target.value)}}
                                        autoComplete="off"/>
                                    <small>minutos</small>
                                </div>
                                
                            </div>
                            <div style={{width:"210px"}}>
                                <label>Para o Status do Pedido</label>
                                <select className='form-select' value={idecommerceorderstatus} onChange={e => setIdeCommerceOrderStatus(e.target.value)} required>
                                    <option value="">Selecione</option>
                                    {eCommerceOrderStatusData.map((_item) => {
                                        return (<option value={_item.idecommerceorderstatus}>{_item.ecommerceorderstatus}</option>)
                                    })}
                                </select>
                            </div>
                        </div>
                        <div style={{display:"flex", gap:5}}>
                            <div style={{width:"100%"}}>
                                <label>Chatbot</label>
                                <select className='form-select' value={idautomationchat} onChange={e => setIDAutomationChat(e.target.value)} required>
                                    <option value="">Selecione</option>
                                    {automationChatData.map((_item) => {
                                        return (<option value={_item.idautomationchat}>{_item.title}</option>)
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-ecommerce-integration' className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}