import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';
import loadingButton  from '../layouts/Buttons'
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalMyNumberEL({showModal, handleCloseModal, data, handleReload,editState}) {
    const [idservice, setIDService]       = useState(0)
    const [appkey, setAppKey]             = useState('')
    const [description, setDescription]   = useState('')

    const setData = (obj,clear) => {
        console.log(obj)
        setAppKey(clear ? '' : obj.appkey)  
        setDescription(clear ? '' : obj.description) 
    }
      
    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-el'),document.querySelector('#submit-el').children[0].classList,document.querySelector('#submit-el').lastChild.nodeValue)
        event.preventDefault()

        try {
            let _data = {idservice,
                         appkey,
                         description
                        }
              
            if (editState)
                await api.put('/el/' + data.idnumber, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-el'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-el'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-el'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/el', _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-el'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-el')) 
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-el'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-el'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        if (editState)
            setData(data,false)
        else 
            setData({},true)
    },[data,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-mynumber-el')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="width450" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-voicemail"></i> Conexão com ElevanLabs
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div className='box-shadow'>
                        <div style={{display:"flex", gap:"5px", flexDirection:"column"}}>
                            <div>
                                <label>Descrição</label>
                                <input type="text" className='form-control'value={description} onChange={e => setDescription(e.target.value)}/>
                            </div>
                            <div>
                                <label>Chave</label>
                                <input type="text" className='form-control'value={appkey} onChange={e => setAppKey(e.target.value)}/>
                            </div>
                            <div className='alert alert-warning' style={{display:"flex", gap:"15px"}}>
                                <div style={{display:"flex", alignItems:"center"}}>
                                    <i className='fa fa-circle-info' style={{fontSize:"25px"}}/>
                                </div>
                                <div style={{display:"flex",flexDirection:"column"}}>
                                    <span>Para obter a chave de acesso da <span style={{fontWeight:"bold"}}>ElevenLabs</span>, crie seu cadastro no site <a href="https://elevenlabs.io">https://elevenlabs.io</a> ou caso já tenha, faça seu login.</span>
                                    <span>Após estar logado, acesse a URL <a href="https://elevenlabs.io/app/speech-synthesis">https://elevenlabs.io/app/speech-synthesis</a> e obtenha sua <span style={{fontWeight:"bold"}}>API Key.</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    <Button type="submit" id="submit-el" className={`btn-theme`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button> 
                    <Button type="button" className="btn-ligth-gray" onClick={e => handleCloseModal()}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    )
}