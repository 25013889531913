import { memo, useCallback, useEffect, useState } from "react";
import { Handle,  Position, useReactFlow } from "reactflow";
import ModalWhatsAppNode from "../../../pages/modals/workflow/modal-whatsapp-node";
import Waveform from "../../waveformer";
import { messageCustom, getIconeFile, phone_format } from "../../Utils"

const WhatsAppNode = (props) => {
    const reactFlowInstance                     = useReactFlow();
    const [showModal, setShowModal]             = useState(false);
    const [message, setMessage]                 = useState("");
    const [objectTypeW, setObjectTypeW ]        = useState(0);
    const [audio, setAudio]                     = useState(null)
    const [destroyInstance, setDestroyInstance] = useState(false);
    const [pauseAudio, setPauseAudio]           = useState(false);
    const [selectedFiles, setSelectedFiles]     = useState([])
    const [Url, setURL]                         = useState('')
    const [id, setID]                           = useState(-1)
    const [whatsapp, setWhatsApp]               = useState('')
    const [activeContact,setActiveContact]      = useState(false)
    const [statistic, setStatistic]             = useState(0)

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            setMessage(nodeInfo.message);
            setObjectTypeW(nodeInfo.objectTypeW || 0);
            setAudio(nodeInfo.audio)
            setURL(nodeInfo.url || '')
            setWhatsApp(nodeInfo.whatsapp || '')
            setSelectedFiles(nodeInfo.selectedFiles || [])
            setActiveContact(nodeInfo.activeContact || false)
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
        };
        handleNodeProps();
    }, [props.data]);


    const handleCloseModal = () => {
        setShowModal(false);
    };

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
            nodeInfo : {
                    node          : _id,
                    parentId      : -1, 
                    targetId      : -1,
                    message       : props.data.nodeInfo.message,
                    audio         : props.data.nodeInfo.audio,
                    objectTypeW   : props.data.nodeInfo.objectTypeW, 
                    origin        : props.data.nodeInfo.origin,
                    selectedFiles : props.data.nodeInfo.selectedFiles,
                    Url           : props.data.nodeInfo.Url,
                    activeContact : props.data.nodeInfo.activeContact,
                    whatsapp      : props.data.nodeInfo.whatsapp,
                    objectType    : 17
                                        
                }
            }
        }))
    }

    const deleteNode = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
        setDestroyInstance(true);
    }, [reactFlowInstance, props]);

    return (
        <>
            <ModalWhatsAppNode
                nodeId={props.id}
                nodeData={props.data.nodeInfo}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
            ></ModalWhatsAppNode>

            <div className="rfnode rfnode-whatsapp d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow"  onClick={(e) => setShowModal(true)}>
                <div className="rfnode-title">#{id} Whatsapp</div>

                <div style={{background: "rgba(37, 211, 102, 0.1098039216)", margin:"5px 10px", padding:"5px", borderRadius:"5px", color:"#25d366", width:"85%", textAlign:"center"}}>
                    {objectTypeW <= 0 && (
                        <div style={{ display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <i className="fab fa-whatsapp"/>
                            </div>
                            <span style={{fontSize:"6px"}}>Aqui você adiciona uma mensagem de texto a ser enviada!</span>
                        </div>
                    )}
                    {objectTypeW > 0 && (
                        <div style={{fontSize:"6px", display:"flex", flexDirection:"column", marginBottom:"10px"}}>
                            <span>Enviar mensagem para:</span>
                            <span style={{fontWeight:"bold"}}>{phone_format(whatsapp)}</span>
                        </div>
                    )}
                    {objectTypeW === 1 && (
                        <>
                            <div style={{ display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                <div style={{display:"flex", justifyContent:"center"}}>
                                    <i className="fa fa-keyboard"/>
                                </div>
                            </div>
                            <div>
                                <pre style={{fontSize:"6px",textAlign:"left", textWrap:"wrap"}} dangerouslySetInnerHTML={{__html: messageCustom(message)}}></pre>
                            </div>
                        </>
                    )}
                    {objectTypeW === 2 && (
                        <>
                            <div style={{ display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                <div style={{display:"flex", justifyContent:"center"}}>
                                    <i className="fa fa-file"/>
                                </div>
                            </div>
                            <div>
                                {selectedFiles.length > 0 && (
                                    <div>
                                        <div style={{padding:"5px 0px"}}>
                                            {selectedFiles.map(_file => {
                                                return (
                                                    <div style={{background :"#25d3661c", borderRadius:"5px", padding:"5px", display:"flex", height:"35px", marginTop:"2px", fontSize:"6px"}}>
                                                        <div>
                                                            <img alt="arquivo" style={{maxWidth:"20px"}} src={_file.src} onError={(e)=>{e.target.src=getIconeFile(_file.type,_file.name) }}/>
                                                        </div>
                                                    
                                                        <div style={{marginLeft:"10px"}} title={_file.name}>
                                                            <div style={{maxWidth: "70px",whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>
                                                                <span>{_file.name}</span>
                                                            </div>
                                                            <div style={{textAlign:"right", marginTop:"-2px"}}>
                                                                <span>{parseFloat((_file.size / 1024) /1024).toFixed(2)} Mb</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div>
                                            <pre style={{fontSize:"6px",textAlign:"left", textWrap:"wrap"}} dangerouslySetInnerHTML={{__html: messageCustom(message)}}></pre>
                                        </div>
                                    </div>
                                )}
                                {Url !== '' && ( 
                                    <div style={{padding:"5px", background:"#25d366", margin:"0px 15px 15px 15px", borderRadius:"5px", lineHeight:"10px"}}>
                                        <span style={{fontSize:"8px"}}>{Url}</span>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {objectTypeW === 3 && (
                        <>
                            <div style={{ display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                <div style={{display:"flex", justifyContent:"center"}}>
                                    <i className="fa fa-microphone"/>
                                </div>
                            </div>
                            <div>
                                <Waveform
                                    waveFormInstance={`waveform-${props.id}`}
                                    url={audio}
                                    destroyInstance={destroyInstance}
                                    pauseAudio={pauseAudio}
                                    isModal={false}
                                ></Waveform>
                            </div>
                        </>
                    )}
                    {objectTypeW === 4 && (
                        <>
                            <div style={{ display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                <div style={{display:"flex", justifyContent:"center"}}>
                                    <i className="fa fa-address-card"/>
                                </div>
                            </div>
                            {activeContact ? (
                                <div>
                                    <span style={{fontSize:"6px"}}>Contato ativo no fluxo</span>
                                </div>
                            ) : (
                                selectedFiles.map(_file => {
                                    return (
                                        <div style={{ padding:"5px", display:"flex", height:"35px", marginTop:"2px"}}>
                                            <div>
                                                <img alt="arquivo" style={{maxWidth:"20px"}} src={_file.src} onError={(e)=>{e.target.src=getIconeFile(_file.type,_file.name) }}/>
                                            </div>
                                            <div style={{marginLeft:"10px"}}>
                                                <div style={{maxWidth: "75px",fontSize: "6px",whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>
                                                    <span>{_file.name}</span>
                                                </div>
                                                <div style={{textAlign:"right", marginTop:"-2px"}}>
                                                    <span style={{fontSize:"6px"}}>{parseFloat(_file.size / 1024).toFixed(2)} Mb</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            )}
                        </>
                    )}
                    </div>
                    <div style={{display:"flex",fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #25d366"}}>
                        <div style={{display:"flex", gap:"5px"}}>   
                            <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                                <i className="fa fa-copy text-primary"></i>
                            </div>
                            <div onClick={() => deleteNode()} title="Excluir Mensagem" className="workflow-delete" >
                                <i className="fa fa-trash text-danger"></i>
                            </div>
                        </div>
                        <div title="Número de vezes que o lead passou pela ação">
                            <span style={{fontSize:"10px", color:"#25d366"}}>{statistic || ''}</span>
                        </div>
                    </div>
            </div>

            <Handle type="source" position={Position.Bottom} id='node-message1'  />
            <Handle type="target" position={Position.Top} id='node-message2' />
            <Handle type="target" position={Position.Left} id='node-message3' />
            <Handle type="source" position={Position.Right} id='node-message4'  />
        </>
  );
};

export default memo(WhatsAppNode);
