import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import { ValidationForm} from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";
import api from '../../../services/api';
import Loading from '../../../components/Loading';

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalBridgeNode({ nodeId, showModal, handleCloseModal, nodeData}) {
    const [loading, setLoading]               = useState(false)
    const [idattendance, setIdAttendance]     = useState(0);
    const [attendance, setAttendance]         = useState('')
    const [dataAttendance, setDataAttendance] = useState([])
    const reactFlowInstance                   = useReactFlow();

    const handleAttendance = (id,text) => {
        setIdAttendance(id)
        setAttendance(text)
    }
    
    useEffect(() => {
        const getData = async() => {
            setLoading(true)
            await api.get('/getUserData')
                     .then(response => {
                        setIdAttendance(nodeData.idattendance || 0);
                        setDataAttendance(response.data)  
                        setLoading(false) 
                     })
                     .catch( error => {      
                        ShowMessages(error) 
                     })
        }

        getData()
    }, [nodeData]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (idattendance === 0) {
            ShowMessages({
                status: 500,
                message: "Informe um atendente",
            });
            return;
        }

        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node       : parseInt(nodeData.node),
                            parentId   : parseInt(nodeData.parentId), 
                            targetId   : parseInt(nodeData.targetId),
                            objectType : 15,
                            isClosed   : true, 
                            isHuman    : false,
                            idattendance,
                            attendance
                        },
                    };
                }

                return node;
            })
        );

        handleCloseModal();
    };

    return (
        <Modal size="md" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width450"  animation={true}  backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-people-arrows"></i> Ponte de Atendimento
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth330px"/>
                    )}
                    <div className={`box-shadow ${loading ? "hidden" : ""}`} style={{padding:"10px"}}>                      
                        <div style={{marginTop:"5px"}}>
                            <div className="mb-3">
                                <label>Atendente</label>
                                <select className="form-select" value={idattendance} onChange={e => handleAttendance(e.target.value, e.target.options[e.target.selectedIndex].text)}>
                                    <option value={0}>Selecione</option>
                                    {dataAttendance.map((_item, key) => {
                                        return (
                                            <option value={_item.iduser} key={key}>{_item.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-audio-info">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
    </Modal>
  );
}
