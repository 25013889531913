import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";
import Parameters from "../../../components/parameters";

import EmojiPicker from 'emoji-picker-react';
import EMOJI from './../../../assets/Emoji.png'

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalMessageNode({
    nodeId,
    showModal,
    handleCloseModal,
    nodeData,
}) {
    const [message, setMessage] = useState("");
    const [showParams, setShowParams] = useState(false);
    const [showEmoji, setShowEmoji] = useState(false)
    const [timeToAction, setTimeToAction] = useState(0)

    const reactFlowInstance = useReactFlow();

    const handleHideParams = () => {
        setShowParams(false);
    };

    const handleTextParams = (textParams) => {
        const text = textParams.target.innerText;
        const textParam = text.substring(0, text.indexOf("]") + 1);

        const insertParamsToMessage = message + " " + textParam + " ";
        setMessage(insertParamsToMessage);
        handleHideParams();
    };

    useEffect(() => {
        setMessage(nodeData.message);
        setTimeToAction(nodeData.timeToAction || 0)
    }, [nodeData]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (timeToAction < 0 || timeToAction > 120) {
            ShowMessages({ status: 500, message: "O status de digitando só pode durar entre 0 e 120 segundos" });
            return;
        }

        if (message.length === "") {
            ShowMessages({ status: 500, message: "Campo mensagem está vazio!" });
            return;
        }

        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node: parseInt(nodeData.node),
                            parentId: parseInt(nodeData.parentId),
                            targetId: parseInt(nodeData.targetId),
                            timeToAction,
                            objectType: 1,
                            message
                        },
                    };
                }

                return node;
            })
        );

        handleCloseModal();
    };

    return (
        <Modal
            size="md"
            show={showModal}
            onSubmit={handleSubmit}
            onHide={handleCloseModal}
            dialogClassName="maxHeight450 width500"
            animation={true}
            backdrop="static"
            dialogAs={DraggableModalDialog}
        >
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-comments"></i> Mensagens de Texto
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showParams ? (
                        <div className="box-shadow" style={{ padding: "10px" }}>
                            <Parameters
                                handleHideParams={handleHideParams}
                                handleTextParams={handleTextParams}
                            ></Parameters>
                        </div>
                    ) : (
                        <div className="box-shadow" style={{ padding: "10px" }}>
                            <div className="alert alert-primary" style= {{display:"flex", flexDirection:"column"}}>
                                <span>Palavras entre * ficam em <b>negrito</b></span>
                                <span>Palavras entre _ ficam em <i>itálico</i></span>
                                <span>Palavras entre ~ ficam <s>riscadas</s></span>
                            </div>
                            <div className="my-2">
                                <label>Mensagem</label>
                                <div className="input-group">
                                    <TextInput
                                        multiline={true}
                                        rows="6"
                                        cols="50"
                                        name="message"
                                        id="message"
                                        type="text"
                                        required
                                        autoFocus={true}
                                        onFocus={e => setShowEmoji(false)}
                                        maxLength={2000}
                                        errorMessage={{
                                            required: "Por favor, informe uma mensagem!",
                                        }}
                                        value={message}
                                        className="form-control "
                                        onChange={(e) => setMessage(e.target.value)}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div className="d-flex" style={{ justifyContent: "space-between" }}>
                                <div>
                                    <div className="d-flex">
                                        <button
                                            type="button"
                                            className={`btn btn-warning`}
                                            onClick={(e) => setShowParams(true)}
                                        >
                                            <i className="fa fa-icons" style={{ fontSize: "12px" }} />{" "}
                                            <span style={{ fontSize: "12px" }}>Parâmetros</span>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <div className="emoji-button" onClick={e => setShowEmoji(!showEmoji)}>
                                        <div>
                                            <img src={EMOJI} alt="" />
                                        </div>
                                    </div>
                                </div>
                                {showEmoji && (
                                    <div style={{ position: "fixed", top: "-125px", left: "500px" }}>
                                        <EmojiPicker onEmojiClick={e => setMessage(message + ' ' + e.emoji)} searchPlaceholder="Pesquisar" previewConfig={{
                                            showPreview: false
                                        }} categories={[
                                            {
                                                category: 'smileys_people',
                                                name: 'Pessoas e Rostos',
                                            },
                                            {
                                                category: 'animals_nature',
                                                name: 'Animais e Natureza',
                                            },
                                            {
                                                category: 'food_drink',
                                                name: 'Comida e Bebida',
                                            },
                                            {
                                                category: 'travel_places',
                                                name: 'Viagem e Lugares',
                                            },
                                            {
                                                category: 'activities',
                                                name: 'Atividades',
                                            },
                                            {
                                                category: 'objects',
                                                name: 'Objetos',
                                            },
                                            {
                                                category: 'symbols',
                                                name: 'Símbolos',
                                            },
                                            {
                                                category: 'flags',
                                                name: 'Bandeiras',
                                            }
                                        ]} />
                                    </div>
                                )}
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "5px", marginTop: "15px" }}>
                                <span>Enviar o status de digitando por</span>
                                <div style={{ width: "100px" }}>
                                    <input type="number" className="form-control" min={0} max={120} value={timeToAction} onChange={e => setTimeToAction(e.target.value)} />
                                    <small style={{color:"red"}}>0 não envia o status</small>
                                </div>
                                <span>segundos</span>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-campaign">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button
                        type="button"
                        className="btn-ligth-gray"
                        onClick={handleCloseModal}
                    >
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    );
}
