import React, { useState, useEffect } from 'react'

import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Button, Nav, NavLink, NavItem, TabPane, TabContent } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import { TagsInput } from "react-tag-input-component";

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import loadingButton from '../layouts/Buttons'
import { getFields } from "./../../components/Utils";


class DraggableModalDialog extends React.Component {
    render() {
        return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
    }
}

export default function ModalIntegrationEmail({ showModal, handleCloseModal, editState, data, handleReload }) {
    const [loading, setLoading] = useState(false)
    const [getAttachments, setGetAttachments] = useState(false)
    const [intervalMail, setIntervalMail] = useState(1)
    const [createleads, setCreateLeads] = useState(false)
    const [checkjunk, setCheckjunk] = useState(false)
    const [monitorytitle, setMonitoryTitle] = useState('')
    const [extension, setExtension] = useState('')
    const [tags, setTags] = useState([])

    const [tag, setTag] = useState('')
    const [startText, setStartText] = useState('')
    const [endText, setEndText] = useState('')
    const [tagMatch, setTagMatch] = useState('')

    const [dataTags, setDataTags] = useState([])
    const [tagsLead, setTagsLead] = useState([])
    const [tagLead, setTagLead] = useState('')

    const [tabActive, setTabActive] = useState(0)

    const [fields, setFields] = useState([])
    const [chatData, setChatData] = useState([])
    const [idautomationchat, setIDAutomationChat] = useState(0)

    const removeTag = (_tag) => {
        const _remove = tags.filter((t, i) => {
            if (t.tag !== _tag)
                return true;

            return false;
        });
        setTags(_remove)
    }

    const addTag = (e) => {
        e.preventDefault()

        if (tag === '') {
            ShowMessages({ status: 500, message: "Tag não informada" })
            return
        }

        if (tag.indexOf(' ') >= 0) {
            ShowMessages({ status: 500, message: "Tag não pode conter espaços" })
            return 
        }

        if (startText === '') {
            ShowMessages({ status: 500, message: "Texto inicial não informado" })
            return
        }

        if (startText.indexOf(' ') >= 0) {
            ShowMessages({ status: 500, message: "Texto inicial não pode conter espaços" })
            return 
        }

        if (endText === '') {
            ShowMessages({ status: 500, message: "Texto final não informado" })
            return
        }

        if (endText.indexOf(' ') >= 0) {
            ShowMessages({ status: 500, message: "Texto final não pode conter espaços" })
            return 
        }

        setTags(tags => tags.concat({
            tag,
            startText,
            endText,
            tagMatch
        }))
        setTag('')
        setStartText('')
        setEndText('')
        setTagMatch('')
    }

    const editTag = (_tag) => {
        let _edit = tags.filter((t, i) => {
            if (t.tag === _tag)
                return true;

            return false;
        })[0];

        setTag(_edit.tag)
        setStartText(_edit.startText)
        setEndText(_edit.endText)
        setTagMatch(_edit.tagMatch)

        removeTag(_tag)
    }

    const setData = (obj, clear) => {
        setCreateLeads(clear ? false : obj.createleads)
        setCheckjunk(clear ? false : obj.checkjunk)
        setMonitoryTitle(clear ? '' : obj.monitorytitle)
        setIntervalMail(clear ? '' : obj.interval)
        setTags(clear ? [] : obj.tags || [])
        setTagsLead(clear ? [] : obj.tagsemail || [])
        setIDAutomationChat(clear ? 0 : obj.idautomationchat)
        setGetAttachments(clear ? false : obj.getattachments)
        setExtension(clear ? '' : obj.extension)
        setTagLead('')
        setTabActive(0)

    }

    const getData = async () => {
        try {
            await api.get('/getDataCampaing')
                .then(async response1 => {

                    await api.get('/getChatBotGroup')
                                 .then(async response2 => {
                                    setChatData(response2.data)
                                 })
                                 .catch( error => {
                                    ShowMessages(error)
                                    setLoading(false) 
                                 })

                
                    setFields(getFields())
                })
                .catch(error => {
                    ShowMessages(error)
                })
        } catch (response) {
            ShowMessages(response)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (parseInt(intervalMail) < 1) {
            ShowMessages({ status: 500, message: "Você não pode informar um intervalo menor que 1 minuto" })
        }

        if ((tagsLead.length <= 0) && (createleads)){
            ShowMessages({ status: 500, message: 'Informe uma tag de de contato antes de gravar!' })
            return
        }

        if (getAttachments && (extension === '')) {
            ShowMessages({ status: 500, message: 'Informe uma extensão para o arquivo a ser baixado' })
            return
        }

        let _checkExtension = tags.filter(_tag => {
            return _tag.tag === '[CSV]'
        })

        if ((_checkExtension.length <= 0) && (extension === '.csv')){
            ShowMessages({ status: 500, message: 'Em configurações de Email, quando e marcado a opção de obter arquivo em CSV é obrigatório o uso da tag [CSV]' })
            return
        }

        _checkExtension = tags.filter(_tag => {
            return _tag.tag === '[ANEXO]'
        })

        if ((_checkExtension.length <= 0) && (extension === 'anexo')){
            ShowMessages({ status: 500, message: 'Em configurações de Email, quando e marcado a opção de obter arquivo é obrigatório o uso da tag [ANEXO]' })
            return
        }

        let _check = tags.filter(_tag => {
            return _tag.tag === '[PHONE]'
        })

        if (_check.length <= 0) {
            ShowMessages({ status: 500, message: 'Em configurações de Email, é obrigatório o uso da tag [PHONE]' })
            return
        }

        loadingButton(true, document.querySelector('#submit-integration-email'), document.querySelector('#submit-integration-email').children[0].classList, document.querySelector('#submit-integration-email').lastChild.nodeValue)

        try {
            let _data = {
                tagsLead,
                createleads,
                checkjunk,
                monitorytitle,
                tags,
                extension,
                getAttachments,
                intervalMail,
                idautomationchat
            }

            if (editState)
                await api.put('/integration-email/' + data.idintegrationemail, _data)
                    .then(response => {
                        ShowMessages(response)
                        if (response.ok) {
                            if (response.data.status === 200) {
                                handleCloseModal()
                                handleReload(true)
                            } else
                                loadingButton(false, document.querySelector('#submit-integration-email'))
                        } else
                            loadingButton(false, document.querySelector('#submit-integration-email'))
                    })
                    .catch(error => {
                        loadingButton(false, document.querySelector('#submit-integration-email'))
                        ShowMessages(error)
                    })
            else
                await api.post('/integration-email', _data)
                    .then(response => {
                        ShowMessages(response)
                        if (response.ok) {
                            if (response.data.status === 200) {
                                handleCloseModal()
                                handleReload(true)
                            } else
                                loadingButton(false, document.querySelector('#submit-integration-email'))
                        } else
                            loadingButton(false, document.querySelector('#submit-integration-email'))
                    })
                    .catch(error => {
                        loadingButton(false, document.querySelector('#submit-integration-email'))
                        ShowMessages(error)
                    })
        } catch (response) {
            loadingButton(false, document.querySelector('#submit-integration-email'))
            ShowMessages(response)
        }
    }

    //
    const addValidationTag = (_tagLead) => {
        if (_tagLead.trim() === '')
            return false
        _tagLead = _tagLead.toString().toUpperCase()
        return !tagsLead.includes(_tagLead)
    }

    const handleTagInput = (value) => {
        value = value.map(function (x) { return x.toString().toUpperCase().replace(/[^a-zA-Z0-9_]/g, ''); })

        if (value === '')
            return false

        if (tagsLead.length !== value.length)
            setTagsLead(value)
    }

    useEffect(() => {
        if (editState)
            setData(data, false)
        else
            setData({}, true)
    }, [data, editState, showModal])

    useEffect(() => {
        getData()
        setStatistic('modals\\modal-integration-email')
    }, [])

    return (
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content">
                <Modal.Header closeButton>
                    <Modal.Title style={{ marginBottom: "0px" }}>
                        <i className="fa fa-envelopes-bulk"></i> Propriedades de Integração por Email
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} />
                    )}
                    <div className={`${loading ? "hidden" : ""}`}>
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={tabActive === 0 ? "active" : ""} onClick={() => { setTabActive(0); }}>
                                    <span id="personal-data">Configurações Gerais</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tabActive === 1 ? "active" : ""} onClick={() => { setTabActive(1); }}>
                                    <span id="other-contacts">Configurações de Email</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={tabActive}>
                            <TabPane tabId={0}>
                                <div style={{ display: "flex", gap:"5px" }}>
                                    <div style={{ display: "flex", marginTop: "5px", width:"100%", gap:"5px"}}>
                                        <div style={{width: "100%" }}>
                                            <label>Título a ser monitorado</label><label className="text-required">*</label>
                                            <input type="text" className="form-control" required value={monitorytitle} onChange={e => setMonitoryTitle(e.target.value)} />
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "column", width:"350px", justifyContent:"end"}}>
                                        <div style={{ display: "flex" }}>
                                            <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                <input type="checkbox" checked={createleads} value={createleads} onChange={(e) => setCreateLeads(!createleads)} />
                                                <span style={{ marginTop: "5px" }}></span>
                                            </label>
                                            <span style={{ marginLeft: "5px", marginTop: "5px" }}>Gravar dados como leads?</span>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                <input type="checkbox" checked={checkjunk} value={checkjunk} onChange={(e) => setCheckjunk(!checkjunk)} />
                                                <span style={{ marginTop: "5px" }}></span>
                                            </label>
                                            <span style={{ marginLeft: "5px", marginTop: "5px" }}>Monitorar a pasta lixo eletrônico?</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:"flex",  gap:"5px", width:"100%"}}>
                                    <div style={{ display: "flex", width:"485px" }}>
                                        <div style={{ width: "100%" }}>
                                            <span>Chatbot</span><label className="text-required">*</label>

                                            <select className="form-select" value={idautomationchat} onChange={e => setIDAutomationChat(e.target.value)} required>
                                                <option value="">Selecione</option>
                                                {chatData.map((item) => {
                                                    return (<option value={item.idautomationchat}>{item.title}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div style={{  width: "150px", display:"flex", alignItems:"center", gap:"5px" }}>
                                        <div>
                                            <label>Monitorar a cada</label><label className="text-required">*</label>
                                            <input type="number" className="form-control" required value={intervalMail} onChange={e => setIntervalMail(e.target.value)} />
                                        </div>
                                        <small style={{marginTop:"15px"}}>minutos</small>
                                    </div>  
                                </div>
                                {createleads && (
                                    <div style={{ display: "flex" }}>
                                        <div style={{ width: "100%" }}>
                                            <div style={{ display: "flex", marginTop: "15px" }}>
                                                <div style={{ width: "300px" }}>
                                                    <label>Tags de Contato</label><label className="text-required">*</label>
                                                    <div className="input-group">
                                                        <TagsInput value={tagsLead} onChange={e => handleTagInput(e)} beforeAddValidate={e => addValidationTag(e)} />
                                                    </div>
                                                    <small className="text-danger">Após digitar a <span style={{ fontWeight: "bold" }}>Tag</span>, aperte <span style={{ fontWeight: "bold" }}>Enter</span></small>
                                                </div>

                                                <div style={{ marginLeft: "15px" }}>
                                                    <button title="Click aqui para incluir uma tag já existente" type="button" className="btn btn-success" style={{ marginTop: "15px" }} onClick={e => handleTagInput(addValidationTag(tagLead) ? [...tagsLead, tagLead] : tagsLead)}><i className="fa fa-arrow-left"></i></button>
                                                </div>
                                                <div style={{ marginLeft: "15px" }}>
                                                    <div style={{ width: "200px" }}>
                                                        <span>Tags já existentes</span>
                                                        <select className="form-select" value={tagLead} onChange={e => setTag(e.target.value)} style={{ minHeight: "37px" }}>
                                                            <option value="">Selecione</option>
                                                            {dataTags.map((item) => {
                                                                return (<option value={item.tags}>{item.tags}</option>)
                                                            })}
                                                        </select>
                                                    </div>
                                                    <small className="text-danger">Selecione uma <span style={{ fontWeight: "bold" }}>Tag</span> e click no botão ao lado para inserir</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </TabPane>
                            <TabPane tabId={1}>
                                
                                <div style={{  display: "flex", flexDirection: "column" }}>
                                    <div style={{display:"flex", marginBottom:"10px", paddingBottom:"10px", borderBottom:"1px solid #c9c9c9", gap:"15px"}}>
                                        <div style={{display:"flex"}}>
                                            <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                <input type="checkbox" checked={getAttachments} 
                                                                        value={getAttachments} 
                                                                        onClick={e => setGetAttachments(!getAttachments)} />
                                                <span style={{ marginTop: "5px" }}></span>
                                            </label>
                                            <div  style={{marginLeft:"5px", marginTop:"2px", alignItems:"center", display:"flex"}}>
                                                <span>Deseja obter os anexos?</span>
                                            </div>
                                        </div>
                                        {getAttachments && (
                                            <>
                                                <div style={{display:"flex", flexDirection:"column", width:"200px"}}>
                                                    <span>Formato do Arquivo</span>
                                                    <select className='form-select' value={extension} onChange={e => setExtension(e.target.value)}>
                                                        <option value=''>Selecione</option>
                                                        <option value='.csv'>CSV</option>
                                                        <option value='anexo'>ANEXO</option>
                                                    </select>
                                                </div>
                                                <div className='alert alert-warning'>
                                                    <div>
                                                        <span style={{fontWeight:"bold"}}>Formatos</span>
                                                    </div>
                                                    <div style={{display:"flex", flexDirection:"column"}}>
                                                        <div style={{display:"flex", gap:"10px"}}>
                                                            <span style={{fontWeight:"bold", width:"50px"}}>.CSV</span><span>Quando utilizado a opção de CSV, é obrigatório o uso da tag [CSV]</span>
                                                        </div>
                                                        <div style={{display:"flex", gap:"10px"}}>
                                                            <span style={{fontWeight:"bold", width:"50px"}}>ANEXO</span><span>Quando utilizado a opção de ANEXO, é obrigatório o uso da tag [ANEXO]</span>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </>

                                        )}
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div>
                                            <label>TAG</label>
                                            <input type="text" className="form-control" maxLength={50} value={tag} onChange={e => setTag(e.target.value)} />
                                        </div>
                                        <div style={{ marginLeft: "15px" }}>
                                            <label>Texto Inicial</label>
                                            <input type="text" className="form-control" maxLength={50} value={startText} onChange={e => setStartText(e.target.value)} />
                                        </div>
                                        <div style={{ marginLeft: "15px" }}>
                                            <label>Texto Final</label>
                                            <input type="text" className="form-control" maxLength={50} value={endText} onChange={e => setEndText(e.target.value)} />
                                        </div>
                                        <div style={{ marginLeft: "15px" }}>
                                            <label>TAG Correspondente</label>
                                            <select className="form-select" value={tagMatch} onChange={e => setTagMatch(e.target.value)}>
                                                {
                                                    fields.map(_item => {
                                                        return (
                                                            <option value={_item.value}>{_item.label}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <button type="button" className="btn btn-success" style={{ marginLeft: "5px", marginTop: "15px" }} onClick={e => addTag(e)}><i className="fa fa-plus"></i></button>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "20px", overflowY: "auto", maxHeight: "320px", width: "727px" }}>
                                        <table className="table table-striped w-100">
                                            <thead className="thead-theme">
                                                <tr>
                                                    <th className='head-th' >TAG</th>
                                                    <th className='head-th'>Texto Inicial</th>
                                                    <th className='head-th'>Texto Final</th>
                                                    <th className='head-th'>TAG Correspondente</th>
                                                    <th className='head-th' style={{ textAlign: "center" }}>...</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tags.map((_tag, id) => {
                                                    return (<tr className="gradeX" key={id}>
                                                        <td style={{ verticalAlign: "middle" }}>{_tag.tag}</td>
                                                        <td style={{ verticalAlign: "middle" }}>{_tag.startText}</td>
                                                        <td style={{ verticalAlign: "middle" }}>{_tag.endText}</td>
                                                        <td style={{ verticalAlign: "middle" }}>{_tag.tagMatch}</td>
                                                        <td style={{ textAlign: "center", verticalAlign: "middle", width:"100px" }}>
                                                            <button type='button' className="btn btn-warning btn-sm" onClick={e => editTag(_tag.tag)}><i className="fa fa-edit" /></button>
                                                            <button type='button' className="btn btn-danger btn-sm" onClick={e => removeTag(_tag.tag)} style={{ marginLeft: "5px" }}><i className="fa fa-trash" /></button>
                                                        </td>
                                                    </tr>)
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                        <Button type="submit" id="submit-integration-email" className={`btn-theme ${loading ? "hidden" : ""}`}>
                            <em className="fa fa-save"></em> Salvar
                        </Button>
                        <Button className="btn-ligth-gray" onClick={handleCloseModal} style={{ marginLeft: "15px" }}>
                            <em className="fa fa-door-closed"></em> Fechar
                        </Button>
                    </div>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    )
}