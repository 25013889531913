import { memo, useCallback, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import ModalConditionNode from "../../../pages/modals/workflow/modal-condition-node";

const ConditionNodeItem = ({ handleId, value, icon }) => {
    return (
        <div className="custom-node__options" style={{ minWidth: "95px" }}>
            <li className="list-item d-flex align-items-center" style={{ minWidth: "95px" }}>
                <div className="list-marker me-1">
                    <i className={icon}></i>
                </div>
                <div style={{ textOverflow: "ellipsis", maxWidth: "170px", whiteSpace: "nowrap", overflow: "hidden" }} title={value}>{value}</div>
            </li>
            <Handle type="source" position={Position.Right} id={`node-condition${handleId}`} style={{ marginLeft: "150px" }} />
        </div>

    );
};

const ConditionNode = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [condition, setConditions] = useState('');
    const [conditionId, setConditionId] = useState(-1);
    const [conditionData, setConditionData] = useState([])
    const [type, setType] = useState('')
    const [tagCondition, setTagCondition] = useState('')
    const [tagLead, setTagLead] = useState('')
    const [id, setID] = useState(-1)
    const [statistic, setStatistic] = useState(0)
    const [options, setOptions] = useState([])

    const reactFlowInstance = useReactFlow();

    const constas = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            setConditions(nodeInfo.condition);
            setConditionId(nodeInfo.conditionId);
            setConditionData(nodeInfo.conditionData)
            setType(nodeInfo.type)
            setTagCondition(nodeInfo.tagCondition)
            setOptions(nodeInfo.options)
            setTagLead(nodeInfo.tagLead)
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
        };
        handleNodeProps();
    }, [props.data]);

    const copy = (e) => {
        e.stopPropagation()
        let _id = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat({
            ...props,
            id: _id,
            position: { x: _get.position.x, y: _get.position.y + _get.height + 20 },
            height: _get.height,
            width: _get.width,
            data: {
                nodeInfo: {
                    node: _id,
                    parentId: -1,
                    targetId: -1,
                    condition: props.data.nodeInfo.condition,
                    conditionId: props.data.nodeInfo.conditionId,
                    conditionData: props.data.nodeInfo.conditionData,
                    type: props.data.nodeInfo.type,
                    tagCondition: props.data.nodeInfo.tagCondition,
                    options: [],
                    tagLead: props.data.nodeInfo.tagLead,
                    objectType: 11
                }
            }
        }))
    }

    const handleShowModal = (e) => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <ModalConditionNode nodeId={props.id} showModal={showModal} handleCloseModal={handleCloseModal} nodeData={props.data.nodeInfo}></ModalConditionNode>
            <div className="rfnode rfnode-condition d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) => handleShowModal(e)}>
                <div className="rfnode-title">#{id} Condição</div>

                <div style={{ padding: "5px" }}>
                    <div style={{ background: "rgba(131, 145, 146, 0.1098039216)", borderRadius: "5px", padding: "5px", alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <span style={{ fontSize: "8px", color: "#262728", textAlign: "center" }}>{condition === '' ? 'Escolha a condição' : condition}</span>
                    </div>
                    <div>
                        {options.length > 0 && (
                            <div style={{ background: "rgba(131, 145, 146, 0.1098039216)", borderRadius: "5px", padding: "5px", alignItems: "center", display: "flex", justifyContent: "center", marginTop: "5px", flexDirection: "column" }}>

                                {
                                    (options || []).map(_item => {
                                        return (<div style={{ position: "sticky", background: "#839192", color: "#262728", borderRadius: "5px", padding: "5px", display: "flex", height: "27px", marginTop: "2px", fontSize: "8px", alignItems: "center", width: "125px" }}>
                                            <ConditionNodeItem
                                                value={_item.value}
                                                handleId={_item.id}
                                                icon={_item.icon}
                                            ></ConditionNodeItem>
                                        </div>)
                                    })
                                }

                            </div>)}
                    </div>
                </div>
                <div style={{ display: "flex", fontSize: "8px", padding: "3px 5px", justifyContent: "space-between", width: "100%", borderTop: "1px dashed #839192" }}>
                    <div style={{ display: "flex", gap: "5px" }}>
                        <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                            <i className="fa fa-copy text-primary"></i>
                        </div>
                        <div onClick={() => constas()} title="Excluir Mensagem" className="workflow-delete" >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    </div>

                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{ fontSize: "10px", color: "#839192" }}>{statistic || ''}</span>
                    </div>
                </div>
            </div>

            <Handle type="target" position={Position.Bottom} id='node-condition1' />
            <Handle type="target" position={Position.Left} id='node-condition2' />
            <Handle type="target" position={Position.Top} id='node-condition3' />
        </>
    );
};

export default memo(ConditionNode);
