import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'
import { TagsInput } from "react-tag-input-component";

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalUpdateLead({showModal, handleCloseModal, data, handleReload}) {
    const [loading, setLoading]      = useState(false)
    const [dataTags, setDataTags]    = useState([])
    const [tags, setTags]            = useState('')
    const [tag, setTag]              = useState('')

    const getData = async() => {
        try {
            await api.get('/getDataCampaing')
                    .then(response => { 
                        let _tags  = []
                        let _check = []

                        response.data.tags.map(item => {
                            if (item.tags.length > 1) {
                                item.tags.map(item1 => {
                                    _check = _tags.filter(_filter => {
                                        return (_filter.tags[0] === item1)
                                    })

                                    if (_check.length === 0)
                                        _tags.push({'tags' : [item1]}) 
                                })
                            } else {
                                _check = _tags.filter(_filter => {
                                    return _filter.tags[0] === item.tags[0]
                                })

                                if (_check.length === 0)   
                                    _tags.push(item)
                            }
                        })  
                        setDataTags(_tags)
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            ShowMessages(response)
        }
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-update-lead'),document.querySelector('#submit-update-lead').children[0].classList,document.querySelector('#submit-update-lead').lastChild.nodeValue)
        try {
            let _data = {tags}

            await api.post('/leads/update/' + data.idpeople, _data)
                     .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else
                                 loadingButton(false,document.querySelector('#submit-update-lead'))
                         } else
                             loadingButton(false,document.querySelector('#submit-update-lead'))
                     })
                     .catch( error => {
                         loadingButton(false,document.querySelector('#submit-update-lead'))
                         ShowMessages(error) 
                     })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-update-lead'))
            ShowMessages(response)
        }
    }
    
    const handleTagInput = (value) => { 
        value = value.map(function(x){ return x.toString().toUpperCase().replace(/[^a-zA-Z0-9_]/g,''); })     
        
        if (value === '')
            return false   
        
        if (tags.length !== value.length)
            setTags(value)
    }

    const addValidationTag = (_tag) => {
        if (_tag.trim() === '')
            return false
        _tag = _tag.toString().toUpperCase()
        return !tags.includes(_tag)
    }

    const setData = (_data) => {
        console.log(_data)
        setTags(_data.tags || [])
    }

    useEffect(() => {
        setData(data)
    },[data,showModal])
   
    useEffect(() => {
        getData()
        setStatistic('modals\\modal-update-leads')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-user"></i> Propriedades de Lead
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading}/>
                    )}
                    <div className={`box-shadow ${loading ? "hidden" : ""}`}>               
                        <div style={{display:"flex", border:"1px dashed #cacaca", padding:"5px", borderRadius:"5px", flexDirection:"column", marginTop:"15px"}}>
                            <div style={{marginTop:"-15px"}}>
                                <span style={{fontWeight:"bold", background:"#fff"}}>Alteração de Tags do Lead</span>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{width:"300px"}}>
                                    <label>Tags</label>
                                    <div className="input-group">
                                        <TagsInput value={tags} onChange={e => handleTagInput(e)} beforeAddValidate={e => addValidationTag(e)}/>
                                    </div>
                                    <small className="text-danger">Após digitar a <span style={{fontWeight:"bold"}}>Tag</span>, aperte <span style={{fontWeight:"bold"}}>Enter</span></small>
                                </div>     
                        
                                <div style={{marginLeft:"15px", display:"flex",alignItems:"center",justifyContent:"center"}}>
                                    <button title="Click aqui para incluir uma tag já existente" type="button" className="btn btn-success" style={{marginTop:"15px"}} onClick={e => handleTagInput(addValidationTag(tag) ? [...tags,tag] : tags)}><i className="fa fa-arrow-left"></i></button>
                                </div>       
                        
                                <div style={{marginLeft:"15px", display:"flex",alignItems:"center",justifyContent:"center", flexDirection:"column"}}>
                                    <div style={{width:"200px"}}>
                                        <span>Tags já existentes</span>
                                        <select className="form-select" value={tag} onChange={e => setTag(e.target.value)} style={{minHeight:"37px"}}>
                                            <option value="">Selecione</option>
                                            {dataTags.map((item) => {
                                                return(<option value={item.tags}>{item.tags}</option>)
                                            })} 
                                        </select>
                                    </div>
                                    <small className="text-danger">Selecione uma <span style={{fontWeight:"bold"}}>Tag</span> e click no botão ao lado para inserir</small>
                                </div>                                    
                            </div>                          
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-update-lead" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}