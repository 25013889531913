import { memo, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import ModalNodeStart from "../../../pages/modals/workflow/modal-start";
import { phone_format} from "./../../Utils";

const StartNode = (props) => {
    const [triggerFlow, setTriggerFlow]      = useState("");
    const [commercial, setCommercial]        = useState(false)
    const [nonCommercial, setNonCommercial]  = useState(false)
    const [showModal, setShowModal]          = useState(false);
    const [name, setName]                    = useState('')
    const [number, setNumber]                = useState([])
    const [selectedTags, setSelectedTags]    = useState([])
    const [id, setID]                        = useState(-1)
    const [statistic, setStatistic]          = useState(0)
    const [limitLeads, setLimitLeads]        = useState(0)

    useEffect(() => {
        const handleDataNode = () => {
            const nodeInfo = props.data.nodeInfo;
            setCommercial(nodeInfo.commercial)
            setNonCommercial(nodeInfo.nonCommercial)
            handleActiveForMessage(nodeInfo.activeForMessage);
            setName(nodeInfo.name)
            setNumber(nodeInfo.number || [])
            setSelectedTags(nodeInfo?.selectedTags)
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
            setLimitLeads(nodeInfo?.limitLeads || 0)
        };
        handleDataNode();
    }, [props.data]);

    const handleActiveForMessage= (activeForMessage) => {
        switch (activeForMessage) {
            case 0:
                setTriggerFlow("Iniciar por Campanha, WebHook ou Gatilho");
                break;
            case 1:
                setTriggerFlow("Aguardar Contato");
                break;
            case 2:
                setTriggerFlow("Agendamento");
                break;
            case 3:
                setTriggerFlow("Notificações de Grupo(s)");
                break;
            default:
                break;
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <ModalNodeStart
                nodeId={props.id}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                nodeData={props.data.nodeInfo}
            ></ModalNodeStart>
            <div className="rfnode rfnode-start d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) =>  setShowModal(true)}>

                <div className="rfnode-title">#{id} Inicio do Fluxo</div>
                
                <div style={{textAlign:"center", padding:"5px", lineHeight:"0.8"}}>
                    <span style={{fontWeight:"bold", fontSize:"10px"}}>{name}</span>
                </div>
                <div style={{textAlign:"center", padding:"5px", lineHeight:"0.8", border:"1px dashed #cacaca", borderRadius:"5px"}}>
                    <span style={{fontSize:"7px"}}>{phone_format(number.toString())}</span>
                </div>
                <div className="my-2" style={{background:"rgb(39 194 76 / 29%)", padding:"5px", borderRadius:"5px", display:"flex", alignItems:"center", justifyContent:"center", width:"80%"}}>
                    <i className="fa fa-robot" style={{fontSize:"10px", marginRight:"2px", color:"#198754"}}></i>
                    <p className="p-0 m-0" style={{fontSize:"6px", color:"#198754", textAlign:"center"}}>{triggerFlow}{parseInt(limitLeads || 0) > 0 ? ` para ${limitLeads} leads` : ''}</p>
                </div>
                <div style={{display:"flex", gap:2, fontSize:"8px", padding:"1px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #0ac06c"}}>
                    <div>
                        <div title="Ativado a qualquer momento" className={`${((commercial && nonCommercial) || (!commercial && !nonCommercial)) ? '' : 'hidden'}`}>
                            <i className="fa fa-calendar-plus text-success"></i>
                        </div>
                        <div title="Ativado somente em horário comercial" className={`${!((commercial && nonCommercial) || (!commercial && !nonCommercial)) && commercial? '' : 'hidden'}`}>
                            <i className="fa fa-calendar-check text-primary"></i>
                        </div>
                        <div title="Ativado somente fora do horário comercial" className={`${!((commercial && nonCommercial) || (!commercial && !nonCommercial)) && nonCommercial? '' : 'hidden'}`}>
                            <i className="fa fa-calendar-xmark text-warning"></i>
                        </div>
                    </div>

                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{fontSize:"10px", color:"#198754"}}>{statistic || ''}</span>
                    </div>
                </div>
            </div>

            <Handle type="source" position={Position.Bottom} id='node-start1' />
            <Handle type="source" position={Position.Right} id='node-start2' />
        </>
    );
};

export default memo(StartNode);
