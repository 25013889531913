import React, {useState, useEffect} from 'react'

import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalCostCenter({showModal, handleCloseModal, idcampaign}) {
    const [loading, setLoading]       = useState(false)
    const [data, setData]             = useState([])
    const [reportType, setReportType] = useState(0)
       
    const getData = async() => {
        setLoading(true)
        if (idcampaign <= 0)
            return 
            
        await api.post('/campaign/metrics', {idcampaign})
                 .then(response => {
                      if (response.ok) {   
                          console.log(response.data)
                          setData(response.data)
                          setLoading(false)
                      }
                })
                 .catch( error => {
                    setLoading(false)
                     ShowMessages(error) 
                })

        
    }

    useEffect(() => {
        getData()
    },[idcampaign,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-campaign-metrics')
    } ,[])


    return (
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-chart-line"></i> Métricas da Campanha
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth390px"/>
                    )}
                    <div className={`${loading ? "hidden" : ""}`}>  
                        <div style={{display:"flex"}}>
                            <div className="alert-primary box-shadow" style={{width:"218px",padding:"10px", display:"flex", marginTop:"5px"}}>
                                <div style={{display:"flex",alignItems:"center"}}><i className="fa fa-earth-america" style={{fontSize:"30px"}}/></div>
                                <div style={{display:"flex",alignItems:"center", flexDirection:"column",width:"100%"}}>
                                    <div style={{textAlign:"center"}}>Alcance</div>
                                    {loading ? 
                                        <div style={{textAlign:"center", fontSize:"32px"}}><i className="fa fa-spinner fa-spin"/></div>
                                    :
                                        <div style={{textAlign:"center", fontSize:"32px"}}>{data.reach || 0}</div>
                                    }
                                </div>                                
                            </div>
                            <div className="alert-primary box-shadow" style={{width:"218px",padding:"10px", display:"flex", marginTop:"5px"}}>
                                <div style={{display:"flex",alignItems:"center"}}><i className="fa fa-paper-plane" style={{fontSize:"30px"}}/></div>
                                <div style={{display:"flex",alignItems:"center", flexDirection:"column",width:"100%"}}>
                                    <div style={{textAlign:"center"}}>Alcançado</div>
                                    {loading ? 
                                        <div style={{textAlign:"center", fontSize:"32px"}}><i className="fa fa-spinner fa-spin"/></div>
                                    :
                                        <div style={{textAlign:"center", fontSize:"32px"}}>{data.reached || 0}</div>
                                    }
                                </div>                                
                            </div>
                            <div className="alert-primary box-shadow" style={{width:"218px",padding:"10px", display:"flex", marginTop:"5px"}}>
                                <div style={{display:"flex",alignItems:"center"}}><i className="fa fa-bug" style={{fontSize:"30px"}}/></div>
                                <div style={{display:"flex",alignItems:"center", flexDirection:"column",width:"100%"}}>
                                    <div style={{textAlign:"center"}}>Erros</div>
                                    {loading ? 
                                        <div style={{textAlign:"center", fontSize:"32px"}}><i className="fa fa-spinner fa-spin"/></div>
                                    :
                                        <div style={{textAlign:"center", fontSize:"32px"}}>{data.errors || 0}</div>
                                    }
                                </div>                                
                            </div>
                            <div className="alert-primary box-shadow" style={{width:"218px",padding:"10px", display:"flex", marginTop:"5px"}}>
                                <div style={{display:"flex",alignItems:"center"}}><i className="fa fa-people-group" style={{fontSize:"30px"}}/></div>
                                <div style={{display:"flex",alignItems:"center", flexDirection:"column",width:"100%"}}>
                                    <div style={{textAlign:"center"}}>Aproveitamento</div>
                                    {loading ? 
                                        <div style={{textAlign:"center", fontSize:"32px"}}><i className="fa fa-spinner fa-spin"/></div>
                                    :
                                        <div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
                                            <div style={{textAlign:"center", fontSize:"32px"}}>{(data.reached / data.reach * 100 || 0).toFixed(2)}</div>
                                            <small>%</small>
                                        </div>
                                        
                                    }
                                </div>                                
                            </div>

                            <div className="alert-primary box-shadow" style={{width:"218px",padding:"10px", display:"flex", marginTop:"5px"}}>
                                <div style={{display:"flex",alignItems:"center"}}><i className="fa fa-exclamation" style={{fontSize:"30px"}}/></div>
                                <div style={{display:"flex",alignItems:"center", flexDirection:"column",width:"100%"}}>
                                    <div style={{textAlign:"center"}}>% Perda</div>
                                    {loading ? 
                                        <div style={{textAlign:"center", fontSize:"32px"}}><i className="fa fa-spinner fa-spin"/></div>
                                    :
                                        <div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
                                            <div style={{textAlign:"center", fontSize:"32px"}}>{(data.errors / data.reach * 100).toFixed(2) || 0}</div>
                                            <small>%</small>
                                        </div>
                                    }
                                </div>                                
                            </div>
                        </div>
                        <div style={{display:"flex"}}>
                            <div className="bg-secondary box-shadow" style={{width:"218px",padding:"10px", display:"flex", marginTop:"5px", color:"#fff"}}>
                                <div style={{display:"flex",alignItems:"center"}}><i className="fa fa-clock" style={{fontSize:"30px"}}/></div>
                                <div style={{display:"flex",alignItems:"center", flexDirection:"column",width:"100%"}}>
                                    <div style={{textAlign:"center"}}>Tempo de Envio</div>
                                    {loading ? 
                                        <div style={{textAlign:"center", fontSize:"32px"}}><i className="fa fa-spinner fa-spin"/></div>
                                    :
                                        <div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
                                            <div style={{textAlign:"center", fontSize:"32px"}}>{parseFloat(data.sendTime || 0).toFixed(2)}</div>
                                            <small>Horas</small>
                                        </div>
                                    }
                                </div>                                
                            </div>
                            <div className="bg-secondary box-shadow" style={{width:"218px",padding:"10px", display:"flex", marginTop:"5px", color:"#fff"}}>
                                <div style={{display:"flex",alignItems:"center"}}><i className="fa fa-share" style={{fontSize:"30px"}}/></div>
                                <div style={{display:"flex",alignItems:"center", flexDirection:"column",width:"100%"}}>
                                    <div style={{textAlign:"center"}}>Mensagens Enviadas</div>
                                    {loading ? 
                                        <div style={{textAlign:"center", fontSize:"32px"}}><i className="fa fa-spinner fa-spin"/></div>
                                    :
                                        <div style={{textAlign:"center", fontSize:"32px"}}>{data.sending}</div>
                                    }
                                </div>                                
                            </div>
                            <div className="bg-secondary box-shadow" style={{width:"218px",padding:"10px", display:"flex", marginTop:"5px", color:"#fff"}}>
                                <div style={{display:"flex",alignItems:"center"}}><i className="fa fa-hourglass-half" style={{fontSize:"30px"}}/></div>
                                <div style={{display:"flex",alignItems:"center", flexDirection:"column",width:"100%"}}>
                                    <div style={{textAlign:"center"}}>Tempo Médio</div>
                                    {loading ? 
                                        <div style={{textAlign:"center", fontSize:"32px"}}><i className="fa fa-spinner fa-spin"/></div>
                                    :
                                        <div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
                                            <div style={{textAlign:"center", fontSize:"32px"}}>{parseFloat(data.avgSend).toFixed(2)}</div>
                                            <small>Minutos</small>
                                        </div>
                                    }
                                </div>                                
                            </div>
                            <div className="bg-secondary box-shadow" style={{width:"218px",padding:"10px", display:"flex", marginTop:"5px", color:"#fff"}}>
                                <div style={{display:"flex",alignItems:"center"}}><i className="fa fa-comment" style={{fontSize:"30px"}}/></div>
                                <div style={{display:"flex",alignItems:"center", flexDirection:"column",width:"100%"}}>
                                    <div style={{textAlign:"center"}}>Mensagens Lidas</div>
                                    {loading ? 
                                        <div style={{textAlign:"center", fontSize:"32px"}}><i className="fa fa-spinner fa-spin"/></div>
                                    :
                                        <div style={{textAlign:"center", fontSize:"32px"}}>{data.read}</div>
                                    }
                                </div>                                
                            </div>
                            <div className="bg-secondary box-shadow" style={{width:"218px",padding:"10px", display:"flex", marginTop:"5px", color:"#fff"}}>
                                <div style={{display:"flex",alignItems:"center"}}><i className="fa fa-people-roof" style={{fontSize:"30px"}}/></div>
                                <div style={{display:"flex",alignItems:"center", flexDirection:"column",width:"100%"}}>
                                    <div style={{textAlign:"center"}}>Engajamento</div>
                                    {loading ? 
                                        <div style={{textAlign:"center", fontSize:"32px"}}><i className="fa fa-spinner fa-spin"/></div>
                                    :
                                        <div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
                                            <div style={{textAlign:"center", fontSize:"32px"}}>{(data.read / (data.reached || 1) * 100).toFixed(2)}</div>
                                            <small>%</small>
                                        </div>
                                    }
                                </div>                                
                            </div>
                        </div> 
                        <div style={{marginTop:"15px"}}>
                            <label>Tipo do Gráfico</label>
                            <select className="form-select" value={reportType} onChange={e => setReportType(parseInt(e.target.value))}>
                                <option value={0}>Enviado x Erros</option>
                                <option value={1}>Enviado x Lido</option>
                            </select>
                        </div>
                        <div style={{height:"300px", marginTop:"50px"}}>
                            {reportType === 0 ?
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        width={400}
                                        height={200}
                                        data={data.data}
                                        margin={{
                                            top: 0,
                                            right: 20,
                                            left: 0,
                                            bottom: 5,
                                        }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="erro" stroke="#8884d8" activeDot={{ r: 8 }} />
                                        <Line type="monotone" dataKey="enviado" stroke="#82ca9d" />
                                    </LineChart>
                                </ResponsiveContainer>
                            : ''}
                            {reportType === 1 ?
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        width={400}
                                        height={200}
                                        data={data.data}
                                        margin={{
                                            top: 0,
                                            right: 20,
                                            left: 0,
                                            bottom: 5,
                                        }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="enviado" stroke="#8884d8" activeDot={{ r: 8 }} />
                                        <Line type="monotone" dataKey="lido" stroke="#82ca9d" />
                                    </LineChart>
                                </ResponsiveContainer>
                            : ""}
                        </div>                                
                    </div>
                </Modal.Body>
                <Modal.Footer>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
        </Modal>
    )
}