import React, { memo, useCallback } from "react";
import { getSmoothStepPath, useReactFlow } from "reactflow";

const foreignObjectSize = 20;

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const reactFlowInstance = useReactFlow();

  const deleteEdge = useCallback(
    (_, edge) => {
      const deletedEdge = reactFlowInstance.getEdge(edge);

      reactFlowInstance.setNodes((nodes) => {
        nodes.map((node) => {
          if (node.id === deletedEdge.source) {
            if (deletedEdge.sourceHandle === 'node-action6') {
                node.data.nodeInfo.option = [{id : 6, targetId: -1}]
            } else {
                node.data.nodeInfo = {
                ...node.data.nodeInfo,
                    targetId: -1,
                };
            }
          }
          if (node.id === deletedEdge.target) {
            node.data.nodeInfo = {
              ...node.data.nodeInfo,
              parentId: -1,
            };
          }
          return node;
        });

        return nodes;
      });

      reactFlowInstance.setEdges((eds) => eds.filter((e) => e.id !== edge));
    },
    [reactFlowInstance]
  );

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div className="text-danger" onClick={(event) => deleteEdge(event, id)} style={{display:"flex", alignItems:"center", justifyContent:"center" ,background:"#fff", border:"1px solid #DEE2E6", borderRadius:"4px", padding:"4px", width:"20px", height:"20px"}}>
            <i className="fa fa-trash" style={{fontSize:"7px"}}></i>
        </div>
      </foreignObject>
    </>
  );
};

export default memo(CustomEdge);
