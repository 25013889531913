import React, {useState, useEffect} from 'react'

import { Button, } from 'reactstrap';
import Draggable from 'react-draggable';

import { phone_format} from "./../../components/Utils";
import { Modal, ModalDialog } from 'react-bootstrap';
import setStatistic from '../../services/apiStatistic'
import ShowMessages from '../../components/Exceptions';
import { useChatData } from './../../components/messageView/chatContext'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalSelectedNumber({showModal, handleCloseModal, props, showChat}) {
    const {  number } = useChatData()
    const [numberSelected, setNumberSelected]         = useState('')

    const handleSubmit = async(event) => {
        event.preventDefault()

        if (numberSelected === '') {
            ShowMessages({status: 500, message:'Informe um número para iniciar o atendimento'})
            return
        }
        
        handleCloseModal()
        showChat(props.notifyName,
                 props.photo,
                 props.to,
                 props._startHuman,
                 props._endHuman,
                 props.idautomationchatheadhistory,
                 props.idpeople,
                 props.idAttendant,
                 numberSelected,
                 false)
    }

    useEffect(() => {
        setNumberSelected(number)
        setStatistic('modals\\modal-selected-number')
    },[])
   
    return (
      
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fab fa-whatsapp"></i> Números Ativos
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div className={`box-shadow`} style={{padding:"15px"}}>            
                        <div>
                            <label>Números</label>
                            <div className="input-group">
                                 <select className='form-select' style={{width:"100%"}} value={numberSelected} onChange={e => setNumberSelected(e.target.value)}>
                                    <option value="">Selecione</option>
                                    {props._numbers && props._numbers.map((_item,id) => {
                                        return (
                                            <option key={id} value={_item.number}>{phone_format(_item.number)}</option>
                                        )
                                    })}
                                 </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button id='submit-transfer-user' className={`btn-theme`} onClick={e => handleSubmit(e)}>
                        <em className="fab fa-whatsapp"></em> Iniciar Atendimento
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
        </Modal>
       
    )
}