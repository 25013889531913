import { useEffect, useState } from "react";
import { getIconeFile } from "./Utils"

const FileVisualizer = ({ setSelectedFiles, style, imagesArray = [], fileSelected = null }) => {
    const [imagesToShow, setImagesToShow] = useState([]);
    const [fileShow, setFileShow]         = useState([])

    useEffect(() => {   
        const handleSelectedImages = () => {
            setImagesToShow(imagesArray);
        };

        if (imagesArray.length > 0) {
            setSelectedFiles(imagesArray)
            setFileShow(imagesArray[0])
            if (fileSelected)
                fileSelected(imagesArray[0])
        }
        handleSelectedImages();

    }, [imagesArray]);

    const deleteFiles = (name) => {
        let _idx = 0
        const _remove = imagesToShow.filter((t, i) => {
            if (t.name !== name) {
                _idx = i
                return true;
            }
        
            return false;
        });
        setSelectedFiles(_remove)
        setImagesToShow(_remove)

        if (name === fileShow.name) {
            setFileShow(_remove[_idx])
            if (fileSelected)
                fileSelected(_remove[_idx])
        }
    }

    const onClick = (image) => {
        fileSelected(image)
        setFileShow(image)
    }

    return (
        <div style={{maxWidth:"900px", overflowX:"auto",overflowY:"hidden"}}>
            <div  style={{...style,display:"flex", marginBottom:"5px"}}>
                {imagesArray.map((image) => (
                    <div style={{display:"flex", flexDirection:"column", width:"100px", minWidth:"100px", maxWidth:"100px",height:"100px",maxHeight:"100px",minHeight:"100px",cursor:"pointer"}} onClick={e => onClick(image)}>
                        <div style={{backgroundColor:`${fileShow?.file === image?.file ? '#f7f8f9' : '#fff'}`, padding:"5px", borderRadius:"5px", border:"1px dashed #cacaca",marginLeft: "5px", display:"flex", flexDirection:"column"}} key={image.src} title={image.name}>
                            <div style={{display:"flex", justifyContent:"space-between"}}>
                                <div style={{display:"flex", alignItems:"center",fontSize:"10px"}}>
                                    <small>{parseFloat((image.size / 1024) / 1024).toFixed(2)} Mb</small>
                                </div>
                                <div onClick={e => deleteFiles(image.name)} style={{cursor:"pointer"}}>
                                    <i className="fa fa-xmark"></i>
                                </div>
                            </div>
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center",minHeight:"40px", maxHeight:"40px", height:"40px"}}>
                                <img
                                    src={image.src}
                                    alt={image.altText}
                                    className="box-image-list-visualization"
                                    onError={(e)=>{e.target.src=getIconeFile(image.type,image.name) }}/>
                            </div>
                            <div style={{maxWidth: "110px",fontSize: "10px",whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",textAlign:"center"}}>
                                <small>{image.name}</small>
                            </div>
                        </div>
                    </div>
                 ))}
            </div>
        </div>
  );
};

export default FileVisualizer;
