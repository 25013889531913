import { memo, useCallback, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import ModalRedirectNode from "../../../pages/modals/workflow/modal-redirect-node";

const RedirectNode = (props) => {
    const [showModal, setShowModal]             = useState(false);
    const [departament, setDepartament]         = useState(0);
    const [departamentName, setDepartamentName] = useState(0);
    const [id, setID]                           = useState(-1)
    const [statistic, setStatistic]             = useState(0)
    const reactFlowInstance                     = useReactFlow();

    const constas = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node            : _id,
                    parentId        : -1, 
                    targetId        : -1,
                    departament     : props.data.nodeInfo.departament,
                    departamentName : props.data.nodeInfo.departamentName,
                    isHuman         : true,
                    isClosed        : true,
                    objectType      : 12                                                       
                }
            }
        }))
    }

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            setDepartament(nodeInfo.departament);
            setDepartamentName(nodeInfo.departamentName)
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
        };
        handleNodeProps();
    }, [props.data]);


    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <ModalRedirectNode nodeId={props.id} showModal={showModal} handleCloseModal={handleCloseModal} nodeData={props.data.nodeInfo}></ModalRedirectNode>
            <div className="rfnode rfnode-redirect d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) => setShowModal(true)}>
                <div className="rfnode-title">#{id} Redireciona Atendimento</div>
                <div style={{fontSize:"6px", padding:"10px", background:"#d63384c1", borderRadius:"5px", margin:"5px 5px", color:"#fff"}}>
                    {departament > 0 ? 
                        <span>{departamentName}</span>
                    :
                        <span>Aqui você informa para qual departamento o contato deve direcionado.</span>
                    }
                </div>
                
                <div style={{display:"flex", fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #d63384"}}>
                    <div style={{display:"flex", gap:"5px"}}>
                        <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                            <i className="fa fa-copy text-primary"></i>
                        </div>
                        <div onClick={() => constas()} title="Excluir Mensagem" className="workflow-delete" >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    </div>
                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{fontSize:"10px", color:"#d63384"}}>{statistic || ''}</span>
                    </div>
                </div>
            </div>

            <Handle type="target" position={Position.Left} id='redirect-action1' />
            <Handle type="target" position={Position.Top} id='redirect-action2' />
            <Handle type="target" position={Position.Bottom} id='redirect-action3' />
            <Handle type="target" position={Position.Right} id='redirect-action4' />
        </>
    );
};

export default memo(RedirectNode);
