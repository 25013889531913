import React, {useState, useEffect} from 'react'

import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Button, } from 'reactstrap';
import Draggable from 'react-draggable';

import { phone_format} from "./../../components/Utils";
import { Modal,  ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'
import PhoneInput from 'react-phone-number-input'
import api from '../../services/api';
import loadingButton  from '../layouts/Buttons'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalProfileEditNumber({showModal, handleCloseModal, data, handleReload}) {
    const [loading, setLoading]             = useState(false)
    const [number, setNumber]               = useState('')

    const [phones, setPhones]               = useState([])

    const addPhone = () => {
        if (number !== "") {
            if (!phones.find(el => el.number === number)) {
                setPhones(phones => phones.concat(number)) 
                setNumber('')                                              
            } else 
                ShowMessages({status : 500, message : 'Telefone informado já está cadastrado!'})
        } else {
            ShowMessages({status : 500,  message : 'Por favor, selecione um número de telefone!'})
        }
    }

    const removePhone = (_number) => {
        const _remove = phones.filter((t, i) => {
            if (t !== _number)
                return true;
        
            return false;
        });
        setPhones(_remove)
    }
 
    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-editnumber'),document.querySelector('#submit-editnumber').children[0].classList,document.querySelector('#submit-editnumber').lastChild.nodeValue)
        event.preventDefault()

        try {
            let _data = {phones}
                     
            await api.put('/user/' + data.iduser, _data)
                     .then(response => {
                        ShowMessages(response)
                        if (response.ok) {
                            if (response.data.status === 200) {
                                handleCloseModal()  
                                handleReload(true)    
                            } else 
                                loadingButton(false,document.querySelector('#submit-editnumber'))
                        } else 
                            loadingButton(false,document.querySelector('#submit-editnumber'))
                    })
                    .catch( error => {
                        loadingButton(false,document.querySelector('#submit-editnumber'))
                        ShowMessages(error) 
                    })
            
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-editnumber'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        if (data && data?.phones) {
            setPhones(data.phones.map(_item => _item.phone)) 
        }
    },[data])
    
    useEffect(() => {
        setStatistic('modals\\modal-profile-edit-number')
    },[])
   
    return (
      
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" > 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fab fa-whatsapp"></i> Propriedades do Alteração de Números
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth330px"/>
                    )}
                    <div className={`box-shadow ${loading ? "hidden" : ""}`}>               
                            <div style={{width:"100%"}}>
                                <div style={{width:"100%", display:"flex",gap:"5px"}}>
                                    <div style={{width:"100%"}}>
                                        <span>WhatsApp</span><label className="text-required">*</label>
                                        <PhoneInput
                                                placeholder=""
                                                initialValueFormat="national"
                                                style={{display:'flex', padding:"0.295rem 0.75rem"}}
                                                value={number}
                                                defaultCountry="BR"
                                                className='form-control'
                                                onChange={setNumber}/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"end"}}>
                                        <button className='btn btn-success' type="button" onClick={e => addPhone()}>
                                            <i className='fa fa-plus'/>
                                        </button>
                                    </div>
                                </div>
                                <div style={{marginTop:"5px"}}>
                                    <table className="table table-striped w-100">
                                        <thead className="thead-theme">
                                            <tr className="head-th">
                                                <td>Números</td>
                                                <td style={{textAlign:"center"}}>...</td>
                                            </tr>
                                        </thead>  
                                        <tbody> 
                                            {phones.map((item) => {
                                                return(<tr>
                                                    <td>{phone_format(item).substr(2,16)}</td>
                                                    <td className='text-center'>
                                                        <button className='btn btn-danger btn-sm' type="button" onClick={e => removePhone(item)}>
                                                            <i className='fa fa-trash'/>
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })}  
                                        </tbody>
                                    </table>
                                </div>            
                            </div>
                  
                        </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-editnumber' className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}