import socketIOClient from "socket.io-client";
import { getUserData,IDCOMPANY, ReadDataStorage,IDUSER } from './auth'

const ENDPOINT = process.env.REACT_APP_IP_CHAT + ':' + process.env.REACT_APP_PORT_CHAT

export let socket = null

export const Connect = () => {
    socket = socketIOClient(ENDPOINT, {})
    if (socket && getUserData())
        socket.on('connect', data => {
            console.log('conectado...',socket.id)
            let _data = {
                room    : '55Zap_' + ReadDataStorage(IDCOMPANY),
                iduser  : ReadDataStorage(IDUSER)
            }   
            socket.emit("joinRoom", JSON.stringify(_data));
        })
 
}

export const Disconnect = () => {
    if (socket) {
        socket.disconnect()
        socket = null
        console.log('desconectado....')
    }
}

export const sendData = (idchat) => {
    socket['idchat'] = idchat
}



