import React, {useState, useEffect} from 'react'

import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalMyNumber({showModal, handleCloseModal, data, handleReload}) {
    const [loadingBtn, setLoadingBtn]       = useState(false)
    const [number,setNumber]                = useState('')
    const [numberFormat, setNumberFormat]   = useState('')
    const [to, setTo]                       = useState('')
    const [environment, setEnvironment]     = useState('')

    const StatusWhatsapp = async() => {
        await api.get('/getAllMyNumbers')
                 .then(async(response) => { 
                    for (const [idx, _row] of response.data.entries()) {
                        await api.post('/api/v1/getStatusNumber',{number : _row.number.replace(/[\W_,[a-z]+/g,""),
                                                                  hash   : _row.environment })
                                 .then(async(_status) => {  
                                    
                                 }).catch(error => {
                                    ShowMessages(error)
                                 })
                    }

                 }).catch(error => {
                    ShowMessages(error)
                 })  
        
    }

    const setData = (obj,clear) => {
        setNumber(clear ? '' : obj.number) 
        setNumberFormat(clear ? '' : obj.phone_format)
        setEnvironment(clear ? '' : obj.environment)
        setTo('')
    }

    const sendMessage = async(e) => {
        e.preventDefault()
        if (to === '') {
            ShowMessages({message : "Informe o número do destinatário de mensagem.", status : 500})
            return
        }
        setLoadingBtn(true)
        await api.post('/api/v1/sendMessage',{number      : number.toString().replace(/[\W_,[a-z]+/g,""),
                                              sender      : to.toString().replace(/[\W_,[a-z]+/g,""),
                                              hash        : environment,
                                              bodyMessage : "Mensagem de teste do 55ZAP, sua API de comunicação com o WhatsApp"})
                 .then((response) => {
                    StatusWhatsapp()
                    if ((response.data) && (response.data.data?.sent))
                        ShowMessages({message : "Mensagem enviada com sucesso!",status : 200})
                    else {
                        ShowMessages({message : response.data.data?.message || 'Servidor desconectado, por favor, entre em contato com o suporte',status : 500})
                    }
                    setLoadingBtn(false)
                 })
                 .catch((error) => {
                    setLoadingBtn(false)
                    ShowMessages(error)
                 })
    }

       
    useEffect(() => {
        setData(data,false)
    },[data,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-mynumber')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>

                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fab fa-whatsapp"></i> Teste de Conexão
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div className="box-shadow">
                        <div className="alert alert-success" style={{padding:"10px", borderRadius:"5px", display:"flex"}}>
                            <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                                <i className="fab fa-whatsapp" style={{fontSize:"55px"}}/>
                            </div>
                            <div style={{marginLeft:"15px"}}>
                                <p>Parabéns, você está conectado com o número <span style={{fontWeight:'bold'}}>{numberFormat}</span></p>
                                <p>Caso queria enviar uma mensagem de teste, preencha o campo abaixo e clique em enviar, ou, se preferir alterar alguma informação de seu número, desconecte primeiro, para posteriormente fazer a alteração.</p>
                            </div>
                        </div>
                        <div style={{display:"flex", marginTop:"15px"}}>
                            <div>
                                <label>Informe o número de destino</label>
                                <PhoneInput
                                    placeholder=""
                                    style={{display:'flex', padding:"0.295rem 0.75rem"}}
                                    value={to}
                                    initialValueFormat="national"
                                    defaultCountry="BR"
                                    className='form-control'
                                    onChange={setTo}/> 

                            </div>
                            <div style={{marginLeft:"15px", display:"flex", alignItems:"center"}}>
                                <button type="button" className="btn btn-success" style={{marginTop:"17px"}} onClick={e => sendMessage(e)}>{loadingBtn ? <i className="fa fa-spin fa-spinner"/> : <i className="fa fa-paper-plane"/>}</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="button" className="btn-ligth-gray" onClick={e => handleCloseModal()}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
        
        </Modal>
    )
}