import React, { useState, useEffect } from 'react';
import ContentWrapper from '../../layouts/contentWrapper';
import Filter from '../../../components/Filter';
import Loading from '../../../components/Loading';
import ModalClient from '../../modals/modal-client';
import Grid from '../../../components/Grid';
import api from '../../../services/api';
import setStatistic from '../../../services/apiStatistic';
import {Dropdown} from 'react-bootstrap'
import { format } from 'date-fns';
import { ValidationForm } from 'react-bootstrap4-form-validation';
import ShowMessages from '../../../components/Exceptions';

export default function Clients() {
    const [loading, setLoading]               = useState(false);
    const [loadingUsers, setLoadingUsers]     = useState(false);
    const [showModal, setShowModal]           = useState(false);
    const [isOpenFilter, setIsOpenFilter]     = useState(false);
    const [reload, setReload]                 = useState(false);
    const [print, setPrint]                   = useState(false);
    const [excel, setExcel]                   = useState(false);
    const [showButtons, setShowButtons]       = useState(false);
    const [gridParams, setGridParams]         = useState({});
    const [data, setData]                     = useState({});
    const [filters, setFilters]               = useState([]);
    const [statusMessages, setStatusMessages] = useState(-99);
    const [showUsers, setShowUsers]           = useState(false)
    const [dataUsers, setDataUsers]           = useState([])
    const [dataStatus, setDataStatus]         = useState([]);
    const [idcompany, setIdCompany]           = useState(0)
    const [client, setClient]                 = useState('')

    const handleSearch = async (e) => {
        e.preventDefault();

        let _filters = []
        if (statusMessages >= 0) 
            _filters.push( { field: 'idstatusplan', key: '=', value: statusMessages, value2: null })

        if (client !== '')
            _filters.push( { field: 'fantasy', key: '%%', value: client, value2: null })

        setFilters(_filters);
    };

    const getClients = async () => {
        await api
            .get('/getStatusClient/0')
            .then((response) => {
                setDataStatus(response.data);
                setGridParams({
                    idcompany: {
                        text: '#ID',
                        mask: { name: 'leftSlice', value: '00000' },
                        primaryKey: true,
                    },
                    create_at: { text: 'Data' },
                });
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleEdit = async (id) => {
        await api
            .get('/company/' + id)
            .then((response) => {
                setData(response.data);
                setShowModal(true);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getLayout = (_data) => {
        return (
            <div className={`box-hover box-shadow box-chatbothistory ${_data.enabled ? "border-success" : ""}`} onClick={(e) => handleEdit(_data.idcompany)}>
                <div style={{display:"flex", gap:"10px", width:"100%"}}>
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '15%'}}>
                        <div>
                            <span style={{ color: '#cacaca' }}>#{_data.idcompany}</span>
                        </div>
                    
                        <div style={{ display: 'flex', alignItems: 'center', height: '110px', flexDirection:"column" ,justifyContent:"center" }}>
                            {_data.idstatusplan === 0 && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px',  boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', color: '#fff' }}className={`${_data.classname}`}>
                                    <i className="fa fa-heart" style={{ fontSize: '24px' }} />
                                </div>
                            )}
                            {_data.idstatusplan === 1 && (
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', color: '#fff', }} className={`${_data.classname}`}>
                                    <i className="fa fa-check" style={{ fontSize: '24px' }} />
                                </div>
                            )}
                            {_data.idstatusplan === 2 && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px', boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', color: '#fff', }}className={`${_data.classname}`}>
                                    <i className="fa fa-calendar-xmark" style={{ fontSize: '24px' }}/>
                                </div>
                            )}
                            {_data.idstatusplan === 3 && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', color: '#fff',}} className={`${_data.classname}`}>
                                    <i className="fa fa-shield-halved" style={{ fontSize: '24px' }}/>
                                </div>
                            )}
                            {_data.idstatusplan === 4 && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px', boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', color: '#fff',}} className={`${_data.classname}`}>
                                    <i className="fa fa-ban" style={{ fontSize: '24px' }} />
                                </div>
                            )}
                            {_data.idstatusplan === 5 && (
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px', boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', color: '#fff', }} className={`${_data.classname}`}>
                                    <i className="fa fa-power-off" style={{ fontSize: '24px' }} />
                                </div>
                            )}
                        </div>
                    </div>
                    
                    <div style={{ width: '85%' }}>
                        {showUsers && idcompany === _data.idcompany ? (
                            loadingUsers ?
                                <div style={{ width: '85%' }}>
                                    <Loading loading={loadingUsers} />
                                </div>
                                :
                                <div style={{display:"flex", gap:"15px"}}>
                                    <div style={{width:"100%"}}>
                                        <div style={{ background: "#f7f8f9", display: "flex", fontWeight: "bold", width:"100%" }}>
                                            <div style={{ width: "85%" }}>
                                                <span>Nome</span>
                                            </div>
                                            <div style={{ width: "15%", minWidth:"115px"  }}>
                                                <span>Usuário</span>
                                            </div>
                                        </div>
                                        {dataUsers.map((_item, key) => {
                                            return (<div key={key} style={{ display: "flex", width:"100%" }}>
                                                <div style={{ width: "85%" }}>
                                                    {_item.name}
                                                </div>
                                                <div style={{ width: "15%", minWidth:"115px" }}>
                                                    {_item.registration}
                                                </div>
                                            </div>
                                            )
                                        })}
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "end" }} title="Voltar">
                                        <button type="button" onClick={e => {
                                            setShowUsers(false)
                                            e.stopPropagation()
                                        }} className='btn btn-warning'><i className='fa fa-rotate-left' /></button>
                                    </div>
                                </div>
                        ) : (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                                        <small style={{ fontWeight: 'bold' }}>Cliente</small>
                                        <span className="chatbot-number">
                                            {_data.fantasy || 'Não Informado'}
                                        </span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Dropdown title='Opções' className="nav-link" style={{ textAlign: "right", display: "flex", marginTop: "-1px", padding: "2px" }} onClick={e => e.stopPropagation()}>
                                            <Dropdown.Toggle style={{ padding: "7px 10px" }}>
                                                <em className="fa fa-bars"></em>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{ padding: "0px" }}>
                                                <Dropdown.Item href="#" >
                                                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }} onClick={e => getUsers(_data.idcompany)}>
                                                        <em className="fa fa-person"></em>
                                                        <span>Usuários Atribuidos</span>
                                                    </div>
                                                </Dropdown.Item>

                                                <Dropdown.Item href="#" >
                                                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }} onClick={e => setShowCompany(_data.idcompany)}>
                                                        <em className="fa fa-building"></em>
                                                        <span>{_data.enabled ? "Não Mostrar na Lista de Empresas" : "Mostrar na Lista de Empresas"}</span>
                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '95px', }}>
                                        <small style={{ fontWeight: 'bold' }}>Data do Cadastro</small>
                                        <span className="chatbot-number">
                                            {format(new Date(_data.amendment.start), 'dd/MM/yyyy')}
                                        </span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '115px', }}>
                                        <small style={{ fontWeight: 'bold' }}>Próximo Vencimento</small>
                                        <span className="chatbot-number">
                                            {format(new Date(_data.amendment.expiration), 'dd/MM/yyyy')}
                                        </span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '95px', }}>
                                        <small style={{ fontWeight: 'bold' }}>Dias do Venc.</small>
                                        <span className="chatbot-number">{_data.expires_in}</span>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '150px', }}>
                                        <small style={{ fontWeight: 'bold' }} className="chatbot-title">
                                            Plano
                                        </small>
                                        <span className="chatbot-number">{_data.name}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '80px', }}>
                                        <small style={{ fontWeight: 'bold' }} className="chatbot-title">
                                            Números
                                        </small>
                                        <span className="chatbot-number">{_data.amendment.numbers}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '80px', }}>
                                        <small style={{ fontWeight: 'bold' }} className="chatbot-title">
                                            Atendentes
                                        </small>
                                        <span className="chatbot-number">
                                            {_data.amendment.attendances}
                                        </span>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '70px', }}>
                                        <small style={{ fontWeight: 'bold' }}>Valor Ticket</small>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <span>
                                                R$ {parseFloat(_data.amendment.ticket || 0).toFixed(2)}
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '80px', }}>
                                        <small style={{ fontWeight: 'bold' }}>Desconto</small>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <span>
                                                R$ {parseFloat(_data.amendment.discount.toString().replace('R$ ', '') || 0,).toFixed(2)}
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: "10px", minWidth: '50px', }}>
                                        <small style={{ fontWeight: 'bold' }}>Email</small>
                                        <div style={{ display: 'flex' }}>
                                            <span>{_data.email}</span>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: "10px", minWidth: '50px', }}>
                                        <small style={{ fontWeight: 'bold' }}>Telefone</small>
                                        <div style={{ display: 'flex' }}>
                                            <span>{_data.phones}</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>


            
                
                </div>
            </div>
        );
    };

    useEffect(() => {
        getClients();
        setStatistic('maintenance\\admin\\client');
    }, []);

    const getUsers = async(id) => {
        setIdCompany(id)
        setLoadingUsers(true)
        await api.post('/showUserCompany',{idcompany : id})
                 .then((response) => {
                     setDataUsers(response.data);
                     setShowUsers(true);
                     setLoadingUsers(false)
                 })
                 .catch((e) => {
                     setLoadingUsers(false)
                     console.log(e);
                 });
    }

    const setShowCompany = async(id) => {
        await api.post('/company/visibled',{idcompany : id})
                 .then((response) => {
                    ShowMessages(response.data);
                    setReload(true)
                 })
                 .catch((e) => {
                     ShowMessages(e)
                     console.log(e);
                 });
    }
    
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleFilter = () => {
        setIsOpenFilter(!isOpenFilter);
    };

    return (
        <ContentWrapper>
            {loading && <Loading loading={loading} />}
            <ModalClient
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                data={data}
                handleReload={setReload}
                dataStatus={dataStatus}
            />
            <div className={`container-lg container-100 ${loading ? 'hidden' : ''}`}>
                <ValidationForm
                    onSubmit={handleSearch}
                    className="modal-content"
                    setFocusOnError={true}
                >
                    <div className="content-heading">
                        <div className="box-maintance-buttons">
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    title="Atualizar Dados"
                                    style={{ marginLeft: '3px' }}
                                    onClick={(e) => setReload(true)}
                                >
                                    <i className="fa fa-redo"></i>
                                </button>
                                {showButtons && (
                                    <>
                                        <button
                                            type="button"
                                            className="btn btn-dark"
                                            title="Impressão"
                                            onClick={(e) => setPrint(true)}
                                            style={{ marginLeft: '3px' }}
                                        >
                                            <i className="fa fa-print"></i>
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-info"
                                            title="Exportar para excel"
                                            onClick={(e) => setExcel(true)}
                                            style={{ marginLeft: '3px' }}
                                        >
                                            <i className="fa fa-file-excel"> </i>
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            title="Filtros Avançados"
                                            style={{ marginLeft: '3px' }}
                                            onClick={(e) => handleFilter()}
                                        >
                                            <i className="fa fa-search-plus"></i>
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ display: 'flex', gap: '15px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label>Status do Cliente</label>
                                    <select
                                        className="form-select"
                                        value={statusMessages}
                                        onChange={(e) =>
                                            setStatusMessages(parseInt(e.target.value))
                                        }
                                    >
                                        <option value={-99} selected>
                                            Todos
                                        </option>
                                        {dataStatus.map((item) => {
                                            return (
                                                <option value={item.idstatusplan}>
                                                    {item.statusplan}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <label>Cliente</label>
                                    <input type="text" className='form-control' value={client} onChange={e => setClient(e.target.value)}/>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'end' }}>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    title="Clique aqui para pesquisar"
                                    onClick={(e) => handleSearch(e)}
                                >
                                    <i className="fa fa-search" />
                                </button>
                            </div>
                        </div>
                    </div>
                </ValidationForm>
            </div>
            <div
                className={`content-box-cart content-box-cart-grid ${loading ? 'hidden' : ''
                    }`}
            >
                <Filter
                    isOpenFilter={isOpenFilter}
                    filters={gridParams}
                    callback={setFilters}
                    callBackOpenFilter={handleFilter}
                />
                <Grid
                    url="/company"
                    headers={gridParams}
                    loading={setLoading}
                    reload={reload}
                    print={print}
                    excel={excel}
                    perPage={12}
                    filters={filters}
                    filename="Historico de Clientes"
                    getGridLayout={getLayout}
                    callbackShowButtons={setShowButtons}
                    callbackExcel={setExcel}
                    callbackPrint={setPrint}
                    callbackReload={setReload}
                    // callbackCellClick   = null
                    callbackButtons={[]}
                />
            </div>
        </ContentWrapper>
    );
}
