import React, { useEffect } from "react";
import FileVisualizer from "../../components/FileVisualizer";
import { Modal } from "react-bootstrap";
import { downloadFile, getIconeFile } from './../Utils'
import InputEmoji from "react-input-emoji";

export default function ShowPreviewImage({ showModal, handleCloseModal, filename, selectedFiles, setSelectedFiles, 
                                           setFilename,showDropdown, commands,setShowDropdown, setTextMessage,
                                           handleKeyPress, handleSubmit, handleSetText, isOnlyShow }) {
                 
    useEffect(() => {
        const onPressEnter = async(e) => { 
            if (e.key === 'Enter'){
                handleSubmit()
            }
        }                                        
              
        if (!isOnlyShow) {
            document.body.addEventListener('keydown', onPressEnter);
            return () => {
                document.body.removeEventListener('keydown',onPressEnter);
            };
        }
    }, [isOnlyShow,selectedFiles])                               
    

    return (
        <Modal size="md" show={showModal} onHide={handleCloseModal} dialogClassName=" height90 width1000" contentClassName='height85' animation={true} backdrop="static" >
            <div style={{ justifyContent: "space-between", display: "flex", flexDirection: 'column', height: "100%" }} >
                <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", borderRadius: "5px", border: "1px dashed #cacaca", margin: "5px", gap: "10px", maxWidth: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "center", width: "90%", wordBreak: "break-all" }}>
                        <span>{filename?.name}</span>
                    </div>
                    <div style={{ borderLeft: "1px dashed #cacaca", padding: "0px 15px 0px 15px", display: "flex", gap: "15px", alignItems: "center" }}>
                        <div style={{ cursor: "pointer" }} title="Baixar">
                            <i alt="" className='fa fa-download' style={{ fontSize: "16px" }} onClick={e => downloadFile(e, filename)} />
                        </div>
                        <div title="Fechar">
                            <i className='fa fa-x' style={{ cursor: "pointer" }} onClick={e => handleCloseModal()} />
                        </div>
                    </div>

                </div>
                <div style={{ flexGrow: "1", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <div className='container-box-image container-box-image2' >
                        <img
                            src={filename?.src}
                            alt={filename?.altText}
                            className='box-image-visualization container-box-image2'
                            onError={(e) => { e.target.src = getIconeFile(filename?.type, filename?.name) }} />
                    </div>
                    {!isOnlyShow && selectedFiles.length > 1 && <FileVisualizer imagesArray={selectedFiles} setSelectedFiles={setSelectedFiles} fileSelected={setFilename} parentType="modal" style={{overflow:"hidden", minWidth:"900px", width:"fit-content",borderTop:"1px solid #cacaca", justifyContent:'center', padding:"5px" }} />}
                </div>

                {!isOnlyShow && (
                    <div style={{ display: "flex", padding: "5px", borderTop: "1px dashed #cacaca" }}>
                        <div style={{ width: "100%", display: "flex" }}>
                            <div style={{ width: "100%" }}>
                                {showDropdown && (
                                    <div className='box-shadow' style={{ overflowX: 'auto', background: "#fff", padding: "0px", marginLeft: "30px", position: "fixed", bottom: "50px", width: "300px", height: "100px", zIndex: "9999" }}>
                                        {commands.map((_item, key) => {
                                            return (
                                                <div key={key} className='box-fast-message' onClick={e => {
                                                    setShowDropdown(false)
                                                    setTextMessage('/' + _item.label)
                                                }}>
                                                    <span>{_item.label}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                                <InputEmoji cleanOnEnter shouldReturn={true} className='button-message' placeholder="Digite sua mensagem" onKeyDown={e => handleKeyPress(e)} onChange={handleSetText} onEnter={handleSubmit} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", padding: "0px 10px", cursor: "pointer" }} title="Enviar Mensagem1" onClick={e => handleSubmit(e)}>
                                <i className='fa fa-paper-plane' style={{ fontSize: "20px", color: "#858585" }} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
}
