import React, {useState, useEffect} from 'react'
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button, } from 'reactstrap';
import Draggable from 'react-draggable';
import { convertToBase64 } from "./../../components/Utils";
import FileVisualizer from "../../components/FileVisualizer";
import { Modal,  ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'
import Waveform from "../../components/waveformer";
import api from '../../services/api';

import { base64ToBlob } from './../../components/Utils'
import loadingButton  from '../layouts/Buttons'
import Dropzone from "../../components/dropzone";
import { AudioRecorder } from 'react-audio-voice-recorder';
import EmojiPicker from 'emoji-picker-react';

import EMOJI from './../../assets/Emoji.png'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalFastMessage({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                   = useState(false)
    const [message, setMessage]                   = useState({})
    const [description, setDescription]           = useState('')
    const [idmessagetype, setIDMessageType]       = useState(1)
    const [selectedFiles, setSelectedFiles]       = useState([]);
    const [textMessage, setTextMessage]           = useState('')
    const [file, setFile]                         = useState([])
    const [isRecording, setIsRecording]           = useState(false)
    const [audio, setAudio]                       = useState('')
    const [showEmoji, setShowEmoji]               = useState(false)
    const [latitude, setLatitude]                 = useState('')
    const [longitude, setLongitude]               = useState('')
    const [idautomationchat, setIDAutomationChat] = useState('')
    const [chatData, setChatData]                 = useState([])


    const deleteAudio = (e) => {
        setAudio('')
        setFile([])

    }

    const handleAudio = async(e) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          // O conteúdo do arquivo em base64 está disponível em reader.result
          const base64Data = reader.result.split(',')[1];
          setAudio(base64Data);
        };
  
        // Lê o arquivo como uma URL de dados (data URL)
        reader.readAsDataURL(e);
        setFile(e)
    }

    const handleCaptureAudio = async (a) => {
        if (a !== undefined) {
            setAudio(await convertToBase64(a))
        }
    }

    const setData = (obj,clear) => {
        if (obj.idmessage) {
            let _blob
            switch (obj.type) {
                case 'chat':
                    setTextMessage(clear ? '' : obj.body)
                    setIDMessageType(1)
                    break;
                case 'document':
                case 'image':
                case 'sticker':
                case 'video':
                    _blob = base64ToBlob(obj.file)
                    setSelectedFiles([{
                        src: URL.createObjectURL(_blob),
                        altText: `${URL.createObjectURL(_blob)}`,
                        file: URL.createObjectURL(_blob),
                        name: obj.filename,
                        size: obj.filesize,
                        type: obj.mimetype,
                        base64 : obj.file
                    }])
                    setTextMessage(clear ? '' : obj.caption || obj.body)
                    setIDMessageType(2)
                    break;
                case 'ppt':
                case 'audio':
                    _blob = base64ToBlob(obj.file)
                    setSelectedFiles([{
                        src: URL.createObjectURL(_blob),
                        altText: `${URL.createObjectURL(_blob)}`,
                        file: URL.createObjectURL(_blob),
                        name: obj.filename,
                        size: obj.filesize,
                        type: obj.mimetype,
                        base64 : obj.file
                    }])
                    setIDMessageType(3)
                    break;
                case 'vcard':
                    _blob = base64ToBlob(obj.file)
                    setSelectedFiles([{
                        src: URL.createObjectURL(_blob),
                        altText: `${URL.createObjectURL(_blob)}`,
                        file: URL.createObjectURL(_blob),
                        name: obj.filename,
                        size: obj.filesize,
                        type: obj.mimetype,
                        base64 : obj.file
                    }])
                    setIDMessageType(4)
                    break;
                case 'location':
                    setLatitude(clear ? '' : obj.location.latitude)
                    setLongitude(clear ? '' : obj.location.longitude)
                    setTextMessage(clear ? '' : obj.location.name)
                    setIDMessageType(19)
                    break;
                default:
                    break;
            }
        } else {
            setTextMessage(clear ? '' : obj.message[0].body)
            setMessage(clear ? '' : obj.message)
            setDescription(clear ? '' : obj.description)
            setLatitude(clear ? '' : obj.message[0]?.coordenaties?.latitude || '')
            setLongitude(clear ? '' : obj.message[0]?.coordenaties?.longitude || '')
            setIDAutomationChat(clear ? '' : obj.message[0]?.idautomationchat || '')
            setIDMessageType(clear ? '' : obj.idmessagetype)   

            if ((!clear) && (['document','image','sticker','video','ppt','audio','vcard'].includes(obj.message[0]?.type))) {
                let _blob = base64ToBlob(obj.file)
                setSelectedFiles([{
                    src: URL.createObjectURL(_blob),
                    altText: `${URL.createObjectURL(_blob)}`,
                    file: URL.createObjectURL(_blob),
                    name: obj.filename,
                    size: obj.filesize,
                    type: obj.mimetype,
                    base64 : obj.file
                }])
            } else 
                setSelectedFiles([] )
        }
    }

    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-fast-message'),document.querySelector('#submit-fast-message').children[0].classList,document.querySelector('#submit-fast-message').lastChild.nodeValue)
        event.preventDefault()

        let _base64 = ''
        if ([2,3,4].includes(parseInt(idmessagetype)) && (file)) {
            if (file.length !== 0 && file.size > 20971020) {
                ShowMessages({ status : 500, message : "O arquivo informado e maior que 20mb, informe um arquivo menor"})
                return
            }
        }
        
        if ([2].includes(parseInt(idmessagetype)) && selectedFiles.length !== 0) {
            for(let i = 0; i < selectedFiles.length; i++){
                if (selectedFiles[i].size > 20971020) {
                    ShowMessages({ status : 500, message : "Um dos arquivos informados e maior que 20mb, informe um arquivo menor"})
                    return
                }
            }
        }

        if ([3].includes(parseInt(idmessagetype)) && (file))
            _base64 = await convertToBase64(file) 

        if ([4].includes(parseInt(idmessagetype)) && (file)) {
            _base64 = await convertToBase64(file)
        }

        let _message = []

        if (selectedFiles.length > 0) {
            for(let i = 0; i < selectedFiles.length; i++){
                _message.push({
                    body : textMessage,
                    base64 : await convertToBase64(selectedFiles[i]),
                    extension : '.' + selectedFiles[i].name.split('.').pop(),
                    name      : selectedFiles[i].name,
                    type      : selectedFiles[i].type,
                })
            }
        } else {
            _message.push({
                body         : textMessage,
                coordenaties : {latitude,longitude},
                base64       : parseInt(idmessagetype) > 1 && parseInt(idmessagetype) < 18 ? _base64 : '',
                type         : idmessagetype === 4 ? file.type : '',
                idautomationchat
            }) 
        }

     
        try {
            let _data = {message : JSON.stringify(_message),
                         description,
                         idmessagetype}
                     
                     
            if (editState)
                await api.put('/fast-message/' + data.idfastmessage, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-fast-message'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-fast-message'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-fast-message'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/fast-message', _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-fast-message'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-fast-message')) 
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-fast-message'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-fast-message'))
            ShowMessages(response)
        }
    }

    const setLayoutMic = () => {
        let _main = document.querySelector('.audio-recorder')
        let _save = document.querySelector('.audio-recorder-mic ')
        let _img  = document.querySelectorAll('.audio-recorder img')

        if (_main)
            _main.style.backgroundColor = 'transparent'

        if (!document.querySelector('.audio-recorder i')) {
            const _iRecord = document.createElement('i');
            const _iPause  = document.createElement('i');
            const _iStop   = document.createElement('i');

            _iRecord.addEventListener('click', function(event) {
                _img[0].click()
                _main.style.backgroundColor = '#EBEBEB'
                _iRecord.style.display      = 'none'
                _iPause.style.display       = 'block'
                _iStop.style.display        = 'block'
                setIsRecording(true)
            });

            _iPause.addEventListener('click', function(event) {
                _img[1].click()
                _main.style.backgroundColor = '#EBEBEB'

                if (_iPause.classList.length > 0) {
                    if (_iPause.classList[1] === 'fa-circle-pause') {
                        _iPause.classList.remove('fa-circle-pause')
                        _iPause.classList.add('fa-circle-play')
                    } else {
                        _iPause.classList.remove('fa-circle-play')
                        _iPause.classList.add('fa-circle-pause')
                    }
                }
            });

            _iStop.addEventListener('click', function(event) {
                _img[2].click()
                _main.style.backgroundColor = '#EBEBEB'
                _iStop.style.display        = 'none'
                _iPause.style.display       = 'none'
                _iRecord.style.display      = 'block'
                _main.style.backgroundColor = 'transparent'
                _save.click()
                setIsRecording(false)
            });

            _iRecord.className          = 'fa fa-microphone'
            _iRecord.style.fontSize     = '20px'
            _iRecord.style.color        = '#858585'
            _iRecord.style.padding      = '12px 15px'
            _iRecord.style.border       = '1px solid #cacaca'
            _iRecord.style.borderRadius = '50%'
            _iRecord.title              = 'Gravar Áudio'

            _iPause.className       = 'fa fa-circle-pause'
            _iPause.style.fontSize  = '24px'
            _iPause.style.color     = '#858585'
            _iPause.style.display   = 'none'
            _iPause.style.padding   = '5px'
            _iPause.title           = 'Pausar Gravação'

            _iStop.className      = 'fa fa-circle-stop'
            _iStop.style.fontSize = '24px'
            _iStop.style.color    = '#858585'
            _iStop.style.display  = 'none'
            _iStop.style.padding  = '5px'
            _iStop.title          = 'Finalizar Gravação'

            if (_main) {
                _main.appendChild(_iRecord)
                _main.appendChild(_iPause)
                _main.appendChild(_iStop)
            }
            
        }

        if (_img.length > 0) {
            _img[0].style.display = 'none'
            _img[1].style.display = 'none'
            _img[2].style.display = 'none'
        }
    }

    const handleFiles = async(arrSelected) => {
        if (arrSelected.length !== 0) {
            let files  = [...selectedFiles];

            for (let i = 0; i < arrSelected.length; i++) {
                if (arrSelected[i].size > 41943040) {
                    ShowMessages({status : 500, message : "O arquivo informado não pode ser maior que 40Mb"})
                } else {
                    files.push({
                        src: URL.createObjectURL(arrSelected[i]),
                        altText: `${URL.createObjectURL(arrSelected[i])}`,
                        file: URL.createObjectURL(arrSelected[i]),
                        name: arrSelected[i].name,
                        size: arrSelected[i].size,
                        type: arrSelected[i].type,
                        base64 : await convertToBase64(arrSelected[i])
                        });
                }
            }

            setSelectedFiles(files);
        }
    };

    const handleMessageType = (idtype) => {
        setIDMessageType(idtype)
        setSelectedFiles([])
    }

    useEffect(() => {
        setLayoutMic()
    },[audio, idmessagetype])

    useEffect(() => {
        setStatistic('modals\\modal-fast-message')
        setLayoutMic()
    },[])

    useEffect(() => {
        const getData = async() => {
            await api.get('/getChatBotGroup')
                     .then(async response2 => {
                        setChatData(response2.data)
                     })
                     .catch( error => {
                        ShowMessages(error)
                        setLoading(false) 
                     })
        }

        getData()
        if ((editState) || (data.idmessage))
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    return (
      
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" > 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-forward-fast"></i> Propriedades de Mensagens Rápidas
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth450px"/>
                    )}
                    <div className={`box-shadow ${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>Atalho</label><label className="text-required">*</label>
                            <div className="input-group">
                                 <TextInput  
                                    name="description"
                                    id="description"
                                    type="text"
                                    required
                                    maxLength={50}
                                    errorMessage={{ required: "Por favor, informe uma descrição!" }}
                                    value={description}
                                    className="form-control "
                                    autoFocus
                                    onChange={e => setDescription(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div style={{display:"flex", overflowY:"auto", flexDirection:"column"}}>
                            <div className="input-control" style={{display:"flex",flexDirection:"column", marginBottom:"7px"}}>
                                <label>Tipo de mensagem</label>
                                <select style={{width:"100%"}} className="form-select " value={idmessagetype} onChange={e => handleMessageType(parseInt(e.target.value))}>
                                    <option value="0">Selecione</option>
                                    <option value="1">Texto</option>
                                    <option value="3">Áudio</option>
                                    <option value="2">Arquivo</option>
                                    <option value="4">Contato</option>
                                    <option value="19">Localização</option>
                                    <option value="99">ChatBot</option>
                                </select>
                            </div>
             
                            {/* TEXTO */}
                            {idmessagetype === 1 && (
                                <div style={{display:"flex",flexDirection:"column", height:"180px"}}>
                                    <div style={{ width: "100%" }}>
                                        <label>Digite a sua mensagem</label>
                                        <textarea
                                            name="textMessage"
                                            id="textMessage"
                                            maxLength={1500}
                                            rows="6"
                                            onFocus={e => setShowEmoji(false)}
                                            value={textMessage}
                                            className="form-control "
                                            onChange={e => setTextMessage(e.target.value)}
                                            autoComplete="off"></textarea>
                                    </div>
                                    <div>
                                        <div className="emoji-button" onClick={e => setShowEmoji(!showEmoji)}>
                                            <div>
                                                <img src={EMOJI} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    {showEmoji && (
                                        <div style={{ position: "fixed", top: "-60px", left:"350px" }}>
                                            <EmojiPicker onEmojiClick={e => setTextMessage(textMessage + ' ' + e.emoji)} searchPlaceholder="Pesquisar" previewConfig={{
                                                showPreview: false
                                            }} categories={[
                                                {
                                                    category: 'smileys_people',
                                                    name: 'Pessoas e Rostos',
                                                },
                                                {
                                                    category: 'animals_nature',
                                                    name: 'Animais e Natureza',
                                                },
                                                {
                                                    category: 'food_drink',
                                                    name: 'Comida e Bebida',
                                                },
                                                {
                                                    category: 'travel_places',
                                                    name: 'Viagem e Lugares',
                                                },
                                                {
                                                    category: 'activities',
                                                    name: 'Atividades',
                                                },
                                                {
                                                    category: 'objects',
                                                    name: 'Objetos',
                                                },
                                                {
                                                    category: 'symbols',
                                                    name: 'Símbolos',
                                                },
                                                {
                                                    category: 'flags',
                                                    name: 'Bandeiras',
                                                }
                                            ]} />
                                        </div> 
                                    )}
                                </div>
                            )}

                            {/* ARQUIVO */}
                            {(idmessagetype === 2) && (
                                <div style={{display:"flex", flexDirection:"column", height:`${selectedFiles.length === 0 ? "255px" : "375px"}`}} >
                                    {selectedFiles.length > 0 && (
                                        <div className="w-100 rounded" style={{overflow:"auto",maxHeight:"200px", marginTop:"10px"}}>
                                            <FileVisualizer imagesArray={selectedFiles} setSelectedFiles={setSelectedFiles} parentType="modal"/>
                                        </div>
                                    )}
                                    <div style={{width:"100%"}}>
                                        <label>Selecione o arquivo</label>
                                        <Dropzone onChange={handleFiles}  multiple={true}/>
                                    </div>
                                    <div style={{width:"100%"}}>
                                        <label>Digite a mensagem que acompanha o arquivo</label>
                                        <textarea  
                                            name="textMessage"
                                            id="textMessage"
                                            maxLength={1500}
                                            rows="6"
                                            value={textMessage}
                                            onFocus={e => setShowEmoji(false)}
                                            className="form-control "
                                            onChange={e => setTextMessage(e.target.value)}
                                            autoComplete="off"></textarea>    
                                    </div>
                                    <div>
                                        <div className="emoji-button" onClick={e => setShowEmoji(!showEmoji)}>
                                            <div>
                                                <img src={EMOJI} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    {showEmoji && (
                                        <div style={{ position: "fixed", top:`${selectedFiles.length === 0 ? "15px" : "130px"}`, left:"350px" }}>
                                            <EmojiPicker onEmojiClick={e => setTextMessage(textMessage + ' ' + e.emoji)} searchPlaceholder="Pesquisar" previewConfig={{
                                                showPreview: false
                                            }} categories={[
                                                {
                                                    category: 'smileys_people',
                                                    name: 'Pessoas e Rostos',
                                                },
                                                {
                                                    category: 'animals_nature',
                                                    name: 'Animais e Natureza',
                                                },
                                                {
                                                    category: 'food_drink',
                                                    name: 'Comida e Bebida',
                                                },
                                                {
                                                    category: 'travel_places',
                                                    name: 'Viagem e Lugares',
                                                },
                                                {
                                                    category: 'activities',
                                                    name: 'Atividades',
                                                },
                                                {
                                                    category: 'objects',
                                                    name: 'Objetos',
                                                },
                                                {
                                                    category: 'symbols',
                                                    name: 'Símbolos',
                                                },
                                                {
                                                    category: 'flags',
                                                    name: 'Bandeiras',
                                                }
                                            ]} />
                                        </div> 
                                    )}
                                </div>
                            )}

                            {/* AUDIO */}
                            {(idmessagetype === 3) && (
                                <div style={{display:"flex", width:"100%", flexDirection:"column"}} >
                                    {audio !== '' ? (
                                <div className={`w-100 ${audio ? "border" : ""}`}  style={{borderRadius:"5px", marginTop:"15px", padding:"10px", display:"flex"}}>
                                    <Waveform
                                        url={audio}
                                        waveFormInstance={'waveFormInstance-99'}
                                        isModal={true}
                                    ></Waveform>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                        <button type="button" onClick={e =>deleteAudio()} className="btn btn-danger"><i className="fa fa-trash"/></button>
                                    </div>
                                </div>
                            ) : (                     
                                <div style={{marginTop:"15px", border:"1px dashed #cacaca", borderRadius:"5px", padding:"5px"}}>
                                    <div style={{marginTop:"-15px", marginLeft:"5px"}}>
                                        <label style={{background:"#fff"}}>Áudio</label>
                                    </div>
                                    {selectedFiles.length > 0 && (
                                        <div className="w-100 rounded" style={{overflow:"auto",maxHeight:"200px", marginTop:"10px"}}>
                                            <FileVisualizer imagesArray={selectedFiles} setSelectedFiles={setSelectedFiles} parentType="modal"/>
                                        </div>
                                    )}
                                    <div className="mb-2">
                                        <Dropzone onChange={handleAudio} multiple={false} accept= {{'audio/*': ['.ogg','.mp3','.wav']}} />
                                    </div>
                                    <div style={{display:"flex", justifyContent:`center`, padding:"10px"}}>
                                        <AudioRecorder
                                            onRecordingComplete={handleCaptureAudio}
                                            audioTrackConstraints={{
                                                noiseSuppression: true,
                                                echoCancellation: true,
                                            }}
                                            onNotAllowedOrFound={(err) => {
                                                ShowMessages({status : 500, message : "Microfone não encontrado"})
                                                if ((document.querySelectorAll('.fa.fa-circle-stop').length > 0) && (document.querySelectorAll('.fa.fa-circle-stop')[0].style.display !== 'none')) {
                                                    document.querySelectorAll('.fa.fa-circle-stop')[0].click()
                                                    setIsRecording(false)
                                                }
                                            }}
                                            downloadOnSavePress={false}
                                            downloadFileExtension="mp3"
                                            mediaRecorderOptions={{
                                                audioBitsPerSecond: 128000,
                                            }}
                                            showVisualizer={true}
                                        />  
                                    </div>
                                </div>
                            )}
                                </div>
                            )}

                            {/* CONTATO */}
                            {(idmessagetype === 4) && (     
                                <div style={{display:"flex", width:"100%", flexDirection:"column"}} >
                                    <div style={{width:"100%"}}>
                                        <label>Selecione o arquivo de contato</label>
                                        <Dropzone onChange={handleFiles}  multiple={true} accept= {{'text/x-vcard': ['.vcard','.vcf']}} />
                                    </div>
                                </div>
                            )}

                            {/* LOCALIZAÇÃO */}
                            {idmessagetype === 19 && (
                                <div style={{display:"flex", width:"100%", flexDirection:"column", height:'360px'}} >
                                    <div className='alert alert-warning'>
                                        Você pode encontrar a <span style={{fontWeight:"bold"}}>Latitude</span> e <span style={{fontWeight:"bold"}}>Longitude</span> de uma localização dentro das mensagem do sistema, basta clicar no canto superior direito de uma mensagem de localização e acessar o menu <span style={{fontWeight:"bold"}}>VISUALIZAR COORDENADAS</span>.
                                    </div>
                                    <div style={{display:"flex", gap:"5px"}}>
                                        <div>
                                            <span>Latitude</span>
                                            <input type="text" className='form-control' value={latitude} onChange={e => setLatitude(e.target.value)}/>
                                        </div>
                                        <div>
                                            <span>Longitude</span>
                                            <input type="text" className='form-control'  value={longitude} onChange={e => setLongitude(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div style={{width:"100%"}}>
                                        <label>Digite a mensagem que acompanha a localização</label>
                                        <textarea  
                                            name="textMessage"
                                            id="textMessage"
                                            maxLength={1500}
                                            rows="6"
                                            value={textMessage}
                                            onFocus={e => setShowEmoji(false)}
                                            className="form-control "
                                            onChange={e => setTextMessage(e.target.value)}
                                            autoComplete="off"></textarea>    
                                    </div>
                                    <div>
                                        <div className="emoji-button" onClick={e => setShowEmoji(!showEmoji)}>
                                            <div>
                                                <img src={EMOJI} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    {showEmoji && (
                                        <div style={{ position: "fixed", top: "120px", left:"480px" }}>
                                            <EmojiPicker onEmojiClick={e => setTextMessage(textMessage + ' ' + e.emoji)} searchPlaceholder="Pesquisar" previewConfig={{
                                                showPreview: false
                                            }} categories={[
                                                {
                                                    category: 'smileys_people',
                                                    name: 'Pessoas e Rostos',
                                                },
                                                {
                                                    category: 'animals_nature',
                                                    name: 'Animais e Natureza',
                                                },
                                                {
                                                    category: 'food_drink',
                                                    name: 'Comida e Bebida',
                                                },
                                                {
                                                    category: 'travel_places',
                                                    name: 'Viagem e Lugares',
                                                },
                                                {
                                                    category: 'activities',
                                                    name: 'Atividades',
                                                },
                                                {
                                                    category: 'objects',
                                                    name: 'Objetos',
                                                },
                                                {
                                                    category: 'symbols',
                                                    name: 'Símbolos',
                                                },
                                                {
                                                    category: 'flags',
                                                    name: 'Bandeiras',
                                                }
                                            ]} />
                                        </div> 
                                    )}
                                </div>
                            )}

                            {/* CHATBOT */}
                            {idmessagetype === 99 && (
                                <div style={{ width: "100%" }}>
                                    <span>Chatbot</span>
                                    <select className="form-select" value={idautomationchat} onChange={e => setIDAutomationChat(e.target.value)} required>
                                        <option value="">Selecione</option>
                                        {chatData.map((item) => {
                                            return (<option value={item.idautomationchat}>{item.title}</option>)
                                        })}
                                    </select>
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-fast-message' className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}