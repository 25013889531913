import { memo, useCallback, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import ModalEmailNode from "../../../pages/modals/workflow/modal-email-node";

const EmailNode = (props) => {
    const [showModal, setShowModal]  = useState(false);
    const [id, setID]                = useState(-1)
    const [to, setTo]                = useState(0);
    const [from, setFrom]            = useState('')
    const [fromText, setFromText]    = useState('')
    const [cc, setCC]                = useState('')
    const [cco, setCCO]              = useState('')
    const [subject, setSubject]      = useState('')
    const [message, setMessage]      = useState('')
    const [statistic, setStatistic]  = useState(0)
    const reactFlowInstance          = useReactFlow();

    const constas = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            setTo(nodeInfo.to);
            setFrom(nodeInfo.from)
            setFromText(nodeInfo.fromText)
            setCC(nodeInfo.cc)
            setCCO(nodeInfo.cco)
            setSubject(nodeInfo.subject)
            setMessage(nodeInfo.message || '')
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
        };
        handleNodeProps();
    }, [props.data]);

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node         : _id,
                    parentId     : -1, 
                    targetId     : -1,
                    message      : props.data.nodeInfo.message,
                    to           : props.data.nodeInfo.to,
                    from         : props.data.nodeInfo.from,
                    fromText     : props.data.nodeInfo.fromText,
                    subject      : props.data.nodeInfo.subject,
                    cc           : props.data.nodeInfo.cc,
                    cco          : props.data.nodeInfo.cco,
                    objectType   : 16
                }
            }
        }))
    }


    const handleShowModal = (e) => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <ModalEmailNode nodeId={props.id} showModal={showModal} handleCloseModal={handleCloseModal} nodeData={props.data.nodeInfo}/>
            <div className="rfnode rfnode-email d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) => handleShowModal(e)}>
                <div className="rfnode-title">#{id} Email</div>

                <div style={{background: "rgba(219, 112, 147, 0.1098039216)", color:"#2F4F4F", margin:"5px 10px", padding:"5px", borderRadius:"5px", width:"85%", textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    {(message === '') ? (
                        <span style={{fontSize:"6px"}}>Informe os dados de Email</span>
                    ) : (
                        <div style={{fontSize:"6px", display:"flex", gap:"5px", flexDirection:"column", justifyContent:"start", width:"100%"}}>
                            <div style={{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                <span style={{fontWeight:"bold"}}>De</span>
                                <span>{fromText}</span>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                <span style={{fontWeight:"bold"}}>Para</span>
                                <span>{to}</span>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                <span style={{fontWeight:"bold"}}>CC</span>
                                <span>{cc}</span>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                <span style={{fontWeight:"bold"}}>CCO</span>
                                <span>{cco}</span>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                <span style={{fontWeight:"bold"}}>Assunto</span>
                                <span>{subject}</span>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                <span style={{fontWeight:"bold"}}>Mensagem</span>
                                <span>{message}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div style={{display:"flex", fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #DB7093"}}>
                    <div style={{display:"flex", gap:"5px"}}>
                        <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                            <i className="fa fa-copy text-primary"></i>
                        </div>
                        <div onClick={() => constas()} title="Excluir Email" className="workflow-delete" >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    </div>

                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{fontSize:"10px", color:"#DB7093"}}>{statistic || ''}</span>
                    </div>
                </div>
            </div>

            <Handle type="source" position={Position.Top} id='node-email1' />
            <Handle type="target" position={Position.Left} id='node-email2' />
            <Handle type="target" position={Position.Bottom} id='node-email3' />
            <Handle type="source" position={Position.Right} id='node-email4' />
        </>
    );
};

export default memo(EmailNode);
