import React, { useState, useEffect } from 'react'

import Loading from '../../components/Loading';

import BASIC_PLAN from './../../assets/basic-plan.png'
import api from './../../services/api'
import { REGISTRATION,ReadDataStorage,WriteDataStorage} from './../../services/auth'

import { QrCodePix } from 'qrcode-pix';
import { ValidationForm } from 'react-bootstrap4-form-validation';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import  loadingButton  from '../layouts/Buttons'
import setStatistic from '../../services/apiStatistic'

export default function Payment(props) {
    const [loading, setLoading]               = useState(false)
    const [method, setMethod]                 = useState(0)
    const [buttonCoupon, setButtonCoupon]     = useState(false)
    const [qrCode, setqrCode]                 = useState('')
    const [copy_past, setCopy_Past]           = useState('')
    const [coupon,setCoupon]                  = useState('')
    const [subtotal, setSubTotal]             = useState(0)
    const [discount, setDiscount]             = useState(0)
    const [total, setTotal]                   = useState(0)
    
    const [keypix, setKeypix]                 = useState('')
    const [company, setCompany]               = useState('')
    const [city, setCity]                     = useState('')  
    const [transaction, setTransaction]       = useState('')
    const [message, setMessage]               = useState('')
    const [cep, setCep]                       = useState('')
    const [plan, setPlan]                     = useState('')
    const [description, setDescription]       = useState('')
    const [period, setPeriod]                 = useState('')

    const Sum = async(_subtotal, _discount,_keypix,_company,_city,_transaction,_message,_cep) => {
        setTotal(_subtotal - (_subtotal * _discount / 100))
        setSubTotal(_subtotal)
        setDiscount((_subtotal * _discount / 100))
        setKeypix(_keypix)
        setCompany(_company)
        setCity(_city)  
        setTransaction(_transaction)
        setMessage(_message)
        setCep(_cep.replace(/\D/g,''))


        const qrCodePix = QrCodePix({
            version       : '01',
            key           : _keypix, 
            name          : _company,
            city          : _city,
            transactionId : _transaction.toString(), 
            message       : _message,
            cep           : _cep.replace(/\D/g,''),
            value         : parseFloat(_subtotal - (_subtotal * _discount / 100))
        });

        let _base64 = await qrCodePix.base64()
        setCopy_Past(qrCodePix.payload())
        setqrCode(_base64)
    }

    const getCoupon = async() => {
        setButtonCoupon(true)
        await api.post('/getCoupon',{
            coupon,
        })
        .then(async(response) => {
            try {
                Sum(subtotal,
                    response.data?.status === 200 ? response.data.value : 0,
                    keypix,
                    company,
                    city,
                    transaction,
                    message,
                    cep)
                
  
                setButtonCoupon(false)
            } catch(e) {
                console.log(e)
                setButtonCoupon(false)
            }

        })
        .catch((error) => {
            setButtonCoupon(false)
            console.log(error)
        })
    }

    const Payment = async() => {
        setLoading(true)
        await api.post('/getPayments')
                 .then(async(response) => {
                    console.log(response.data)
                      setPlan(response.data.plan)
                      setDescription(response.data.description)
                      setPeriod(response.data.period)
                      Sum(response.data.value,
                          discount,
                          response.data.key_pix,
                          response.data.name,
                          response.data.city,
                          response.data.transactionId,
                          response.data.message,
                          response.data.cep.replace(/\D/g,''),
                          response.data.value)
                       setLoading(false)
                 })
                 .catch((error) => {
                    setLoading(false)
                 })
        setStatistic('Register')
    }

    useEffect(() => {
        Payment()
    } ,[])

    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit'),document.querySelector('#submit').children[0].classList,document.querySelector('#submit').lastChild.nodeValue)

        event.preventDefault()
        try {
 
            await api.post('/register', {

            })
                .then(response => {
                    if (response.ok) {
                        
                        
                    }
                    loadingButton(false,document.querySelector('#submit'),'fa fa-user-plus','Cadastrar e Escolher o Plano')
                })
        } catch (response) {
            console.log(response)
            loadingButton(false,document.querySelector('#submit'),'fa fa-user-plus','Cadastrar e Escolher o Plano')
        }
    }

    const Logout = async() => {
        let _registration = ReadDataStorage(REGISTRATION)     
        localStorage.clear();
        WriteDataStorage(REGISTRATION,_registration)
        window.location.href = '/login'
    }

    return (
        <>
        <div className="background">
        </div>
        <div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <NotificationContainer />
            <div className="content">
                <div className={`block-center box-register`}>
                    <div className="card card-flat">
                        <div className="card-body body-register">
                            {loading && (
                                <Loading loading={loading}  addClass="heigth70vh"/>
                            )}
                            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className={loading ? 'hidden' : ''}> 
                                <div style={{display:"flex", padding:"15px", borderRadius:"5px"}} className="bg alert alert-danger">
                                    <div style={{display:"flex", alignItems:"center"}}>
                                        <i className="fa fa-ban" style={{fontSize:"52px"}}/>
                                    </div>
                                    <div style={{display:"flex",flexDirection:"column", padding:"15px", borderRadius:"5px"}}>
                                        <h5>Sua conta encontra-se inativa</h5>
                                        <span>Infelizmente não conseguimos localizar o pagamento do seu plano, siga as intruções de pagamento abaixo</span>
                                        <span> para reativar o seu plano.</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{display:"flex", padding:"15px", borderRadius:"5px", width:"80%"}} className="bg alert alert-warning text-dark">
                                        <div style={{display:"flex", alignItems:"center"}}>
                                            <i className="fa fa-warning" style={{fontSize:"52px"}}/>
                                        </div>
                                        <div style={{display:"flex",flexDirection:"column", padding:"15px", borderRadius:"5px"}}>
                                            <span>Após efetuar o pagamento, envie o comprovante clicando no <span style={{color:"#0d6efd", fontWeight:"bold"}}>Botão Azul</span></span>
                                            <span>A ativação do plano só acontece em <span style={{fontWeight:"bold"}}>Horário Comercial</span></span>
                                            <span>O prazo máximo de ativação e de até <span style={{fontWeight:"bold"}}>1 dia útil</span></span>
                                            <span>Em caso de dúvidas, entre em contato com a nossa <span style={{fontWeight:"bold"}}>Central de Atendimento</span></span>
                                        </div>
                                    </div>  
                                    <div onClick={e => window.open('https://api.whatsapp.com/send?phone=5562991413591')}style={{cursor:"pointer", display:"flex",flexDirection:"column", padding:"15px", borderRadius:"5px", marginLeft:"15px", width:"20%", alignItems:"center", justifyContent:"center"}} className="bg alert alert-success">
                                        <i className="fab fa-whatsapp" style={{fontSize:"52px"}}/>
                                        <span style={{textAlign:"center", marginTop:"15px"}}>Está com dificuldades?</span>
                                    </div>
                                </div>
                                <div className="container-register ">
                                    <div className="col-xl-6 col-md-6 width100 ">
                                        <div className="card border mt-4">
                                            <div style={{display:"flex", padding:"2px"}}>
                                                <div className={`box-payment-method ${method === 0 ? 'box-payment-method-selected' : ''}`} onClick={e => setMethod(0)}>
                                                    <div style={{display:"flex", gap:"5px", justifyContent:"center"}}>
                                                        <input type='radio' className='check-form' checked={method === 0} />
                                                        <span style={{fontWeight:"bold"}}>Pix</span>
                                                    </div>
                                                    <div style={{display:"flex", justifyContent:"center"}}>
                                                        <i className='fab fa-pix' style={{fontSize:"18px"}}/>
                                                    </div>
                                                </div>
                                                <div className={`box-payment-method ${method === 1 ? 'box-payment-method-selected' : ''}`}  onClick={e => setMethod(1)}>
                                                    <div style={{display:"flex", gap:"5px", justifyContent:"center"}}>
                                                        <input type='radio' className='check-form' checked={method === 1}/>
                                                        <span style={{fontWeight:"bold"}}>Cartão</span>
                                                    </div>
                                                    <div style={{display:"flex", justifyContent:"center", gap:"5px"}}>
                                                        <i className='fab fa-cc-visa' style={{fontSize:"18px"}}/>
                                                        <i className='fab fa-cc-mastercard' style={{fontSize:"18px"}}/>
                                                    </div>
                                                </div>
                                                <div className={`box-payment-method ${method === 2 ? 'box-payment-method-selected' : ''}`} onClick={e => setMethod(2)}>
                                                    <div style={{display:"flex", gap:"5px", justifyContent:"center"}}>
                                                        <input type='radio' className='check-form' checked={method === 2} />
                                                        <span style={{fontWeight:"bold"}}>Boleto</span>
                                                    </div>
                                                    <div style={{display:"flex", justifyContent:"center"}}>
                                                        <i className='fa fa-barcode' style={{fontSize:"18px"}}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{display:"flex", padding:"15px", justifyContent:'center',alignItems:"center"}}>
                                                    <img alt="QRCode" style={{width:"200px", height:"200px", maxWidth:"200px"}} src={qrCode}/>  
                                                </div>
                                            </div>
                                            {/* <div className="card-body p-4 pricing-card" style={{justifyContent:"center", display:"flex"}}>
                                                <img alt="QRCode" style={{width:"200px", height:"200px", maxWidth:"200px"}} src={qrCode}/>    
                                            </div>
                                            <div style={{padding:"10px"}}>
                                                <input value={copy_past} readonly className="form-control"/>
                                            </div>
                                            <div style={{padding:"10px", display:"flex"}}>
                                                <button type="button" className="btn btn-success" onClick={e => navigator.clipboard.writeText(copy_past)} style={{ width:"47%", height:"auto"}}>
                                                    <i className="fa fa-copy"/> PIX Copia e Cola
                                                </button>
                                                <button type="button" className="btn btn-primary" onClick={e => window.open('https://api.whatsapp.com/send?phone=5562984383946&text=Segue%20o%20comprovante%20de%20pagamento')} style={{marginLeft:"15px", width:"47%", height:"auto"}}>
                                                    <i className="fa fa-file"/> Enviar Comprovante
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-md-6" style={{marginLeft:"5px"}}>
                                        <div className="card border mt-4">
                                            <div className="card-body p-2 pricing-card">
                                                <div style={{width:"100%", borderRadius:"5px", background:"#f6f6f6", padding:"10px", justifyContent:"center", display:"flex", alignItems:"center"}}>
                                                    <h5 className="text-uppercase font-size-16" style={{marginBottom:"0px"}}>Resumo do Pedido</h5>
                                                </div>
                                            </div>
                                            <div style={{display:"flex", marginLeft:"20px"}}>
                                                <div>
                                                    <img style={{width:"75px"}} src={BASIC_PLAN}/>
                                                </div>
                                                <div style={{display:"flex", flexDirection:"column", marginLeft:"10px"}}>
                                                    <span style={{fontWeight:"bold",fontSize:"16px"}}>{plan}</span>
                                                    <small>{period}</small>
                                                </div>
                                            </div>
                                            <div style={{borderTop:"1px dashed #cacaca", marginTop:"15px", padding:"15px", display:"flex", flexDirection:"row"}}>
                                                <div>
                                                    <span>Cupom de Desconto</span>
                                                    <input type="text" className="form-control" maxLength={15} value={coupon} onChange={e => setCoupon(e.target.value)}/>
                                                </div>
                                                <button type="button" className="btn btn-success" style={{marginTop:"17px", marginLeft:"5px"}} onClick={e => getCoupon()}><i className={`fa ${buttonCoupon ? 'fa-spin fa-spinner' : 'fa-tag'}`}/></button>
                                            </div>
                                            <div style={{borderTop:"1px dashed #cacaca", marginTop:"17px", padding:"15px", display:"flex", flexDirection:"column"}}>
                                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                                    <span style={{fontWeight:"bold"}}>Sub-Total</span>
                                                    <span >R$ {subtotal ? parseFloat(subtotal).toFixed(2).replace('.',',') : '0,00'}</span>
                                                </div>
                                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                                    <span style={{fontWeight:"bold"}}>Desconto</span>
                                                    <span>R$ {discount ? parseFloat(discount).toFixed(2).replace('.',',') : '0,00'}</span>
                                                </div>
                                                <div style={{display:"flex", justifyContent:"space-between", marginTop:"16px", borderTop:"1px dashed #cacaca"}}>
                                                    <span style={{fontWeight:"bold", fontSize:"16px"}}>Total</span>
                                                    <span style={{fontWeight:"bold",fontSize:"16px"}}>R$ {total ? parseFloat(total).toFixed(2).replace('.',',') : '0,00'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{padding:"5px",display:"flex",justifyContent:"flex-end"}}>
                                    <button className="btn btn-theme" onClick={e => Logout()}><i className="fa fa-door-closed"/> Sair</button>
                                </div>
                                
                            </ValidationForm>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )

}


