import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalLead from '../modals/modal-lead';
import ModalLeadMaintenance from '../modals/modal-lead-maintenance';
import ModalSendMail from '../modals/modal-sendmail';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import Swal from 'sweetalert2'

export default function Employee() {
    const [loading, setLoading]                            = useState(false)
    const [loadingDeleteList, setLoadingDeleteList]        = useState(false)
    const [loadingTagDelete, setLoadingTagDelete]          = useState(false)

    const [showModalMaintenance,setShowModalMaintenance]   = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [showModalSendMail, setShowModalSendMail]        = useState(false)

    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [editState, setEditState]                        = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([])
    const [tagSearch, setTagSearch]                        = useState('')
    const [tagIgnoreSearch, setTagIgnoreSearch]            = useState('')
    const [dataTags, setDataTags]                          = useState([])
    const [tagDelete, setTagDelete]                        = useState('')
    const [copy, setCopy]                                  = useState(false)
    const [number, setNumber]                              = useState('')

    // const [editPermission, setEditPermisson]               = useState(false)
    const getLeads = async() => {
        await api.get('/getTags')
                 .then(response => {
                    let _tags  = []

                    response.data?.map(_item => {
                        if (_item.tags.length > 1)
                            _item.tags.map(_item1 => {
                                if (!_tags.includes(_item1))
                                    _tags.push(_item1) 
                            })
                        else if (!_tags.includes(_item.tags)) {
                            _tags.push(_item.tags[0])
                        }
                    })

                    setDataTags(_tags)
                    setGridParams({
                                     idpeople         : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                                  value : '00000000'},
                                                         primaryKey  : true,                          },
                                     update_at        : {text        : "Alterado em...",
                                                         format      : "dd/MM/yyyy HH:mm:ss"},
                                     name            : {text        : "Nome"},
                                     phone_format    : {text        : "Telefone",
                                     searchDefault       : true},
                                     tagsgrid        : {text        : "Tags"},
                                     Options        : {text        : "Opções",
                                                        className   : "text-center",
                                                        order       : false,
                                                        filter      : false,
                                                        item        : [{type  : "Custom",
                                                                        props : {tag              : "button",
                                                                                title             : "Exclusão de contato",
                                                                                className         : 'btn btn-sm btn-danger',
                                                                                icon              : "fa fa-trash",
                                                                                message           : "Deseja realmente excluir o contato?",
                                                                                indexcallback     : 0}},
                                                                        {type  : "Custom",
                                                                        props : {tag               : "button",
                                                                                title             : "Editar contato",
                                                                                className         : 'btn btn-sm btn-warning',
                                                                                visualConditional : {idpeoplestatus : {operator : 'not in',
                                                                                                                        values : [3,5]}},
                                                                                icon              : "fa fa-edit",
                                                                                indexcallback     : 1}},
                                                                        {type  : "Custom",
                                                                         props : {tag               : "button",
                                                                                 title             : "Clonar contato",
                                                                                 className         : 'btn btn-sm btn-primary',
                                                                                 icon              : "fa fa-copy",
                                                                                 indexcallback     : 2}},
                                                                        {type  : "Custom",
                                                                         props : {tag               : "button",
                                                                                  title             : "Iniciar Chatbot",
                                                                                  className         : 'btn btn-sm btn-secondary',
                                                                                  icon              : "fa fa-robot marginLeft-1",
                                                                                  indexcallback     : 2}},
                                                                        {type  : "Custom",
                                                                         props : {tag               : "dropdown",
                                                                                  title             : "Iniciar Conversa",
                                                                                  className         : 'btn-success button-dropdown',
                                                                                  icon              : 'fa fa-comments marginLeft-1',
                                                                                  menus             : [{text : 'WhatsApp',
                                                                                                        indexcallback : 3,
                                                                                                        icon : 'fab fa-whatsapp'},

                                                                                                        {text : 'Email',
                                                                                                        indexcallback : 4,
                                                                                                        icon : 'fa fa-envelope',
                                                                                                        visualConditional : [{email : {operator : '!=',
                                                                                                                                       values   : ''}
                                                                                                                             }],}]}}]}
                                    })              
                 })
                 .catch(e => {
                    console.log(e)
                 })
    }

    useEffect(() => {
        getLeads()
        setStatistic('maintenance\\leads')
    },[])
    
    const handleCloseModal            = () => {setShowModal(false)}
    const handleCloseModalMaintenance = () => {setShowModalMaintenance(false)}
    const handleCloseModalSendMail    = () => {setShowModalSendMail(false)}
    const handleFilter                = () => {setIsOpenFilter(!isOpenFilter)}

    const sendWhatsapp = async(id,number) => {
        window.addTab(null,'Mensagens','fa fa-comment-dots','/messages',{newchat : true,to : number.toString().replace(/[\W_,[a-z]+/g,'')},1,true)
    }

    const handleListDelete = async() => {
        Swal.fire({
            title: 'Confirmação',
            html: "Deseja realmente excluir excluir os leads listados abaixo? <br><br><span style='color:red'>Essa Operação NÃO pode ser revertida!</span>",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                setLoadingDeleteList(true)
                await api.post('/selected-leads/',{filters : btoa(JSON.stringify(filters))} )
                         .then(response => {
                            ShowMessages(response.data)
                            setLoadingDeleteList(false)
                            setReload(true)
                         })
                         .catch( error => {
                            setLoadingDeleteList(false)
                            ShowMessages(error)
                         })
            }
            
        })
    }

    const RemoveLead   = async (id) => {   
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir o lead?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/leads/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
        }) 
    }

    const EditLead = async(id) => {
        await api.get('/leads/' + id)
                 .then(response => {
                    setData(response.data)
                    setEditState(true)
                    setCopy(false)
                    setShowModal(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const CopyLead = async(id) => {
        await api.get('/leads/' + id)
                 .then(response => {
                    setData(response.data)
                    setCopy(true)
                    setEditState(true)
                    setShowModal(true)
                 })
                .catch( error => {
                     ShowMessages(error)
                 })
    }

    const handleSearch = async() => {
        let _filters = []
        if (tagSearch !== '')
            _filters.push({ field      : 'tagsgrid',
                            key        : '=',  
                            value      : tagSearch,  
                          }) 

        if (tagIgnoreSearch !== '')
            _filters.push({ field      : 'nottagsgrid',
                            key        : 'not any',  
                            value      : tagIgnoreSearch,  
                          }) 

        if (number !== '')
            _filters.push({
                field      : 'phone_format',
                key        : '%%',  
                value      : number.toString().replace(/[\W_]+/g,""),
            }) 
        
        setFilters(_filters)
    }

    const handleTagDelete = async() => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir TODOS os contato com a TAG " + tagDelete + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                setLoadingTagDelete(true)
                await api.post('/leads/deleteall', {
                    tag : tagDelete
                })
                         .then(response => {
                            ShowMessages(response.data)
                            setLoadingTagDelete(false)
                            setReload(true)
                         })
                         .catch( error => {
                            setLoadingTagDelete(false)
                            ShowMessages(error)
                         })
            }
            
        }) 
    }

    const sendEmail = async() => {
        setShowModalSendMail(true)
    }

    const enterSearch = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          handleSearch();
        }
      };

    return (    
        <ContentWrapper>
            {loading && (
                <Loading loading={loading}/>
            )}
            <ModalLead showModal={showModal} handleCloseModal = {handleCloseModal} editState={editState} data={data} handleReload={setReload} copy={copy}/>
            <ModalLeadMaintenance showModal={showModalMaintenance} handleCloseModal = {handleCloseModalMaintenance} handleReload={setReload}/>
            <ModalSendMail showModal={showModalSendMail} handleCloseModal = {handleCloseModalSendMail} handleReload={setReload}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div  className="box-maintance-buttons">
                        <div style={{display:"flex",gap:"2px",flexWrap:"wrap", paddingRight:"5px" }}>
                            <button className="btn btn-success" title="Insere um novo registro" onClick={e => {setEditState(false) 
                                                                                                               setShowModal(true)
                                                                                                               setCopy(false) }}><i className="fa fa-plus"></i></button>
                            <button className="btn btn-primary" title="Atualizar Dados"  onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} ><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" title="Exportar para excel" onClick={e => setExcel(true)} ><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados"  onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                            <button className="btn btn-warning" title="Manutenção de Contatos"  onClick={e => setShowModalMaintenance(true)}><i className="fa fa-cogs"></i></button>
                            <button className="btn btn-danger" title="Excluir Contatos Listados"  onClick={e => handleListDelete()} disabled={loadingDeleteList}><i className={`${loadingDeleteList ? "fa fa-spin fa-spinner" : "fa fa-trash"}`}></i></button>
                        </div>
                        <div className="box-maintance-buttons-aux">
                            <div style={{ display:"flex", flexDirection:"column"}}>
                                <label>Filtrar pela TAG</label>
                                <select className="form-select" style={{minWidth:"150px"}} value={tagSearch} onChange={e => setTagSearch(e.target.value)}>
                                    <option value="">Todas</option>
                                    {dataTags?.map((_item,id) => {
                                        return(<option key={id} value={_item}>{_item}</option>)
                                    })}
                                </select>
                            </div>
                            <div style={{ display:"flex", flexDirection:"column"}}>
                                <label>Ignorar a TAG</label>
                                <select className="form-select" style={{minWidth:"150px"}} value={tagIgnoreSearch} onChange={e => setTagIgnoreSearch(e.target.value)}>
                                    <option value="">Não Ignorar</option>
                                    {dataTags?.map((_item,id) => {
                                        return(<option key={id} value={_item}>{_item}</option>)
                                    })}
                                </select>
                            </div>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <label>Número ou parte dele</label>
                                <input
                                    name="number"
                                    id="number"
                                    className="form-control width150"
                                    maxLength="25"
                                    onKeyDown={enterSearch}
                                    onChange={(e) => setNumber(e.target.value)}
                                    value={number}/>
                            </div>
                            <div style={{display:"flex", alignItems:"center"}}>
                                <button className="btn btn-success" title="Clique aqui para filtrar" style={{marginTop:"12px"}} onClick={e => handleSearch()}><i className="fa fa-filter"/></button>
                            </div>
                        </div>
                        <div className="box-maintance-buttons-aux" >
                            <div style={{ display:"flex", flexDirection:"column"}}>
                                <label>Excluir por TAG</label>
                                <select className="form-select" style={{minWidth:"150px"}} value={tagDelete} onChange={e => setTagDelete(e.target.value)}>
                                    <option value="">Selecione</option>
                                    {dataTags?.map((_item,id) => {
                                        return(<option key={id}value={_item}>{_item}</option>)
                                    })}
                                </select>
                            </div>
                            <div style={{ display:"flex", alignItems:"center"}}>
                                <button disabled={tagDelete === '' || loadingTagDelete} className="btn btn-danger" title="Clique aqui para excluir todos os contatos com a TAG selecionada" style={{marginTop:"12px"}} onClick={e => handleTagDelete()}><i className={`fa ${loadingTagDelete ? 'fa-spin fa-spinner' : 'fa-trash'}`}/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/leads' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      callbackShowButtons = {setShowButtons}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackExcel       = {setExcel}
                      filename            = "Leads"
                      callbackCellClick   = {EditLead}
                      callbackButtons     = {[RemoveLead, 
                                              EditLead,
                                              CopyLead,
                                              sendWhatsapp,
                                              sendEmail]}
                      />  
               
            </div>                    
        </ContentWrapper>
    )
}