import { memo, useCallback, useEffect, useState } from "react";
import { Handle,  Position, useReactFlow } from "reactflow";
import { getIconeFile } from "./../../Utils"
import ModalFileNode from "./../../../pages/modals/workflow/modal-file-node";

const FileNode = (props) => {
    const reactFlowInstance = useReactFlow();

    const [showModal, setShowModal]               = useState(false);
    const [selectedFiles, setSelectedFiles]       = useState([]);
    const [id, setID]                             = useState(-1)
    const [description, setDescription]           = useState('')
    const [statistic, setStatistic]               = useState(0)
    const [Url, setUrl]                           = useState('')
    const [tag, setTag]                           = useState('')
    const [objectReference, setObjectReference]   = useState('')
    const [origin,setOrigin]                      = useState(0)

    const deleteNode = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    const handleShowModal = (e) => {
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node            : _id,
                    parentId        : -1, 
                    targetId        : -1,
                    selectedFiles   : props.data.nodeInfo.selectedFiles,
                    description     : props.data.nodeInfo.description,
                    URL             : props.data.nodeInfo.URL,
                    objectReference : props.data.nodeInfo.objectReference,
                    tag             : props.data.nodeInfo.tag,
                    message         : props.data.nodeInfo.message,
                    origin          : props.data.nodeInfo.origin,
                    objectType      : 2
                    
                }
            }
        }))
    }

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;

            setSelectedFiles(nodeInfo.files || []);
            setID(parseInt(nodeInfo.node))
            setDescription(nodeInfo.description)
            setUrl(nodeInfo?.URL || '')
            setTag(nodeInfo?.tag || '')
            setStatistic(nodeInfo.statistic)
            setObjectReference(nodeInfo.objectReference)
            setOrigin(parseInt(nodeInfo.origin))
        };
        handleNodeProps();
    }, [props.data]);

    if (showModal) {
        return (
            <>
                <ModalFileNode
                    nodeId={props.id}
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    nodeData={props.data.nodeInfo}
                ></ModalFileNode>
            </>
        );
    }

    return (
        <>
            <div className="rfnode rfnode-file d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) => handleShowModal(e)}>
                <div className="rfnode-title">#{id} Arquivo</div>
                <div style={{width:"90%", minWidth:"120px", margin:"0px 10px"}}>
                    {description !== '' &&(
                        <div style={{padding:"5px", fontSize:"8px", fontWeight:"bold", borderRadius:"5px", border:"1px dashed #cacaca", display:"flex", marginTop:"5px", justifyContent:"center"}}>
                            {description}
                        </div>
                    )}

                    {selectedFiles.length <= 0 && Url === '' && tag === '' && ( 
                        <div className="my-2" style={{padding:"5px", display:"flex", alignItems:"center", width:"100%", height:"30px", justifyContent:"center"}}>
                            <div style={{ display:"flex", background:"rgb(108 117 125 / 29%)",justifyContent:"center", alignItems:"center", flexDirection:"column", padding:"5px", borderRadius:"5px",}}>
                                <div style={{display:"flex", justifyContent:"center", color:"#6c757d"}}>
                                    <i className="fa fa-file"/>
                                </div>
                                <span style={{fontSize:"6px", color:"#6c757d"}}>
                                    Informe um arquivo ou uma URL
                                </span>
                            </div>
                        </div>
                    )}
                    {selectedFiles.length > 0 && (
                        <div style={{padding:"5px 0px"}}>
                            {selectedFiles.map(_file => {
                                return (
                                    <div style={{background :"#6c757d1c", borderRadius:"5px", padding:"5px", display:"flex", height:"35px", marginTop:"2px", fontSize:"6px"}}>
                                        <div>
                                            <img alt="arquivo" style={{maxWidth:"20px"}} src={_file.src} onError={(e)=>{e.target.src=getIconeFile(_file.type,_file.name) }}/>
                                        </div>
                                  
                                        <div style={{marginLeft:"10px"}} title={_file.name}>
                                            <div style={{maxWidth: "80px",whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>
                                                <span>{_file.name}</span>
                                            </div>
                                            <div style={{textAlign:"right", marginTop:"-2px"}}>
                                                <span>{parseFloat((_file.size / 1024) /1024).toFixed(2)} Mb</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                                })}
                        </div>
                    )}

                    {(Url !== '') && [1,2,3].includes(origin) && ( 
                        <div style={{display:"flex",flexDirection:"column", padding:"5px", background:"#cacaca", marginBottom:"10px", borderRadius:"5px", lineHeight:"10px", wordWrap:"break-word", marginTop:"15px"}}>
                            <span style={{fontSize:"6px", fontWeight:"bold"}}>URL</span>
                            <span style={{fontSize:"8px"}}>{Url}</span>
                        </div>
                    )}

                    {objectReference !== '' && origin === 3 && ( 
                        <div style={{display:"flex",flexDirection:"column",padding:"5px", background:"#Cacaca", marginBottom:"10px", borderRadius:"5px", lineHeight:"10px", wordWrap:"break-word"}}>
                            <span style={{fontSize:"6px", fontWeight:"bold"}}>Referência</span>
                            <span style={{fontSize:"8px"}}>{objectReference}</span>
                        </div>
                    )}
                     

                     {tag !== '' && origin === 2 &&( 
                        <div style={{display:"flex",flexDirection:"column",padding:"5px", background:"#Cacaca", marginBottom:"10px", borderRadius:"5px", lineHeight:"10px", wordWrap:"break-word", marginTop:"15px", justifyContent:"center", display:"flex"}}>
                            <span style={{fontSize:"6px", fontWeight:"bold"}}>Tags</span>
                            <span style={{fontSize:"8px"}}>{tag}</span>
                        </div>
                    )} 
                </div>
                <div style={{display:"flex",fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #6c757d"}}>
                    <div style={{display:"flex", gap:"5px"}}>
                        <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                            <i className="fa fa-copy text-primary"></i>
                        </div>
                        <div onClick={() => deleteNode()} title="Excluir Mensagem" className="workflow-delete" >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    </div>
                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{fontSize:"10px", color:"#6c757d"}}>{statistic || ''}</span>
                    </div>
                </div>
            </div>


            <Handle type="source" position={Position.Bottom} id='node-file1' />
            <Handle type="target" position={Position.Top}    id='node-file2' />
            <Handle type="target" position={Position.Left}   id='node-file3' />
            <Handle type="source" position={Position.Right}  id='node-file4' />
        </>
  );
};

export default memo(FileNode);
