import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import api from './../../../services/api'
import ShowMessages from './../../../components/Exceptions'

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}


export default function ModalActionNode({ nodeId, showModal, handleCloseModal, nodeData }) {
    const [departament, setDepartament]         = useState(0);
    const [departamentName, setDepartamentName] = useState(0);
    const [dataDepartament, setDataDepartament] = useState([]);

    const reactFlowInstance = useReactFlow();

    const handleDepartament = (iddepartament, departament) => {
        setDepartamentName(departament)
        setDepartament(iddepartament)
    }

    useEffect(() => {
        const getData = async() => {
            await api.get('/getDepartament')
                     .then((response) => { 
                        setDataDepartament(response.data);
                     })
                     .catch(error => {
                        ShowMessages(error)
                     })  
        }
        getData()
    }, []);

    useEffect(() => {
        setDepartament(nodeData.departament)
    }, [nodeData]);

    const handleSubmit = (e) => {
        e.preventDefault();

        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node       : parseInt(nodeData.node),
                            parentId   : parseInt(nodeData.parentId), 
                            targetId   : parseInt(nodeData.targetId),
                            objectType : 12,
                            isClosed   : true, 
                            isHuman    : true,
                            departament,
                            departamentName
                        }
                    }
                }

                return node;
            })
        )

        handleCloseModal();
    };

    return (
        <Modal size="sm" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width500" animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-headset"></i> Atendimento
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="box-shadow" style={{padding:"10px"}}>
                            <div className="mb-3">
                                <label>Departamento</label>
                                <div className="input-group w-100">
                                    <select
                                        name="departament"
                                        id="departament"
                                        className="form-select w-100"
                                        placeholder="Selecione"
                                        onChange={(e) => handleDepartament(parseInt(e.target.value),e.target.options[e.target.selectedIndex].text)}
                                        value={departament}>
                                        <option value="">Selecione</option>
                                            {dataDepartament.map((data, id) => {
                                                return (<option key={id} value={data.iddepartament}>{data.departament}</option>);
                                            })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-action-info">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
  );
}
