import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button, } from 'reactstrap';
import Draggable from 'react-draggable';


import { Modal, ModalDialog } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'

import api from '../../services/api';

import loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalTags({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]   = useState(false)
    const [tag, setTag]           = useState('')
    const [color, setColor]       = useState('')
  
    const setData = (obj,clear) => {
        setTag(clear ? '' : obj.tag)
        setColor(clear ? '' : obj.color)
    }
    
    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-color'),document.querySelector('#submit-color').children[0].classList,document.querySelector('#submit-color').lastChild.nodeValue)
        event.preventDefault()

        try {
            let _data = {color,
                         tag}
                     
            if (editState)
                await api.put('/tags/' + data.idtag, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-color'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-color'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-color'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/tags', _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-color'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-color')) 
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-color'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-color'))
            ShowMessages(response)
        }
    }

    const handleColor = (color,event) => {
        if (color !== undefined)
            setColor(color?.hex)
    }

    useEffect(() => {
        setStatistic('modals\\modal-tags')
    },[])

    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    return (
      
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" > 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-tags"></i> Propriedades de Tags
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth330px"/>
                    )}
                    <div className={`box-shadow ${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>Tags</label>
                            <div className="input-group">
                                 <TextInput  
                                    name="tag"
                                    id="tag"
                                    type="tag"
                                    required
                                    maxLength={100}
                                    errorMessage={{ required: "Por favor, informe uma TAG!" }}
                                    value={tag}
                                    className="form-control "
                                    autoFocus
                                    onChange={e => setTag(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div>
                            <label>Cor da Etiqueta</label>
                            <SketchPicker
                            style={{with:"150px"}}
                                color={color }
                                onChangeComplete={ handleColor }
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-color' className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}