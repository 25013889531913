import { memo, useCallback, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import ModalWebHookNode from "../../../pages/modals/workflow/modal-webhook-node";

const HookNodeItem = ({ handleId, value }) => {
    return (
        <div className="custom-node__options">
          <li className="list-item d-flex align-items-center" >
            <div className="list-marker me-1">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            <div style={{textOverflow: "ellipsis", maxWidth:"170px",whiteSpace: "nowrap",overflow: "hidden", fontSize:"8px" }} title={value}>{value}</div>
          </li>
          <div style={{marginLeft:'140px'}}>
            <Handle type="source" position={Position.Right} id={`node-hook${handleId}`}/>
          </div>

        </div>
      );
};

const WebHookNode = (props) => {
    const [showModal, setShowModal]         = useState(false);
    const [method, setMethod]               = useState('')
    const [body, setBody]                   = useState('')
    const [url, setURL]                     = useState('')
    const [conditionData, setConditionData] = useState([])
    const [statistic, setStatistic]         = useState(0)
    const reactFlowInstance                 = useReactFlow();
    const [id, setID]                       = useState(-1)

    const constas = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]
console.log(props.data.nodeInfo)
        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node          : _id,
                    parentId      : -1, 
                    targetId      : -1,
                    method        : props.data.nodeInfo.method,
                    url           : props.data.nodeInfo.url,
                    body          : props.data.nodeInfo.body,
                    description   : props.data.nodeInfo.description,
                    conditionData : props.data.nodeInfo.conditionData,
                    requestType   : props.data.nodeInfo.requestType,
                    objectType    : 10   

                }
            }
        }))
    }

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            setMethod(nodeInfo.method);
            setBody(nodeInfo.body);
            setURL(nodeInfo.url);
            setConditionData(nodeInfo.conditionData);
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
        };
        handleNodeProps();
    }, [props.data]);


    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <ModalWebHookNode nodeId={props.id} showModal={showModal} handleCloseModal={handleCloseModal} nodeData={props.data.nodeInfo}></ModalWebHookNode>
            <div className="rfnode rfnode-webhook d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) => setShowModal(true)}>
                <div className="rfnode-title">#{id} Webhook</div>
                <div style={{ padding:"10px", background:"rgba(13, 202, 240, 0.1098039216)", borderRadius:"5px", margin:"5px 5px", color:"#000", width:"170px", maxWidth:"170px"}}>
                    {url === '' ? (
                        <div style={{ display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", textAlign:"center"}}>
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <i className="fa fa-satellite-dish" style={{color:"#0dcaf0"}}/>
                            </div>
                            <span style={{fontSize:"6px", color:"#0dcaf0"}}>Aqui você informa os parâmetros para enviar uma requisição.</span>
                        </div>
                    ) : (   
                        <div style={{fontSize:"6px",color:"#0dcaf0"}}>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <span style={{fontWeight:"bold"}}>Método</span>
                                <span>{method}</span>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", marginTop:"2px"}}>
                                <span style={{fontWeight:"bold"}}>URL</span> 
                                <span style={{overflowWrap: "anywhere"}}>{url}</span>
                            </div>
                            {body !== '' && (
                                <div style={{display:"flex", flexDirection:"column", marginTop:"2px"}}>
                                    <span style={{fontWeight:"bold"}}>Body</span> 
                                    <pre style={{overflowX:"hidden", textWrap:"balance"}}>{body}</pre>
                                </div>
                            )}
                            {
                                conditionData.map((_item, optionId) => {
                                    return (
                                        <div style={{color:"#fff",background:"#0dcaf0", borderRadius:"5px", padding:"5px", display:"flex", height:"27px", marginTop:"2px" ,fontSize:"8px", alignItems:"center"}}>
                                            <HookNodeItem
                                                key={optionId}
                                                value={_item.field + ' ' + _item.ifText + ' ' + _item.value01}
                                                handleId={optionId + 4}
                                            ></HookNodeItem>
                                        </div>
                                    )   
                                })              
                            }
                        </div>
                    )}
                </div>
                <div style={{display:"flex",fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #0dcaf0"}}>
                    <div style={{display:"flex", gap:"5px"}}>
                        <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                            <i className="fa fa-copy text-primary"></i> 
                        </div>
                        <div onClick={() => constas()} title="Excluir Mensagem" className="workflow-delete" >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    </div>
                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{fontSize:"10px", color:"#0dcaf0"}}>{statistic || ''}</span>
                    </div>

                </div>
            </div>

            <Handle type="target" position={Position.Bottom} id='node-hook1'/>
            <Handle type="target" position={Position.Left} id='node-hook2' />
            <Handle type="target" position={Position.Top} id='node-hook3' />
        </>
    );
};

export default memo(WebHookNode);
