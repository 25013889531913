import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button, } from 'reactstrap';
import Draggable from 'react-draggable';

import { phone_format} from "./../../components/Utils";
import { Modal,  ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'

import api from '../../services/api';
import { IDCOMPANY, ReadDataStorage} from './../../services/auth'
import loadingButton  from '../layouts/Buttons'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalDepartament({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]             = useState(false)
    const [departament, setDepartament]     = useState('')
    const [number, setNumber]               = useState('')

    const [settings, setSettings]           = useState({})
    const [phoneData, setPhoneData]         = useState([])
    const [phoneSelected, setPhoneSelected] = useState([])

    const addPhone = () => {
        if (number !== "") {
            if (!phoneSelected.find(el => el.number === number)) {
                setPhoneSelected(phoneSelected => phoneSelected.concat({number, 
                                                                        number_format : phone_format(number)})) 
                setNumber('')                                              
            } else 
                ShowMessages({status : 500, message : 'Telefone informado já está cadastrado!'})
        } else {
            ShowMessages({status : 500,  message : 'Por favor, selecione um número de telefone!'})
        }
    }

    const removePhone = (_number) => {
        const _remove = phoneSelected.filter((t, i) => {
            if (t.number !== _number)
                return true;
        
            return false;
        });
        setPhoneSelected(_remove)
    }

    const setData = (obj,clear) => {
        setDepartament(clear ? '' : obj.departament)
        setNumber(clear ? '' : obj.number)
        setPhoneSelected(clear ? [] : obj.selectednumbers || [])
    }
 
    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-departament'),document.querySelector('#submit-departament').children[0].classList,document.querySelector('#submit-departament').lastChild.nodeValue)
        event.preventDefault()

        try {
            let _data = {departament,
                         number,
                         selectednumbers : phoneSelected}
                     
                     
            if (editState)
                await api.put('/departament/' + data.iddepartament, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-departament'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-departament'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-departament'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/departament', _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-departament'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-departament')) 
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-departament'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-departament'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        const getSettings = async () => {
            await api.get('/company/' + ReadDataStorage(IDCOMPANY))
                     .then(async(response) => {
                        setSettings(response.data.settings)
                        setLoading(false)
                     }).catch((error) => {
                        ShowMessages(error)
                     })  
        }

        const getData = async() => {
            await api.get('/getAllMyWhatsAppNumbers')
                     .then(response => {
                        getSettings()
                        setPhoneData(response.data)     
                     })
                     .catch( error => {      
                        ShowMessages(error) 
                     })
        }
        setLoading(true)
        getData()
        setStatistic('modals\\modal-departament')
    },[])

    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    return (
      
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" > 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-users-between-lines"></i> Propriedades do Departamento
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth330px"/>
                    )}
                    <div className={`box-shadow ${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>Departamento</label><label className="text-required">*</label>
                            <div className="input-group">
                                 <TextInput  
                                    name="street"
                                    id="street"
                                    type="text"
                                    required
                                    maxLength={100}
                                    errorMessage={{ required: "Por favor, informe um departamento!" }}
                                    value={departament}
                                    className="form-control "
                                    autoFocus
                                    onChange={e => setDepartament(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        {settings.modeType === 2 && (
                            <div style={{width:"100%"}}>
                                <div style={{width:"100%", display:"flex",gap:"5px"}}>
                                    <div style={{width:"100%"}}>
                                        <span>WhatsApp Padrão</span><label className="text-required">*</label>
                                        <select className="form-select" value={number} onChange={e => setNumber(e.target.value)} >
                                            <option value="">Selecione</option>
                                            {phoneData.map((item) => {
                                                return(<option value={item.number}>{item.phone_format}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div style={{display:"flex", alignItems:"end"}}>
                                        <button className='btn btn-success' type="button" onClick={e => addPhone()}>
                                            <i className='fa fa-plus'/>
                                        </button>
                                    </div>
                                </div>
                                <div style={{marginTop:"5px", overflowX:"auto",height:"250px"}}>
                                    <table className="table table-striped w-100">
                                        <thead className="thead-theme">
                                            <tr className="head-th">
                                                <td>Números</td>
                                                <td style={{textAlign:"center"}}>...</td>
                                            </tr>
                                        </thead>  
                                        <tbody> 
                                            {phoneSelected.map((item) => {
                                                return(<tr>
                                                    <td>{item.number_format}</td>
                                                    <td className='text-center'>
                                                        <button className='btn btn-danger btn-sm' type="button" onClick={e => removePhone(item.number)}>
                                                            <i className='fa fa-trash'/>
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })}  
                                        </tbody>
                                    </table>
                                </div>            
                            </div>
                        )}
                        </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-departament' className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}