import { memo, useCallback, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import ModalTriggerNode from "../../../pages/modals/workflow/modal-trigger-node";

const TriggerNode = (props) => {
    const [showModal, setShowModal]       = useState(false);
    const [id, setID]                     = useState(-1)
    const [idautomation, setIdAutomation] = useState(0)
    const [workflow, setWorkflow]         = useState('')
    const [statistic, setStatistic]       = useState(0)
    const reactFlowInstance = useReactFlow();

    const constas = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            setIdAutomation(nodeInfo.idautomation || 0);
            setWorkflow(nodeInfo.workflow)
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
        };
        handleNodeProps();
    }, [props.data]);

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node         : _id,
                    parentId     : -1, 
                    targetId     : -1,
                    idautomation : props.data.nodeInfo.idautomation,
                    workflow : props.data.nodeInfo.workflow,
                    objectType   : 14
                }
            }
        }))
    }


    const handleShowModal = (e) => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <ModalTriggerNode nodeId={props.id} showModal={showModal} handleCloseModal={handleCloseModal} nodeData={props.data.nodeInfo}/>
            <div className="rfnode rfnode-trigger d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) => handleShowModal(e)}>
                <div className="rfnode-title">#{id} Gatilho</div>

                <div style={{background: "rgba(192, 162, 208, 0.1098039216)", color:"#C0A2D0", margin:"5px 10px", padding:"5px", borderRadius:"5px", color:"#6f42c1", width:"85%", textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    {(idautomation === 0) ? (
                        <span style={{fontSize:"6px"}}>Aqui você conecta um outro fluxo para seguir com a automação.</span>
                    ) : (
                        <span style={{fontSize:"6px"}}>{workflow}</span>
                    )}
                </div>
                <div style={{display:"flex",fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #C0A2D0"}}>
                    <div style={{display:"flex", gap:"5px"}}>
                        <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                            <i className="fa fa-copy text-primary"></i>
                        </div>
                        <div onClick={() => constas()} title="Excluir Mensagem" className="workflow-delete" >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    </div>

                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{fontSize:"10px", color:"#C0A2D0"}}>{statistic || ''}</span>
                    </div>
                </div>
            </div>

            <Handle type="target" position={Position.Top} id='node-trigger1' />
            <Handle type="target" position={Position.Left} id='node-trigger2' />
            <Handle type="target" position={Position.Bottom} id='node-trigger3' />
            <Handle type="target" position={Position.Right} id='node-trigger4' />
        </>
    );
};

export default memo(TriggerNode);
