import React, { useState, useEffect } from 'react'
import ContentWrapper from '../../layouts/contentWrapper'
import api from '../../../services/api'
import ShowMessages from '../../../components/Exceptions'
import setStatistic from '../../../services/apiStatistic'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,  PieChart, Pie,ResponsiveContainer } from 'recharts';
import { format,differenceInDays }   from 'date-fns'
import Loading from '../../../components/Loading';

export default function DashboardClients() {
    const [loading, setLoading]                 = useState(false)

    const [clients, setClients]                 = useState(0)
    const [active, setActive]                   = useState(0) 
    const [inactive, setInactive]               = useState(0)
    const [trial, setTrial]                     = useState(0)   
    const [growth, setGrowth]                   = useState([])
    const [inactives, setInactives]             = useState([])
    const [modules, setModules]                 = useState([])
    const [due, setDue]                         = useState([])
    const [expired, setExpired]                 = useState([])
    const [last, setLast]                       = useState([])
    const [access, setAccess]                   = useState([])

    const getData = async() => {
        setLoading(true)
        await api.get('/admin/dashboard-client')
                 .then(response => {
                    let data = response.data
                    console.log(response.data)
                    setClients(data.clients)
                    setActive(data.active)
                    setInactive(data.inactive)
                    setTrial(data.trial)
                    setGrowth(data.growth)
                    setInactives(data.inactives)
                    setModules(data.modules)
                    setDue(data.due)
                    setExpired(data.expired)
                    setLast(data.last)
                    setAccess(data.access)
                    setLoading(false)
                 })
                 .catch((error) => {
                    ShowMessages(error)
                 })
    }

    useEffect(() => {
        getData()
        setStatistic('maintenance\\admin\\dashboard-clients')
    },[])

    
    return (    
        <ContentWrapper>
            {loading && (
                <Loading loading={loading} />
            )}
            <div className={`box-shadow content-box-cart ${(loading ? "hidden" : "")}`} style={{padding:"10px", display:"flex", flexDirection:"column"}}>
                <div style={{display:"flex"}}>
                    <div style={{width:"100%"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0" >
                            <div className="col-4 d-flex align-items-center bg bg-primary-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-users fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 alert alert-primary rounded-right" style={{borderTopRightRadius:"7px", borderBottomRightRadius:"7px", borderBottomLeftRadius:"0px",borderTopLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h2 mt-0" style={{textAlign:"right", paddingRight:"10px"}}>{clients}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Total Clientes</div>
                            </div>
                        </div>
                    </div> 
                    <div style={{width:"100%", marginLeft:"5px"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0">
                            <div className="col-4 d-flex align-items-center bg bg-success-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-user-check fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 alert alert-success rounded-right" style={{borderTopRightRadius:"7px", borderBottomRightRadius:"7px", borderBottomLeftRadius:"0px",borderTopLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h2 mt-0" style={{textAlign:"right", paddingRight:"10px"}}>{active}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Clientes Ativos</div>
                            </div>
                        </div>
                    </div>  
                    <div style={{width:"100%", marginLeft:"5px"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0">
                            <div className="col-4 d-flex align-items-center bg bg-danger-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-user-slash fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 alert alert-danger rounded-right" style={{borderTopRightRadius:"7px", borderBottomRightRadius:"7px", borderBottomLeftRadius:"0px",borderTopLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h2 mt-0" style={{textAlign:"right", paddingRight:"10px"}}>{inactive}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Clientes Inativos</div>
                            </div>
                        </div>
                    </div>   
                    <div style={{width:"100%", marginLeft:"5px"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0">
                            <div className="col-4 d-flex align-items-center bg bg-warning-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-user-clock fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 alert alert-warning  rounded-right" style={{borderTopRightRadius:"7px", borderBottomRightRadius:"7px", borderBottomLeftRadius:"0px",borderTopLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h2 mt-0" style={{textAlign:"right", paddingRight:"10px"}}>{trial}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Trial | Free</div>
                            </div>
                        </div>
                    </div>   
                </div>
                <div style={{display:"flex"}}>
                    <div style={{height:"300px",padding:"15px 5px", width:"33%"}} className="box-shadow">
                        <div style={{padding:"5px", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}><i className="fa fa-chart-line"></i> Crescimento Mensal</span>
                        </div>
                        <ResponsiveContainer width="100%" height="95%">
                            <LineChart
                                width={500}
                                height={300}
                                data={growth}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                            }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis domain={[0, 10]}/>
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="crescimento" stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div style={{height:"300px",padding:"15px 5px", marginLeft:"10px", width:"33%"}} className="box-shadow">
                        <div style={{padding:"5px", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}><i className="fa fa-magnifying-glass-chart"></i> Ativos x Inativos</span>
                        </div>
                        <ResponsiveContainer width="100%" height="95%">
                            <LineChart
                                width={500}
                                height={300}
                                data={inactives}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                            }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis domain={[0, 10]}/>
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="crescimento" stroke="#8884d8" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="inativo" stroke="#82ca9d" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div style={{height:"300px",padding:"15px 5px", marginLeft:"10px", width:"33%"}} className="box-shadow">
                        <div style={{padding:"5px", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}><i className="fa fa-cube"></i> Módulos Usados</span>
                        </div>
                        <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                            <Pie
                                dataKey="value"
                                startAngle={180}
                                endAngle={0}
                                data={modules}
                                cx="50%"
                                cy="50%"
                                outerRadius={80}
                                fill="#8884d8"
                                label
                            />
                            <Tooltip />
                        </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div style={{display:"flex", marginTop:"15px"}}>
                    <div className="box-shadow" style={{padding:"5px", overflowX:"auto",height:"310px", width:"25%"}}>
                        <div style={{padding:"5px", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}><i className="fa fa-calendar-check"></i> Próximos a Vencer</span>
                        </div>
                        <table className='table table-striped w-100' style={{height:'10px', marginTop:"5px"}}>
                            <thead className="thead-light">
                                <tr className='head-th'>
                                    <th style={{width:"55%"}}>Cliente</th>
                                    <th style={{width:"25%"}}>Vencimento</th>
                                    <th style={{width:"20%"}}>Vence em</th>
                                </tr>
                            </thead>
                            <tbody>
                                {due?.map(_item => {
                                    return(
                                        <tr className="gradeX">
                                            <td>{_item.fantasy}</td>
                                            <td>{format(new Date(_item.amendment.expiration),'dd/MM/yyyy')}</td>
                                            <td className="text-center">{differenceInDays(new Date(_item.amendment.expiration),new Date())}</td>
                                        </tr>)
                                    })}
                            </tbody>
                        </table>
                    </div>
                    <div className="box-shadow" style={{padding:"5px", marginLeft:"10px", overflowX:"auto",height:"310px", width:"25%"}}>
                        <div style={{padding:"5px", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}><i className="fa fa-calendar-check"></i> Vencidos | Inativos</span>
                        </div>
                        <table className='table table-striped w-100' style={{height:'10px', marginTop:"5px"}}>
                            <thead className="thead-light">
                                <tr className='head-th'>
                                    <th style={{width:"55%"}}>Cliente</th>
                                    <th style={{width:"25%"}}>Vencimento</th>
                                    <th style={{width:"20%"}}>Venceu à</th>
                                </tr>
                            </thead>
                            <tbody>
                                {expired?.map(_item => {
                                    return(
                                        <tr className="gradeX">
                                            <td>{_item.fantasy}</td>
                                            <td>{format(new Date(_item.amendment.expiration),'dd/MM/yyyy')}</td>
                                            <td className="text-center">{differenceInDays(new Date(),new Date(_item.amendment.expiration))}</td>
                                        </tr>)
                                    })}
                            </tbody>
                        </table>
                    </div>
                    <div className="box-shadow" style={{padding:"5px", marginLeft:"10px", overflowX:"auto",height:"310px", width:"25%"}}>
                        <div style={{padding:"5px", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}><i className="fa fa-user-plus"></i> Últimos Cadastros</span>
                        </div>
                        <table className='table table-striped w-100' style={{height:'10px', marginTop:"5px"}}>
                            <thead className="thead-light">
                                <tr className='head-th'>
                                    <th style={{width:"55%"}}>Cliente</th>
                                    <th style={{width:"25%"}}>Data Cadastro</th>
                                    <th style={{width:"20%"}}>Dias</th>
                                </tr>
                            </thead>
                            <tbody>
                                {last?.map(_item => {
                                    return(
                                        <tr className="gradeX">
                                            <td>{_item.fantasy}</td>
                                            <td>{format(new Date(_item.amendment.start),'dd/MM/yyyy')}</td>
                                            <td className="text-center">{differenceInDays(new Date(),new Date(_item.amendment.start))}</td>
                                        </tr>)
                                    })}
                            </tbody>
                        </table>
                    </div>
                    <div className="box-shadow" style={{padding:"5px", marginLeft:"10px", overflowX:"auto",height:"310px", width:"25%"}}>
                        <div style={{padding:"5px", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}><i className="fa fa-person-circle-exclamation"></i> Acima 5 dias sem usar</span>
                        </div>
                        <table className='table table-striped w-100' style={{height:'10px', marginTop:"5px"}}>
                            <thead className="thead-light">
                                <tr className='head-th'>
                                    <th style={{width:"55%"}}>Usuário</th>
                                    <th style={{width:"25%"}}>Último Acesso</th>
                                    <th style={{width:"20%"}}>Dias</th>
                                </tr>
                            </thead>
                            <tbody>
                                {access?.map(_item => {
                                    return(
                                        <tr className="gradeX">
                                            <td>{_item.registration}</td>
                                            <td>{format(new Date(_item.create_at),'dd/MM/yyyy')}</td>
                                            <td className="text-center">{differenceInDays(new Date(),new Date(_item.create_at))}</td>
                                        </tr>)
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>                    
        </ContentWrapper>
    )
}