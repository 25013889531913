import React from 'react';
import { BaseFormControl } from 'react-bootstrap4-form-validation';

export default class MaskMoneyValidation extends BaseFormControl {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  getInputRef() {
    return this.inputRef.current;
  }

  formatCurrency(value) {
    const options = {
      style: this.props.formatNumber || 'currency',
      currency: 'BRL',
    };

    const formattedValue = Number(value) / 100;

    return formattedValue.toLocaleString('pt-BR', options);
  }

  handleChange = (e) => {
    if (this.props.onChange) {
      const { value } = e.target;
      const numericValue = value.replace(/\D/g, ''); // Remove caracteres não numéricos
      const formattedValue = this.formatCurrency(numericValue);
      this.checkError();
      this.props.onChange(formattedValue.replace(' ', ' '));
    }
  };

  render() {
    return (
      <React.Fragment>
        <input
          type="text"
          {...this.filterProps()}
          onChange={this.handleChange}
          ref={this.inputRef}
        />
        {this.displayErrorMessage()}
        {this.displaySuccessMessage()}
      </React.Fragment>
    );
  }
}
