import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import setStatistic from '../../services/apiStatistic'

import ModalImport from '../modals/modal-import';
import ModalImportWhats from '../modals/modal-import-whats';
import ModalImportGoogle from '../modals/modal-import-google';
import ModalImportInstagram from '../modals/modal-import-instagram';

import { Button } from 'reactstrap';

export default function Imports(props) {
    const [showModal, setShowModal]                    = useState(false)
    const [showModalWhats, setShowModalWhats]          = useState(false)
    const [showModalGoogle, setShowModalGoogle]        = useState(false)
    const [showModalInstagram, setShowModalInstagram]  = useState(false)

    useEffect(() => {
        setStatistic('maintenance\\imports')
    },[])

    const handleImport = (importType) => {
        switch (importType) {
            case 1 : setShowModal(true); break;
            case 2 : setShowModalWhats(true); break;
            case 3 : setShowModalGoogle(true); break;
            case 4 : setShowModalInstagram(true); break;
            default:
                break;
        }
        
    }

    const handleCloseModal          = () => {setShowModal(false) }
    const handleCloseModalWhats     = () => {setShowModalWhats(false) }
    const handleCloseModalGoogle    = () => {setShowModalGoogle(false) }
    const handleCloseModalInstagram = () => {setShowModalInstagram(false) }

    return (    
        <ContentWrapper className="content-wrapper-bottom81 ">
            <ModalImport showModal={showModal} handleCloseModal = {handleCloseModal} />
            <ModalImportWhats showModal={showModalWhats} handleCloseModal = {handleCloseModalWhats} />
            <ModalImportGoogle showModal={showModalGoogle} handleCloseModal = {handleCloseModalGoogle} />
            <ModalImportInstagram showModal={showModalInstagram} handleCloseModal = {handleCloseModalInstagram} />

            <div className={`content-box-cart-page adjust-height`} style={{alignItems:"center"}}>
                <div style={{width:"100%", justifyContent:"center",alignItems:"center", display:"flex", flexWrap:"wrap"}}>
                    <div className="box-shadow alert alert-success box-import" style={{marginRight:"15px"}} onClick={e => handleImport(1)}>
                        <div style={{textAlign:"center"}}>
                            <i style={{fontSize:"42px"}} className="fa fa-file-excel"/>
                        </div>
                        <div style={{paddingTop:"15px",textAlign:"center"}}>
                            Importar do Arquivo Excel
                        </div>
                    </div>
                    <div className="box-shadow alert alert-primary box-import" style={{marginRight:"15px"}} onClick={e => handleImport(2)}>
                        <div style={{textAlign:"center"}}>
                            <i style={{fontSize:"42px"}} className="fab fa-whatsapp"/>
                        </div>
                        <div style={{paddingTop:"15px",textAlign:"center"}}>
                            Importar Contatos do WhatsApp
                        </div>
                    </div>
                    <div className="box-shadow alert alert-info box-import" style={{marginRight:"15px"}} onClick={e => handleImport(3)}>
                        <div style={{textAlign:"center"}}>
                            <i style={{fontSize:"42px"}} className="fab fa-google"/>
                        </div>
                        <div style={{paddingTop:"15px",textAlign:"center"}}>
                            Importar contatos do Google Contacts
                        </div>
                    </div>
                    <div className="box-shadow alert alert-warning box-import" style={{ marginRight:"15px"}} onClick={e => alert("Em breve")}>
                        <div className="badge badge-success" style={{position:"absolute", marginTop:"-25px"}}>Em breve</div>
                        <div style={{textAlign:"center"}}>
                            <i style={{fontSize:"42px"}} className="fab fa-facebook"/>
                        </div>
                        <div style={{paddingTop:"15px",textAlign:"center"}}>
                            Importar contatos do Facebook
                        </div>
                    </div>
                    <div className="box-shadow alert alert-danger box-import" style={{marginRight:"15px"}} onClick={e => alert("Em breve")}>
                        <div className="badge badge-success" style={{position:"absolute", marginTop:"-25px"}}>Em breve</div>
                        <div style={{textAlign:"center"}}>
                            <i style={{fontSize:"42px"}} className="fab fa-linkedin"/>
                        </div>
                        <div style={{paddingTop:"15px",textAlign:"center"}}>
                            Importar contatos do Linkedin
                        </div>
                    </div>
                    <div className="box-shadow alert alert-secondary box-import" style={{marginRight:"15px", color:"#fff"}} onClick={e => alert("Em breve")}>
                        <div className="badge badge-success" style={{position:"absolute", marginTop:"-25px"}}>Em breve</div>
                        <div style={{textAlign:"center"}}>
                            <i style={{fontSize:"42px", color:"#272829"}} className="fab fa-telegram"/>
                        </div>
                        <div style={{paddingTop:"15px",textAlign:"center", color:"#272829"}}>
                            Importar números do Telegram
                        </div>
                    </div>
                    <div className="box-shadow alert alert-ligth box-import" onClick={e => handleImport(4)}>
                        <div style={{textAlign:"center"}}>
                            <i style={{fontSize:"42px"}} className="fab fa-instagram"/>
                        </div>
                        <div style={{paddingTop:"15px",textAlign:"center"}}>
                            Importar números do Instagram
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer footer-fixed modal-footer-bottom-10">
                <Button className="btn-ligth-gray">
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </div>
        </ContentWrapper>
    )
}