import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalCampaign from '../modals/modal-campaign';
import ModalCampaignNumbers from '../modals/modal-campaign-numbers';
import ModalCampaignMetrics from '../modals/modal-campaign-metrics';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import Swal from 'sweetalert2'
import { format,addDays }   from 'date-fns'

export default function Campaing() {
    const [loading, setLoading]                            = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [showModalNumber, setShowModalNumber]            = useState(false)
    const [showModalMetrics, setShowModalMetrics]          = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [editState, setEditState]                        = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [dataNumbers, setDataNumbers]                    = useState({})
    const [dataMetrics, setDataMetrics]                    = useState({})
    const [idcampaign, setIdCampaign]                      = useState(0)
    const [filters, setFilters]                            = useState([{ field      : 'create_at',
                                                                         key        : 'between',  
                                                                         value      : format(addDays (new Date(),-7),'yyyy-MM-dd'),
                                                                         value2     : format(new Date(),'yyyy-MM-dd'),
                                                                         ignoretime : true},
                                                                       
                                                                       { field      : 'idstatuscampaign',
                                                                         key        : 'in',  
                                                                         value      : [0,1,4,6,8],
                                                                         value2     : null}])
                                                                         
    const [dataStatus, setDataStatus]                      = useState([])

    const [startDate, setStartDate]                        = useState(format(addDays (new Date(),-7),'yyyy-MM-dd'))
    const [endDate, setEndDate]                            = useState(format(new Date(),'yyyy-MM-dd'))
    const [statusFilter,setStatusFilter]                   = useState(-1)

    const [selecteds, setSelecteds]                        = useState([])
    const [action, setAction]                              = useState(0)

    const getCampaign = async() => {
        await api.get('/statusCampaign/0')
                 .then(response => {
                     setDataStatus(response.data)
                     setGridParams({options          : {text        : "...",
                                                         className   : "text-center",
                                                         order       : false,
                                                         filter      : false,
                                                         item        : [{type  : "Custom",
                                                                         props : {tag            : "checkbox"}
                                                                        }],
                                                         indexcallback  : 0},
                                    idcampaign        : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                             value : '00000'},
                                                         primaryKey  : true},          
                                    create_at         : {text : "Data da Criação",
                                                         format : "dd/MM/yyyy HH:mm"},
                                    campaign          : {text                 : "Campanha",
                                                         icon                 : {
                                                            color             : '#dc3545',
                                                            title             : 'Envio usando Time Machine',
                                                            icon              : 'fa fa-person-running',
                                                            visualConditional : {timemachine : {operator : '===',
                                                                                                values : true}}
                                                         }},
                                    numbers           : {text        : "Números" },
                                    tagsgrid          : {text        : "Tags",
                                                         searchDefault : true},
                                    reach             : {text        : "Alcance",
                                                         className : "text-right",
                                                         item        : {className : "text-right"}},
                                    reached           : {text        : "Alcançado",
                                                         className : "text-right",
                                                         item        : {className : "text-right"}},
                                    error             : {text        : "Erro",
                                                         className : "text-right",
                                                         item        : {className : "text-right"}},
                                    remaining         : {text        : "Restante",
                                                         className : "text-right",
                                                         item        : {className : "text-right"}},
                                    statuscampaign    : {text        : "Status",
                                                         item        : {className : "text-center",
                                                         switch      : {data       : response.data,
                                                                        key        : 'idstatuscampaign',
                                                                        titleField : 'errormessage'}}},
                                    Options           : {text        : "Opções",
                                                         className   : "text-center",
                                                         order       : false,
                                                         filter      : false,
                                                         item        : [{type  : "Custom",
                                                                         props : {tag            : "button",
                                                                                  title          : "Exclusão de campanha",
                                                                                  className      : 'btn btn-sm btn-danger',
                                                                                  icon           : "fa fa-trash",
                                                                                  message        : "Deseja realmente excluir a campanha?",
                                                                                  visualConditional : {idstatuscampaign : {operator : 'in',
                                                                                                                            values : [0,1,2,6,7,8,9]}},
                                                                                  indexcallback  : 0}},
                                                                        {type  : "Custom",
                                                                         props : {tag            : "button",
                                                                                  title          : "Editar campanha",
                                                                                  className      : 'btn btn-sm btn-warning',
                                                                                  visualConditional : {idstatuscampaign : {operator : 'in',
                                                                                                                            values : [0,2,6,7,8,9]}},

                                                                                  icon           : "fa fa-edit",
                                                                                  indexcallback  : 1}},
                                                                        {type  : "Custom",
                                                                         props : {tag            : "button",
                                                                                  title          : "Iniciar Campanha",
                                                                                  className      : 'btn btn-sm btn-success padding0',
                                                                                  icon           : "fa fa-play",
                                                                                  visualConditional : {idstatuscampaign : {operator : 'in',
                                                                                                                            values : [1,6,8]}},
                                                                                  indexcallback  : 2}},
                                                                        {type  : "Custom",
                                                                         props : {tag            : "button",
                                                                                  title          : "Pausar Campanha",
                                                                                  className      : 'btn btn-sm btn-warning padding0',
                                                                                  icon           : "fa fa-pause",
                                                                                  visualConditional : {idstatuscampaign : {operator : 'in',
                                                                                                                           values : [4,9]}},
                                                                                  indexcallback  : 5}},
                                                                        {type  : "Custom",
                                                                         props : {tag            : "button",
                                                                                  title          : "Alterar Números de Envio",
                                                                                  className      : 'btn btn-sm btn-primary padding0',
                                                                                  icon           : "fab fa-whatsapp",
                                                                                  visualConditional : {idstatuscampaign : {operator : 'in',
                                                                                                                           values   : [1,6,7]}},
                                                                                  indexcallback  : 7}},
            
                                                                        {type  : "Custom",
                                                                         props : {tag               : "button",
                                                                                  title             : "Limpar erros e voltar status da campanha",
                                                                                  className         : 'btn btn-sm btn-purple padding0',
                                                                                  icon              : "fa fa-eraser",
                                                                                  visualConditional : [{error : {operator : '>',
                                                                                                                 values   : 0}},
                                                                                                       {idstatuscampaign : {operator : 'in',
                                                                                                                            values   : [1,6,7,9]}}],
                                                                                           indexcallback     : 8}},
                                                                        {type  : "Custom",
                                                                         props : {tag               : "button",
                                                                                  title             : "Exportar contatos",
                                                                                  className         : 'btn btn-sm btn-secondary padding0',
                                                                                  icon              : "fa fa-file-export",
                                                                                  indexcallback     : 9}},
                                                                        {type  : "Custom",
                                                                         props : {tag            : "button",
                                                                                  title          : "Aprovar Campanha",
                                                                                  className      : 'btn btn-sm btn-theme padding0',
                                                                                  icon           : "fa fa-check",
                                                                                  visualConditional : {idstatuscampaign : {operator : 'in',
                                                                                                                           values : [0,7]}},
                                                                                  indexcallback  : 6}},

                                                                        {type  : "Custom",
                                                                         props : {tag            : "button",
                                                                                  title          : "Clonar Campanha",
                                                                                  className      : 'btn btn-sm btn-info padding0',
                                                                                  icon           : "fa fa-copy",
                                                                                  indexcallback  : 3}},
                                                                        {type  : "Custom",
                                                                         props : {tag            : "button",
                                                                                  title          : "Metricas da Campanha",
                                                                                  className      : 'btn btn-sm btn-primary padding0',
                                                                                  visualConditional : {idstatuscampaign : {operator : 'in',
                                                                                                                           values : [4,5,6,7,8,9]}},
                                                                                  icon           : "fa fa-chart-line",
                                                                                  indexcallback  : 4}}]}
                                             
                    })
                })
                .catch( error => { 
                   ShowMessages(error)
               })
    }

    useEffect(() => {
        getCampaign()
        setStatistic('maintenance\\campaign')
    },[])
    
    const handleCloseModal        = () => {setShowModal(false)}
    const handleCloseModalNumber  = () => {setShowModalNumber(false)}
    const handleCloseModalMetrics = () => {setShowModalMetrics(false)}
    const handleFilter            = () => {setIsOpenFilter(!isOpenFilter)}

    const exportContacts = async(id) => {
        await api.post('/campaign/exportContacts/' + id)
                 .then(response => {
                    var link = document.createElement('a');
                    link.download = 'campanha_' + id + '.xlsx';
                    link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data;
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                    
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const RemoveCampaign = async (id) => {  
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir a campanha?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/campaign/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                        .catch( error => {
                            ShowMessages(error)
                        })    
            }
            
        })
    }

    const EditCampaign = async(id) => {
        await api.get('/campaign/' + id)
                 .then(response => {
                    setData(response.data)
                    console.log(data)
                    if ([0,2,6,7,8,9].includes(response.data.idstatuscampaign)) {
                        setEditState(true)
                        setShowModal(true)
                    }
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const PauseCampaign = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente pausar a campanha?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/campaign/pause/' + id)
                         .then(response => {
                            ShowMessages(response)
                            setTimeout(() => {
                                setReload(true)
                            },2000)  
                          })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
          })
    }

    const clearErros = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente limpar os erros e voltar o status da campanha?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/campaign/clear-erros/' + id)
                         .then(response => {
                            ShowMessages(response)
                            setTimeout(() => {
                                setReload(true)
                            },2000)  
                          })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
          })
    }

    const approvedCampaign = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente aprovar a campanha?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                 let _status = {status : 1}

                 api.post('/campaign/status/' + id,_status)
                         .then((response) => {
                            if (!response.data.auth) 
                                ShowMessages({message : response.data.message,status : response.data.status})
                            setReload(true) 
                          })
                          .catch((error) => {
                              ShowMessages(error)
                          })
            }
            
          })
    }

    const startCampaign = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente iniciar a campanha?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                 api.post('/api/v1/sendCampaign/' + id)
                         .then((response) => {
                            if (!response.data.auth) 
                                ShowMessages({message : response.data.message,status : response.data.status})
                            setTimeout(() => {
                                    setReload(true)
                            },2000)  
                          })
                          .catch((error) => {
                              ShowMessages(error)
                          })
            }
            
          })
    }

    const editNumbers = async(id) => {
        await api.get('/campaign/' + id)
                 .then(response => {
                    setDataNumbers({
                        idcampaign : id,
                        numbers : response.data.numbers
                    })

                    setShowModalNumber(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const cloneCampaign = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente clonar a campanha?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/campaign/copy/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                        .catch( error => {
                            ShowMessages(error)
                        })
            }
            
          })
    }

    const metrics = async(id) => {
        setIdCampaign(id)
        setShowModalMetrics(true)
    }

    const handleSearch = async() => {
        let _filters = [{ field      : 'create_at',
                          key        : 'between',  
                          value      : startDate,
                          value2     : endDate,
                          ignoretime : true    
        }]

        if (statusFilter === -1) {
            _filters.push({
                field      : 'idstatuscampaign',
                key        : 'in',  
                value      : [0,1,4,6,8],
                value2     : null
            })
        } else if (statusFilter >= 0)
            _filters.push({
                field      : 'idstatuscampaign',
                key        : '=',  
                value      : statusFilter,
                value2     : null
            })

        setFilters(_filters)
    }

    const handleExecute = async() => {
        if (action <= 0) {
            ShowMessages({status : 500, message : 'Informe a ação a ser executada'})
            return
        }

        if (selecteds.length <= 0) {
            ShowMessages({status : 500, message : 'Selecione pelo menos um item para executar a ação.'})
            return
        }

        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente executar a ação?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/campaign/allActions',{action,selecteds})
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                          })
                         .catch( error => {
                            ShowMessages(error)
                          })               
            } 
          })

    }

    return (    
        <ContentWrapper>
            {loading && (
                <Loading loading={loading}/>
            )}
            <ModalCampaign showModal={showModal} handleCloseModal = {handleCloseModal} editState={editState} data={data} handleReload={setReload}/>
            <ModalCampaignNumbers showModal={showModalNumber} handleCloseModal = {handleCloseModalNumber}  data={dataNumbers} handleReload={setReload}/>
            <ModalCampaignMetrics showModal={showModalMetrics} handleCloseModal = {handleCloseModalMetrics} idcampaign={idcampaign}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-success" title="Insere um novo registro" onClick={e => {setEditState(false) 
                                                                                                               setShowModal(true)}}><i className="fa fa-plus"></i></button>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        </div>
                    </div>
                    <div style={{display:"flex",marginLeft:"30px"}}>
                        <div style={{ display:"flex", flexDirection:"column", borderLeft:"1px dashed #cacaca", paddingLeft:"10px"}}>
                            <label>Período</label>
                            <div style={{ display:"flex"}}>
                                <input type="date" className="form-select" value={startDate} onChange={e => setStartDate(e.target.value)}/>
                                <label style={{marginLeft:"15px", marginTop:"10px"}}>até</label> 
                                <input style={{marginLeft:"15px"}} type="date" className="form-select" value={endDate} onChange={e => setEndDate(e.target.value)}/>
                            </div>
                        </div>
                        <div style={{ display:"flex", flexDirection:"column", marginLeft:"15px"}}>
                            <label>Status</label>
                            <select className="form-select" value={statusFilter} onChange={e => setStatusFilter(parseInt(e.target.value))}>
                                <option value={-2}>Todos</option>
                                <option value={-1} selected>Campanhas em Aberto</option>
                                {dataStatus.map(item => {
                                    return (<option value={item.idstatuscampaign}>{item.statuscampaign}</option>)  
                                })}
                            </select>
                        </div>
                        <div style={{marginLeft:"5px", display:"flex", alignItems:"center"}}>
                            <button type="button" className="btn btn-success" title="Clique aqui para filtrar" style={{marginTop:"12px"}} onClick={e => handleSearch()}><i className="fa fa-filter"/></button>
                        </div>
                    </div>
                    <div style={{display:"flex",marginLeft:"30px"}}>
                        <div style={{ display:"flex", flexDirection:"column", borderLeft:"1px dashed #cacaca", paddingLeft:"10px"}}>
                            <label>Executar Ação</label>
                            <select className="form-select" value={action} onChange={e => setAction(parseInt(e.target.value))}>
                                <option value={0}>Selecione</option>
                                <option value={1}>Aprovar Selecionados</option>
                                <option value={6}>Pausar Selecionados</option>
                                <option value={4}>Iniciar Selecionados</option>
                            </select>
                        </div>
                        <div style={{marginLeft:"5px", display:"flex", alignItems:"center"}}>
                            <button type="button" className="btn btn-warning" title="Clique aqui para executar a ação selecionada" style={{marginTop:"12px"}} onClick={e => handleExecute()}><i className="fa fa-play"/></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/campaign' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      filters             = {filters}
                      setSelecteds        = {setSelecteds}
                      callbackShowButtons = {setShowButtons}
                      callbackReload      = {setReload}
                      callbackCellClick   = {EditCampaign}
                      callbackButtons     = {[RemoveCampaign, 
                                              EditCampaign,
                                              startCampaign,
                                              cloneCampaign,
                                              metrics,
                                              PauseCampaign,
                                              approvedCampaign,
                                              editNumbers,
                                              clearErros,
                                              exportContacts]}
                      />  
               
            </div>                    
        </ContentWrapper>
    )
}