import { memo, useCallback, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import ModalInputNode from "../../../pages/modals/workflow/modal-input-node";

const InputNode = (props) => {
    const [showModal, setShowModal]   = useState(false);
    const [tagName, setTagName]       = useState('')
    const [tagLink, setTagLink]       = useState('')
    const [commandEnd, setCommandEnd] = useState('')
    const [validation, setValidation] = useState('')
    const [id, setID]                 = useState(-1)
    const [statistic, setStatistic]   = useState(0)

    const reactFlowInstance = useReactFlow();

    const constas = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;

            setTagName(nodeInfo.tagName);
            setTagLink(nodeInfo.tagLink);
            setCommandEnd(nodeInfo.commandEnd)
            setValidation(nodeInfo.validation)
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
        };
        handleNodeProps();
    }, [props.data]);

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node            : _id,
                    parentId        : -1, 
                    targetId        : -1,
                    tagName         : props.data.nodeInfo.tagName,
                    tagLink         : props.data.nodeInfo.tagLink,
                    commandEnd      : props.data.nodeInfo.commandEnd,
                    validation      : props.data.nodeInfo.validation,
                    objectType      : 7
                    
                }
            }
        }))
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <ModalInputNode nodeId={props.id} showModal={showModal} handleCloseModal={handleCloseModal} nodeData={props.data.nodeInfo}></ModalInputNode>
            <div className="rfnode rfnode-input d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) => setShowModal(true)}>
                <div className="rfnode-title">#{id} Captura de Dados</div>
                <div style={{padding:"10px", background:"rgba(33, 37, 41, 0.1098039216)",borderRadius:"5px", margin:"5px 5px", color:"#fff", width:"100px", maxWidth:"100px",fontSize:"6px"}}>
                    {tagName !== '' ?
                        <div>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <span style={{fontWeight:"bold",color:"#272829"}}>Tag</span>
                                <span style={{color:"#272829"}}>{tagName}</span>
                            </div>
                            {tagLink !== "" && ( 
                                <div style={{display:"flex", flexDirection:"column", marginTop:"2px"}}>
                                    <span  style={{fontWeight:"bold",color:"#272829"}}>Link</span> 
                                    <span style={{color:"#272829"}}>{tagLink}</span>
                                </div>
                            )}
                            {commandEnd !== '' && (
                                <div style={{display:"flex", flexDirection:"column", marginTop:"2px"}}>
                                    <span style={{fontWeight:"bold",color:"#272829"}}>Comando para Sair</span> 
                                    <span style={{color:"#272829"}}>{commandEnd}</span>
                                </div>
                            )}
                            {validation !== '' && (
                                <div style={{display:"flex", flexDirection:"column", marginTop:"2px"}}>
                                    <span style={{fontWeight:"bold",color:"#272829"}}>Validar com</span> 
                                    <span style={{color:"#272829"}}>{validation}</span>
                                </div>
                            )}
                        </div>
                    :
                        <div style={{ display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", color:"#212529"}}>
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <i className="fa fa-keyboard"/>
                            </div>
                            <span style={{fontSize:"6px",color:"#272829"}}>Aqui você configura a captura das informações solicitadas ao usuário</span>
                        </div>
                    }
                </div>
                
                <div style={{display:"flex", fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #212529"}}>
                    <div style={{display:"flex", gap:"5px"}}>
                        <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                            <i className="fa fa-copy text-primary"></i>
                        </div>
                        <div onClick={() => constas()} title="Excluir caputra de dados" className="workflow-delete" >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    </div>

                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{fontSize:"10px", color:"#212529"}}>{statistic || ''}</span>
                    </div>
                </div>
            </div>

            <Handle type="source" position={Position.Bottom} id='node-input1'/>
            <Handle type="target" position={Position.Left} id='node-input2' />
            <Handle type="target" position={Position.Top} id='node-input3' />
            <Handle type="source" position={Position.Right} id='node-input4' />
        </>
    );
};

export default memo(InputNode);
