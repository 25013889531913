import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button} from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';

import ShowMessages from '../../components/Exceptions'

import api from '../../services/api'
import setStatistic from '../../services/apiStatistic'
import loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function CompanySettigns({showModal, handleCloseModal, data, handleReload}) {
    const [enabledWhatsapp, setEnabledWhatsapp ]      = useState(false)

    const handleSubmit = async(e) => {
        e.preventDefault()
        loadingButton(true,document.querySelector('#companySettings'),document.querySelector('#companySettings').children[0].classList,document.querySelector('#companySettings').lastChild.nodeValue)
        try {
            await api.post('/company/settings', { enabledWhatsapp,
                                                  idcompany : data.idcompany })
                     .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                            if (response.data.status === 200) {
                                handleCloseModal()  
                                handleReload()
                            } else 
                                loadingButton(false,document.querySelector('#companySettings')) 
                         } else 
                            loadingButton(false,document.querySelector('#companySettings')) 
                     }).catch(response => {
                        loadingButton(false,document.querySelector('#companySettings'))
                        ShowMessages(response)              
                     })
        } catch (response) {
            loadingButton(false,document.querySelector('#companySettings'))
            ShowMessages(response)          
        }
    }

    const setData = () => {
        setEnabledWhatsapp(data.settings === null ? false : data.settings.enabledWhatsapp )
    }

    useEffect(() => {
        if (showModal) {
            setData()
            setStatistic('modals\\modal-company-settings')
        }
    } ,[showModal])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-cogs"></i> Configurações da Empresa
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "Flex", flexDirection:"column" }}>
                        <div style={{display:"flex"}}>
                            <label className="switch">
                                <input type="checkbox" checked={enabledWhatsapp} value={enabledWhatsapp} onClick={e => setEnabledWhatsapp(!enabledWhatsapp)}  />
                                <span></span>
                            </label>
                            <span style={{marginLeft: "10px"}}>Habilitar envio de mensagens do whatsapp</span>
                        </div>
                </div>    
            </Modal.Body>
            <Modal.Footer>  
                <Button type="submit" id="companySettings" className="btn-theme">
                    <em className="fa fa-save"></em> Salvar
                </Button>          
                <Button className="btn-ligth-gray" onClick={e => handleCloseModal(false)}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </Modal.Footer>
            </ValidationForm>
        </Modal>
    )
}