import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Accordion, Modal, ModalDialog } from 'react-bootstrap';
import { TextInput, ValidationForm } from 'react-bootstrap4-form-validation';
import Draggable from 'react-draggable';
import { Button } from 'reactstrap';
import ShowMessages from '../../components/Exceptions';

import Swal from 'sweetalert2'

import { convertToBase64 } from "./../../components/Utils";

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';
import loadingButton from '../layouts/Buttons';

import { v4 as uuidv4 } from 'uuid';

import CELPHONE from './../../assets/cel.png';
import CONTACT from './../../assets/contact.png';
import LIST from './../../assets/list.png';
import LOGO_COLAPSE from './../../assets/logo-colapse.png';
import PDF from './../../assets/pdf.png';
import VIDEO from './../../assets/video.png';


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalCampaign({showModal, handleCloseModal, handleReload, editState, data, isStart = true}) {
    const inputEl = useRef(null)
    const [loading, setLoading]                       = useState(false)
    const [showParams, setShowParams]                 = useState(false)
    const [campaign, setCampaign]                     = useState('')
    const [scheduling, setScheduling]                 = useState(false)
    const [startDate, setStartDate]                   = useState(null)
    const [endDate, setEndDate]                       = useState(null)

    const [sendTimeStart, setSendTimeStart]           = useState(1);
    const [sendTimeEnd, setSendTimeEnd]               = useState(1);
  
    const [tag, setTag]                               = useState('')
    const [messages, setMessages]                     = useState([])
    const [statistics, setStatistics]                 = useState([])
    const [commercial, setCommercial]                 = useState(false)
    const [number, setNumber]                         = useState('')
    const [inactivatecontacts, setInactivateContacts] = useState(false)              
    const [dataNumber, setDataNumber]                 = useState([])
    const [selectNumbers, setSelectNumbers]           = useState([])
    const [selectTags, setSelectTags]                 = useState([])
    const [dataTags, setDataTags]                     = useState([])
    const [idstatuscampaign, setIDStatusCampaing]     = useState(0)

    const [idtype, setIDType]                         = useState('')
    const [type, setType]                             = useState('')
    const [message, setMessage]                       = useState('')
    const [file, setFile]                             = useState([])
    const [fileDocuments, setFileDocuments]           = useState([])
    const [sleeping, setSleeping]                     = useState(1)
    const [titleButton, setTitleButton]               = useState('')
    const [footerButton, setFooterButton]             = useState('')
    const [textButton, setTextButton]                 = useState('')
    const [bodyButton, setBodyButton]                 = useState('')
    const [button1, setButton1]                       = useState('')
    const [button2, setButton2]                       = useState('')
    const [button3, setButton3]                       = useState('')
    const [title, setTitle]                           = useState('')
    const [description, setDescription]               = useState('')
    const [body, setBody]                             = useState('')
    const [footer, setFooter]                         = useState('')
    const [firstTextList, setFirstTextList]           = useState('')
    const [secondTextList, setSecondTextList]         = useState('')
    const [thirdTextList, setThirdTextList]           = useState('')

    const [urlPhone1, setUrlPhone1]                   = useState('')
    const [urlPhone2, setUrlPhone2]                   = useState('')
    const [buttonOption1, setButtonOption1]           = useState(0)
    const [buttonOption2, setButtonOption2]           = useState(0)

    const [reach, setReach]                           = useState(0)
    const [percentual, setPercentual]                 = useState(0)
    const [totalSend, setTotalSend]                   = useState(0)
    const [listExit, setListExit]                     = useState(false)
    const [timemachine, setTimeMachine]               = useState(false)
    const [restartErrors, setRestartErrors]           = useState(true)

    const setData = (obj,clear) => { 
        setIDType(0)
        setNumber('')   
        setMessage('')
        setTitleButton('')
        setFooterButton('')
        setBodyButton('')
        setTextButton('')
        setButton1('')
        setButton2('')
        setButton3('')
        setUrlPhone1('')
        setUrlPhone2('')
        setButtonOption1(0)
        setButtonOption2(0)
        setSleeping(1)
        setFile([])
        setTitle('')
        setDescription('')
        setBody('')
        setFooter('')
        setFirstTextList('')
        setSecondTextList('')
        setThirdTextList('')
        setIDStatusCampaing(clear ? 0 : parseInt(obj.idstatuscampaign))
        setCampaign(clear ? '' : obj.campaign)
        

        if ((obj.scheduling) && (obj.enddate) && (obj.startdate)){
            setStartDate(clear ? '' : obj.startdate.substring(0,19))
            setEndDate(clear ? '' : obj.enddate.substring(0,19))
            setCommercial(clear ? false : obj.commercial)
            setScheduling(clear ? false : obj.scheduling)
        } else  {
            setStartDate('')
            setEndDate('')
            setCommercial(false)
            setScheduling(false)
        }

        setMessages(clear ? [] : obj.messages)
        setSendTimeStart(clear ? 1 : obj.sendtimestart)
        setSendTimeEnd(clear ? 1 : obj.sendtimeend)
        setInactivateContacts(clear ? false : obj.inactivatecontacts)
        setSelectTags(clear ? [] : obj.tags.map((item) => {return({tag : item})}))
        setReach(clear ? 0 : obj.statistics.reach)
        setPercentual(clear ? 0.00 : obj.statistics.percentual)
        setSelectNumbers(clear ? [] : obj.numbers || [])

        let _checkExitList = (obj.messages || []).filter(_item => _item.isExitList)
        setListExit(_checkExitList.length > 0)
        setTimeMachine(clear ? false : obj.timemachine)
        setRestartErrors(clear ? false : obj.restarterrors)

        isStart = false
    }

    const addMessage = async() => {
      
        if ((idtype === "") || (idtype < 1))
            return 
   
        if ((idtype === '1') && (message === "")) {
            ShowMessages({ status : 500, message : "Informe uma mensagem antes de inserir!"})
            return
        }
        
        if ([3,4].includes(parseInt(idtype)) && (!file || (file.length <= 0))) {
            ShowMessages({ status : 500, message : "Informe um arquivo antes de inserir!"})
            return
        }

        if ([2].includes(parseInt(idtype)) && (!fileDocuments || (fileDocuments.length <= 0))) {
            ShowMessages({ status : 500, message : "Informe um arquivo antes de inserir!"})
            return
        }


        let id      = uuidv4()
        let _base64 = ''

        if ([2,3,4].includes(parseInt(idtype)) && (file)) {
            if (file.length !== 0 && file.size > 20971020) {
                ShowMessages({ status : 500, message : "O arquivo informado e maior que 20mb, informe um arquivo menor"})
                return
            }
        }
        
        if ([2].includes(parseInt(idtype)) && fileDocuments.length !== 0) {
            for(let i = 0; i < fileDocuments.length; i++){
                if (fileDocuments[i].size > 20971020) {
                    ShowMessages({ status : 500, message : "Um dos arquivos informados e maior que 20mb, informe um arquivo menor"})
                    return
                }
            }
        }

        if ([3].includes(parseInt(idtype)) && (file))
            _base64 = await convertToBase64(file) 

        if ([2].includes(parseInt(idtype)) && (fileDocuments))
            for(let i = 0; i < fileDocuments.length; i++){
                _base64 = await convertToBase64(fileDocuments[i]) 
            }

        if ((parseInt(idtype) === 4) && (file))
            _base64 = await convertToBase64(file)
            
        let order = messages.length + 1
   
        if([2,3,4].includes(parseInt(idtype)) && fileDocuments.length > 0) {
            for(let i = 0; i < fileDocuments.length; i++){
                let id      = uuidv4()
                setMessages(messages => messages.concat({id,
                    idtype, 
                    type,
                    message,
                    titleButton,
                    footerButton,
                    textButton,
                    bodyButton,
                    button1,
                    button2,
                    button3,
                    urlPhone1,
                    urlPhone2,
                    buttonOption1,
                    buttonOption2,
                    title, 
                    description, 
                    body, 
                    footer, 
                    firstTextList, 
                    secondTextList, 
                    thirdTextList, 
                    base64 : parseInt(idtype) > 1 ? _base64 : '',
                    sleeping,
                    extension : '.' + fileDocuments[i].name.split('.').pop(),
                    filename  : fileDocuments[i].name,
                    mimetype  : fileDocuments[i].type,
                    isExitList : false,
                    order})) 
            }
        }else {
            setMessages(messages => messages.concat({id,
                idtype, 
                type,
                message,
                titleButton,
                footerButton,
                textButton,
                bodyButton,
                button1,
                button2,
                button3,
                urlPhone1,
                urlPhone2,
                buttonOption1,
                buttonOption2,
                title, 
                description, 
                body, 
                footer, 
                firstTextList, 
                secondTextList, 
                thirdTextList, 
                isExitList : false,
                base64 : parseInt(idtype) > 1 ? _base64 : '',
                sleeping,
                extension : ([2,3,4].includes(parseInt(idtype))) ? '.' + file.name.split('.').pop(): '',
                filename  : ([2,3,4].includes(parseInt(idtype))) ? file.name : '',
                mimetype  : ([2,3,4].includes(parseInt(idtype))) ? file.type  : '',
                order}))
        }

        setMessage('')
        setTitleButton('')
        setFooterButton('')
        setBodyButton('')
        setTextButton('')
        setButton1('')
        setButton2('')
        setButton3('')
        setUrlPhone1('')
        setUrlPhone2('')
        setButtonOption1(0)
        setButtonOption2(0)
        setSleeping(1)
        setIDType(0)
        setFile([])    
        setTitle('')
        setDescription('')
        setBody('')
        setFooter('')
        setFirstTextList('')
        setSecondTextList('')
        setThirdTextList('')
        setFileDocuments([])
    }

    const addNumber = async() => {
        if (number === '') {
            ShowMessages({status : 500, message : "Número não informado"})
            return
        }

        let _data = selectNumbers.filter((_item) => {return(_item.number === number)})

        if (_data.length > 0) {
            ShowMessages({status : 500, message : "Número já informado"})
            return
        }

        setSelectNumbers(selectNumbers => selectNumbers.concat({number})) 

        setNumber('')
    }

    const editMessage = (id) => {
        let _edit = messages.filter((t, i) => {
            if (t.id === id)
                return true;
        
            return false;
        })[0];

        setIDType(_edit.idtype)
        setSleeping(_edit.sleeping)
        switch (parseInt(_edit.idtype)) {
            case 1:
                setMessage(_edit.message)
                break;
            
            case 5:
                setTextButton(_edit.textButton)
                setTitleButton(_edit.titleButton)
                setButtonOption1(_edit.buttonOption1)
                setButtonOption2(_edit.buttonOption2)
                setButton1(_edit.button1)
                setButton2(_edit.button2)
                setUrlPhone1(_edit.urlPhone1)
                setUrlPhone2(_edit.urlPhone2)                
                break;
            case 6:
                setTitle(_edit.title)
                setDescription(_edit.description)
                setBody(_edit.body)
                setFooter(_edit.footer)
                setFirstTextList(_edit.firstTextList)
                setSecondTextList(_edit.secondTextList)
                setThirdTextList(_edit.thirdTextList)
                break;
            default:
                break;
        }
        removeMessage(_edit.id)
    }

    const convertToTxt = async(file) => {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsText(file);
        });

        return result_base64;
    }

    const getStatistics = async(tag) => {
   //     if (isStart)
     //       return 
        if (tag.length <= 0) {
            setReach(0)  
            setPercentual(0)   
            return
        }

        setLoading(true)        
        setTag(tag)
        try {
            await api.post('/getCampaignStatistics',
                     {tag})
                    .then(response => {
                        setReach(response.data.reach)  
                        setPercentual(response.data.percentual)   
                        setLoading(false)
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                        setLoading(false)
                    })
        } catch (response) {  
            ShowMessages(response)
            setLoading(false)
        }
    }

    const removeMessage = (id) => {
        const _remove = messages.filter((t, i) => {
            if (t.id !== id)
                return true;
            else    
                setListExit(false)
        
            return false;
        });
        setMessages(_remove)
    }

    const removeNumber = (_number) => {
        const _remove = selectNumbers.filter((t, i) => {
            if (t.number !== _number)
                return true;
        
            return false;
        });

        setSelectNumbers(_remove)
    }

    const addTag = () => {
        const tagAlreadyExistInArrayTags = selectTags.some((item) => {
            if(item.tag === tag) {
                return true
            }
            return false;
        })

        if(tag.length === 0){
            ShowMessages({status : 500, message : "Tag não informada"})
            return
        }
        if(selectTags.length >= 5){
            ShowMessages({status : 500, message : "Permitido apenas 5 tags"})
            return
        }
        
        if(tagAlreadyExistInArrayTags){
            ShowMessages({status : 500, message : "Tag já existe"})
            return
        }
        
        getStatistics([...selectTags, {tag: tag}])
        setSelectTags([...selectTags, {tag: tag}]);

    };

    const removeTag = (_tag) => {
        let arrayOfTags = selectTags.filter((item) => {
            return item.tag !== _tag;
        })

        getStatistics(arrayOfTags)
        setSelectTags(arrayOfTags);
    }

    const handleFilesDocuments = (e) => {
        setFileDocuments(e.target.files)
    }

    const handleType = async(id,text) => {
        setIDType(id)
        setType(text)
    }

    const getData = async() => {
        try {
            await api.get('/getDataCampaing')
                    .then(response => {
                        setDataNumber(response.data.numbers)   
                        let _tags  = []
                        let _check = []

                        response.data.tags.map(item => {
                            if (item.tags.length > 1) {
                                item.tags.map(item1 => {
                                    _check = _tags.filter(_filter => {
                                        return (_filter.tags[0] === item1)
                                    })

                                    if (_check.length === 0)
                                        _tags.push({'tags' : [item1]}) 
                                })
                            } else {
                                _check = _tags.filter(_filter => {
                                    return _filter.tags[0] === item.tags[0]
                                })

                                if (_check.length === 0)   
                                    _tags.push(item)
                            }
                        })  
                        setDataTags(_tags)
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            ShowMessages(response)
        }
    }

    const handleSubmit = async(event) => {
        const postData = async() => {
            loadingButton(true,document.querySelector('#submit-campaign'),document.querySelector('#submit-campaign').children[0].classList,document.querySelector('#submit-campaign').lastChild.nodeValue)

            try {

                const _data = {
                    campaign,
                    scheduling,
                    startDate,
                    endDate,
                    sendTimeStart,
                    sendTimeEnd,
                    statistics,
                    commercial,
                    timemachine,
                    restartErrors,
                    inactivatecontacts,
                    tags : selectTags,
                    selectNumbers : ((selectNumbers.length <= 0) && (number !== '')) ? [{number}] : selectNumbers,
                    messages 
                }

                if (editState) 
                    await api.put('/campaign/' + data.idcampaign, _data)
                             .then(response => {
                                ShowMessages(response)
                                if (response.ok) {   
                                    if (response.data.status === 200) {  
                                        handleCloseModal()
                                        setTimeout(() => {
                                            handleReload(true)
                                        },2000)          
                                    }
                                }
                                loadingButton(false,document.querySelector('#submit-campaign'),'fa fa-save','Salvar')        
                            })   
                            .catch( error => {
                                loadingButton(false,document.querySelector('#submit-campaign'),'fa fa-save','Salvar')    
                                ShowMessages(error) 
                            })
                else
                    await api.post('/campaign', _data)
                             .then(response => {
                                ShowMessages(response)
                                if (response.ok) {   
                                    if (response.data.status === 200) {  
                                        handleCloseModal()  
                                        handleReload(true) 
                                    }
                                }
                                loadingButton(false,document.querySelector('#submit-campaign'),'fa fa-save','Salvar')        
                             })   
                             .catch( error => {
                                console.log(error)
                                loadingButton(false,document.querySelector('#submit-campaign'),'fa fa-save','Salvar')    
                                ShowMessages(error) 
                            })
            } catch (response) {
                console.log(response)
                loadingButton(false,document.querySelector('#submit-campaign'),'fa fa-save','Salvar')    
                ShowMessages(response)
            }
        }

        event.preventDefault()

        if ((scheduling) && ((startDate === null) || (endDate === null))) {
            ShowMessages({status  : 500, message : "Informe a data de inicio e fim do agendamento"})
            return
        }

        if (timemachine) {
            Swal.fire({
                title: 'Confirmação',
                text: "Você está gravando uma campanha usando o recurso de Time Machine, deseja realmente continuar?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
              }).then(async(result) => {
                if (result.isConfirmed)
                    postData()
              })
        } else 
            postData()
    }
       
    const handleInputOnFocus = (e) => {
        inputEl.current = e;
    }

    const setTextParams = (e) => {
       let _text = e.target.innerText 
        if(inputEl.current === null){
            ShowMessages({status : 500, message : "Campo de Texto não selecionado"});  
            setShowParams(false)
            return
        }
        
       switch(inputEl.current?.target.name) {
           
           case 'message' || null: 
                setMessage(message + ' ' + _text.substring(0,_text.indexOf(']') + 1))
                break
           case 'titlebutton': 
                setTitleButton(titleButton + ' ' + _text.substring(0,_text.indexOf(']') + 1))
                break
           case 'textbutton': 
                setTextButton(textButton + ' ' + _text.substring(0,_text.indexOf(']') + 1))
                break
           case 'button1': 
                setButton1(button1 + ' ' + _text.substring(0,_text.indexOf(']') + 1))
                break
           case 'button2': 
                setButton2(button2 + ' ' + _text.substring(0,_text.indexOf(']') + 1))
                break
           case 'title': 
                setTitle(title + ' ' + _text.substring(0,_text.indexOf(']') + 1))
                break
           case 'description': 
                setDescription(description + ' ' + _text.substring(0,_text.indexOf(']') + 1))
                break
           case 'body': 
                setBody(body + ' ' + _text.substring(0,_text.indexOf(']') + 1))
                break
           case 'footer': 
                setFooter(footer + ' ' + _text.substring(0,_text.indexOf(']') + 1))
                break
           case 'firsttextlist': 
                setFirstTextList(firstTextList + ' ' + _text.substring(0,_text.indexOf(']') + 1))
                break
           case 'secondtextlist': 
                setSecondTextList(secondTextList + ' ' + _text.substring(0,_text.indexOf(']') + 1))
                break
           case 'thirdtextlist': 
                setThirdTextList(thirdTextList + ' ' + _text.substring(0,_text.indexOf(']') + 1))
                break
           default:
            setMessage(message + ' ' + _text.substring(0,_text.indexOf(']') + 1))
            break
        }

       setShowParams(false)
    }

    const checkExistList = (value) => {
        let _check = messages.filter(_item => _item.isExitList)
        setListExit(value)
        if ((_check.length <= 0) && (value)){
            setMessages(messages => messages.concat({
                id            : uuidv4(),
                idtype        : 5, 
                message       : '',
                type          : 'Botão',
                buttonOption1 : 1,
                buttonOption2 : 1,
                textButton    : "Caso deseje sair de nossa lista, escolha uma opção abaixo!",
                button1       : 'Sair da Lista',
                base64        : '',
                button2       : 'BLOQUEAR CONTATO',
                order         : messages.length + 1,
                isExitList    : true}))
        } else {
            removeMessage(_check[0].id)
        }
        
        
    }

    const updatePosition = useCallback((pos, newPosition) => {
        let actualMessages = messages.slice(0,100);
        let idx = pos + newPosition       


        let a = actualMessages[pos].order
        let b = actualMessages[idx].order

        actualMessages[pos].order = b
        actualMessages[idx].order = a
 
        setMessages(actualMessages.sort((previous, posterior) =>  parseInt(previous.order) - parseInt(posterior.order)))
    }, [messages])  

    useEffect(() => {
        if (!showModal)
            return

        if (editState)
            setData(data,false)
        else 
            setData({},true)
    },[showModal])   

    useEffect(() => {
        getData()
        setStatistic('modals\\modal-mynumber')
    } ,[])

    useEffect(() => {
        setTotalSend(reach *  messages.length)

        setStatistics({
            reach,
            percentual,
        })

    } ,[reach,percentual,messages])

    const Enter = (e) => {
        // if (e.nativeEvent.keyCode === 13)
        //     addMessage()
    }

    const handleTimeMachine = (value) => {
        setTimeMachine(value)
        if (value) {
            Swal.fire({
                title : 'Alerta de uso de funcionalidade?',
                html : '<div style="text-align:left"><p>Ao enviar usando o método de <b>Time Machine</b>, o sistema irá enviar até o WhatsApp bloquear o número.</p><p>NÃO responsabilizamos por números perdidos.</p><p>Esse método não registra estatísticas de envio.</p></div>',
                icon : 'warning'
            })
        }        
    }

    return (
        <Modal size='xl' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogClassName="height750 width1200" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-cloud-arrow-up"></i> Propriedades de Campanhas
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div style={{display:"flex", padding:"5px"}} className='box-shadow'>
                        <div>
                            <div style={{width:"100%", display:"flex", height:"65px"}}>
                            <div className="alert alert-primary box-shadow" style={{width:"218px",padding:"10px", display:"flex", marginTop:"5px", height:"60px"}}>
                                <div style={{display:"flex",alignItems:"center"}}><i className="fa fa-earth-america" style={{fontSize:"26px"}}/></div>
                                <div style={{display:"flex",alignItems:"center", flexDirection:"column",width:"100%"}}>
                                    <div style={{textAlign:"center", fontSize:"10px"}}>Alcance</div>
                                    {loading ? 
                                        <div style={{textAlign:"center", fontSize:"22px"}}><i className="fa fa-spinner fa-spin"/></div>
                                    :
                                        <div style={{textAlign:"center", fontSize:"22px"}}>{reach}</div>
                                    }
                                </div>                                
                            </div>
                            <div className="alert alert-primary box-shadow" style={{width:"218px",padding:"10px", display:"flex", marginTop:"5px", height:"60px"}}>
                                <div style={{display:"flex",alignItems:"center"}}><i className="fa fa-percent" style={{fontSize:"26px"}}/></div>
                                <div style={{display:"flex",alignItems:"center", flexDirection:"column",width:"100%"}}>
                                    <div style={{textAlign:"center", fontSize:"10px"}}>(%) do Total</div>
                                    {loading ? 
                                        <div style={{textAlign:"center", fontSize:"22px"}}><i className="fa fa-spinner fa-spin"/></div>
                                    :
                                        <div style={{textAlign:"center", fontSize:"22px"}}>{percentual}%</div>
                                    }
                                </div>                                
                            </div>
                            <div className="alert alert-primary box-shadow" style={{width:"218px",padding:"10px", display:"flex", marginTop:"5px", height:"60px"}}>
                                <div style={{display:"flex",alignItems:"center"}}><i className="fa fa-paper-plane" style={{fontSize:"26px"}}/></div>
                                <div style={{display:"flex",alignItems:"center", flexDirection:"column",width:"100%"}}>
                                    <div style={{textAlign:"center", fontSize:"10px"}}>Total de Disparos</div>
                                    {loading ? 
                                        <div style={{textAlign:"center", fontSize:"22px"}}><i className="fa fa-spinner fa-spin"/></div>
                                    :
                                        <div style={{textAlign:"center", fontSize:"20px"}}>{totalSend}</div>
                                    }
                                </div>                                
                            </div>
                            <div className="alert alert-primary box-shadow" style={{width:"218px",padding:"10px", display:"flex", marginTop:"5px", height:"60px"}}>
                                <div style={{display:"flex",alignItems:"center"}}><i className="fa fa-message" style={{fontSize:"26px"}}/></div>
                                <div style={{display:"flex",alignItems:"center", flexDirection:"column",width:"100%"}}>
                                    <div style={{textAlign:"center", fontSize:"10px"}}>Mensagens</div>
                                    <div style={{textAlign:"center", fontSize:"22px"}}>{messages.length}</div>
                                </div>                                
                            </div>
                            </div>
                            <div className="box-shadow" style={{marginTop:"15px", padding:"10px"}}>
                            <div style={{display:"flex"}}>
                                <div style={{display: 'flex', alignItems: "center"}}>
                                    <div style={{display: 'flex', flexDirection:"column"}}>
                                        <div style={{width:"250px"}}>               
                                            <label>Nome da Campanha</label><label className="text-required">*</label>
                                            <div className="input-group" style={{marginBottom: '10px'}}>
                                                <TextInput  
                                                    name="campaign"
                                                    id="campaign"
                                                    type="text"
                                                    required
                                                    errorMessage={{ required: "Por favor, informe o nome da campanha!" }}
                                                    maxLength={100}
                                                    value={campaign}
                                                    className="form-control "
                                                    onChange={e => setCampaign(e.target.value)}
                                                    autoComplete="off"/>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{display:"flex",background:"#fff", width:"fit-content"}}>
                                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                    <input type="checkbox" checked={inactivatecontacts} value={inactivatecontacts} onClick={e => setInactivateContacts(!inactivatecontacts)} />
                                                    <span style={{ marginTop: "5px" }}></span>
                                                </label>
                                                <span style={{marginLeft:"5px",marginTop:"5px"}}>Inativar contatos inválidos?</span>
                                            </div>  
                                            <div>
                                                <div style={{display:"flex",background:"#fff", width:"fit-content"}}>
                                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                        <input type="checkbox" checked={commercial} value={commercial} onClick={e => setCommercial(!commercial)}  />
                                                        <span style={{ marginTop: "5px" }}></span>
                                                    </label>
                                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Enviar somente em horário comercial?</span>
                                                </div>
                                            </div>  
                                            <div>
                                                <div style={{display:"flex",background:"#fff", width:"fit-content"}}>
                                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                        <input type="checkbox" checked={listExit} value={listExit} onClick={e => checkExistList(!listExit)}  />
                                                        <span style={{ marginTop: "5px" }}></span>
                                                    </label>
                                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Enviar mensagem de sair da lista?</span>
                                                </div>
                                            </div>  
                                            <div>
                                                <div style={{display:"flex",background:"#fff", width:"fit-content"}}>
                                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                        <input type="checkbox" checked={timemachine} value={timemachine} onClick={e => handleTimeMachine(!timemachine)}  />
                                                        <span style={{ marginTop: "5px" }}></span>
                                                    </label>
                                                    <span style={{marginLeft:"5px",marginTop:"5px"}} className="text-danger">Enviar em Time Machine?</span>
                                                </div>
                                            </div>  
                                            <div style={{width:"255px"}}>
                                                <div style={{display:"flex",background:"#fff", width:"fit-content"}}>
                                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                        <input type="checkbox" checked={restartErrors} value={restartErrors} onClick={e => setRestartErrors(!restartErrors)}  />
                                                        <span style={{ marginTop: "5px" }}></span>
                                                    </label>
                                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Reiniciar automaticamente zerando os erros ao finalizar a campanha?</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{marginLeft:"15px",width:"130px"}}>
                                    <label>Enviar entre</label><label className="text-required">*</label>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <div>
                                                <div>
                                                    <TextInput  
                                                        name="sendtimestart"
                                                        id="sendtimestart"
                                                        type="number"
                                                        maxLength={100}
                                                        value={sendTimeStart}
                                                        minvalue={0}
                                                        className="form-control "
                                                        onChange={e => setSendTimeStart(e.target.value < 1 ? 1 : parseInt(e.target.value))}
                                                        autoComplete="off"/>
                                                    
                                                </div>
                                            </div> 
                                            <div>
                                                <label style={{marginLeft:"5px"}}>segs.</label>
                                            </div>   
                                        </div>  
                                        <label> até </label>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <div>
                                                <div>
                                                    <TextInput  
                                                        name="sendtimeend"
                                                        id="sendtimeend"
                                                        type="number"
                                                        maxLength={100}
                                                        value={sendTimeEnd}
                                                        minvalue={0}
                                                        className="form-control "
                                                        onChange={e => setSendTimeEnd(e.target.value < 1 ? 1 : parseInt(e.target.value) )}
                                                        autoComplete="off"/>
                                                    
                                                </div>
                                            </div> 
                                            <div style={{display:"flex"}}>
                                                <label style={{marginLeft:"5px"}}>segs.</label>
                                            </div>   
                                        </div>  
                                    </div>        
                                </div>                       
                                <div style={{display:"flex", marginLeft:"15px"}}>
                                    <div>
                                        <div style={{display:"flex"}}>
                                            <div style={{width:"160px"}}>
                                                <span>Número</span><label className="text-required">*</label>
    
                                                <select className="form-select" value={number} onChange={e => setNumber(e.target.value)} >
                                                    <option value="">Selecione</option>
                                                    {dataNumber.map((item) => {
                                                        return(<option value={item.number}>{item.phone_format}</option>)
                                                    })}
                                                </select>
                                            </div>
                                                
                                            <div style={{display:"flex",alignItems:"center", marginLeft:"10px", width:"40px"}}>
                                                <button title="Clique para inserir o número" type="button" className="btn btn-success" style={{marginTop:"18px"}} onClick={e => addNumber()} ><i className="fa fa-plus"/></button>
                                            </div>
                                        </div>
                                        <div>
                                            <small style={{fontSize:"10px"}}className="text-danger">Após escolher o <span style={{fontWeight:"bold"}} >Número</span>, click no botão ao lado.</small>
                                        </div>
                                        <div style={{marginTop:"5px", width:"206px",position: "absolute"}}>
                                            <table className="table table-striped w-100">
                                                <thead className="thead-theme">
                                                    <tr className='head-th'>
                                                        <td>Números</td>
                                                        <td style={{textAlign:"center"}}>...</td>
                                                    </tr>
                                                </thead>  
                                                <tbody>                        
                                                    {selectNumbers.map((_actions,id) => {
                                                        return(<tr className="gradeX" key={id}>
                                                                    <td style={{verticalAlign: "middle"}}>{_actions.number}</td>
                                                                        <td style={{textAlign:"center",verticalAlign: "middle"}}>
                                                                            <button type='button' className="btn btn-danger btn-sm" onClick={e => removeNumber(_actions.number)}><i className="fa fa-trash"/></button>
                                                                        </td>
                                                               </tr>)
                                                        }) }
                                                </tbody> 
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:"flex", marginLeft:"15px"}}>
                                    <div>
                                        <div style={{display:"flex"}}>
                                            <div style={{width:"160px"}}>
                                                <span>Tags do Contato</span><label className="text-required">*</label>
                                                <select className="form-select" value={tag} onChange={e => setTag(e.target.value)} disabled={idstatuscampaign > 0}>
                                                    <option value="">Selecione</option>
                                                    {dataTags.map((item) => {
                                                        return(<option value={item.tags}>{item.tags}</option>)
                                                    })}
                                                </select>
                                                
                                            </div>
                                                
                                            <div style={{display:"flex",alignItems:"center", marginLeft:"10px", width:"40px"}}>
                                                <button type="button" title="Clique para inserir a tag" className="btn btn-success" style={{marginTop:"18px"}} onClick={e => addTag()}  disabled={idstatuscampaign > 0}><i className="fa fa-plus"/></button>
                                            </div>
                                        </div>
                                        <div>
                                            <small style={{fontSize:"10px"}} className="text-danger">Após escolher a <span style={{fontWeight:"bold"}}>Tag</span>, click no botão ao lado.</small>
                                        </div>
                                        <div style={{marginTop:"5px", width:"206px",position: "absolute"}}>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className="own-acordion">
                                                        <span className='w-100'>Tags</span>
                                                    </Accordion.Header>

                                                    <Accordion.Body className="p-0">
                                                        <table className="table table-striped w-100">
                                                            <tbody>                        
                                                                {selectTags.map((_actions,id) => {
                                                                    return(<tr className="gradeX w-100" key={id}>
                                                                                <td>{_actions.tag}</td>
                                                                                {idstatuscampaign <=  0 ?
                                                                                    <td style={{textAlign:"center",verticalAlign: "middle"}}>
                                                                                        <button 
                                                                                            type='button' className="btn btn-danger btn-sm" 
                                                                                            onClick={e => removeTag(_actions.tag)}
                                                                                            >
                                                                                                <i className="fa fa-trash"/>
                                                                                        </button>
                                                                                    </td>
                                                                                : ''}
                                                                        </tr>)
                                                                    }) }
                                                            </tbody> 
                                                    </table>    
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                
                            </div> 
                            <div style={{marginTop:"15px"}}>
                                <div style={{display:"flex", flexDirection:"column", border: "1px solid #cacaca", padding: "10px", borderRadius: "5px", width:"fit-content"}}>
                                    <div style={{display:"flex", marginTop: "-25px", background:"#fff", width:"fit-content"}}>
                                        <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                            <input type="checkbox" checked={scheduling} value={scheduling} onClick={e => setScheduling(!scheduling)}  />
                                            <span style={{ marginTop: "5px" }}></span>
                                        </label>
                                        <span style={{marginLeft:"5px",marginTop:"5px"}}>Campanha Agendada?</span>
                                    </div>
                                    <div style={{display:"flex", flexDirection:"column"}}>
                                        <div style={{display:"flex"}}>
                                            <div>
                                                <div style={{width:'150px'}}>
                                                    <label>Inicio</label>
                                                    <div style={{display:"flex"}}>
                                                        <input   
                                                            name="startdate"
                                                            id="startdate"
                                                            type="datetime-local"
                                                            value={startDate}
                                                            disabled={!scheduling}
                                                            className="form-control "
                                                            onChange={e => setStartDate(e.target.value)}
                                                            autoComplete="off"/>
                                                    </div>
                                                </div>
                                            </div>  
                                            <div style={{marginLeft:"15px"}}>
                                                <div style={{width:'150px'}}>
                                                    <label>Fim</label>
                                                    <div style={{display:"flex"}}>
                                                        <input   
                                                            name="endDate"
                                                            id="endDate"
                                                            type="datetime-local"
                                                            value={endDate}
                                                            disabled={!scheduling}
                                                            className="form-control "
                                                            onChange={e => setEndDate(e.target.value)}
                                                            autoComplete="off"/>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>                        
                                    </div>
                                </div>
                            </div>
                            <div style={{borderTop : "1px dashed #cacaca", marginTop:"10px", maxWidth:"850px"}}>
                                <div style={{marginTop:"15px", background:"#f6f7f9",borderRadius:"5px", padding:"5px"}}>
                                    <div className={`${showParams ? '' : 'hidden'}`} style={{overflowY:"auto", height:"175px"}}>
                                        <div style={{display:"flex", marginLeft:"15px", justifyContent:'space-between'}}>
                                            <div>
                                                <div style={{display:"flex", flexDirection:"column"}}>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[NOME]</span></div>Nome do usuário do WhatsApp</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[PHONE]</span></div>WhatsApp do usuário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[DATA_ATUAL] </span></div>Data do momento do envio</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[HORA_ATUAL]</span> </div>Hora do momento do envio</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[DATA_HORA_ATUAL]</span> </div>Data e Hora do momento do envio</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[EMAIL]</span> </div>Email do usuário destinatário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[ENDEREÇO]</span> </div>Endereço do usuário destinatário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[COMPLEMENTO]</span> </div>Complemento do usuário destinatário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[NÚMERO]</span> </div>Número da residência do usuário destinatário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[BAIRRO]</span> </div>Bairro do usuário destinatário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[CIDADE]</span> </div>Cidade do usuário destinatário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[UF]</span> </div>UF do usuário destinatário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[CEP]</span> </div>CEP do usuário destinatário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[PAIS]</span> </div>País do usuário destinatário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[ENDEREÇO_COMPLETO]</span> </div>Endereço completo do usuário destinatário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[TELEGRAM]</span> </div>Telegram do usuário destinatário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[FACEBOOK]</span> </div>Facebook do usuário destinatário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[LINKEDIN]</span> </div>Linkedin do usuário destinatário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[INSTAGRAM]</span> </div>Instagram do usuário destinatário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[TAG]</span> </div>TAG do usuário destinatário</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[CAMPO_LIVRE01]</span> </div>Campo de valor livre, o valor dele vem da importação do arquivo em excel</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[CAMPO_LIVRE02]</span> </div>Campo de valor livre, o valor dele vem da importação do arquivo em excel</div>
                                                    <div className="item-params" onClick={e => setTextParams(e)}><div ><span>[CAMPO_LIVRE03]</span> </div>Campo de valor livre, o valor dele vem da importação do arquivo em excel</div>
                                                </div>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                                <button type="button" className="btn btn-warning" onClick={e => setShowParams(false)} style={{marginTop:"5px", height:"25px", padding:"1px 10px"}} ref={(el) => el && el.style.setProperty('min-height', "25px", "important")}><i className="fa fa-rotate-left" style={{fontSize:"12px"}}/> <span style={{fontSize:"12px"}}>Voltar</span></button>
                                            </div>   
                                        </div>
                                    </div>

                                    <div style={{display:"flex", maxHeight:"170px", overflowY:"auto"}} className={`${showParams ? 'hidden' : ''}`}>
                                        <div className="input-control" style={{width:"120px", minWidth:"120px", display:"flex",flexDirection:"column", paddingLeft:"5px" }}>
                                            <label>Tipo de mensagem</label>
                                            <select className="form-select " value={idtype} onChange={e => handleType(e.target.value, e.target.options[e.target.selectedIndex].text)}>
                                                <option value="">Selecione</option>
                                                <option value="1">Texto</option>
                                                <option value="3">Áudio</option>
                                                <option value="2">Arquivo</option>
                                                <option value="4">Contato</option>
                                            </select>
                                            <div style={{display:"flex"}}>
                                                <button type="button" className={`btn btn-warning ${![5,6].includes(parseInt(idtype)) ? 'hidden' : ''}`} onClick={e => setShowParams(true)} style={{marginTop:"5px", height:"25px", padding:"1px 10px", width:"100%"}} ref={(el) => el && el.style.setProperty('min-height', "25px", "important")}><i className="fa fa-icons" style={{fontSize:"12px"}}/> <span style={{fontSize:"12px"}}>Parâmetros</span></button>
                                            </div>
                                        </div>

                                        {idtype === '1' ? 
                                            <div style={{display:"flex"}} >
                                                <div style={{marginLeft:"15px", width:"350px"}}>
                                                    <label>Digite a sua mensagem</label>
                                                    <textarea  
                                                        name="message"
                                                        data-set-state="Message"
                                                        id="message"
                                                        maxLength={2500}
                                                        rows="3"
                                                        value={message}
                                                        className="form-control "
                                                        onFocus = {e => handleInputOnFocus(e)}
                                                        onKeyPress={Enter}
                                                        onChange={e => setMessage(e.target.value)}
                                                        autoComplete="off"></textarea>
                                                        
                                                </div>
                                                <div style={{display:"flex", marginLeft:"15px", justifyContent:"space-between", flexDirection:"column"}}> 
                                                    <div style={{display:"flex"}}>
                                                        <button type="button" className="btn btn-warning" onClick={e => setShowParams(true)} style={{marginTop:"5px", height:"25px", padding:"1px 10px", width:"100%"}} ref={(el) => el && el.style.setProperty('min-height', "25px", "important")}><i className="fa fa-icons" style={{fontSize:"12px"}}/> <span style={{fontSize:"12px"}}>Parâmetros</span></button>
                                                    </div>
                                                </div>
                                                <div style={{display:"flex", flexDirection:"column", marginLeft:"15px"}}>
                                                    <div style={{display:"flex",marginLeft:"5px", width:"85px", flexDirection:"column"}}>
                                                        <label>Aguardar</label>
                                                        <div style={{display:"flex"}}>
                                                            <input type="number" className="form-control" value={sleeping} onChange={e => setSleeping(e.target.value < 1 ? 1 : e.target.value )}/>
                                                            <label style={{margin:"5px 0px 0px 5px"}}>segs</label>
                                                        </div>     
                                                    </div>

                                                    <div style={{display:"flex",margin:"7px 0px 0px 5px"}}>
                                                        <button type="button" className="btn btn-success" style={{marginTop:"-4px"}} onClick={e => addMessage()} title="Click para incluir uma mensagem"><i className="fa fa-plus"/></button>
                                                    </div>
                                                </div>                                        
                                            </div>                               
                                        :''}
                                        {idtype === "3" ?
                                            <div style={{display:"flex"}}>
                                                <div style={{display:"flex", width:"560px", flexDirection:"column"}} >
                                                    <div style={{marginLeft:"15px", width:"555px"}}>
                                                        <label>Selecione o arquivo de áudio</label>
                                                        <div className="input-group">
                                                            <input type="file" accept=".ogg,.mp3" onChange={e => setFile(e.target.files[0])} className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload"/>
                                                        </div>  
                                                    </div>
                                                </div>
                                                <div style={{display:"flex",  marginLeft:"15px"}}>
                                                    <div style={{display:"flex",marginLeft:"5px", width:"85px", flexDirection:"column"}}>
                                                        <label>Aguardar</label>
                                                        <div style={{display:"flex"}}>
                                                            <input type="number" className="form-control" value={sleeping} onChange={e => setSleeping(e.target.value < 1 ? 1 : e.target.value )}/>
                                                            <label style={{margin:"5px 0px 0px 5px"}}>segs</label>
                                                        </div>     
                                                    </div>

                                                    <div style={{display:"flex",margin:"21px 0px 0px 5px"}}>
                                                        <button type="button" className="btn btn-success" style={{marginTop:"-4px"}} onClick={e => addMessage()} title="Click para incluir uma mensagem"><i className="fa fa-plus"/></button>
                                                    </div>
                                                </div>
                                            </div>                                                       
                                        : ''}
                                        {idtype === "2" ?
                                            <div style={{display:"flex"}}>
                                                <div style={{display:"flex", width:"560px", flexDirection:"column"}} >
                                                    <div style={{marginLeft:"15px", width:"555px"}}>
                                                        <label>Selecione o arquivo</label>
                                                        <div className="input-group">
                                                            <input type="file" multiple 
                                                                onChange={e => {
                                                                    // setFile(e.target.files[0]);
                                                                    handleFilesDocuments(e);
                                                                }} 
                                                                className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload"/>
                                                        </div>
                                                    </div>
                                                    <div style={{marginLeft:"15px", width:"695px"}}>
                                                        <label>Digite a mensagem que acompanha o arquivo</label>
                                                        <textarea  
                                                            name="message"
                                                            data-set-state="Message"
                                                            id="message"
                                                            maxLength={2500}
                                                            rows="3"
                                                            value={message}
                                                            className="form-control "
                                                            onFocus = {e => handleInputOnFocus(e)}
                                                            onKeyPress={Enter}
                                                            onChange={e => setMessage(e.target.value)}
                                                            autoComplete="off"></textarea>    
                                                    </div>
                                                </div>
                                                <div style={{display:"flex",  marginLeft:"15px"}}>
                                                    <div style={{display:"flex",marginLeft:"5px", width:"85px", flexDirection:"column"}}>
                                                        <label>Aguardar</label>
                                                        <div style={{display:"flex"}}>
                                                            <input type="number" className="form-control" value={sleeping} onChange={e => setSleeping(e.target.value < 1 ? 1 : e.target.value )}/>
                                                            <label style={{margin:"5px 0px 0px 5px"}}>segs</label>
                                                        </div>     
                                                    </div>

                                                    <div style={{display:"flex",margin:"21px 0px 0px 5px"}}>
                                                        <button type="button" className="btn btn-success" style={{marginTop:"-4px"}} onClick={e => addMessage()} title="Click para incluir uma mensagem"><i className="fa fa-plus"/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        : ''} 
                                        {idtype === "4" ?
                                            <div style={{display:"flex"}}>
                                                <div style={{display:"flex", width:"560px", flexDirection:"column"}} >
                                                    <div style={{marginLeft:"15px", width:"555px"}}>
                                                        <label>Selecione o arquivo de contato</label>
                                                        <div className="input-group">
                                                            <input type="file"  accept=".vcf" onChange={e => setFile(e.target.files[0])} className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload"/>
                                                        </div>
                                                    </div>
                                                    <div style={{marginLeft:"15px", width:"695px"}}>
                                                        <label>Digite a mensagem que acompanha o contato</label>
                                                        <textarea  
                                                            name="message"
                                                            data-set-state="Message"
                                                            id="message"
                                                            maxLength={2500}
                                                            rows="3"
                                                            value={message}
                                                            className="form-control "
                                                            onFocus = {e => handleInputOnFocus(e)}
                                                            onKeyPress={Enter}
                                                            onChange={e => setMessage(e.target.value)}
                                                            autoComplete="off"></textarea>    
                                                    </div> 
                                              </div>
                                               <div style={{display:"flex",  marginLeft:"15px"}}>
                                                    <div style={{display:"flex",marginLeft:"5px", width:"85px", flexDirection:"column"}}>
                                                        <label>Aguardar</label>
                                                        <div style={{display:"flex"}}>
                                                            <input type="number" className="form-control" value={sleeping} onChange={e => setSleeping(e.target.value < 1 ? 1 : e.target.value )}/>
                                                            <label style={{margin:"5px 0px 0px 5px"}}>segs</label>
                                                        </div>     
                                                    </div>

                                                    <div style={{display:"flex",margin:"21px 0px 0px 5px"}}>
                                                        <button type="button" className="btn btn-success" style={{marginTop:"-4px"}} onClick={e => addMessage()} title="Click para incluir uma mensagem"><i className="fa fa-plus"/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        : ''}
                                    </div>
                                </div>
                                <div style={{marginTop:"20px", overflowY:"auto", width:"857px", overflowX:"hidden", height:"100px"}}>
                                    <table className="table table-striped w-100">
                                        <thead className="thead-theme">
                                            <tr className='head-th'>
                                                <td>Tipo</td>
                                                <td>Mensagem</td>
                                                <td style={{textAlign:"center"}}>Ativar</td>
                                                <td style={{textAlign:"center"}}>Aguardar</td>
                                                <td style={{textAlign:"center"}}>Opções</td>
                                            </tr>
                                        </thead>  
                                        <tbody>                        
                                            {messages.map((_actions,id) => {
                                                return(<tr className="gradeX" key={id} >
                                                            <td style={{verticalAlign: "middle"}}>{_actions.type}</td>
                                                            <td style={{verticalAlign: "middle"}} className={`${_actions.idtype !== "0" ? 'text-center' : ''}`}>
                                                                {_actions.idtype === "1" ? 
                                                                    <span>{_actions.message}</span>
                                                                : ""}
                                                                {_actions.idtype === "3" ? 
                                                                    <div style={{display:'flex',alignItems:'center',flexDirection:"column"}}>
                                                                        <span>{_actions.message}</span>
                                                                        <button type='button' className="btn btn-primary btn-sm" style={{marginLeft:"5px"}}><i className="fa fa-file-audio"/></button>
                                                                    </div>
                                                                : ''}
                                                                {_actions.idtype === "2" ? 
                                                                    <div style={{display:'flex',alignItems:'center',flexDirection:"column"}}>
                                                                        {(_actions.mimetype.indexOf('image')>= 0) ? 
                                                                            <img alt="" src={_actions.base64} style={{borderRadius:"5px", maxWidth:'50px', maxHeight:'50px', width:"100%"}} />
                                                                        : (_actions.mimetype.indexOf('pdf')>= 0) ?
                                                                            <img alt="" src={PDF} style={{borderRadius:"5px", maxWidth:'50px', maxHeight:'50px', width:"100%"}} />
                                                                        : (_actions.mimetype.indexOf('mp4')>= 0) || (_actions.mimetype.indexOf('ogg')>= 0) ?
                                                                            <img alt="" src={VIDEO} style={{borderRadius:"5px", maxWidth:'50px', maxHeight:'50px', width:"100%"}} />
                                                                        : 'Tipo Desconhecido'}
                                                                        <span>{_actions.message}</span>
                                                                    </div>
                                                                : ""}
                                                                    
                                                                {_actions.idtype === "4" ? 
                                                                    <div style={{display:'flex',alignItems:'center',flexDirection:"column"}}>
                                                                        <img alt="" src={CONTACT} style={{borderRadius:"5px", maxWidth:'50px', maxHeight:'50px', width:"100%"}} />
                                                                        <span>{_actions.message}</span>
                                                                    </div>
                                                                : ''}                  
                                                            </td>
                                                            <td style={{textAlign:"center",verticalAlign: "middle"}}>{_actions.sleeping}</td>
                                                            <td style={{textAlign:"center",verticalAlign: "middle"}}>
                                                                <div style={{display:"flex", justifyContent:"flex-end"}}>
                                                                    <div style={{display:"flex", alignItems:"center"}}>
                                                                        <button type='button' className="btn btn-danger btn-sm" onClick={e => removeMessage(_actions.id)}><i className="fa fa-trash"/></button>
                                                                        {[1,5,6].includes(parseInt(_actions.idtype)) ? 
                                                                            <button type='button' className="btn btn-warning btn-sm" style={{marginLeft:"5px"}} onClick={e => editMessage(_actions.id)}><i className="fa fa-edit"/></button>
                                                                        : ''}
                                                                    </div>
                                                                     
                                                                    <div style={{display:"flex", flexDirection:"column", marginLeft:"5px"}}>
                                                                        {(id > 0) ? 
                                                                            <button type='button' className="btn btn-success btn-sm" title="Subir a mensagem" onClick={e => updatePosition(id,-1)}><i className="fa fa-arrow-up"/></button>
                                                                        : ''}
                                                                        
                                                                        {(messages.length - 1) !== id ? 
                                                                            <button type='button' className="btn btn-success btn-sm" title="Descer a mensagem" onClick={e => updatePosition(id,1)} style={{marginTop:"5px"}}><i className="fa fa-arrow-down"/></button>
                                                                        : '' }
                                                                    </div>
                                                                </div>
 
                                                            </td>
                                                        </tr>)
                                            }) }
                                        </tbody> 
                                    </table>
                                </div>
                            </div>
                            </div>                       
                        </div>
                        <div>
                            <div style={{position:"fixed"}}>
                                <img alt="Cel" src={CELPHONE} style={{minWidth:"300px"}}/>
                            </div>
                            <div style={{marginLeft: "35px", marginTop: "86px", position: "absolute", height: "384px", width: "230px", overflowY: "auto"}}>
                                {messages.map((item, key) => {
                                    return(
                                        <>
                                            <div style={{display:"flex", justifyContent:"end", marginTop:"5px"}}>
                                                <div style={{background:"#d9fdd3", width:"fit-content", borderRadius:"5px", marginLeft:"50px",padding:"3px", maxWidth:"220px", overflowX:"hidden"}}>
                                                    {item.idtype === "1" ?
                                                        <pre style={{fontSize:"11px", maxWidth:"215px",textWrap: "wrap"}}>{item.message}</pre>
                                                    : ''}
                                                    {item.idtype === "3" ?
                                                        <div style={{display:"flex"}}>
                                                            <div style={{borderRadius:"50%", backgroundColor:"#fff", width:"25px", height:"25px"}}>
                                                                <img alt="" src={LOGO_COLAPSE} style={{width:"20px", height:"20px"}}/>
                                                                <div style={{marginTop:"-8px", marginLeft:"18px"}}>
                                                                    <i className="fa fa-sm fa-microphone"/>
                                                                </div>
                                                            </div>
                                                            <div style={{display:"flex", alignItems:"center", marginLeft:"10px"}}>
                                                                <div>
                                                                    <i className="fa fa-play"/>
                                                                </div>
                                                            </div>
                                                            <div style={{width:"70px", height:"25px", display:"flex",alignItems:"center", marginLeft:"5px"}}>
                                                                <div style={{width:"2px", height:"15px", background:"#cacaca"}}></div>
                                                                <div style={{width:"2px", height:"10px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"18px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"18px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"5px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"4px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"7px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"9px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"19px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"2px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"1px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"8px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"15px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"10px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"10px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"7px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"11px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"6px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"8px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"9px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"16px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"15px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"9px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                                <div style={{width:"2px", height:"8px", background:"#cacaca", marginLeft:"1px"}}></div>
                                                            </div>
                                                        </div>
                                                    : ''}
                                                    {item.idtype === "2" ? 
                                                        <div style={{display:'flex',alignItems:'center',flexDirection:"column"}}>
                                                            {(item.mimetype.indexOf('image')>= 0) ? 
                                                                <img alt="" src={item.base64} style={{borderRadius:"5px", maxWidth:'100px', maxHeight:'70px', width:"100%"}} />
                                                            : (item.mimetype.indexOf('pdf')>= 0) ?
                                                                 <img alt="" src={PDF} style={{borderRadius:"5px", maxWidth:'50px', maxHeight:'50px', width:"100%"}} />
                                                            : (item.mimetype.indexOf('mp4')>= 0) ?
                                                                 <img alt="" src={VIDEO} style={{borderRadius:"5px", maxWidth:'50px', maxHeight:'50px', width:"100%"}} />
                                                            : 'Tipo Desconhecido'}
                                                                  <pre style={{fontSize:"11px", maxWidth:"215px",textWrap: "wrap"}}>{item.message}</pre>
                                                        </div>
                                                    : ""}
                                                    {item.idtype === "4" ? 
                                                        <div style={{display:'flex',alignItems:'center',flexDirection:"column"}}>
                                                            <div style={{display:"flex"}}>
                                                                <div style={{borderRadius:"50%", backgroundColor:"#fff", width:"25px", height:"25px"}}>
                                                                    <img alt="" src={LOGO_COLAPSE} style={{width:"20px", height:"20px"}}/>
                                                                </div>
                                                                <div style={{width:"70px", height:"25px", display:"flex",alignItems:"center", marginLeft:"5px"}}>
                                                                    <span style={{fontSize:"11px", fontWeight:"bold"}}>Contato</span>
                                                                </div>
                                                            </div>
                                                            <pre style={{fontSize:"11px", maxWidth:"215px",textWrap: "wrap"}}>{item.message}</pre>
                                                        </div>
                                                    : ''}
                                                    {item.idtype === "5" ? 
                                                        <div style={{display:'flex',alignItems:'center',flexDirection:"column"}}>
                                                            <div style={{fontWeight:"bold"}}>{item.titleButton.substring(0,120)}</div>
                                                            <div>{item.textButton.substring(0,120)}</div>
                                                            <div style={{display:"flex", justifyContent:"center"}}>
                                                                <div style={{ borderRadius:"5px", padding:"5px", backgroundColor:"#fff"}}>{item.buttonOption1 === 0 ? '' : item.buttonOption1 === 1 ? <i className="fa fa-globe" style={{marginRight:"5px"}}/> : <i className="fa fa-phone" style={{marginRight:"5px"}}/> }{item.button1}</div>
                                                                {item.button2 !== "" ? 
                                                                    <div style={{ borderRadius:"5px", padding:"5px", backgroundColor:"#fff", marginLeft:"5px"}}>{item.buttonOption2=== 0 ? '' : item.buttonOption2 === 1 ? <i className="fa fa-globe" style={{marginRight:"5px"}}/> : <i className="fa fa-phone" style={{marginRight:"5px"}}/> }{item.button2}</div>
                                                                : ""}
                                                            </div>
                                                        </div>
                                                    : ''}
                                                    {item.idtype === "6" ? 
                                                        <img alt="" src={LIST} style={{borderRadius:"5px", maxWidth:'50px', maxHeight:'50px', width:"100%"}} />
                                                    : ''}  
                                                </div> 
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="button" id="submit-campaign" disabled={loading} onClick={e => handleSubmit(e)}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}