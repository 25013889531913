import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";
import FileVisualizer from "../../../components/FileVisualizer";
import { convertToBase64 } from "./../../../components/Utils";
import Dropzone from "../../../components/dropzone";
import EmojiPicker from 'emoji-picker-react';
import EMOJI from './../../../assets/Emoji.png'

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalFileNode({nodeId, showModal,  handleCloseModal,nodeData,editData}) {
    const [selectedFiles, setSelectedFiles]     = useState([]);
    const [description, setDescription]         = useState('')
    const [message, setMessage]                 = useState('')
    const [origin, setOrigin]                   = useState(0)
    const [Url, setUrl]                         = useState('')
    const [showEmoji, setShowEmoji]             = useState(false)
    const [tag, setTag]                         = useState('[ANEXO]')
    const [objectReference, setObjectReference] = useState('')

    const reactFlowInstance = useReactFlow();

    const handleFiles = async(arrSelected) => {
        if (arrSelected.length !== 0) {
            let files  = [...selectedFiles];

            for (let i = 0; i < arrSelected.length; i++) {
                if (arrSelected[i].size > 41943040) {
                    ShowMessages({status : 500, message : "O arquivo informado não pode ser maior que 40Mb"})
                } else {
                    files.push({
                        src: URL.createObjectURL(arrSelected[i]),
                        altText: `${URL.createObjectURL(arrSelected[i])}`,
                        file: URL.createObjectURL(arrSelected[i]),
                        name: arrSelected[i].name,
                        size: arrSelected[i].size,
                        type: arrSelected[i].type,
                        base64 : await convertToBase64(arrSelected[i])
                        });
                }
            }

            setSelectedFiles(files);
        }
    };

    useEffect(() => {
        setSelectedFiles(nodeData.files)
        setDescription(nodeData.description)
        setMessage(nodeData.message)
        setOrigin(nodeData.origin)
        setUrl(nodeData.URL)
        setTag(nodeData.tag || '[ANEXO]')
        setObjectReference(nodeData.objectReference)
    }, [nodeData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if ((origin === 1) && (Url.toString().trim() === '')) {
            ShowMessages({status: 500, message: "Informe uma URL"});
            return;
        }

        if (selectedFiles.length > 5) {
            ShowMessages({
                status: 500,
                message: "Quantidade de arquivos deve ser até 5, para inserir um número maior, crie um outro objeto de arquivos!",
                });
            return;
        }

        if (origin === 3) {
            if (Url === '') {
                ShowMessages({
                    status: 500,
                    message: "Informe qual a URL será acessada",
                    });
            }

            if (objectReference === '') {
                ShowMessages({
                    status: 500,
                    message: "Informe qual o objeto no DOM do site sera buscado a URL de download",
                    });
            }

            if (!['.','#'].includes((objectReference.toString())[0])) {
                ShowMessages({
                    status: 500,
                    message: "A referência deve iniciar com os caracteres '.' para uma classe ou '#' para um ID",
                    });
            }
        }

        
        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                 if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node        : parseInt(nodeData.node),
                            parentId    : parseInt(nodeData.parentId), 
                            targetId    : parseInt(nodeData.targetId),
                            files       : origin > 1 ? [] : selectedFiles,
                            URL         : Url,
                            objectReference,
                            tag,
                            description,
                            message,
                            origin, 
                            objectType  : 2
                        },
                    };
                }

          return node;
        })
      );

        handleCloseModal();
    };

    return (
        <Modal size="md" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width500" animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-file"></i> Arquivos
                    </Modal.Title>
                    </Modal.Header>
                <Modal.Body>
                   <div className="box-shadow" style={{padding:"10px"}}>
                        <div>
                            <label>Descrição de Identificação</label><small style={{color:"red", marginLeft:"10px"}}> (Texto não enviado no arquivo)</small>
                            <input type="text" className="form-control" value={description} onChange={e => setDescription(e.target.value)}/>
                        </div>
                        <div>
                            <label>Mensagem do Arquivo</label><small style={{color:"green", marginLeft:"10px"}}> (Texto enviado no arquivo)</small>
                            <div className="input-group">
                                    <TextInput
                                        multiline={true}
                                        rows="4"
                                        cols="50"
                                        name="message"
                                        id="message"
                                        type="text"
                                        onFocus={e => setShowEmoji(false)}
                                        autoFocus={true}
                                        maxLength={1500}
                                        value={message}
                                        className="form-control "
                                        onChange={(e) => setMessage(e.target.value)}
                                        autoComplete="off"
                                    />
                             </div>
                            <div>
                                <div className="emoji-button" onClick={e => setShowEmoji(!showEmoji)}>
                                    <div>
                                        <img src={EMOJI} alt="" />
                                    </div>
                                </div>
                            </div>
                            {showEmoji && (
                                <div style={{ position: "fixed", top: "-125px", left: "500px" }}>
                                    <EmojiPicker onEmojiClick={e => setMessage(message + ' ' + e.emoji)} searchPlaceholder="Pesquisar" previewConfig={{
                                        showPreview: false
                                    }} categories={[
                                        {
                                            category: 'smileys_people',
                                            name: 'Pessoas e Rostos',
                                        },
                                        {
                                            category: 'animals_nature',
                                            name: 'Animais e Natureza',
                                        },
                                        {
                                            category: 'food_drink',
                                            name: 'Comida e Bebida',
                                        },
                                        {
                                            category: 'travel_places',
                                            name: 'Viagem e Lugares',
                                        },
                                        {
                                            category: 'activities',
                                            name: 'Atividades',
                                        },
                                        {
                                            category: 'objects',
                                            name: 'Objetos',
                                        },
                                        {
                                            category: 'symbols',
                                            name: 'Símbolos',
                                        },
                                        {
                                            category: 'flags',
                                            name: 'Bandeiras',
                                        }
                                    ]} />
                                </div>
                            )}
                        </div>
                        <div style={{borderRadius:"5px", border:"1px dashed #cacaca", padding:"10px", marginTop:"15px", display:"flex", justifyContent:"space-between"}}>
                            <div style={{display:"flex", gap:"5px", cursor:"pointer"}} onClick={e => setOrigin(0)}>
                                <input type="radio" className="form-check-input" checked={origin === 0} value={origin} />
                                <label>Arquivo do Computador</label>
                            </div>
                            <div style={{display:"flex", gap:"5px", cursor:"pointer"}} onClick={e => setOrigin(1)}>
                                <input type="radio" className="form-check-input" checked={origin === 1} value={origin} />
                                <label>Baixar da Internet</label>
                            </div>
                            <div style={{display:"flex", gap:"5px", cursor:"pointer"}} onClick={e => setOrigin(2)}>
                                <input type="radio" className="form-check-input" checked={origin === 2} value={origin} />
                                <label>Obter do WebHook</label>
                            </div>
                            <div style={{display:"flex", gap:"5px", cursor:"pointer"}} onClick={e => setOrigin(3)}>
                                <input type="radio" className="form-check-input" checked={origin === 3} value={origin} />
                                <label>Obter do Site</label>
                            </div>
                        </div>
                        {origin === 0 && (
                            <>
                                <div className="w-100 rounded" style={{overflow:"auto",maxHeight:"200px", marginTop:"10px"}}>
                                    <FileVisualizer imagesArray={selectedFiles} setSelectedFiles={setSelectedFiles} parentType="modal"/>
                                </div>
                                <div className="mb-3">
                                    <div>
                                        <label>Arquivos</label>
                                        <Dropzone onChange={handleFiles}  multiple={true}/>
                                    </div>
                                </div>
                            </>
                        )}
                        {origin === 1 && (
                            <>
                                <div style={{display:"flex", gap:"10px"}}>
                                    <div style={{width:"100%"}}>
                                        <label>URL</label>
                                        <input type="text" className="form-control" value={Url} onChange={e => setUrl(e.target.value)}/>
                                    </div>
                                </div>
                            </>

                        )}
                        {origin === 2 && (
                            <>
                                <div style={{display:"flex", gap:"10px"}}>
                                    <div style={{width:"100%"}}>
                                        <label>Tag</label>
                                        <input type="text" disabled className="form-control" value={tag}/>
                                    </div>
                                </div>
                            </>

                        )}
                        {origin === 3 && (
                            <>
                                <div style={{display:"flex", gap:"10px"}}>
                                    <div style={{width:"100%"}}>
                                        <label>URL</label>
                                        <input type="text" className="form-control" value={Url} onChange={e => setUrl(e.target.value)}/>
                                    </div>
                                </div>
                                <div style={{display:"flex", gap:"10px"}}>
                                    <div style={{width:"100%"}}>
                                        <label>Referência do Objeto</label>
                                        <input type="text" className="form-control" value={objectReference} onChange={e => setObjectReference(e.target.value)}/>
                                    </div>
                                </div>
                            </>

                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-modal-file">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    );
}
