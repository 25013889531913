import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Loading from '../../components/Loading';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import { Button } from 'reactstrap';
import { IDCOMPANY, ReadDataStorage, IDPLAN} from './../../services/auth'
import  loadingButton  from '../layouts/Buttons'
import Swal from 'sweetalert2'
import {setDataDB, getDataDB, dbDelete } from '../../services/idb'
import EmojiPicker from 'emoji-picker-react';

import EMOJI from './../../assets/Emoji.png'

export default function Settings(props) {
    const [loading, setLoading]                                       = useState(false)
    const [prefixDefault, setPrefixDefault]                           = useState('')
    const [showAttendenceName, setShowAttendanceName]                 = useState(false)
    const [allowAudio, setAllowAudio]                                 = useState(false)
    const [allowFiles, setAllowFiles]                                 = useState(false)
    const [allowSystemAttendance, setAllowSystemAttendance]           = useState(false)
    const [saveLeadAttendance, setSaveLeadAttendance]                 = useState(false)
    const [quantityAttendance, setQuantityAttendance]                 = useState(1)
    const [modeType, setModeType]                                     = useState(0)
    const [newMessages, setNewMessages]                               = useState(false)
    const [showLogo55ZapRedirectGroup, setShowLogo55ZapRedirectGroup] = useState(false)
    const [allowSounds, setAllowSounds]                               = useState(false)
    const [allowNotifications, setAllowNofifications]                 = useState(false)
    const [messageFinishAtendance, setMessageFinishAtendance]         = useState('')
    const [officialAPI, setOfficialAPI]                               = useState(false)
    const [hourBusinessMondayStart, setHourBusinessMondayStart]       = useState(null)
    const [hourBusinessMondayEnd, setHourBusinessMondayEnd]           = useState(null)
    const [hourBusinessSaturdayStart, setHourBusinessSaturdayStart]   = useState(null)
    const [hourBusinessSaturdayEnd, setHourBusinessSaturdayEnd]       = useState(null)
    const [hourBusinessSundayStart, setHourBusinessSundayStart]       = useState(null)
    const [hourBusinessSundayEnd, setHourBusinessSundayEnd]           = useState(null)
    const [allowGroupChats, setAllowGroupChats]                       = useState(false)
    const [closeInteraction, setCloseInteraction]                     = useState(30)
    const [idPlan, setIDPlan]                                         = useState(0)
    const [sendMessageFinishAtendance, setSendMessageFinishAtendance] = useState(true)
    const [showEmoji, setShowEmoji]                                   = useState(false)
    const handleSubmit = async(e) => {
        e.preventDefault()
        if (closeInteraction < 0) {
            ShowMessages({status : 500, message : 'O encerramento da interação não pode ser menor que 0 minutos.'})
            return
        }

        if (closeInteraction > 10080) {
            ShowMessages({status : 500, message : 'O encerramento da interação não pode ser maior que 7 dias.'})
            return
        }

        if ((sendMessageFinishAtendance) && ((messageFinishAtendance || '').toString().trim() === '')) {
            ShowMessages({status : 500, message : 'Informe uma mensagem para o fim do atendimento'})
            return
        }

        loadingButton(true,document.querySelector('#companySettings'),document.querySelector('#companySettings').children[0].classList,document.querySelector('#companySettings').lastChild.nodeValue)
        try {
            await api.post('/company/settings', { prefixDefault,
                                                  showAttendenceName,
                                                  allowAudio,
                                                  allowFiles,
                                                  allowSystemAttendance,
                                                  modeType,
                                                  officialAPI,
                                                  newMessages,
                                                  quantityAttendance,
                                                  allowSounds,
                                                  allowNotifications,
                                                  saveLeadAttendance,
                                                  showLogo55ZapRedirectGroup,
                                                  allowGroupChats,
                                                  sendMessageFinishAtendance,
                                                  messageFinishAtendance,
                                                  hourBusinessMondayStart,
                                                  hourBusinessMondayEnd,
                                                  hourBusinessSaturdayStart, 
                                                  hourBusinessSaturdayEnd, 
                                                  hourBusinessSundayStart, 
                                                  hourBusinessSundayEnd,
                                                  closeInteraction,
                                                  idcompany : ReadDataStorage(IDCOMPANY)})
                     .then(response => {
                         ShowMessages(response)
                         loadingButton(false,document.querySelector('#companySettings')) 
                     }).catch(response => {
                        loadingButton(false,document.querySelector('#companySettings'))
                        ShowMessages(response)              
                     })
        } catch (response) {
            loadingButton(false,document.querySelector('#companySettings'))
            ShowMessages(response)          
        }
    }

    const getData = async() => {
        setLoading(true)
        await api.get('/company/' + ReadDataStorage(IDCOMPANY))
                 .then(response => {
                    if (response.ok) {
                        console.log(response.data.settings)
                        setIDPlan(JSON.parse(ReadDataStorage(IDPLAN)).idplan)
                        setPrefixDefault(response.data?.settings?.prefixDefault || '')
                        setShowAttendanceName(response.data?.settings?.showAttendenceName || false)
                        setAllowAudio(response.data?.settings?.allowAudio || false)
                        setAllowFiles(response.data?.settings?.allowFiles || false)
                        setAllowSystemAttendance(response.data?.settings?.allowSystemAttendance || false)
                        setAllowGroupChats(response.data?.settings?.allowGroupChats || false)
                        setQuantityAttendance(response.data?.settings?.quantityAttendance || 1)
                        setModeType(response.data?.settings?.modeType || 0)
                        setNewMessages(response.data?.settings?.newMessages || false)
                        setOfficialAPI(response.data?.settings?.officialAPI || false)
                        setShowLogo55ZapRedirectGroup(response.data?.settings?.showLogo55ZapRedirectGroup || false)
                        setSaveLeadAttendance(response.data?.settings?.saveLeadAttendance || false)
                        setAllowSounds(response.data?.settings?.allowSounds || false)
                        setAllowNofifications(response.data?.settings?.allowNotifications || false)
                        setHourBusinessMondayStart(response.data?.settings?.hourBusinessMondayStart || null)
                        setHourBusinessMondayEnd(response.data?.settings?.hourBusinessMondayEnd || null)
                        setHourBusinessSaturdayStart(response.data?.settings?.hourBusinessSaturdayStart || null)
                        setHourBusinessSaturdayEnd(response.data?.settings?.hourBusinessSaturdayEnd || null) 
                        setHourBusinessSundayStart(response.data?.settings?.hourBusinessSundayStart || null)
                        setHourBusinessSundayEnd(response.data?.settings?.hourBusinessSundayEnd || null)
                        setMessageFinishAtendance(response.data?.settings?.messageFinishAtendance || '')
                        setCloseInteraction(response.data?.settings?.closeInteraction || 30)
                        setSendMessageFinishAtendance(response.data?.settings?.sendMessageFinishAtendance)
                    }                                                  

                    setLoading(false)
                  })
                 .catch(error =>{
                    setLoading(false)
                    ShowMessages(error)
                 })
    }

    const maintananceMessages = () => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente executar a manutenção de mensagens?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await dbDelete(ReadDataStorage(IDCOMPANY))  
                console.log(process.env.REACT_APP_DB_VERSION)
                setDataDB('DBVersion',process.env.REACT_APP_DB_VERSION,null,ReadDataStorage(IDCOMPANY)) 
                ShowMessages({status : 200, message : "Manutenção realizada com sucesso!"})
            }
         })
    }

    const deleteMessage = () => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir todo o histório de conversas?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/historyall') 
                         .then(async(response) => {  
                            ShowMessages(response)
                            await dbDelete(ReadDataStorage(IDCOMPANY))  
                            setDataDB('DBVersion',process.env.REACT_APP_DB_VERSION,null,ReadDataStorage(IDCOMPANY)) 
                            props.setUpdate()
                        }).catch(error => {
                            ShowMessages(error)
                        })  
            }
            
          })
    }

    const handleNotifications = () => {
        setAllowNofifications(!allowNotifications)
        if (!allowNotifications) {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                  console.log("Permissão concedida para exibir notificações.");
                } else {
                    setAllowNofifications(false) 
                }
            });
        } else {
            ShowMessages({status : 325, message : 'Para revogar as permissões de notificações, acesse as permissões de notificações do seu navegador!'})
        }
    }

    const handleSystemAttendance = (e,value) => {
        setAllowSystemAttendance(value)
        if ((value) && (idPlan === 2)) {
            window.addTab(e,'Upgrade Plano','fa fa-crown','/payment',{},1,true)
            setAllowSystemAttendance(false)
        }
    }
    useEffect(() => {
        getData()
        setStatistic('maintenance\\settings')
    },[])

    return (    
        <ContentWrapper className="content-wrapper-bottom81 ">
            {loading && (
                <Loading loading={loading} addClass="heigth50vh"/>
            )}
            <div className={`content-box-cart-page adjust-height-settings ${(loading ? "hidden" : "")}`} style={{gap:"5px"}}>
                <div style={{display:"flex", flexDirection:"column",height: "fit-content", maxHeight: "667px"}} className='box-shadow'>
                    <div style={{width:"100px"}}>
                        <label>País Padrão DDI</label>
                        <select className='form-select' value={prefixDefault} onChange={e => setPrefixDefault(e.target.value)}>
                            <option value =''>Selecione</option>
                            <option value={55}>+55</option>
                        </select>
                    </div>
                    <div style={{display:"flex",border:"1px solid #cacaca", marginTop:"15px", borderRadius:"5px", flexDirection:"column"}}>
                        <div style={{marginTop:"-10px", marginLeft:"10px"}}>
                            <span style={{ background:"#fff"}}>Horário de Funcionamento</span>
                        </div>

                        <div style={{padding:"10px", display:"flex", gap:"15px"}}>
                            <div>
                                <label>Segunda a Sexta</label>
                                <div style={{display:"flex", gap:"5px", width:"180px"}}>
                                    <input type="time" className='form-control' value={hourBusinessMondayStart} onChange={e => setHourBusinessMondayStart(e.target.value)}/>
                                    <input type="time" className='form-control' value={hourBusinessMondayEnd} onChange={e => setHourBusinessMondayEnd(e.target.value)}/>
                                </div>
                            </div>
                            <div>
                                <label>Sábado</label>
                                <div style={{display:"flex", gap:"5px", width:"180px"}}>
                                    <input type="time" className='form-control'  value={hourBusinessSaturdayStart} onChange={e => setHourBusinessSaturdayStart(e.target.value)}/>
                                    <input type="time" className='form-control'  value={hourBusinessSaturdayEnd} onChange={e => setHourBusinessSaturdayEnd(e.target.value)}/>
                                </div>
                            </div>
                            <div>
                                <label>Domingo</label>
                                <div style={{display:"flex", gap:"5px", width:"180px"}}>
                                    <input type="time" className='form-control' value={hourBusinessSundayStart} onChange={e => setHourBusinessSundayStart(e.target.value)}/>
                                    <input type="time" className='form-control' value={hourBusinessSundayEnd} onChange={e => setHourBusinessSundayEnd(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{border:"1px solid #cacaca", marginTop:"15px", borderRadius:"5px", width:"600px", padding:"10px"}}>
                        <div style={{marginTop:"-21px"}}>
                            <span style={{background:"#fff"}}>Opções do Sistema</span>
                        </div>
                        <div>
                            <div style={{display:"flex"}}>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={officialAPI} 
                                                           value={officialAPI} 
                                                           onClick={e => setOfficialAPI(!officialAPI)} />
                                    <span style={{ marginTop: "5px" }}></span>
                                </label>
                                <div  style={{marginLeft:"5px", marginTop:"2px"}}>
                                    <span>Utiliza API Oficial do Whatsapp?</span>
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={allowSystemAttendance} 
                                                           value={allowSystemAttendance} 
                                                           onClick={e => handleSystemAttendance(e,!allowSystemAttendance)} />
                                    <span style={{ marginTop: "5px" }}></span>
                                </label>
                                <div  style={{marginLeft:"5px", marginTop:"2px", display:"flex", gap:"5px"}}>
                                    <span>Deseja utilizar o sistema de atendimento?</span>
                                    {(idPlan === 2) && (
                                        <div className='bg bg-warning' style={{padding:"1px 8px", borderRadius:"8px", fontSize:"10px", width:"70px", display:"flex",gap:"2px", alignItems:"center"}}>
                                            <i className='fa fa-crown' style={{color:"#272829"}}/>
                                            <span style={{color:"#272829"}}>Upgrade</span>
                                        </div> 
                                    )}
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={showLogo55ZapRedirectGroup} 
                                                           value={showLogo55ZapRedirectGroup} 
                                                           onClick={e => setShowLogo55ZapRedirectGroup(!showLogo55ZapRedirectGroup)} />
                                    <span style={{ marginTop: "5px" }}></span>
                                </label>
                                <div  style={{marginLeft:"5px", marginTop:"2px"}}>
                                    <span>Mostrar Logomarca da 55Zap no redirecionamento de link?</span>
                                </div>
                            </div>
                            {allowSystemAttendance && (
                                <>
                                    <div style={{ display: "flex" }}>
                                        <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                            <input type="checkbox" checked={allowNotifications}
                                                value={allowNotifications}
                                                onClick={e => handleNotifications(e)} />
                                            <span style={{ marginTop: "5px" }}></span>
                                        </label>
                                        <div style={{ marginLeft: "5px", marginTop: "2px" }}>
                                            <span>Permitir que o sistema envie notificações?</span>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                            <input type="checkbox" checked={allowSounds}
                                                value={allowSounds}
                                                onClick={e => setAllowSounds(!allowSounds)} />
                                            <span style={{ marginTop: "5px" }}></span>
                                        </label>
                                        <div style={{ marginLeft: "5px", marginTop: "2px" }}>
                                            <span>Permitir que o sistema toque sons de alerta?</span>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {allowSystemAttendance && (
                        <div style={{border:"1px solid #cacaca", marginTop:"15px", borderRadius:"5px", width:"600px", padding:"10px", overflow:"auto"}}>
                            <div style={{marginTop:"-21px", position:"fixed", width:"150px"}}>
                                <span style={{background:"#fff"}}>Opções de Atendimento</span>
                            </div>
                            <div>
                                <div style={{display:"flex", border:"1px dashed #cacaca", padding:"10px", flexDirection:"column", gap:"5px", borderRadius:"5px", marginTop:"10px"}}>
                                    <div style={{marginTop:"-20px"}}>
                                        <span style={{fontWeight:"bold", background:"#fff"}}>Modo de Uso</span>
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <input type="radio" name="type" className='form-check-input' style={{cursor:"pointer"}} checked={modeType === 0} onChange={e => setModeType(0)}/>
                                        <span style={{marginLeft:"5px", color:"#27c24c"}}>Modo Conversação </span>
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <input type="radio" name="type" className='form-check-input' style={{cursor:"pointer"}} checked={modeType === 4} onChange={e => setModeType(4)}/>
                                        <span style={{marginLeft:"5px"}}>Modo Conversação Direta</span>
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <input type="radio" name="type" className='form-check-input' style={{cursor:"pointer"}} checked={modeType === 3} onChange={e => setModeType(3)}/>
                                        <span style={{marginLeft:"5px"}}>Modo Conversação Com Atendimento</span>
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <input type="radio" name="type" className='form-check-input' style={{cursor:"pointer"}} checked={modeType === 2} onChange={e => setModeType(2)}/>
                                        <span style={{marginLeft:"5px", color:"#27c24c"}}>Modo Conversação Vinculado ao Departamento | Sem ChatBot</span>
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <input type="radio" name="type" className='form-check-input' style={{cursor:"pointer"}} checked={modeType === 6} onChange={e => setModeType(6)}/>
                                        <span style={{marginLeft:"5px"}}>Modo Conversação Vinculado ao Departamento | Com ChatBot</span>
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <input type="radio" name="type" className='form-check-input' style={{cursor:"pointer"}} checked={modeType === 1} onChange={e => setModeType(1)}/>
                                        <span style={{marginLeft:"5px"}}>Modo Atendimento</span>
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <input type="radio" name="type" className='form-check-input' style={{cursor:"pointer"}} checked={modeType === 5} onChange={e => {
                                            setModeType(5)
                                            setAllowGroupChats(false)
                                        }}/>
                                        <span style={{marginLeft:"5px", color:"#27c24c"}}>Modo Atendimento Sem Fila</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={newMessages} 
                                                               value={newMessages} 
                                                               onClick={e => setNewMessages(!newMessages)} />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <div  style={{marginLeft:"5px", marginTop:"2px"}}>
                                        <span>Permitir criar novas mensagens pelo atendimento?</span>
                                    </div>
                                </div>
                                {(modeType !== 5) && (
                                    <div style={{display:"flex"}}>
                                        <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                            <input type="checkbox" checked={allowGroupChats} 
                                                                value={allowGroupChats} 
                                                                onClick={e => setAllowGroupChats(!allowGroupChats)} />
                                            <span style={{ marginTop: "5px" }}></span>
                                        </label>
                                        <div  style={{marginLeft:"5px", marginTop:"2px"}}>
                                            <span>Permitir utilizar grupos no chat?</span>
                                        </div>
                                    </div>
                                )}
                                <div style={{display:"flex"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={showAttendenceName} 
                                                               value={showAttendenceName} 
                                                               onClick={e => setShowAttendanceName(!showAttendenceName)} />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <div  style={{marginLeft:"5px", marginTop:"2px"}}>
                                        <span>Mencionar o nome do antendente ao enviar mensagem?</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={allowAudio} 
                                                               value={allowAudio} 
                                                               onClick={e => setAllowAudio(!allowAudio)} />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <div  style={{marginLeft:"5px", marginTop:"2px"}}>
                                        <span>Permitir o envio de audio?</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={allowFiles} 
                                                               value={allowFiles} 
                                                               onClick={e => setAllowFiles(!allowFiles)} />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <div  style={{marginLeft:"5px", marginTop:"2px"}}>
                                        <span>Permitir o envio de imagens ou arquivos?</span>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={saveLeadAttendance} 
                                                               value={saveLeadAttendance} 
                                                               onClick={e => setSaveLeadAttendance(!saveLeadAttendance)} />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <div  style={{marginLeft:"5px", marginTop:"2px"}}>
                                        <span>Quando receber uma mensagem, salvar o contato como lead de atendimento?</span>
                                    </div>
                                </div>
                                <div style={{display:"flex", gap:"10px"}}>
                                    {[1].includes(modeType) && (
                                        <div style={{width:"240px", marginTop:15}}>
                                            <span>Atendimentos simultâneos por atendente</span>
                                            <input  
                                                name=""
                                                id="card-name"
                                                type="number"
                                                required
                                                autoFocus={true}
                                                maxLength={100}
                                                errorMessage={{ required: "Por favor, informe uma quantidade!" }}
                                                value={quantityAttendance}
                                                className="form-control "
                                                onChange={e => setQuantityAttendance(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                    )}
                                    <div style={{width:"420px", marginTop:15}}>
                                        <div style={{display:"flex", flexDirection:"column"}}>
                                            <span>Encerrar atendimento após</span><small style={{color:"#27c24c"}}>(Para desligar esta opção, informe 0 como valor)</small>
                                        </div>
                                        <div style={{width:"300px", display:"flex", alignItems:"center",gap:"5px"}}>
                                            <input  
                                                name="closeInteraction"
                                                id="closeInteraction"
                                                type="number"
                                                required
                                                maxLength={100}
                                                errorMessage={{ required: "Por favor, informe uma quantidade!" }}
                                                value={closeInteraction}
                                                className="form-control text-right"
                                                onChange={e => setCloseInteraction(e.target.value)}
                                                autoComplete="off"/>
                                            <span style={{width :"250px"}}>minutos de inatividade</span>
                                        </div>
                                    </div>
                                </div>
                                {[1,2,3,5].includes(modeType) && (
                                    <div style={{marginTop:"15px"}}>
                                        <div style={{display:"flex", marginBottom:"10px", alignItems:"center"}}>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                    <input type="checkbox" checked={sendMessageFinishAtendance} 
                                                                    value={sendMessageFinishAtendance} 
                                                                    onClick={e => setSendMessageFinishAtendance(!sendMessageFinishAtendance)} />
                                                    <span style={{ marginTop: "5px" }}></span>
                                                </label>
                                            </div>
                                            <div  style={{marginLeft:"5px", marginTop:"2px"}}>
                                                <span>Deseja utilizar uma mensagem personalizada para o encerramento do atendimento?</span>
                                            </div>
                                        </div>
                                        {sendMessageFinishAtendance &&  (
                                            <>
                                                <textarea className='form-control' onFocus={e => setShowEmoji(false)} value={messageFinishAtendance} onChange={e => setMessageFinishAtendance(e.target.value)}></textarea>
                                                <div>
                                                    <div className="emoji-button" onClick={e => setShowEmoji(!showEmoji)}>
                                                        <div>
                                                            <img src={EMOJI} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    
                                )}
                            </div>
                            <div style={{display:"flex", justifyContent:"center", gap:"5px"}}>
                                <div style={{marginTop:"15px", display:"flex", justifyContent:"center"}}>
                                    <button className='btn btn-danger' type="button" onClick={e=> deleteMessage()}><i className='fa fa-trash'/> Excluir Todos Históricos de Conversas</button>
                                </div>
                                <div style={{marginTop:"15px", display:"flex", justifyContent:"center"}}>
                                    <button className='btn btn-warning' type="button" onClick={e=> maintananceMessages()}><i className='fa fa-rotate'/> Executar Manutenção de Mensagens</button>
                                </div>
                            </div>
                        </div>
                    )}
                   
                </div>
                {allowSystemAttendance && (
                    <div className='box-shadow' style={{ width: "450px", padding: "0px" }}>
                        {modeType === 0 && (
                            <div>
                                <div style={{ display: "flex", justifyContent: "center", background: "#f7f8f9", padding: "10px" }}>
                                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>Modo de Conversação</span>
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <ul>
                                        <li>Todos podem ver todas as conversas</li>
                                        <li>Todos podem interagir em conversas</li>
                                        <li>Não tem interação com o chatbot</li>
                                        <li>E necessário iniciar atendimento para iniciar uma iteração.</li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        {modeType === 1 && (
                            <div>
                                <div style={{ display: "flex", justifyContent: "center", background: "#f7f8f9", padding: "10px" }}>
                                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>Modo de Atendimento</span>
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <ul>
                                        <li>Somente o administrador do sistema pode ver todas as conversas</li>
                                        <li>O supervisor do departamento pode ver todas as conversas do departamento</li>
                                        <li>As conversas são separadas por departamentos</li>
                                        <li>As conversas entram em uma fila de atendimento, sendo distribuídas entre os atendentes com maior disponibildiade</li>
                                        <li>Espera por atendente disponível</li>
                                        <li>Necessita da interação com o chatbot</li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        {modeType === 2 && (
                            <div>
                                <div style={{ display: "flex", justifyContent: "center", background: "#f7f8f9", padding: "10px" }}>
                                    <span style={{ fontSize: "16px", fontWeight: "bold", textAlign: "center" }}>Modo de Conversação Vinculado ao Departamento | Sem ChatBot</span>
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <ul>
                                        <li>Somente o administrador do sistema pode ver todas as conversas</li>
                                        <li>O supervisor do departamento pode ver todas as conversas do departamento referentes aos números vinculados</li>
                                        <li>O atendente só poderá ver as conversas vinculadas ao seu departamento</li>
                                        <li>Não tem interação com o chatbot</li>
                                        <li>Não é necessário iniciar atendimento para iniciar uma iteração.</li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        {modeType === 3 && (
                            <div>
                                <div style={{ display: "flex", justifyContent: "center", background: "#f7f8f9", padding: "10px" }}>
                                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>Modo de Conversação Com Atendimento</span>
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <ul>
                                        <li>Todos podem ver todas as conversas</li>
                                        <li>Apenas um atendente por vez pode interagir com o usuário</li>
                                        <li>Não tem interação com o chatbot</li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        {modeType === 4 && (
                            <div>
                                <div style={{ display: "flex", justifyContent: "center", background: "#f7f8f9", padding: "10px" }}>
                                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>Modo de Conversação Direta</span>
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <ul>
                                        <li>Todos podem ver todas as conversas</li>
                                        <li>Todos podem interagir em conversas</li>
                                        <li>Não tem interação com o chatbot</li>
                                        <li>Não é necessário iniciar atendimento para iniciar uma iteração.</li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        {modeType === 5 && (
                            <div>
                                <div style={{ display: "flex", justifyContent: "center", background: "#f7f8f9", padding: "10px" }}>
                                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>Modo Atendimento Sem Fila</span>
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <ul>
                                        <li>Somente o administrador do sistema pode ver todas as conversas</li>
                                        <li>O supervisor do departamento pode ver todas as conversas do departamento</li>
                                        <li>As conversas são separadas por departamentos</li>
                                        <li>Não existe fila de atendimento</li>
                                        <li>As conversas são iniciadas pelo primeiro atendente que pegar</li>
                                        <li>Necessita da interação com o chatbot</li>
                                        <li>É necessário iniciar atendimento para iniciar uma iteração.</li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        {modeType === 6 && (
                            <div>
                                <div style={{ display: "flex", justifyContent: "center", background: "#f7f8f9", padding: "10px" }}>
                                    <span style={{ fontSize: "16px", fontWeight: "bold", textAlign: "center" }}>Modo de Conversação Vinculado ao Departamento | Com ChatBot</span>
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <ul>
                                        <li>Somente o administrador do sistema pode ver todas as conversas</li>
                                        <li>O supervisor do departamento pode ver todas as conversas do departamento referentes aos números vinculados</li>
                                        <li>O atendente só poderá ver as conversas vinculadas ao seu departamento</li>
                                        <li>Tem interação com o chatbot</li>
                                        <li>Não é necessário iniciar atendimento para iniciar uma iteração.</li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                 {showEmoji && (
                        <div style={{ position: "absolute", top: "170px", marginLeft: "600px" }}>
                            <EmojiPicker onEmojiClick={e => setMessageFinishAtendance(messageFinishAtendance + ' ' + e.emoji)} searchPlaceholder="Pesquisar" previewConfig={{
                                showPreview: false
                            }} categories={[
                                {
                                    category: 'smileys_people',
                                    name: 'Pessoas e Rostos',
                                },
                                {
                                    category: 'animals_nature',
                                    name: 'Animais e Natureza',
                                },
                                {
                                    category: 'food_drink',
                                    name: 'Comida e Bebida',
                                },
                                {
                                    category: 'travel_places',
                                    name: 'Viagem e Lugares',
                                },
                                {
                                    category: 'activities',
                                    name: 'Atividades',
                                },
                                {
                                    category: 'objects',
                                    name: 'Objetos',
                                },
                                {
                                    category: 'symbols',
                                    name: 'Símbolos',
                                },
                                {
                                    category: 'flags',
                                    name: 'Bandeiras',
                                }
                            ]} />
                        </div>
                    )}
            </div>
            <div className="modal-footer footer-fixed modal-footer-bottom-10">
                <Button type="submit" id="companySettings" className={`btn-theme ${loading ? "hidden" : ""}`} onClick={e => handleSubmit(e)}>
                    <em className="fa fa-save"></em> Salvar
                    </Button>
                <Button className="btn-ligth-gray" onClick={e => window.removeTab(`tab_${props.id}`,e)}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </div>
        </ContentWrapper>
    )
}
