import React, { useEffect, useState } from 'react';

import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';
import { Button } from 'reactstrap';
import ShowMessages from '../../../components/Exceptions';

import CurrencyInput from '../../../components/moneyInput';

import { TextInput, ValidationForm } from 'react-bootstrap4-form-validation';

import api from '../../../services/api';
import setStatistic from '../../../services/apiStatistic';
import loadingButton from '../../layouts/Buttons';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalPlan({showModal, handleCloseModal, handleReload, data, editState}) {
    const [name, setName]                         = useState('')
    const [description, setDescription]           = useState(null)
    const [id, setID]                             = useState(null)
    const [descriptions, setDescriptions]         = useState([])
    const [valueChatBot, setValueChatBot]         = useState(0)
    const [numbervalue, setNumberValue]           = useState(0)
    const [attendancevalue, setAttendanceValue]   = useState(0)
    const [month, setMonth]                       = useState(0)
    const [valueMonth, setValueMonth]             = useState(0)
    const [discounts, setDiscounts]               = useState([])
    const [ordem, setOrdem]                       = useState(0)

    const handleSubmit = async(event) => {
        event.preventDefault()

        if (ordem === '') {
            ShowMessages({status : 500, message : "Informe a ordem do card no site"})
            return
        }


        if (descriptions.length <= 0) {
            ShowMessages({status : 500, message : "Itens do plano não foram informados"})
            return
        }

        loadingButton(true,document.querySelector('#submit-plan'),document.querySelector('#submit-plan').children[0].classList,document.querySelector('#submit-plan').lastChild.nodeValue)
        try {
            let _dataToSend = {
                name,
                ordem,
                descriptions,
                valueChatBot,
                numbervalue,
                attendancevalue,
                discounts
            }


            if (editState)
                await api.put('/plans/' + data.idplan, _dataToSend)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else
                                    loadingButton(false,document.querySelector('#submit-plan'),'fa fa-save','Salvar')
                            } else
                                loadingButton(false,document.querySelector('#submit-plan'),'fa fa-save','Salvar')
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-plan'),'fa fa-save','Salvar')
                            ShowMessages(error) 
                        })
            else
                await api.post('/plans', _dataToSend)
                         .then(response => {
                             ShowMessages(response)
                                if (response.ok) {
                                    if (response.data.status === 200) {
                                        handleCloseModal()  
                                        handleReload(true)    
                                    } else
                                        loadingButton(false,document.querySelector('#submit-plan'),'fa fa-save','Salvar')
                                } else
                                    loadingButton(false,document.querySelector('#submit-plan'),'fa fa-save','Salvar')
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-plan'),'fa fa-save','Salvar')
                            ShowMessages(error) 
                        })

        } catch (response) {
            loadingButton(false,document.querySelector('#submit-plan'),'fa fa-save','Salvar')
            ShowMessages(response)
        }
    }

    const setData = (obj,clear) => {
        setName(clear ? ''  : obj.name)
        setDescriptions(clear ? []  : JSON.parse(obj?.description || '[]'))
        setValueChatBot(clear ? 0  : obj.valuechatbot)
        setNumberValue(clear ? 0  : obj.numbervalue)
        setAttendanceValue(clear ? 0  : obj.attendancevalue)
        setDiscounts(clear ? []  : obj.discount)
        setOrdem(clear ? []  : obj.ordem)
    }

    const addDescription = async() => {

        if (description === '') {
            ShowMessages({status : 500, message : "Descrição não informada"})
            return
        }

        if (descriptions.includes(description)) {
            ShowMessages({status : 500, message : "Descrição já informada"})
            return
        } else {
            let _id =  (id !== null? id : descriptions.length + 1)

            setDescriptions(descriptions  => descriptions.concat({id : _id, description : description}))
        }

        setDescription('')
        setID(null)
    }

    const removeDescription = (_description) => {
        const _remove = descriptions.filter((t, i) => {
            if (t.id !== _description.id)
                return true;
        
            return false;
        });

        setDescriptions(_remove)
    }

    const editDescription = (_description) => {
        setID(_description?.id || null)
        setDescription(_description?.description || '')
        removeDescription(_description)
    }

    const addDiscount = async() => {
        if (month === 0) {
            ShowMessages({status : 500, message : "Período não informado"})
            return
        }

        let _value = valueMonth.toString().replace('R$ ','').replace(',','.')
    
        if (isNaN(parseFloat(_value))) {
            ShowMessages({status : 500, message : "Valor inválido"})
            return
        }

        if (parseFloat(_value) <= 0) {
            ShowMessages({status : 500, message : "Valor não informado"})
            return
        }

        let _data = discounts.filter((_item) => {return(_item.month === month)})

        if (_data.length > 0) {
            ShowMessages({status : 500, message : "Desconto já informado"})
            return
        }

        setDiscounts(discounts => discounts.concat({month,valueMonth}))

        setMonth(0)
        setValueMonth(0)
    }

    const removeDiscount = (_month) => {
        const _remove = discounts.filter((t, i) => {
            if (t.month !== _month)
                return true;
        
            return false;
        });

        setDiscounts(_remove)
    }

    useEffect(() => {
        setStatistic('modals\\modal-plan')
    } ,[])

    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    } ,[data,editState, showModal])

    return (
        <Modal size="md" show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="width550" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content">
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-folder-tree"></i> Propriedade de Planos
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='box-shadow'> 
                        <div style={{display:"flex", gap:"5px"}}>
                            <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                                <span>Nome do Plano</span>
                                <TextInput  
                                    name="name"
                                    id="name"
                                    type="text"
                                    required
                                    autoFocus={true}
                                    errorMessage={{ required: "Por favor, informe o nome do plano" }}
                                    value={name}
                                    className="form-control "
                                    onChange={e => setName(e.target.value)}
                                    autoComplete="off"/>
                            </div> 
                            <div style={{display:"flex", flexDirection:"column", width:"75px"}}>
                                <span>Ordem</span>
                                <TextInput  
                                    name="number"
                                    id="number"
                                    type="text"
                                    required
                                    autoFocus={true}
                                    errorMessage={{ required: "Informe a ordem que o card aparece no site" }}
                                    value={ordem}
                                    className="form-control "
                                    onChange={e => setOrdem(e.target.value)}
                                    autoComplete="off"/>
                            </div> 
                        </div>
                        
                        <div style={{display:"flex", gap:"5px"}}> 
                            <div style={{width:"100%"}}>
                                <span>Valor por Número</span>
                                <CurrencyInput  
                                    name="numbervalue"
                                    id="numbervalue"
                                    type="text"
                                    value={numbervalue}
                                    className="form-control text-right"
                                    onChange={setNumberValue}
                                    autoComplete="off"/>
                            </div>
                            <div style={{width:"100%"}}>
                                <span>Valor por Atendente</span>
                                <CurrencyInput  
                                    name="attendancevalue"
                                    id="attendancevalue"
                                    type="text"
                                    value={attendancevalue}
                                    className="form-control text-right"
                                    onChange={setAttendanceValue}
                                    autoComplete="off"/>
                            </div>
                            <div style={{width:"100%"}}>
                                <span>Valor Chatbot</span>
                                <CurrencyInput  
                                    name="value"
                                    id="value"
                                    type="text"
                                    required
                                    value={valueChatBot}
                                    className="form-control text-right"
                                    onChange={setValueChatBot}
                                    autoComplete="off"/>
                            </div> 
                        </div>
                        <div style={{display:"flex", flexDirection:"column", padding:"5px", border:"1px dashed #cacaca", marginTop:"15px", borderRadius:"5px"}}>
                            <div style={{marginTop:"-16px"}}>
                                <span style={{background:"#fff", fontWeight:"bold"}}>Itens do Plano</span>
                            </div>
                            
                            <div>
                                <div style={{display:"flex", gap:"5px"}}>
                                    <div style={{width:"100%"}}>
                                        <span>Descrição</span>
                                        <TextInput  
                                            name="description"
                                            id="description"
                                            type="text"
                                            value={description}
                                            className="form-control "
                                            onChange={e => setDescription(e.target.value)}
                                            autoComplete="off"/>
                                    </div>   
                                    <div style={{display:"flex",alignItems:"center",width:"40px"}}>
                                        <button type="button" className="btn btn-success" style={{marginTop:"18px"}} onClick={e => addDescription()}><i className="fa fa-plus"/></button>
                                    </div>
                                </div>
                                <div style={{marginTop:"5px", width:"100%", maxHeight:"150px", overflow:"auto"}}>
                                    <table className="table table-striped w-100">
                                        <thead className="thead-theme">
                                            <tr>
                                                <th className='head-th'>Itens</th>
                                                <th className='head-th' style={{textAlign:"center"}}>...</th>
                                            </tr>
                                        </thead>  
                                        <tbody>                        
                                            {descriptions.sort((a, b) => a.id - b.id).map((_description,id) => {
                                                return(<tr className="gradeX-" key={id}>
                                                            <td style={{verticalAlign: "middle"}}>{_description.description}</td>
                                                            <td className="grid-plan">
                                                                <button type='button' className="btn btn-warning btn-sm" onClick={e => editDescription(_description)}><i className="fa fa-trash"/></button>
                                                                <button type='button' className="btn btn-danger btn-sm" onClick={e => removeDescription(_description)}><i className="fa fa-trash"/></button>
                                                            </td>
                                                        </tr>)
                                                        }) }
                                        </tbody> 
                                    </table>
                                </div>
                            </div>
                        </div> 
                        <div style={{display:"flex", flexDirection:"column", padding:"5px", border:"1px dashed #cacaca", marginTop:"15px", borderRadius:"5px"}}>
                            <div style={{marginTop:"-16px"}}>
                                <span style={{background:"#fff", fontWeight:"bold"}}>Descontos</span>
                            </div>
                            
                            <div>
                                <div style={{display:"flex", gap:"5px"}}>
                                    <div style={{width:"100%"}}>
                                        <span>Período</span>
                                        <select className='form-select' value={month} onChange={e => setMonth(e.target.value)}>
                                            <option value={0}>Selecione</option>
                                            <option value={1}>1 Mês</option>
                                            <option value={3}>3 Meses</option>
                                            <option value={6}>6 Meses</option>
                                            <option value={12}>12 Meses</option>
                                        </select>
                                    </div> 
                                    <div style={{width:"100%"}}>
                                        <span>Valor Mensal</span>
                                        <CurrencyInput  
                                            name="valueMonth"
                                            id="valueMonth"
                                            type="text"
                                            value={valueMonth}
                                            className="form-control text-right"
                                            onChange={setValueMonth}
                                            autoComplete="off"/>
                                    </div>  
                                    <div style={{display:"flex",alignItems:"center", width:"40px"}}>
                                        <button type="button" className="btn btn-success" style={{marginTop:"18px"}} onClick={e => addDiscount()}><i className="fa fa-plus"/></button>
                                    </div>
                                </div>
                                <div style={{marginTop:"5px", width:"100%", maxHeight:"150px", overflow:"auto"}}>
                                    <table className="table table-striped w-100">
                                        <thead className="thead-theme">
                                            <tr>
                                                <th className='head-th'>Período</th>
                                                <th className='text-right'>Valor</th>
                                                <th style={{textAlign:"center"}}>...</th>
                                            </tr>
                                        </thead>  
                                        <tbody>                        
                                            {discounts.map((_discount,id) => {
                                                return(<tr className="gradeX" key={id}>
                                                            <td style={{verticalAlign: "middle"}}>{_discount.month} Meses</td>
                                                            <td style={{verticalAlign: "middle"}} className='text-right'>{_discount.valueMonth}</td>
                                                            <td style={{textAlign:"center",verticalAlign: "middle"}}>
                                                                <button type='button' className="btn btn-danger btn-sm" onClick={e => removeDiscount(_discount.month)}><i className="fa fa-trash"/></button>
                                                            </td>
                                                        </tr>)
                                                        }) }
                                        </tbody> 
                                    </table>
                                </div>
                            </div>
                        </div>                  
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-plan" className={`btn-theme`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    )
}