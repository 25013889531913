import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";
import FileVisualizer from "../../../components/FileVisualizer";
import { convertToBase64 } from "../../../components/Utils";
import Dropzone from "../../../components/dropzone";
import PhoneInput from 'react-phone-number-input'

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalLocationNode({ nodeId, showModal, handleCloseModal, nodeData, editData }) {
    const [latitude, setLatitude] = useState([]);
    const [longitude, setLongitude] = useState(0)

    const reactFlowInstance = useReactFlow();

     useEffect(() => {
        setLatitude(nodeData.latitude)
        setLongitude(nodeData.longitude)
    }, [nodeData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if ((latitude === '') || (latitude === 0) || (longitude === '') || (longitude === 0)){
            ShowMessages({
                status: 500,
                message: "Latitude ou Longitude não forma informadas",
            });
            return;
        }

        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node: parseInt(nodeData.node),
                            parentId: parseInt(nodeData.parentId),
                            targetId: parseInt(nodeData.targetId),
                            latitude,
                            longitude,
                            objectType: 19
                        },
                    };
                }

                return node;
            })
        );

        handleCloseModal();
    };

    return (
        <Modal size="md" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width500" animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-map-location"></i> Localização
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box-shadow" style={{ padding: "10px" }}>
                        <div className="mb-3" style={{display:"flex", gap:"5px"}}>
                            <div style={{width:"50%"}}>
                                <span> Latitude</span>
                                <input className="form-control" value={latitude}  onChange={e => setLatitude(e.target.value)}/>
                            </div>
                            <div style={{width:"50%"}}>
                                <span> Longitude</span>
                                <input className="form-control" value={longitude} onChange={e => setLongitude(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-modal-file">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    );
}
