import { memo, useCallback, useEffect, useState } from "react";
import { Handle,  Position, useReactFlow } from "reactflow";
import { getIconeFile, phone_format } from "./../../Utils"
import ModalContactNode from "./../../../pages/modals/workflow/modal-contact-node";
import ReactCountryFlag from "react-country-flag"

const ContactNode = (props) => {
    const reactFlowInstance = useReactFlow();

    const [showModal, setShowModal]         = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [id, setID]                       = useState(-1)
    const [statistic, setStatistic]         = useState(0)
    const [origin, setOrigin]               = useState(0)
    const [name, setName]                   = useState('')
    const [whatsapp, setWhatsapp]           = useState('')

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node            : _id,
                    parentId        : -1, 
                    targetId        : -1,
                    selectedFiles   : props.data.nodeInfo.selectedFiles,
                    name            : props.data.nodeInfo.name,
                    whatsapp        : props.data.nodeInfo.whatsapp,
                    origin          : props.data.nodeInfo.origin,
                    objectType      : 4
                }
            }
        }))
    }

    const deleteNode = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    const handleShowModal = (e) => {
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;

            setSelectedFiles(nodeInfo.files || []);
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
            setOrigin(nodeInfo.origin || 0)
            setWhatsapp(nodeInfo.whatsapp || '')
            setName(nodeInfo.name || '')
        };
        handleNodeProps();
    }, [props.data]);

    if (showModal) {
        return (
            <>
                <ModalContactNode
                    nodeId={props.id}
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    nodeData={props.data.nodeInfo}
                ></ModalContactNode>
            </>
        );
    }

    return (
        <>
            <div className="rfnode rfnode-contact d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) => handleShowModal(e)}>
                <div className="rfnode-title">#{id} Contato</div>
                <div style={{background: "rgb(32 201 151/ 29%)", margin:"5px 10px", padding:"5px", borderRadius:"5px", color:"#6f42c1", width:"85%", textAlign:"center"}}>
                        {selectedFiles.length <= 0  && whatsapp === '' ? (
                            <div style={{ padding:"5px", borderRadius:"5px", display:"flex", alignItems:"center", width:"100%", height:"30px", justifyContent:"center", flexDirection:"column"}}>
                                <i className="fa fa-address-card" style={{fontSize:"10px", marginRight:"2px", color:"#20c997"}}></i>
                                <span style={{fontSize:"6px", color:"#20c997"}}>Informe um arquivo ou um número de contato</span>
                            </div>
                        ) : ( 
                            origin === 0 ?                     
                                selectedFiles.map(_file => {
                                    return (
                                        <div style={{ padding:"5px", display:"flex", height:"35px", marginTop:"2px"}}>
                                            <div>
                                                <img alt="arquivo" style={{maxWidth:"20px"}} src={_file.src} onError={(e)=>{e.target.src=getIconeFile(_file.type,_file.name) }}/>
                                            </div>
                                            <div style={{marginLeft:"10px"}}>
                                                <div style={{maxWidth: "110px",fontSize: "8px",whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>
                                                    <span>{_file.name}</span>
                                                </div>
                                                <div style={{textAlign:"right", marginTop:"-2px",fontSize: "8px"}}>
                                                    <span style={{fontSize:"8px"}}>{parseFloat(_file.size / 1024).toFixed(2)} Mb</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            : 
                                <div style={{fontSize:"6px", display:"flex", justifyContent:"flex-start", flexDirection:"column"}}>
                                    <div style={{display:"flex", flexDirection:"column",alignItems:"flex-start"}}>
                                        <span style={{fontWeight:"bold"}}>Contato</span>
                                        <span>{name}</span>
                                    </div>
                                    <div style={{display:"flex", flexDirection:"column",alignItems:"flex-start"}}>
                                        <span style={{fontWeight:"bold"}}>Whatsapp</span>
                                        <div>
                                            <ReactCountryFlag 
                                                countryCode="BR"
                                                svg
                                                style={{
                                                    width: '1.5em',
                                                    height: '1.5em',
                                                    borderRadius:'5px'
                                                }}
                                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                cdnSuffix="svg"
                                                title="BR" />
                                            <span> { phone_format(whatsapp).toString().substring(3)}</span>
                                        </div>
                                    </div>
                                </div>
                        )}
                      

                     
                </div>
                <div style={{display:"flex", fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #20c997"}}>
                    <div style={{display:"flex", gap:"5px"}}>
                        <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                            <i className="fa fa-copy text-primary"></i>
                        </div>
                        <div onClick={() => deleteNode()} title="Excluir Mensagem" className="workflow-delete" >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    </div>
                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{fontSize:"10px", color:"#20c997"}}>{statistic || ''}</span>
                    </div>

                </div>
            </div>


            <Handle type="source" position={Position.Bottom} id='node-file1' />
            <Handle type="target" position={Position.Top}    id='node-file2' />
            <Handle type="target" position={Position.Left}   id='node-file3' />
            <Handle type="source" position={Position.Right}  id='node-file4' />
        </>
  );
};

export default memo(ContactNode);
