import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";
import Parameters from "../../../components/parameters";
import Waveform from "../../../components/waveformer";
import Dropzone from "../../../components/dropzone";
import { AudioRecorder } from 'react-audio-voice-recorder';
import { convertToBase64 } from "./../../../components/Utils";
import FileVisualizer from "../../../components/FileVisualizer";
import { format } from 'date-fns'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import EmojiPicker from 'emoji-picker-react';

import EMOJI from './../../../assets/Emoji.png'


class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalWhatsappNode({ nodeId, showModal, handleCloseModal, nodeData }) {
    const [audio, setAudio] = useState('');
    const [message, setMessage] = useState('')
    const [objectType, setObjectType] = useState(0)
    const [showParams, setShowParams] = useState(false)
    const [origin, setOrigin] = useState(0)
    const [isRecording, setIsRecording] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [activeContact, setActiveContact] = useState(false)
    const [Url, setUrl] = useState('')
    const [tag, setTag] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [showEmoji, setShowEmoji] = useState(false)

    const reactFlowInstance = useReactFlow();

    const handleFiles = async (arrSelected) => {
        if (arrSelected.length !== 0) {
            let files = [...selectedFiles];

            for (let i = 0; i < arrSelected.length; i++) {
                if (arrSelected[i].size > 41943040) {
                    ShowMessages({ status: 500, message: "O arquivo informado não pode ser maior que 40Mb" })
                } else {
                    files.push({
                        src: URL.createObjectURL(arrSelected[i]),
                        altText: `${URL.createObjectURL(arrSelected[i])}`,
                        file: URL.createObjectURL(arrSelected[i]),
                        name: arrSelected[i].name,
                        size: arrSelected[i].size,
                        type: arrSelected[i].type,
                        base64: await convertToBase64(arrSelected[i])
                    });
                }
            }

            setSelectedFiles(files);
        }
    };

    const handleAudio = async (files) => {
        if (files[0] && files[0].size > 20 * 1024 * 1024) {
            ShowMessages({ status: 500, message: "O arquivo não pode ser maior que 20Mb" })
            return
        }

        if (files[0]) {
            setAudio(await convertToBase64(files[0]))
        }
    };

    const handleCaptureAudio = async (a) => {
        if (a !== undefined) {
            setAudio(await convertToBase64(a))
        }
    }

    const handleHideParams = () => {
        setShowParams(false);
    };

    const handleTextParams = (textParams) => {
        const text = textParams.target.innerText;
        const textParam = text.substring(0, text.indexOf("]") + 1);

        const insertParamsToMessage = message + " " + textParam + " ";
        setMessage(insertParamsToMessage);
        handleHideParams();
    };

    const setLayoutMic = () => {
        let _main = document.querySelector('.audio-recorder')
        let _save = document.querySelector('.audio-recorder-mic ')
        let _img = document.querySelectorAll('.audio-recorder img')

        if (_main)
            _main.style.backgroundColor = 'transparent'

        if (!document.querySelector('.audio-recorder i')) {
            const _iRecord = document.createElement('i');
            const _iPause = document.createElement('i');
            const _iStop = document.createElement('i');

            _iRecord.addEventListener('click', function (event) {
                _img[0].click()
                _main.style.backgroundColor = '#EBEBEB'
                _iRecord.style.display = 'none'
                _iPause.style.display = 'block'
                _iStop.style.display = 'block'
                setIsRecording(true)
            });

            _iPause.addEventListener('click', function (event) {
                _img[1].click()
                _main.style.backgroundColor = '#EBEBEB'

                if (_iPause.classList.length > 0) {
                    if (_iPause.classList[1] === 'fa-circle-pause') {
                        _iPause.classList.remove('fa-circle-pause')
                        _iPause.classList.add('fa-circle-play')
                    } else {
                        _iPause.classList.remove('fa-circle-play')
                        _iPause.classList.add('fa-circle-pause')
                    }
                }
            });

            _iStop.addEventListener('click', function (event) {
                _img[2].click()
                _main.style.backgroundColor = '#EBEBEB'
                _iStop.style.display = 'none'
                _iPause.style.display = 'none'
                _iRecord.style.display = 'block'
                _main.style.backgroundColor = 'transparent'
                _save.click()
                setIsRecording(false)
            });

            _iRecord.className = 'fa fa-microphone'
            _iRecord.style.fontSize = '20px'
            _iRecord.style.color = '#858585'
            _iRecord.style.padding = '12px 15px'
            _iRecord.style.border = '1px solid #cacaca'
            _iRecord.style.borderRadius = '50%'
            _iRecord.title = 'Gravar Áudio'

            _iPause.className = 'fa fa-circle-pause'
            _iPause.style.fontSize = '24px'
            _iPause.style.color = '#858585'
            _iPause.style.display = 'none'
            _iPause.style.padding = '5px'
            _iPause.title = 'Pausar Gravação'

            _iStop.className = 'fa fa-circle-stop'
            _iStop.style.fontSize = '24px'
            _iStop.style.color = '#858585'
            _iStop.style.display = 'none'
            _iStop.style.padding = '5px'
            _iStop.title = 'Finalizar Gravação'

            if (_main) {
                _main.appendChild(_iRecord)
                _main.appendChild(_iPause)
                _main.appendChild(_iStop)
            }

        }

        if (_img.length > 0) {
            _img[0].style.display = 'none'
            _img[1].style.display = 'none'
            _img[2].style.display = 'none'
        }
    }

    useEffect(() => {
        if (audio === '')
            setLayoutMic()
    }, [audio, objectType])

    useEffect(() => {
        setLayoutMic()
    }, [])

    useEffect(() => {
        setMessage(nodeData.message || '')
        setAudio(nodeData.audio || '')
        setObjectType(nodeData.objectTypeW || 0)
        setOrigin(nodeData.origin || 0)
        setSelectedFiles(nodeData.selectedFiles || [])
        setActiveContact(nodeData.activeContact || false)
        setUrl(nodeData.url || '')
        setTag(nodeData.tag || '')
        setWhatsapp(nodeData.whatsapp || '')
    }, [nodeData]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (whatsapp === '') {
            ShowMessages({ status: 500, message: 'Informe o número de whatsapp!' })
            return
        }
        switch (objectType) {
            case 1:
                if (message === '') {
                    ShowMessages({ status: 500, message: 'Informe a mensagem a ser enviada!' })
                    return
                }
                break;

            default:
                break;
        }
        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node: parseInt(nodeData.node),
                            parentId: parseInt(nodeData.parentId),
                            targetId: parseInt(nodeData.targetId),
                            message,
                            audio,
                            objectTypeW: objectType,
                            origin,
                            selectedFiles,
                            activeContact,
                            whatsapp,
                            Url,
                            tag,
                            objectType: 17
                        },
                    };
                }
                return node;
            })
        );

        handleCloseModal();
    };

    return (
        <Modal size="md" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width500" animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fab fa-whatsapp"></i> WhatsApp
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box-shadow" style={{ padding: "10px" }}>
                        {showParams ? (
                            <div className="box-shadow" style={{ padding: "10px" }}>
                                <Parameters
                                    handleHideParams={handleHideParams}
                                    handleTextParams={handleTextParams}
                                ></Parameters>
                            </div>
                        ) : (
                            <div>
                                <div style={{ display: "flex", gap: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <span>Tipo de Mensagem</span>
                                        <select className="form-select" value={objectType} onChange={e => setObjectType(parseInt(e.target.value))}>
                                            <option value={0}>Selecione</option>
                                            <option value={1}>Mensagem</option>
                                            <option value={3}>Áudio</option>
                                            <option value={2}>Arquivo</option>
                                            <option value={4}>Contato</option>
                                        </select>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <label>Número para envio</label>
                                        <PhoneInput
                                            placeholder=""
                                            style={{ display: 'flex', padding: "0.295rem 0.75rem" }}
                                            value={whatsapp}
                                            initialValueFormat="national"
                                            defaultCountry="BR"
                                            className='form-control'
                                            onChange={setWhatsapp} />
                                    </div>
                                </div>
                                <div>
                                    {objectType === 1 && (
                                        <div>
                                            <div className="my-2">
                                                <label>Mensagem</label>
                                                <div className="input-group">
                                                    <TextInput
                                                        multiline={true}
                                                        rows="6"
                                                        cols="50"
                                                        name="message"
                                                        id="message"
                                                        type="text"
                                                        required={objectType === 1}
                                                        autoFocus={true}
                                                        maxLength={1500}
                                                        onFocus={e => setShowEmoji(false)}
                                                        errorMessage={{
                                                            required: "Por favor, informe uma mensagem!",
                                                        }}
                                                        value={message}
                                                        className="form-control "
                                                        onChange={(e) => setMessage(e.target.value)}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                            <div style={{display:"flex", justifyContent:"space-between"}}>
                                                <div className="d-flex" style={{ justifyContent: "space-between" }}>
                                                    <div>
                                                        <div className="d-flex">
                                                            <button type="button" className={`btn btn-warning`} onClick={(e) => setShowParams(true)}>
                                                                <i className="fa fa-icons" style={{ fontSize: "12px" }} />{" "}
                                                                <span style={{ fontSize: "12px" }}>Parâmetros</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="emoji-button" onClick={e => setShowEmoji(!showEmoji)}>
                                                        <div>
                                                            <img src={EMOJI} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {showEmoji && (
                                                <div style={{ position: "fixed", top: `${selectedFiles.length === 0 ? "15px" : "130px"}`, left: "500px" }}>
                                                    <EmojiPicker onEmojiClick={e => setMessage(message + ' ' + e.emoji)} searchPlaceholder="Pesquisar" previewConfig={{
                                                        showPreview: false
                                                    }} categories={[
                                                        {
                                                            category: 'smileys_people',
                                                            name: 'Pessoas e Rostos',
                                                        },
                                                        {
                                                            category: 'animals_nature',
                                                            name: 'Animais e Natureza',
                                                        },
                                                        {
                                                            category: 'food_drink',
                                                            name: 'Comida e Bebida',
                                                        },
                                                        {
                                                            category: 'travel_places',
                                                            name: 'Viagem e Lugares',
                                                        },
                                                        {
                                                            category: 'activities',
                                                            name: 'Atividades',
                                                        },
                                                        {
                                                            category: 'objects',
                                                            name: 'Objetos',
                                                        },
                                                        {
                                                            category: 'symbols',
                                                            name: 'Símbolos',
                                                        },
                                                        {
                                                            category: 'flags',
                                                            name: 'Bandeiras',
                                                        }
                                                    ]} />
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {objectType === 3 && (
                                        audio !== '' ? (
                                            <div className={`w-100 ${audio ? "border" : ""}`} style={{ borderRadius: "5px", marginTop: "15px", padding: "10px", display: "flex" }}>
                                                <Waveform
                                                    url={audio}
                                                    waveFormInstance={'instance' + format(new Date(), 'yyyyMMddhhmm')}
                                                    isModal={true}
                                                ></Waveform>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <button type="button" onClick={e => setAudio('')} className="btn btn-danger"><i className="fa fa-trash" /></button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div style={{ marginTop: "15px", border: "1px dashed #cacaca", borderRadius: "5px", padding: "5px" }}>
                                                <div style={{ marginTop: "-15px", marginLeft: "5px" }}>
                                                    <label style={{ background: "#fff" }}>Áudio</label>
                                                </div>
                                                <div className="mb-2">
                                                    <Dropzone onChange={handleAudio} multiple={false} accept={{ 'audio/*': ['.ogg', '.mp3', '.wav'] }} />
                                                </div>
                                                <div style={{ display: "flex", justifyContent: `center` }}>

                                                    <AudioRecorder
                                                        onRecordingComplete={handleCaptureAudio}
                                                        audioTrackConstraints={{
                                                            noiseSuppression: true,
                                                            echoCancellation: true,
                                                        }}
                                                        onNotAllowedOrFound={(err) => {
                                                            ShowMessages({status : 500, message : "Microfone não encontrado"})
                                                            if ((document.querySelectorAll('.fa.fa-circle-stop').length > 0) && (document.querySelectorAll('.fa.fa-circle-stop')[0].style.display !== 'none')) {
                                                                document.querySelectorAll('.fa.fa-circle-stop')[0].click()
                                                                setIsRecording(false)
                                                            }
                                                        }}
                                                        downloadOnSavePress={false}
                                                        downloadFileExtension="mp3"
                                                        mediaRecorderOptions={{
                                                            audioBitsPerSecond: 128000,
                                                        }}
                                                        showVisualizer={true}
                                                    />

                                                </div>
                                            </div>
                                        )
                                    )}

                                    {objectType === 2 && (
                                        <div>
                                            <div>
                                                <label>Mensagem do Arquivo</label>
                                                <div className="input-group">
                                                    <TextInput
                                                        multiline={true}
                                                        rows="4"
                                                        cols="50"
                                                        name="message"
                                                        id="message"
                                                        type="text"
                                                        onFocus={e => setShowEmoji(false)}
                                                        autoFocus={true}
                                                        maxLength={1500}
                                                        value={message}
                                                        className="form-control "
                                                        onChange={(e) => setMessage(e.target.value)}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                            <div style={{display:"flex", justifyContent:"space-between", marginTop:"5px"}}>
                                                <div className="d-flex" style={{ justifyContent: "space-between" }}>
                                                    <div>
                                                        <div className="d-flex">
                                                            <button type="button" className={`btn btn-warning`} onClick={(e) => setShowParams(true)}>
                                                                <i className="fa fa-icons" style={{ fontSize: "12px" }} />{" "}
                                                                <span style={{ fontSize: "12px" }}>Parâmetros</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="emoji-button" onClick={e => setShowEmoji(!showEmoji)}>
                                                        <div>
                                                            <img src={EMOJI} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {showEmoji && (
                                                <div style={{ position: "fixed", top: `${selectedFiles.length === 0 ? "15px" : "130px"}`, left: "500px" }}>
                                                    <EmojiPicker onEmojiClick={e => setMessage(message + ' ' + e.emoji)} searchPlaceholder="Pesquisar" previewConfig={{
                                                        showPreview: false
                                                    }} categories={[
                                                        {
                                                            category: 'smileys_people',
                                                            name: 'Pessoas e Rostos',
                                                        },
                                                        {
                                                            category: 'animals_nature',
                                                            name: 'Animais e Natureza',
                                                        },
                                                        {
                                                            category: 'food_drink',
                                                            name: 'Comida e Bebida',
                                                        },
                                                        {
                                                            category: 'travel_places',
                                                            name: 'Viagem e Lugares',
                                                        },
                                                        {
                                                            category: 'activities',
                                                            name: 'Atividades',
                                                        },
                                                        {
                                                            category: 'objects',
                                                            name: 'Objetos',
                                                        },
                                                        {
                                                            category: 'symbols',
                                                            name: 'Símbolos',
                                                        },
                                                        {
                                                            category: 'flags',
                                                            name: 'Bandeiras',
                                                        }
                                                    ]} />
                                                </div>
                                            )}
                                            <div style={{ borderRadius: "5px", border: "1px dashed #cacaca", padding: "10px", marginTop: "15px", display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", gap: "5px", cursor: "pointer" }} onClick={e => setOrigin(0)}>
                                                    <input type="radio" className="form-check-input" checked={origin === 0} value={origin} />
                                                    <label>Arquivo do Computador</label>
                                                </div>
                                                <div style={{ display: "flex", gap: "5px", cursor: "pointer" }} onClick={e => setOrigin(1)}>
                                                    <input type="radio" className="form-check-input" checked={origin === 1} value={origin} />
                                                    <label>Baixar da Internet</label>
                                                </div>
                                                <div style={{ display: "flex", gap: "5px", cursor: "pointer" }} onClick={e => setOrigin(2)}>
                                                    <input type="radio" className="form-check-input" checked={origin === 2} value={origin} />
                                                    <label>Obter do WebHook</label>
                                                </div>
                                            </div>
                                            {origin === 0 && (
                                                <>
                                                    <div className="w-100 rounded" style={{ overflow: "auto", maxHeight: "200px", marginTop: "10px" }}>
                                                        <FileVisualizer imagesArray={selectedFiles} setSelectedFiles={setSelectedFiles} parentType="modal" />
                                                    </div>
                                                    <div className="mb-3">
                                                        <div>
                                                            <label>Arquivos</label>
                                                            <Dropzone onChange={handleFiles} multiple={true} />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {origin === 1 && (
                                                <>
                                                    <div style={{ display: "flex", gap: "10px" }}>
                                                        <div style={{ width: "100%" }}>
                                                            <label>URL</label>
                                                            <input type="text" className="form-control" value={Url} onChange={e => setUrl(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {origin === 2 && (
                                                <>
                                                    <div style={{ display: "flex", gap: "10px" }}>
                                                        <div style={{ width: "100%" }}>
                                                            <label>TAG</label>
                                                            <input type="text" className="form-control" value={tag} onChange={e => setTag(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}

                                    {objectType === 4 && (
                                        <div>
                                            <div style={{ display: "flex", marginBottom: "10px" }}>
                                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                    <input type="checkbox" checked={activeContact} value={activeContact} onClick={e => setActiveContact(!activeContact)} />
                                                    <span style={{ marginTop: "5px" }}></span>
                                                </label>
                                                <span style={{ marginLeft: "5px", marginTop: "5px" }}>Enviar como contato ativo no fluxo?</span>
                                            </div>
                                            {!activeContact && (
                                                <>
                                                    <div className="w-100 rounded" style={{ overflow: "auto", maxHeight: "200px" }}>
                                                        <FileVisualizer imagesArray={selectedFiles} setSelectedFiles={setSelectedFiles} parentType="modal" />
                                                    </div>
                                                    <div className="mb-3">
                                                        <div>
                                                            <label>Arquivos</label>
                                                            <div className="mb-2">
                                                                <Dropzone onChange={handleFiles} multiple={true} accept={{
                                                                    'text/vcard': ['.vcf'],
                                                                    'text/x-vcard': ['.vcard']
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-modal-whatsapp">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    );
}
