import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";
import Parameters from "../../../components/parameters";
import { create } from 'apisauce';

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalActionNode({ nodeId, showModal, handleCloseModal, nodeData }) {
    const [loading, setLoading]                     = useState(false)
    const [method, setMethod]                       = useState(0);
    const [url, setURL]                             = useState('');
    const [body, setBody]                           = useState('')
    const [description, setDescription]             = useState('')
    const [conditionalContent, setConditionContent] = useState('')
    const [conditionalFor, setConditionalFor]       = useState('')
    const [requestType, setRequestType]             = useState('')
    const [showParams,setShowParams]                = useState(false)
    const [returnData, setReturnData]               = useState(null) 
    const [_for, setFor]                            = useState([])
    const [_forText, setForText]                    = useState([])
    const [value01, setValue01]                     = useState([])
    const [value02, setValue02]                     = useState([])
    const [forData, setForData]                     = useState([
        {
            value : 1,
            label : 'Igual a'
        },
        {
            value : 2,
            label : 'Diferente de'
        },
        {
            value : 3,
            label : 'Maior que'
        },
        {
            value : 4,
            label : 'Maior e Igual que'
        },
        {
            value : 5,
            label : 'Menor que'
        },
        {
            value : 6,
            label : 'Menor e Igual que'
        },
        {
            value : 7,
            label : 'Contenha'
        },
        {
            value : 8,
            label : 'Começa com'
        },
        {
            value : 9,
            label : 'Termina com'
        },
        {
            value : 10,
            label : 'Vazio'
        },
        {
            value : 11,
            label : 'Entre'
        }])

    const [conditionData, setConditionData] = useState([])

    const reactFlowInstance = useReactFlow();

    useEffect(() => {
        setMethod(nodeData.method);
        setURL(nodeData.url);
        setBody(nodeData.body)
        setDescription(nodeData.description)
        setConditionData(nodeData.conditionData || [])
        setRequestType(nodeData.requestType || '')
        setReturnData(nodeData.returnData || [])
        console.log(nodeData)
    }, [nodeData]);

    const handleFor = (e,id) => {
        let _item     = [..._for]
        let _itemText = [..._forText]
        _item[id]     = e.target.value
        _itemText[id] = e.target.options[e.target.selectedIndex].text 

        setFor(_item)
        setForText(_itemText)
    }

    const handleValue01 = (e,id) => {
        let _item = [...value01]
        _item[id] = e.target.value
        setValue01(_item)  
    }

    const handleValue02 = (e,id) => {
        let _item = [...value02]
        _item[id] = e.target.value
        setValue02(_item)  
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let _conditionData = [...conditionData]

        _conditionData = _conditionData.map((_item, key) => {
            _item['if']       = _for[key] || _item.if
            _item['ifText']   = _forText[key] || _item.ifText
            _item['value01']  = value01[key] || _item.value01
            _item['value02']  = value02[key] || _item?.value02 || ''
            _item['operator'] = ''
            _item['targetId'] = _item?.targetId || -1
            return _item
        })


        if (method === '') {
            ShowMessages({status : 500, message : 'Informe o tipo de requisição'})
            return
        }

        if (url === '') {
            ShowMessages({status : 500, message : 'Informe a URL'})
            return
        }

        if (requestType === '') {
            ShowMessages({status : 500, message : 'Informe o corpo da requisição'})
            return
        }

        if (conditionData.length <= 0) {
            ShowMessages({status : 500, message : 'Informe pelo menos um retorno condicional'})
            return
        }

        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node       : parseInt(nodeData.node),
                            parentId   : parseInt(nodeData.parentId), 
                            targetId   : parseInt(nodeData.targetId),
                            returnData,
                            objectType : 10,
                            description,
                            requestType,
                            conditionData : _conditionData,
                            method,
                            url,
                            body
                        }
                    }
                }

                return node;
            })
        )

        handleCloseModal();
    };

    const removeConditions = (id) => {
        const _remove = conditionData.filter((t, i) => {
            if (t.id !== id)
                return true;
        
            return false;
        });
        setConditionData(_remove)
    }

    const testHook = async() => {
        if (url === '') {
            ShowMessages({status : 500, message : 'URL não informada'})
            return
        }

        if (method === '') {
            ShowMessages({status : 500, message : 'Método não informado'})
            return   
        }
        setLoading(true)
        try {
            const apiHook = create({
                baseURL: url, 
                headers: {
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': requestType === 'JSON' ? 'application/json' : '', 
                },
            });

            let response = null
            
            switch (method) {
                case 'GET':
                    response = await apiHook.get(); 
                    break;
                case 'POST':
                    response = await apiHook.post('',requestType === 'JSON' ? JSON.parse(body) : body); 
                    break;
                case 'PUT':
                    response = await apiHook.put('',requestType === 'JSON' ? JSON.parse(body) : body); 
                    break;
                case 'DELETE':
                    response = await apiHook.delete('',requestType === 'JSON' ? JSON.parse(body) : body);
                    break;
                default:
                    break;
            }
            console.log(response)
            if (response?.ok) {
                const data = response.data;
                setReturnData(typeof data === 'object' ? data : [] )
            //  console.log('Dados da API:', data, typeof data);
            } else {
                ShowMessages({status : 500, message : 'Erro ao executar a requisição!'})
                console.error('Erro na requisição:', response?.data || response);
            }
            setLoading(false)
        } catch(e) {
            console.log(e)
            setLoading(false)
        }
    }

    const addConditions = (_field, id) => {
        setConditionData(conditionData => conditionData.concat({
            field : _field,
            id : id + 4,
            if : '',
            value01 : '',
            value02: '',
            operator: ''
        }))
        setConditionContent('')
        setConditionalFor('')
    }

    const handleHideParams = () => {
        setShowParams(false);
    };

    const handleTextParams = (textParams) => {
        const text      = textParams.target.innerText;
        const textParam = text.substring(0, text.indexOf("]") + 1);

        const insertParamsToMessage = body + " " + textParam + " ";
        setBody(insertParamsToMessage);
        handleHideParams();
    };


    return (
        <Modal size="lg" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width1000" animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-satellite-dish"></i> WebHook
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {showParams ? (
                            <div className="box-shadow" style={{padding:"10px"}}>
                                <Parameters
                                    handleHideParams={handleHideParams}
                                    handleTextParams={handleTextParams}
                                ></Parameters>
                            </div>
                        ) : (
                            <>
                            <div className="alert alert-success">
                                <div>
                                    <span>Inorm</span>
                                </div>
                                <div>
                                    
                                </div>
                                <div>
                                    
                                </div>
                            </div>
                            <div style={{display:"flex", gap:"15px"}}>
                                <div className="box-shadow" style={{padding:"0px", width:"350px", height:"500px"}}>
                                    <div style={{background:"#cacaca", display:"flex", justifyContent:"center", padding:"5px"}}>
                                        <span style={{fontWeight:"bold"}}>CONFIGURAÇÕES DA REQUISIÇÃO</span>
                                    </div>
                                    <div style={{padding:"10px"}}>
                                    <div style={{display:"flex", gap:'5px'}}>
                                        <div className="mb-2" style={{width:"100%"}}>
                                            <label>Descrição</label>
                                            <div className="input-group w-100">
                                                <TextInput
                                                    name="description"
                                                    id="description"
                                                    className="form-control w-100"
                                                    maxLength="100"
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    value={description}/>
                                            </div>
                                        </div>
                                        <div className="mb-2" style={{width:"150px"}}>
                                            <label>Tipo</label>
                                            <div className="input-group w-100">
                                                <select
                                                    name="method"
                                                    id="method"
                                                    className="form-select w-100"
                                                    placeholder="Selecione"
                                                    onChange={(e) => setMethod(e.target.value)}
                                                    value={method}>
                                                        <option value="">Selecione</option>
                                                        <option value="GET">GET</option>
                                                        <option value="POST">POST</option>
                                                        <option value="PUT">PUT</option>
                                                        <option value="DELETE">DELETE</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-2">
                                        <label>URL</label>
                                        <div className="input-group w-100">
                                            <TextInput
                                                name="url"
                                                id="url"
                                                className="form-control w-100"
                                                placeholder="Informe a URL que deseja realizar o request"
                                                onChange={(e) => setURL(e.target.value)}
                                                value={url}/>
                                        </div>
                                    </div>

                                    <div style={{display:"flex", gap:"5px"}}>
                                        <div className="mb-2" style={{width:"70%"}}>
                                            <label>Corpo da Requisição</label>
                                            <select
                                                name="method"
                                                id="method"
                                                className="form-select w-100"
                                                placeholder="Selecione"
                                                onChange={(e) => setRequestType(e.target.value)}
                                                value={requestType}>
                                                <option value="">Selecione</option>
                                                <option value="RAW">RAW</option>
                                                <option value="JSON">JSON</option>
                                            </select>
                                        </div>
                                        <div title="Parâmetros" style={{display:"flex", alignItems:"center", marginTop:"9px", width:"175px"}}>
                                            <button type="button" className="btn btn-warning" onClick={e => setShowParams(true)}><i className={`fa fa-icons`}/><span style={{marginLeft:"5px"}}>Parâmetros</span></button>
                                        </div>
                                        <div title="Testar Requisição" style={{display:"flex", alignItems:"center", marginTop:"9px"}}>
                                            <button type="button" disabled={loading} className="btn btn-success" onClick={e => testHook()}><i className={`fa ${loading ? 'fa-spin fa-spinner' : 'fa-arrow-right-arrow-left'}`}/></button>
                                        </div>
                                    </div>

                                    <div className="input-group w-100" style={{marginTop:"5px"}}>
                                        <textarea
                                            name="body"
                                            id="body"
                                            rows={13}
                                            className="form-control"
                                            placeholder='Exemplo do preenchimento do JSON

                                                         {"protocol" : "10203040",
                                                          "cpf" : "00000000000"}'
                                            onChange={(e) => setBody(e.target.value)}
                                            value={body}>
                                        </textarea>
                                    </div>
                                    </div>
                                    
                                </div>
                                <div className="box-shadow" style={{padding:"0px", width:"350px", height:"500px"}}>
                                    <div style={{background:"#cacaca", display:"flex", justifyContent:"center", padding:"5px"}}>
                                        <span style={{fontWeight:"bold"}}>RESULTADO DA REQUISIÇÃO</span>
                                    </div>
                                
                                    <div style={{padding:"10px", gap:"2px", display:"flex", flexDirection:"column",maxHeight:"460px", overflow:"auto"}}>
                                        {returnData && (returnData || []).map((_item) => {
                                            if ((!Array.isArray(_item)) && (typeof _item === 'object')) {
                                            return _item;
                                            }
                                        }).map(_item => {
                                            return Object.keys(_item).map((_json,_key) => {
                                                return (
                                                    <div className="box-webhook" onClick={e => addConditions(_json,_key)}>
                                                        <div className="badge badge-success" style={{display:"flex",alignItems:"center"}}>{_json}</div>
                                                        {_item[_json] && (
                                                            <div className="badge badge-primary" style={{textWrap:"wrap",display:"flex",alignItems:"center"}}>{_item[_json]}</div>
                                                        )}
                                                    </div>
                                                )
                                            })
                                        })} 
                                    </div>
                                </div>
                                <div className="box-shadow" style={{padding:"0px", width:"350px", height:"500px"}}>
                                    <div style={{background:"#cacaca", display:"flex", justifyContent:"center", padding:"5px"}}>
                                        <span style={{fontWeight:"bold"}}>COMPORTAMENTO DA REQUISIÇÃO</span>
                                    </div>
                                    <div style={{maxHeight:"460px", overflow:"auto"}}>
                                        {conditionData.map((_item, key) => {
                                            return (
                                                <>
                                                <div className="box-shadow" style={{margin:"5px"}}>
                                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                                        <div style={{display:"flex", flexDirection:"column"}}>
                                                            <small style={{fontWeight:"bold"}}>Se o campo</small>
                                                            <div className="badge badge-success" style={{display:"flex",alignItems:"center", width:"max-content"}}>
                                                                <span style={{fontSize:"14px"}}>{_item.field}</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button type="button" className="btn btn-sm btn-danger" onClick={e => removeConditions(_item.id)}><i className="fa fa-trash"/></button>
                                                        </div>
                                                    </div>

                                                    <div style={{display:"flex", flexDirection:"column"}}>
                                                        <small style={{fontWeight:"bold"}}>for</small>
                                                        <select className="form-select" value={_for[key] || _item.if} onChange={e => (handleFor(e,key))}>
                                                            <option value="">Selecione</option>
                                                            {forData.map(_item => {
                                                                 return <option value={_item.value}>{_item.label}</option>
                                                            })}
                                                        </select>
                                                    </div> 
                                                    <div>
                                                        <div style={{marginTop:"5px"}}>
                                                            <input type="text" className="form-control" value={value01[key] || _item.value01} onChange={e => handleValue01(e,key)}/> 
                                                        </div>
                                                        {parseInt(_for[key]) === 11 && (
                                                            <>
                                                                <span> e </span>
                                                                <div style={{marginTop:"5px"}}>
                                                                    <input type="text" className="form-control" value={value02[key] || _item.value02} onChange={e => handleValue02(e,key)}/> 
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div style={{display:"flex", justifyContent:"center"}}>
                                                    <select className="form-select" style={{width:"75px"}}>
                                                        <option value="AND">E</option>
                                                        <option value="OR">OU</option>
                                                    </select>
                                                </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            </>
                        )}
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-action-info">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
  );
}
