import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button, } from 'reactstrap';
import Draggable from 'react-draggable';

import { v4 as uuidv4 } from 'uuid';
import MaskWithValidation from '../../components/maskInput';

import { Modal, ModalDialog, Accordion } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'

import api from '../../services/api';

import loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}



export default function ModalGroupLink({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                 = useState(false)
    const [showButtonGroup, setShowButtonGroup] = useState(false)
    const [groupLink, setGroupLink]             = useState('')
    const [number, setNumber]                   = useState('')
    const [group, setGroup]                     = useState('')
    const [groupName, setGroupName]             = useState('')
    const [groups, setGroups]                   = useState([])
    const [phoneData, setPhoneData]             = useState([])
    const [balancegroups, setBalanceGroups]     = useState(true)
    const [creategroupfull, setCreateGroupfull] = useState(false)
    const [SEO, setSEO]                         = useState([])
    const [SEOCompany, setSEOCompany]           = useState(-1)
    const [TAG, setTag]                         = useState('')
    const [link, setLink]                       = useState('')
    const [groupData, setGroupData]             = useState([])
    const [inviteCode, setInvideCode]           = useState('')
    const [hash, setHash]                       = useState('')

    const [whatsappADM, setWhatsappADM]         = useState('')
    const [ADMS, setADMS]                       = useState([])
    const [titleGroup, setTitleGroup]           = useState('')
    const [description, setDescription]         = useState('')
    const [image, setImage]                     = useState('')
    const [participants, setParticipants]       = useState(0)

    const SEOData = [
        {
            name  : 'Selecione',
            value : -1
        },
        {
            name  : 'Meta PIXEL',
            value : 0
        },
        {
            name  : 'Google ADS',
            value : 1
        }
    ]

    const handleGroup = async(item,value) => {
        setGroup(item)
        setGroupName(value)
    }

    const addGroup = async() => {
        if (number === '') {
            ShowMessages({status : 500, message : "Número não informado"})
            return
        }

        if (group === '') {
            ShowMessages({status : 500, message : "Link do grupo não informado"})
            return
        }

        let _data = groups.filter((_item) => {return(_item.group === group)})

        if (_data.length > 0) {
            ShowMessages({status : 500, message : "Grupo já incluido"})
            return
        }

        
        setGroups(groups => groups.concat({number,group,groupName,inviteCode, participants}))

        setGroup('')
        setInvideCode('')
    }

    const removeGroup = (_group) => {
        const _remove = groups.filter((t, i) => {
            if (t.group !== _group)
                return true;
        
            return false;
        });

        setGroups(_remove)
    }

    const editGroup = (_group) => {
        let _edit = groups.filter((t, i) => {
            if (t.group === _group)
                return true;
        
            return false;
        })[0];

        setNumber(_edit.number)
        setGroup(_edit.group)
        setInvideCode(_edit.inviteCode)
        setParticipants(_edit.participants)
        removeGroup(_edit.id)
    }

    const addSEO = async() => {
        if (SEOCompany === -1) {
            ShowMessages({status : 500, message : "SEO não informado"})
            return
        }

        let _data = SEO.filter((_item) => {return(_item.TAG === TAG)})

        if (_data.length > 0) {
            ShowMessages({status : 500, message : "TAG já incluida"})
            return
        }

        setSEO(SEO => SEO.concat({SEOCompany,TAG})) 

        setSEOCompany(-1)
        setTag('')
    }

    const removeSEO = (_tag) => {
        const _remove = SEO.filter((t, i) => {
            if (t.TAG !== _tag)
                return true;
        
            return false;
        });

        setSEO(_remove)
    }

    const editSEO = (_tag) => {
        let _edit = SEO.filter((t, i) => {
            if (t.TAG === _tag)
                return true;
        
            return false;
        })[0];

        setSEOCompany(_edit.SEOCompany)
        setTag(_edit.TAG)
        removeSEO(_edit.TAG)
    }

    const setData = (obj,clear) => {
        let _hash = uuidv4()
        setHash(clear ? _hash : obj.hash)
        setGroupLink(clear ? '' : obj.grouplink)
        setLink(clear ? 'https://groups-55zap.com.br/groups/' + _hash : obj.link)
        setGroups(clear ? [] : obj.groups)
        setBalanceGroups(clear ? true  : obj.balancegroups)
        setCreateGroupfull(clear ? false : obj.creategroupfull)
        setSEO(clear ? [] : obj.seo)
        setADMS(clear ? [] : obj?.adms || [])
        setTitleGroup(clear ? [] : obj.titlegroup)
        setDescription(clear ? '' : obj.description)
        setImage(clear ? '' : obj.image)
    }

    const handleImagemChange = (event) => {
        setImage(event.target.files[0]);
    };

    const convertImageBase64 = async(image) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
    
          reader.onload = (e) => {
            resolve(e.target.result);
          };
    
          reader.onerror = (error) => {
            reject(error);
          };
    
          reader.readAsDataURL(image);
        });
    };

    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-groupLink'),document.querySelector('#submit-groupLink').children[0].classList,document.querySelector('#submit-groupLink').lastChild.nodeValue)
        event.preventDefault()

        try {
            let _image = image

            if ((image !== '') && (image.toString().indexOf('base64') < 0)) 
                _image = await convertImageBase64(image)

            let _data = {groupLink,
                         link,
                         groups,
                         balancegroups,
                         creategroupfull,
                         hash,
                         titleGroup,
                         ADMS,
                         SEO,
                         description,
                         image : _image}
                     
            if (editState)
                await api.put('/grouplink/' + data.idgrouplink, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-groupLink'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-groupLink'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-groupLink'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/grouplink', _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-groupLink'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-groupLink')) 
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-groupLink'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-groupLink'))
            ShowMessages(response)
        }
    }

    const getData = async() => {
        try {
            await api.get('/getAllMyWhatsAppNumbers')
                    .then(response => {
                        setPhoneData(response.data)     
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            ShowMessages(response)
        }
    }

    const copy = () => {
        const inputTemporario = document.createElement('input');
        inputTemporario.value = link;
    
        document.body.appendChild(inputTemporario);
    
        inputTemporario.select();
        inputTemporario.setSelectionRange(0, 99999); 
    
        document.execCommand('copy');
    
        document.body.removeChild(inputTemporario);
    
        ShowMessages({status : 200, message : 'Link copiado com sucesso!'});
    };

    const getGroups = async() => {
        if (number === '') {
            setGroupData([])
            return 
        }

        
        try {
            await api.get('/leads/groups/' + number)
                    .then(response => {
                        setGroupData(response.data.data || []) 
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            console.log(response) 
            ShowMessages(response)
        }
    }

    const getInviteCode = async() => {
        setShowButtonGroup(false)
        if ((number === '') || (group === ''))
            return 

        let _data = {
            number,
            group
        }

        try {
            await api.post('/invitegroupcode',_data)
                    .then(response => {
                        if (response.data.invite.indexOf('undefined') > 0) {
                            console.log(response.data)
                            ShowMessages({status : 500, message : "O grupo informado não permite a geração de links de convite."})
                            return
                        }
                        console.log(response.data)
                        setInvideCode(response.data.invite)
                        setParticipants(response.data.participants)
                        setShowButtonGroup(true)
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            console.log(response) 
            ShowMessages(response)
        }
    }

    const addNumber = async() => {
        if (whatsappADM === '') {
            ShowMessages({status : 500, message : "Número não informado"})
            return
        }

        let _data = ADMS.filter((_item) => {return(_item.number === number)})

        if (_data.length > 0) {
            ShowMessages({status : 500, message : "Número já informado"})
            return
        }

        setADMS(ADMS => ADMS.concat({number : whatsappADM})) 

        setWhatsappADM('')
    }

    const removeNumber = (_number) => {
        const _remove = ADMS.filter((t, i) => {
            if (t.number !== _number)
                return true;
        
            return false;
        });

        setADMS(_remove)
    }

    useEffect(() => {
        setShowButtonGroup(false)
        getData()
        setStatistic('modals\\modal-grouplink')
    },[])

    useEffect(() => {
        setShowButtonGroup(false)
        getGroups()
    },[number])

    useEffect(() => {
        getInviteCode()
    },[group])


    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    return (
      
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} dialogClassName="width700" backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" > 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-link"></i> Propriedades do Link de Distribuição
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth330px"/>
                    )}
                    <div className={`box-shadow${loading ? "hidden" : ""}`}>       
                        <div>
                            <div  style={{display:"flex",gap:"5px"}}>
                                <div style={{width:"100%"}}>
                                    <label>Link</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="link"
                                            id="link"
                                            type="link"
                                            disabled
                                            maxLength={100}
                                            value={link}
                                            className="form-control "
                                            autoFocus
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div style={{display:"flex",alignItems:"end"}}>
                                    <button type="button" className="btn btn-success" onClick={e => copy(e)}><i className="fa fa-copy"/></button>
                                </div>
                            </div>
                            <div>
                                <label>Descrição</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="grouplink"
                                        id="grouplink"
                                        type="grouplink"
                                        required
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe a descrição!" }}
                                        value={groupLink}
                                        className="form-control "
                                        autoFocus
                                        onChange={e => setGroupLink(e.target.value)}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div>
                                <div style={{display:"flex", gap:"5px"}}>
                                    <div style={{width:"250px"}}>
                                        <span>Número</span><label className="text-required">*</label>
                                        <select className="form-select" value={number} onChange={e => setNumber(e.target.value)} >
                                            <option value="">Selecione</option>
                                            {phoneData.map((item) => {
                                                return(<option value={item.number}>{item.phone_format}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div style={{width:"100%"}}>
                                        <span>Grupo</span><label className="text-required">*</label>
                                        <select className="form-select" value={group} onChange={e => handleGroup(e.target.value,e.target.options[e.target.selectedIndex].text)} >
                                            <option value="">Selecione</option>
                                            {groupData.map((item) => {
                                                return(<option value={item.id.user}>{item.name}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div style={{display:"flex",alignItems:"end"}}>
                                        <button type="button" disabled={!showButtonGroup} className="btn btn-success" onClick={e => addGroup(e)}><i className="fa fa-plus"/></button>
                                    </div>
                                </div>
                                <div style={{marginTop:"15px"}}>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className="own-acordion">
                                                <span className="w-100">Grupos</span>
                                            </Accordion.Header>
                                            <Accordion.Body className="p-0" style={{height:"150px", overflow:"auto"}}>
                                                <table className="table table-striped w-100" style={{maxHeight:"150px", overflow:"auto"}}>
                                                    <tbody>
                                                        {groups.map((_actions, id) => {
                                                            return (
                                                                <tr className="gradeX" key={id}>
                                                                    <td style={{width:"100%"}}>
                                                                        {_actions.groupName}
                                                                    </td>
                                                                    <td style={{width:"100%"}}>
                                                                        {_actions.number}
                                                                    </td>
                                                                    <td style={{width:"100%"}}>
                                                                        {_actions.inviteCode}
                                                                    </td>
                                                                    <td>
                                                                        <div style={{display:"flex", gap:"2px"}}>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-danger btn-sm"
                                                                                onClick={(e) =>
                                                                                    removeGroup(_actions.group)
                                                                                }>
                                                                                <i className="fa fa-trash" />
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-warning btn-sm"
                                                                                onClick={(e) =>
                                                                                    editGroup(_actions.group)
                                                                                }>
                                                                                <i className="fa fa-edit" />
                                                                            </button>
                                                                        </div>
                              
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div style={{marginTop:"10px", display:"flex", gap:"15px"}}>
                                <div style={{width:"100%"}}>
                                    <div style={{display:"flex", gap:"5px"}}>
                                        <div style={{width:"50%"}}>
                                            <span>SEO</span><label className="text-required">*</label>
                                            <select className="form-select" value={SEOCompany} onChange={e => setSEOCompany(parseInt(e.target.value))} >
                                                <option value="">Selecione</option>
                                                {SEOData.map((item) => {
                                                    return(<option value={item.value}>{item.name}</option>)
                                                })}
                                            </select>
                                        </div>

                                        <div style={{width:"50%px"}}>
                                            <label>TAG</label>
                                            <div className="input-group">
                                                <TextInput  
                                                    name="TAG"
                                                    id="TAG"
                                                    type="TAG"
                                                    maxLength={250}
                                                    value={TAG}
                                                    className="form-control "
                                                    onChange={e => setTag(e.target.value)}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div> 

                                        <div style={{display:"flex",alignItems:"end"}}>
                                            <button type="button" className="btn btn-success" onClick={e => addSEO(e)}><i className="fa fa-plus"/></button>
                                        </div>
                                    </div>
                                    <div style={{marginTop:"15px"}}>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="own-acordion">
                                                    <span className="w-100">SEO</span>
                                                </Accordion.Header>
                                                <Accordion.Body className="p-0" style={{height:"150px", overflow:"auto"}}>
                                                    <table className="table table-striped w-100" style={{maxHeight:"150px", overflow:"auto"}}>
                                                        <tbody>
                                                            {SEO.map((_actions, id) => {
                                                                return (
                                                                    <tr className="gradeX" key={id}>
                                                                        <td style={{width:"100%"}}>
                                                                            {SEOData.filter(_item => _item.value === _actions.SEOCompany)[0].name}
                                                                        </td>
                                                                        <td style={{width:"100%"}}>
                                                                            {_actions.TAG}
                                                                        </td>
                                                                        <td>
                                                                            <div style={{display:"flex", gap:"2px"}}>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-danger btn-sm"
                                                                                    onClick={(e) =>
                                                                                        removeSEO(_actions.TAG)
                                                                                    }>
                                                                                    <i className="fa fa-trash" />
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-warning btn-sm"
                                                                                    onClick={(e) =>
                                                                                        editSEO(_actions.TAG)
                                                                                    }>
                                                                                    <i className="fa fa-edit" />
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                                {creategroupfull && (
                                    <div style={{borderLeft:"1px dashed #cacaca", paddingLeft:"15px",width:"90%"}}>
                                        <div style={{width:"100%"}}>
                                            <div>
                                                <label>Nome do grupo a ser criado</label>
                                                <TextInput  
                                                    name="titleGroup"
                                                    id="grouplink"
                                                    type="titleGroup"
                                                    required
                                                    maxLength={100}
                                                    errorMessage={{ required: "Por favor, informe o nome do grupo!" }}
                                                    value={titleGroup}
                                                    className="form-control "
                                                    autoFocus
                                                    onChange={e => setTitleGroup(e.target.value)}
                                                    autoComplete="off"
                                                />
                                            </div>                                            
                                            <div style={{width:"100%"}}>
                                                <label>Descrição do Grupo</label>
                                                <textarea
                                                    name="description"
                                                    id="description"
                                                    maxLength={250}
                                                    className="form-control "
                                                    value={description}
                                                    onChange={e => setDescription(e.target.value)}
                                                    autoComplete="off"/>
                                            </div>
                                            <div style={{width:"100%"}}>
                                                <label>Imagem do Grupo</label>
                                                <input
                                                    name="image"
                                                    id="image"
                                                    type="file"
                                                    accept="image/png, image/gif, image/jpeg"
                                                    className="form-control "
                                                    onChange={handleImagemChange}
                                                    autoComplete="off"/>
                                            </div>
                                        </div>
                                        <div style={{width:"100%", display:"flex", gap:"5px", marginTop:"15px"}}>
                                            <div style={{width:"100%"}}>
                                                <label>WhatsApp Administradores</label>
                                                <MaskWithValidation
                                                    name="whatsapp"
                                                    id="whatsapp"
                                                    type="text"
                                                    className="form-control "
                                                    value={whatsappADM}
                                                    onChange={e => setWhatsappADM(e.target.value)}
                                                    autoComplete="off"
                                                    mask={['(',/[0-9]/,/[0-9]/,')',' ',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/] }/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"end"}}>
                                                <button type="button" className='btn btn-success' onClick={e => addNumber()}><i className='fa fa-plus'/></button>
                                            </div>
                                        </div>
                                        <div style={{width:"100%", marginTop:"5px"}}>
                                            <table className="table table-striped w-100">
                                                <thead className="thead-theme">
                                                    <tr className='head-th'>
                                                        <td>Números</td>
                                                        <td style={{textAlign:"center"}}>...</td>
                                                    </tr>
                                                </thead>  
                                                <tbody>                        
                                                    {ADMS.map((_actions,id) => {
                                                        return(<tr className="gradeX" key={id}>
                                                                    <td style={{verticalAlign: "middle"}}>{_actions.number}</td>
                                                                        <td style={{textAlign:"center",verticalAlign: "middle"}}>
                                                                            <button type='button' className="btn btn-danger btn-sm" onClick={e => removeNumber(_actions.number)}><i className="fa fa-trash"/></button>
                                                                        </td>
                                                               </tr>)
                                                        }) }
                                                </tbody> 
                                            </table>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div style={{marginTop:"15px"}}>
                                <div style={{display:"flex",gap:"5px"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={balancegroups} 
                                                               value={balancegroups} 
                                                               onClick={e => setBalanceGroups(!balancegroups)} />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <div style={{display:'flex', alignItems:"center"}}>
                                        <span>Fazer o balanceamento de usuários nos grupos?</span>
                                    </div>
                                </div>
                                <div style={{display:"flex",gap:"5px"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={creategroupfull} 
                                                               value={creategroupfull} 
                                                               onClick={e => setCreateGroupfull(!creategroupfull)} />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <div style={{display:'flex', alignItems:"center"}}>
                                        <span>Criar um novo grupo ao encher os informados?</span>
                                    </div>
                                </div>
                            </div>
                        </div>        
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-groupLink' className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}