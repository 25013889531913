import React, { useState, useEffect } from 'react';
import ContentWrapper from '../layouts/contentWrapper';
import Loading from '../../components/Loading';
import Grid from '../../components/Grid';
import Filter from '../../components/Filter';
import { Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ModalAfiliateRegistration from '../modals/modal-afiliate-registration';
import ModalAfiliateWithdrawal from '../modals/modal-afiliate-withdrawal';
import api from '../../services/api'
import ShowMessages from './../../components/Exceptions'

const initialState = {
  banco: '',
  agencia: '',
  conta: '',
  kindofperson: '',
  cnpj: '',
  cpf: '',
  name: '',
  pix: '',
};

export default function MyAfiliates() {
    const [loading, setLoading]                                             = useState(false);
    const [gridParams, setGridParams]                                       = useState({});
    const [filters, setFilters]                                             = useState([]);
    const [isOpenFilter, setIsOpenFilter]                                   = useState(false);
    const [reload, setReload]                                               = useState(false);
    const [print, setPrint]                                                 = useState(false);
    const [excel, setExcel]                                                 = useState(false);
    const [showButtons, setShowButtons]                                     = useState(false);
    const [showModalAfiliateRegistration, setShowModalAfiliateRegistration] = useState(false);
    const [showModalAfiliateWithdrawal, setShowModalAfiliateWithdrawal]     = useState(false);
    const [formErrors, setFormErrors]                                       = useState(initialState);
    const [recurrence, setRecurrence]                                       = useState(0)

    useEffect(() => {
        getAfiliates();
        getTotalize()
    }, []); 

    const getTotalize = async () => {
        await api.post('/affiliate/totalize')
                 .then(async(response) => { 
                    setRecurrence(response.data?.recurrence || 0)
                 }).catch(error => {
                     ShowMessages(error)
                 }) 
    }

    const getAfiliates = async () => {
        try {
            setGridParams({ idcompany: { text: 'id',
                                         primaryKey: true ,
                                         mask : { name  : 'leftSlice',
                                                  value : '00000'}},
                            fantasy : {  text: 'Nome | Razão Social',
                                         className: 'text-bold'},
                            value   : {  text : 'Valor do Ticket',
                                         dataType : 'money',
                                         className: 'text-right',
                                         item: { className: 'text-right' }},
                            create_at: { text: 'Data do Cadastro',
                                         format: 'dd/MM/yyyy HH:mm:ss',
                                         className: 'text-center',
                                         item: { className: 'text-center' }},
                            expiration: { text: 'Vencimento',
                                         format: 'dd/MM/yyyy',
                                         className: 'text-center',
                                         item: { className: 'text-center' }},
                            status: { text: 'Status Atual',
                                         className: 'text-center',
                                         item: { className: 'text-center' }},
                            Options: {
                                text: 'Opções',
                                className: 'text-center',
                                order: false,
                                filter: false,
                                item: [{type: 'Custom',
                                        props: {tag: 'button',
                                                title: 'Nota Fiscal',
                                                className: 'btn btn-sm btn-warning',
                                                icon: 'fa fa-file-invoice',
                                                indexcallback: 0 }}],
                            }
            });
        } catch (error) {
            console.error('Error fetching affiliates:', error);
        }
    };

    const handleFilter = () => {
        setIsOpenFilter(!isOpenFilter);
    };

    const toggleModalAfiliateRegistration = () => {
        setShowModalAfiliateRegistration(!showModalAfiliateRegistration);
        setFormErrors(initialState);
    };


    return (
        <ContentWrapper>
            {loading && <Loading loading={loading} />}
            <ModalAfiliateRegistration  showModalAfiliateRegistration={showModalAfiliateRegistration}   toggleModalAfiliateRegistration={toggleModalAfiliateRegistration}/>
            <ModalAfiliateWithdrawal showModal={showModalAfiliateWithdrawal} handleCloseModal={setShowModalAfiliateWithdrawal}/>

            <div className="content-heading">
                <div className="d-flex flex-row" style={{ marginBottom: '1.25rem' }}>
                    <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                    <button className="btn btn-info" title="Cadastro de Conta Recebimento" style={{ marginLeft: '3px' }} onClick={() => setShowModalAfiliateRegistration(true)}>
                        <i className="fa-solid fa-address-card"></i>
                    </button>
                    <button className="btn btn-success" title="Solicitação de Saque" style={{ marginLeft: '3px' }} onClick={() => setShowModalAfiliateWithdrawal(true)}>
                        <i className="fa-solid fa-money-bill-transfer"></i>
                    </button>
                </div>

                <div style={{ display: 'flex', flexWrap: 'wrap', width: 100 + '%' }}>
                    <Col xl={4} md={4}>
                        <div className="card flex-row p-2 align-items-center align-items-stretch border-0">
                            <div className="col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left" style={{borderBottomLeftRadius: '5px', borderTopLeftRadius: '5px'}}>
                                <em className="fa fa-file-invoice fa-2x"></em>
                            </div>
                            <div className="col-8 py-1  alert alert-primary rounded-bottom-right alert-55" style={{ borderBottomRightRadius: '5px', borderTopRightRadius: '5px'}}>
                                <div className="h3 mt-0 text-center">R$ {recurrence}</div>
                                <div className="h4 text-uppercase text-center">Total Recorrente</div>
                            </div>
                        </div>
                    </Col>

                    <Col xl={4} md={4}>
                        <div className="card flex-row p-2 align-items-center align-items-stretch border-0">
                            <div className={`col-4 d-flex align-items-center bg-success-dark justify-content-center rounded-left`} style={{ borderBottomLeftRadius: '5px', borderTopLeftRadius: '5px' }}>
                                <em className="fa fa-receipt fa-2x"></em>
                            </div>
                            <div className={`col-8 py-1 alert alert-success rounded-right alert-55`} style={{borderBottomRightRadius: '5px', borderTopRightRadius: '5px' }}>
                                <div className="h3 mt-0 text-center">R$ 0,00</div>
                                <div className="h4 text-uppercase text-center">Saldo Disponível</div>
                            </div>
                        </div>
                    </Col>

                    <Col xl={4} md={4}>
                        <div className="card flex-row p-2 align-items-center align-items-stretch border-0">
                            <div className={`col-4 d-flex align-items-center bg-warning-dark justify-content-center rounded-left`} style={{ borderBottomLeftRadius: '5px', borderTopLeftRadius: '5px' }}>
                                <em className="fa fa-receipt fa-2x"></em>
                            </div>
                        <div className={`col-8 py-1 alert alert-warning rounded-right alert-55`} style={{borderBottomRightRadius: '5px', borderTopRightRadius: '5px'}}>
                            <div className="h3 mt-0 text-center">R$ 0,00</div>
                                <div className="h4 text-uppercase text-center">Em Processamento</div>
                            </div>
                        </div>
                    </Col>
                </div>
            </div>
      <Filter isOpenFilter={isOpenFilter}filters={gridParams} callback={setFilters}  callBackOpenFilter={handleFilter}/>
      <Grid
        url="/affiliate"
        headers={gridParams}
        loading={setLoading}
        reload={reload}
        print={print}
        excel={excel}
        filters={filters}
        callbackShowButtons={setShowButtons}
        callbackPrint={setPrint}
        callbackReload={setReload}
        callbackExcel={setExcel}
        filename="Afiliados"
        callbackButtons={[]}
      />
    </ContentWrapper>
  );
}
