import { memo, useCallback, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import Waveform from "../../waveformer";
import ModalAudioNode from "./../../../pages/modals/workflow/modal-audio-node";


const AudioNode = (props) => {
    const reactFlowInstance                     = useReactFlow();
    const [showModal, setShowModal]             = useState(false);
    const [audio, setAudio]                     = useState(null)

    const [editData, setEditData]               = useState(false)
    const [destroyInstance, setDestroyInstance] = useState(false);
    const [pauseAudio, setPauseAudio]           = useState(false);
    const [description, setDescription]         = useState('')
    const [id, setID]                           = useState(-1)
    const [statistic, setStatistic]             = useState(0)
    const [timeToAction, setTimeToAction]       = useState(0)

    const constas = useCallback(() => {
        setDestroyInstance(true);
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);   


    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node            : _id,
                    parentId        : -1, 
                    targetId        : -1,
                    description     : props.data.nodeInfo.description,
                    audio           : props.data.nodeInfo.audio,
                    timeToAction    : props.data.nodeInfo.timeToAction,
                    objectType      : 3
                }
            }
        }))
    }

    const handleShowModal = (e) => {
        setEditData(audio !== null)
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setPauseAudio(true);
    };

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            setAudio(nodeInfo?.audio || null)
            setDescription(nodeInfo?.description || '')
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
            setTimeToAction(nodeInfo.timeToAction || 0)
        };
        handleNodeProps();
    }, [props.data]);

    if (showModal) {
        return (
            <>
                <ModalAudioNode
                    nodeId={props.id}
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    waveFormInstance={`waveform-${props.id}`}
                    nodeData={props.data.nodeInfo}
                    editData={editData}
                ></ModalAudioNode>
            </>
        );
    }

    return (
        <div>
            <div className="rfnode rfnode-audio d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) => handleShowModal(e)}>
                <div className="rfnode-title">#{id} Mensagem de Áudio</div>

                <div style={{padding:"8px 5px", width:"90%"}}>
                    {description !== '' &&(
                        <div style={{padding:"5px", fontSize:"8px", fontWeight:"bold", borderRadius:"5px", border:"1px dashed #cacaca", display:"flex", marginBottom:"5px"}}>
                            {description}
                        </div>
                    )}
                    <div className="message-container" style={{ minHeight: "30px", justifyContent:'center', alignItems:"center", display:'flex' }}>
                        {audio !== null ? (
                            <Waveform
                                waveFormInstance={`waveform-${props.id}`}
                                url={audio}
                                destroyInstance={destroyInstance}
                                pauseAudio={pauseAudio}
                                isModal={false}
                            ></Waveform>
                        ) : (
                            <div style={{ display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                <div style={{display:"flex", justifyContent:"center", color:"#0d6efd"}}>
                                    <i className="fa fa-microphone"/>
                                </div>
                                <span style={{fontSize:"6px", color:"#0d6efd"}}>
                                    Aqui você adiciona um audio a ser enviado!
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                {timeToAction > 0 && (
                    <div style={{display:"flex",gap:"2px", alignItems:"center", color:'#0d6efd', marginBottom:"5px"}}>
                        <i className="fa fa-microphone" style={{fontSize:"7px"}}/> 
                        <span style={{fontSize:"7px"}}>Gravando por {timeToAction} segundos</span>
                    </div>
                )}
                <div style={{display:"flex", gap:2, fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #0d6efd"}}>
                    <div style={{display:"flex", gap:"5px"}}>
                        <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                                <i className="fa fa-copy text-primary"></i>
                        </div>
                        <div onClick={() => constas()} title="Excluir Mensagem" className="workflow-delete" >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    </div>
                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{fontSize:"10px", color:"#0d6efd"}}>{statistic || ''}</span>
                    </div>
                </div>
            </div>

            <Handle type="source" position={Position.Bottom} id='node-audio1' />
            <Handle type="target" position={Position.Top} id='node-audio2' />
            <Handle type="target" position={Position.Left} id='node-audio3' />
            <Handle type="source" position={Position.Right} id='node-audio4' />
        </div>
    );
};

export default memo(AudioNode);
