import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';
import Swal from 'sweetalert2'
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalFindGroups({showModal, handleCloseModal}) {
    const [loading, setLoading]       = useState(false)
    const [dataNumber, setDataNumber] = useState([])
    const [whatsapp, setWhatsapp]     = useState('')
    const [search, setSearch]         = useState('')
    const [groups, setGroups]         = useState([])

    const [checked, setChecked]       = useState([])

    const handleChecked = (id) => {
        setChecked(checked.map(_item => {
            if (Object.keys(_item)[0] ==='input_' + id)
                _item['input_' + id] = !_item['input_' + id]
       
            return _item
        }))
    }

    const checkAndUncheckAll = (value) => {
        setChecked(checked.map(_item => {
            _item[Object.keys(_item)[0]] = value
       
            return _item
        }))
    }

    const handleSubmit = async(event) => {
        event.preventDefault()

        let _check = checked.filter(_item => {
            return Object.values(_item)[0]
        })


        if (_check.length <= 0) {
            ShowMessages({status : 500, message : "Você precisa selecionar pelo menus um grupo para entrar"})
            return
        }

        loadingButton(true,document.querySelector('#submit-groups'),document.querySelector('#submit-groups').children[0].classList,document.querySelector('#submit-groups').lastChild.nodeValue)

        try {
            
            Swal.fire({
                title: 'Confirmação',
                text: "Deseja realmente entrar nos grupos selecionados?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
              }).then(async(result) => {
                if (result.isConfirmed) {
                    await api.post('/waiting-groups',{whatsapp,checked : JSON.stringify(checked)})
                             .then(() => {  
                                    ShowMessages({status : 200, message : 'Você irá entrar nos grupos automaticamente ao decorrer do tempo!'})
                                    loadingButton(false,document.querySelector('#submit-groups'))
                                    handleCloseModal()
                            }).catch(error => {
                                loadingButton(false,document.querySelector('#submit-groups'))
                                ShowMessages(error)
                            })  
                } else 
                    loadingButton(false,document.querySelector('#submit-groups'))
              })

            
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-groups'))
            ShowMessages(response)
        }
    }

    const getData = async() => {
        setWhatsapp('')
        setSearch('')
        setGroups([])
        setChecked([])

        await api.get('/getAllMyWhatsAppNumbers')
                 .then(response => {
                      if (response.ok) {   
                          setDataNumber(response.data)
                      }
                })
                 .catch( error => {
                     ShowMessages(error) 
                })

        
    }

    const handleSearch = async(value) => {
        setSearch(value)
        if (value.length < 3) {
            setGroups([])
            return 
        }

        setLoading(true)
        await api.post('/admin/searchGroups', {search})
                 .then(response => {
                      if (response.ok) {   
                          setGroups(response.data) 
                          let _aux = []
                          response.data.map(_item => {
                            let _json = {}
                            _json['input_' + _item.idwhatsappgrouplist] = false
                            _aux.push(_json)
                          })
                          setChecked(_aux)
                          setLoading(false)
                      }
                })
                 .catch( error => {
                     ShowMessages(error)
                     setLoading(false) 
                })
    }

    const getCheck = (id) => {
        let _item = checked.filter(_item => {
            return Object.keys(_item)[0] === 'input_' + id
        })[0]
        
        return Object.values(_item || {})[0] || false
    }

    useEffect(() => {
        getData()
    },[showModal])
   
    useEffect(() => {
        setStatistic('modals\\admin\\ModalFindGroups')
    } ,[])

    useEffect(() => {
        let _item = checked.filter(_item => {
            return Object.keys(_item)[0] === 'input_2883'
        })[0]
        
        console.log(Object.values(_item || {})[0])
    },[checked])
    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-people-group"></i> Pesquisa de Grupos
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div>  
                        <div>
                            <label>Número</label>
                            <div>
                                <SelectGroup 
                                    name="idparent" 
                                    id="idparent" 
                                    placeholder="Selecione" 
                                    value={whatsapp} 
                                    required
                                    errorMessage={{ required: "Por favor, informe um Numero!"}}
                                    className="form-select"
                                    onChange={e => setWhatsapp(e.target.value)}>
                                    <option  value=''>Selecione</option>
                                    {dataNumber.map((data, id) => {
                                        return (<option key={id} value={data.number}>{data.phone_format} - {data.description}</option>)
                                                    })} 
                                </SelectGroup>   
                            </div>
                        </div>
                        <div style={{marginTop:"15px"}}>
                            <div style={{display:"flex"}}>
                                <div style={{width:"280px"}}>
                                    <label>Digite para pesquisar o grupo</label>
                                    <input  
                                        name="search"
                                        id="search"
                                        type="text"
                                        value={search}
                                        maxLength={100}
                                        className="form-control "
                                        onChange={e => handleSearch(e.target.value)}
                                        autoComplete="off"/>   
                                </div>
                                <div style={{marginLeft:"5px"}}>
                                    <button type="button" className="btn btn-success" title="Selecionar todos" style={{marginTop:"17px"}} onClick={e => checkAndUncheckAll(true)}><i className="fa fa-check"></i></button>
                                    <button type="button" className="btn btn-warning" title="Cancelar seleção de todos" style={{marginLeft:"5px",marginTop:"17px"}} onClick={e => checkAndUncheckAll(false)}><i className="fa fa-ban"></i></button>
                                </div>
                            </div>

                            <div style={{marginTop:"5px", maxHeight:"300px", overflowY:"auto"}} >
                                <table className={`table table-striped w-100 `}>
                                    <thead className="thead-theme">
                                        <tr className="head-th">
                                            <td style={{width:"40px", minWidth:"40px", maxWidth:"40px", textAlign:"center"}} className="head-th">...</td>
                                            <td style={{width:"225px", minWidth:"175px", maxWidth:"175px"}} className="head-th">Descrição</td>
                                            <td style={{textAlign:"center", width:"80px"}}>
                                                ...
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {groups.map((item, key) => {
                                            return (
                                                <tr className="head-th" key={key}>
                                                    <td>
                                                        <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                        <input type="checkbox" checked={getCheck(item.idwhatsappgrouplist)} value={getCheck(item.idwhatsappgrouplist)} onClick={e => handleChecked(item.idwhatsappgrouplist)} />
                                                        <span style={{ marginTop: "5px" }}></span>
                                                    </label>
                                                    </td>
                                                    <td>{item.description}</td>
                                                              
                                                    <td style={{textAlign:"center"}}>
                                                        <button type="button" className="btn btn-danger btn-sm" ><em className="fa fa-trash"></em></button>
                                                        <button type="button" className="btn btn-warning  btn-sm" style={{marginLeft:"5px"}}><em className="fa fa-edit"></em></button>
                                                    </td>
                                                </tr>
                                            )
                                        })}      
                                     </tbody>
                                </table>
                                <div className={`box-sem-dados alert alert-warning ${groups.length > 0 ? "hidden" : ""}`}>Nenhum grupo encontrado!</div>
                            </div>
                            

                        </div>
                          
                                                        
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-groups" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}