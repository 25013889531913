import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";

import { getFields } from "./../../../components/Utils";

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalInputNode({nodeId, showModal,  handleCloseModal,nodeData,editData}) {
    const [tagName, setTagName]       = useState('')
    const [tagLink, setTagLink]       = useState('')
    const [commandEnd, setCommandEnd] = useState('')
    const [validation, setValidation] = useState('')

    const [fields, setFields]                   = useState([])

    const reactFlowInstance = useReactFlow();

    useEffect(() => {
        setTagName(nodeData.tagName);
        setTagLink(nodeData.tagLink);
        setCommandEnd(nodeData.commandEnd)
        setValidation(nodeData.validation)
        setFields(getFields())
    }, [nodeData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        let _last = tagName[tagName.length - 1];

        if ((tagName.toString().indexOf('[') !== 0) || (_last !== ']')){
            ShowMessages({status : 500, message : "A Tag precisa estar dentro de []"})
            return
        }

        

        if ((tagLink !== '') && (commandEnd !== '')) {
            ShowMessages({status : 500, message : "Você não pode usar um comando de finalização, usando uma TagLink!"})
            return
        }

        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                 if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node       : parseInt(nodeData.node),
                            parentId   : parseInt(nodeData.parentId), 
                            targetId   : parseInt(nodeData.targetId),
                            tagName,
                            tagLink,
                            validation,
                            commandEnd,
                            objectType : 7
                        },
                    };
                }

          return node;
        })
      );

        handleCloseModal();
    };

    return (
        <Modal size="md" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width500" animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-keyboard"></i> Captura de Dados
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <div className="box-shadow" style={{padding:"10px"}}>
                        <div className="mb-3">
                            <div style={{display:"flex", gap:"15px"}}>
                                <div style={{width:"50%"}}title="Ex: se você solicita o nome do usuário, você informa a tag como NOME, se solicitar a cidade, você informa a tag como CIDADE. O nome da tag e livre e pode escolher o de sua preferência">
                                    <label>Tag</label> <i className="fa fa-circle-question text-primary"/>
                                    <TextInput
                                        name="tag"
                                        id="tag"
                                        type="text"
                                        className="form-control"
                                        maxLength={25}
                                        required
                                        value={tagName}
                                        onChange={e => setTagName(e.target.value)}
                                        errorMessage={{required: "Informe uma tag" }}
                                    />
                                </div>
                                <div style={{width:"50%"}} title="Abaixo estão os campos fixos disponíveis no sistema, caso queria gravar no nome do lead, selecione a TAG [NOME], se você quiser gravar uma TAG que não existe nas opção, deixe o campo sem preencher.">
                                    <label>Tag Link</label> <i className="fa fa-circle-question text-primary"/>
                                    <select className="form-select" name="tag" id="tag" value={tagLink}  onChange={e => setTagLink(e.target.value)}>
                                        {
                                            
                                            fields.map(_item => {
                                                return (
                                                    <option value={_item.value}>{_item.label}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div style={{display:"flex",gap:"15px"}}>
                                <div title="Com esse comando informado, a captura so será encerrada e os dados capturados ao digitar o comando configurado." style={{width:"50%"}} >
                                    <label>Comando de Finalização</label> <i className="fa fa-circle-question text-primary"/>
                                    <TextInput
                                        name="tag"
                                        id="tag"
                                        type="text"
                                        value={commandEnd}
                                        className="form-control"
                                        onChange={e => setCommandEnd(e.target.value)}
                                    />
                                </div>
                                <div style={{width:"50%"}} title="Se configurado, o fluxo só continua se a informação enviada passar ela regra de validação.">
                                    <label>Validar Informação</label> <i className="fa fa-circle-question text-primary"/>
                                    <select className="form-select" name="validation" id="validation" value={validation}  onChange={e => setValidation(e.target.value)}>
                                        <option value="">Não Validar</option>
                                        <option value="CPF">Validar CPF</option>
                                        <option value="CNPJ">Validar CNPJ</option>
                                        <option value="EMAIL">Validar Email</option>
                                        <option value="DATA">Validar Data</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="alert alert-warning">
                                <div>
                                    <span style={{fontWeight:"bold"}}>SEMPRE</span>, anterior a esse evento, você deve incluir um <span style={{fontWeight:"bold"}}>EVENTO DE AÇÃO</span> contendo a ação <span style={{fontWeight:"bold"}}>AGUARDAR O USUÁRIO RESPONDER</span>.
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-modal-file">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    );
}
