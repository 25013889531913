import React, {useState, useEffect} from 'react'

import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';
import loadingButton  from '../layouts/Buttons'
import { SketchPicker } from 'react-color';
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalMyNumberSettings({showModal, handleCloseModal, data, handleReload}) {
    const [rejectcall, setRejectCall]       = useState(false)
    const [messagereject,setMessageReject]  = useState('')
    const [loading, setLoading]             = useState(false)
    const [numbercolor, setNumberColor]     = useState('')

    const handleColor = (color,event) => {
        if (color !== undefined)
            setNumberColor(color?.hex || '')
    }

    const setData = (obj,clear) => {
        setRejectCall(clear ? false : obj.rejectcall) 
        setMessageReject(clear ? '' : obj.messagereject)
        setNumberColor(clear ? '' : obj?.numbercolor || '')
    }

    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-mynumber-settings'),document.querySelector('#submit-mynumber-settings').children[0].classList,document.querySelector('#submit-mynumber-settings').lastChild.nodeValue)
        event.preventDefault()

        try {
            let _data = {rejectcall,
                         messagereject,
                         numbercolor}

            await api.put('/mynumbers/' + data.idnumber, _data)
                     .then(response => {
                        ShowMessages(response)
                        if (response.ok) {
                            if (response.data.status === 200) {
                                handleCloseModal()  
                                handleReload(true)    
                            } else 
                                loadingButton(false,document.querySelector('#submit-mynumber-settings'))
                        } else 
                            loadingButton(false,document.querySelector('#submit-mynumber-settings'))
                    })
                    .catch( error => {
                        loadingButton(false,document.querySelector('#submit-mynumber-settings'))
                        ShowMessages(error) 
                    })

        } catch (response) {
            loadingButton(false,document.querySelector('#submit-mynumber-settings'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        setData(data,false)
    },[data,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-mynumber-settings')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} dialogClassName="width500" animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm  onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-cogs"></i> Configurações
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div className='box-shadow' style={{padding:"10px", display:"flex", gap:"5px", flexDirection:"column"}}>
                        <div>
                            <label>Cor de destaque do número no atendimento</label>
                            <SketchPicker
                                width={240}
                                color={numbercolor }
                                onChangeComplete={ handleColor }
                            />
                        </div>
                        <div style={{display:"flex"}}>
                            <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                <input type="checkbox" checked={rejectcall} 
                                                       value={rejectcall} 
                                                       onClick={e => setRejectCall(!rejectcall)} />
                                <span style={{ marginTop: "5px" }}></span>
                            </label>
                            <span style={{marginLeft:"5px", marginTop:"5px"}}>Rejeitar chamadas de voz e video?</span>
                        </div>
                        <div className="mb-2" >
                            <label>Mensagem a ser enviada ao receber uma ligação</label>
                            <div className="input-group w-100">
                                <textarea
                                    name="rejectCall"
                                    id="rejectCall"
                                    rows={6}
                                    disabled={!rejectcall}
                                    className="form-control"
                                    onChange={(e) => setMessageReject(e.target.value)}
                                    value={messagereject}>
                                </textarea>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-mynumber-settings' className={`btn-theme ${loading ? "hidden" : ""}`} >  
                        <em className="fa fa-save"></em> Salvar
                    </Button>
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}