import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import Grid from '../../components/Grid';
import setStatistic from '../../services/apiStatistic'
import ShowMessages from '../../components/Exceptions'
import api from '../../services/api'

import { format }   from 'date-fns'

export default function ReportSend() {
    const [loading, setLoading]                            = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [filters, setFilters]                            = useState([{ field      : 'create_at',
                                                                         key        : 'between',  
                                                                         value      : format(new Date(),'yyyy-MM-dd'),
                                                                         value2     : format(new Date(),'yyyy-MM-dd'),
                                                                         ignoretime : true}])

    const [startDate, setStartDate]                        = useState(format(new Date(),'yyyy-MM-dd'))
    const [endDate, setEndDate]                            = useState(format(new Date(),'yyyy-MM-dd'))
    const [statusFilter,setStatusFilter]                   = useState('')

    const [dataStatus, setDataStatus]                      = useState([])

    const handleSearch = async() => {
        let _filters = [{ field      : 'create_at',
                          key        : 'between',  
                          value      : startDate,
                          value2     : endDate,
                          ignoretime : true    
        }]

        if (statusFilter !== '') {
            _filters.push({
                field      : 'idstatusmessage',
                key        : '=',  
                value      : statusFilter,
                value2     : null
            })
        } 

        setFilters(_filters)
    }

    const getReportSend = async() => {
        await api.get('/statusmessage/0')
                 .then(response => {
                    setDataStatus(response.data)
                    setGridParams({idmessagehistory : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                                    value : '00000'},
                                                       primaryKey  : true,
                                                       visible     : false },
                                   number           : {text        : "Remetente"},
                                   to               : {text        : "Destinatário",
                                                       searchDefault : true},
                                   campaign         : {text        : "Campanha"},
                                   statusmessages   : {text        : "Status",
                                                       item        : {className : "text-center",
                                                       switch      : {data  : response.data,
                                                                      key   : 'idstatusmessage'}}},
                                   hasmedia         : {text        : "Arquivo?",
                                                       dataType    : "boolean",
                                                       item        : {className : "text-center"}},
                                   body             : {text        : "Mensagem",
                                                       props       : {maxLength :100,
                                                                     fieldTitle : true}},
                                   create_at        : {text        : "Enviado em",
                                                       dataType    : "date",
                                                       table       : 'message_histories',
                                                       ignoretime  : true},
                                   read_at          : {text        : "Lido em",
                                                       dataType    : "date",
                                                       ignoretime  : true},
                                   played_at        : {text        : "Escutado em",
                                                       dataType    : "date",
                                                       ignoretime  : true}                                             
                    })
                 })
                 .catch( error => { 
                      ShowMessages(error)
                 })

    }

    useEffect(() => {
        getReportSend()
        setStatistic('maintenance\\report-send')
    },[])
    
    const handleFilter     = () => {setIsOpenFilter(!isOpenFilter)}

    return (    
        <ContentWrapper>
            {loading && (
                <Loading loading={loading}/>
            )}
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                            <button className="btn btn-info"  title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                            <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                        </div>
                    </div>
                    <div style={{display:"flex",marginLeft:"30px"}}>
                        <div style={{ display:"flex", flexDirection:"column", borderLeft:"1px dashed #cacaca", paddingLeft:"10px"}}>
                            <label>Período</label>
                            <div style={{ display:"flex"}}>
                                <input type="date" className="form-select" value={startDate} onChange={e => setStartDate(e.target.value)}/>
                                <label style={{marginLeft:"15px", marginTop:"10px"}}>até</label> 
                                <input style={{marginLeft:"15px"}} type="date" className="form-select" value={endDate} onChange={e => setEndDate(e.target.value)}/>
                            </div>
                        </div>
                        <div style={{ display:"flex", flexDirection:"column", marginLeft:"15px"}}>
                            <label>Status</label>
                            <select className="form-select" value={statusFilter} onChange={e => setStatusFilter(parseInt(e.target.value))}>
                                <option value="">Todos</option>
                                {dataStatus.map(item => {
                                    return (<option value={item.idstatusmessage}>{item.statusmessages}</option>)  
                                })}
                            </select>
                        </div>
                        <div style={{marginLeft:"5px", display:"flex", alignItems:"center"}}>
                            <button className="btn btn-success" title="Clique aqui para filtrar" style={{marginTop:"12px"}} onClick={e => handleSearch()}><i className="fa fa-filter"/></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/report-send' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      filename            = "Relatorio de Envios"
                      callbackExcel       = {setExcel}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackButtons     = {[]}
                      />  
            </div>                    
        </ContentWrapper>
    )
}