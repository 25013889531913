import { memo, useCallback, useEffect, useState } from "react";
import { Handle,  Position, useReactFlow } from "reactflow";
import ModalIANode from "../../../pages/modals/workflow/modal-IA-node";

const IANodeItem = ({ handleId, value, index }) => {
    return (
        <div className="custom-node__options" style={{minWidth:"150px"}} title={value}>
          <li className="list-item d-flex align-items-center" style={{minWidth:"150px"}}>
            {index === undefined && (
                <div className="list-marker me-1">
                <i className="fa-solid fa-circle-check"></i>
                </div>
            )}
  
            <div style={{textOverflow: "ellipsis", color:"#2473e0", maxWidth:"150px",whiteSpace: "nowrap",overflow: "hidden" }} >{(index !== undefined) && (<><span style={{fontWeight:"bold"}}>{index}</span> <span>-</span></>)} {value}</div>
          </li>
          <Handle type="source" position={Position.Right} id={`node-IA${handleId}`} style={{marginTop:"50px"}}/>
        </div>
      );
};

const IANode = (props) => {
    const reactFlowInstance               = useReactFlow();
    const [showModal, setShowModal]       = useState(false);
    const [prompt, setPrompt]             = useState("");
    const [maxtoken, setMaxToken]         = useState('')
    const [idnumber,setIdNumber]          = useState('')
    const [idmodel, setIdModel]           = useState('')
    const [agent, setAgent]               = useState('')
    const [model, setModel]               = useState('')
    const [id, setID]                     = useState(-1)
    const [statistic, setStatistic]       = useState(0)

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            setPrompt(nodeInfo.prompt || '');
            setID(parseInt(nodeInfo.node))
            setMaxToken(parseInt(nodeInfo.maxtoken))
            setIdNumber(nodeInfo.node)
            setIdModel(nodeInfo.idmodel)
            setAgent(nodeInfo.agent)
            setModel(nodeInfo.model)
            setStatistic(nodeInfo.statistic)
        };
        handleNodeProps();
    }, [props.data]);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node                  : _id,
                    parentId              : -1, 
                    targetId              : -1,
                    prompt                : props.data.nodeInfo.prompt,
                    maxtoken              : props.data.nodeInfo.maxtoken,
                    idnumber              : props.data.nodeInfo.idnumber,
                    idmodel               : props.data.nodeInfo.idmodel,
                    agent                 : props.data.nodeInfo.agent,
                    model                 : props.data.nodeInfo.model,
                    closeInteraction      : props.data.nodeInfo.closeInteraction,
                    objectType            : 18,
                                        
                }
            }
        }))
    }

    const deleteNode = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    return (
        <>
            <ModalIANode
                nodeId={props.id}
                nodeData={props.data.nodeInfo}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
            ></ModalIANode>

            <div className="rfnode rfnode-ia d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow"  onClick={(e) => setShowModal(true)} style={{minWidth:"150px", maxWidth:"250px"}}>
                <div className="rfnode-title">#{id} Inteligência Artificial</div>

                <div style={{background: "rgba(13, 110, 253, 0.1098039216)", margin:"5px 10px", padding:"5px", borderRadius:"5px", color:"#0d6efd", width:"85%", textAlign:"center",maxHeight:"300px", overflowX:"auto"}}>
                    {prompt === '' ? (
                        <div style={{ display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <i className="fa fa-robot"/>
                            </div>
                            <span style={{fontSize:"6px"}}>Adicione informações de treinamento da IA</span>
                        </div>
                    ) : (
                        <div>
                            <div style={{display:"flex",flexDirection:"column", alignItems:"flex-start", width:"100%", fontSize:"8px"}}>
                                <span style={{fontWeight:"bold"}}>Agente</span>
                                <span>{agent}</span>
                            </div>
                            <div style={{display:"flex",flexDirection:"column", alignItems:"flex-start", width:"100%", fontSize:"8px"}}>
                                <span style={{fontWeight:"bold"}}>Modelo</span>
                                <span>{model}</span>
                            </div>
                            <div style={{display:"flex",flexDirection:"column", alignItems:"flex-start", width:"100%", fontSize:"8px"}}>
                                <span style={{fontWeight:"bold"}}>Máximo de Token</span>
                                <span>{maxtoken}</span>
                            </div>
                            <div style={{marginTop:"10px"}}>
                                <pre style={{fontSize:"6px",textAlign:"left", textWrap:"wrap", overflow:"hidden"}}>{prompt}</pre>  
                            </div>
                            <div style={{marginTop:"15px"}}>
                                <div style={{ background: "#2473e07c", borderRadius: "5px", padding: "5px", display: "flex", height: "27px", marginTop: "2px", fontSize: "8px", alignItems: "center", marginBottom:"5px" }}>
                                    <IANodeItem value="Ao finalizar a Inteligência Artificial" handleId={4}/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div style={{display:"flex", fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #0d6efd"}}>
                    <div style={{display:"flex", gap:"5px"}}>
                        <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                            <i className="fa fa-copy text-primary"></i>
                        </div>
                        <div onClick={() => deleteNode()} title="Excluir Mensagem" className="workflow-delete" >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    </div>
                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{fontSize:"10px", color:"#6f42c1"}}>{statistic || ''}</span>
                    </div>
                </div>
                
            </div>

            <Handle type="target" position={Position.Bottom} id='node-IA1'  />
            <Handle type="target" position={Position.Top} id='node-IA2' />
            <Handle type="target" position={Position.Left} id='node-IA3' />
        </>
  );
};

export default memo(IANode);
