import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";
import Parameters from "../../../components/parameters";
import api from '../../../services/api';

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalEmailNode({nodeId, showModal,  handleCloseModal,nodeData,editData}) {
    const [message, setMessage]       = useState('')
    const [to, setTo]                 = useState('')
    const [from, setFrom]             = useState('')
    const [fromText, setFromText]     = useState('')
    const [cc, setCC]                 = useState('')
    const [cco, setCCO]               = useState('')           
    const [subject, setSubject]       = useState('')

    const [dataFrom, setDataFrom]     = useState([])
    const [showParams,setShowParams]  = useState(false)

    const reactFlowInstance = useReactFlow();

    const handleHideParams = () => {
        setShowParams(false);
    };

    const handleTextParams = (textParams) => {
        const text      = textParams.target.innerText;
        const textParam = text.substring(0, text.indexOf("]") + 1);

        const insertParamsToMessage = message + " " + textParam + " ";
        setMessage(insertParamsToMessage);
        handleHideParams();
    };

    useEffect(() => {
        const getData = async() => {
            try {
                await api.get('/getAllMyEmails')
                        .then(response => {
                            console.log(response.data)
                            setDataFrom(response.data)   
                            setMessage(nodeData.message || '');
                            setTo(nodeData.to || '');
                            setFrom(nodeData.from || '');
                            setFromText(nodeData.fromText || '');
                            setSubject(nodeData.subject || '');
                            setCC(nodeData.cc || '');
                            setCCO(nodeData.cco || '');  
                        })
                        .catch( error => {      
                            ShowMessages(error) 
                        })
            } catch (response) { 
                ShowMessages(response)
            }
        }
        getData()

    }, [nodeData]);

    const handleFrom = (index,text) => {
        setFrom(index)
        setFromText(text)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (from === '') {
            ShowMessages({status : 500, message : "Informe qual é o remetente."})
            return
        }
        if ((to === '') && (cc === '') && (cco === '')) {
            ShowMessages({status : 500, message : "Pelo menos um dos campos Destinatário, CC ou CCO devem estar preenchidos."})
            return
        }

        if (message === '') {
            ShowMessages({status : 500, message : "Informe o conteúdo do email"})
            return
        }

        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                 if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node       : parseInt(nodeData.node),
                            parentId   : parseInt(nodeData.parentId), 
                            targetId   : parseInt(nodeData.targetId),
                            message,
                            to,
                            from,
                            fromText,
                            subject,
                            cc,
                            cco,
                            objectType : 16
                        },
                    };
                }

          return node;
        })
      );

        handleCloseModal();
    };

    return (
        <Modal size="md" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width500" animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-envelope"></i> Email
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box-shadow" style={{padding:"10px"}}>
                        {showParams ? (
                            <div className="box-shadow" style={{padding:"10px"}}>
                                <Parameters
                                    handleHideParams={handleHideParams}
                                    handleTextParams={handleTextParams}
                                ></Parameters>
                            </div>
                        ) : (
                            <>
                                <div>
                                    <div>
                                        <span>Email do Remetente</span>
                                        <select className="form-select" value={from} onChange={e => handleFrom(e.target.value,e.target.options[e.target.selectedIndex].text)}>
                                            <option value="">Selecione</option>
                                            {dataFrom.map((_item, key) => {
                                                return <option key={key}value={_item.idnumber}>{_item.number}</option> 
                                            })}
                                        </select>
                                    </div>
                                    <div style={{borderTop:"1px dashed #cacaca", marginTop:"5px"}}>
                                        <div>
                                            <span>Destinatário</span>
                                            <input className="form-control" value={to} onChange={e => setTo(e.target.value)}/>
                                        </div>
                                        <div>
                                            <span>CC</span>
                                            <input className="form-control" value={cc} onChange={e => setCC(e.target.value)}/>
                                        </div>
                                        <div>
                                            <span>CCO</span>
                                            <input className="form-control" value={cco} onChange={e => setCCO(e.target.value)}/>
                                        </div>
                                        <div>
                                            <span>Assunto</span>
                                            <input className="form-control" value={subject} onChange={e => setSubject(e.target.value)}/>
                                        </div>
                                        <div>
                                            <span>Mensagem</span>
                                            <textarea rows={8} className="form-control" value={message} onChange={e => setMessage(e.target.value)}/>
                                        </div>
                                    </div>
                                </div> 
                                <div className="d-flex" style={{justifyContent:"space-between", marginTop:"10px"}}>
                                    <button type="button" className={`btn btn-warning`} onClick={(e) => setShowParams(true)} >
                                        <i className="fa fa-icons" style={{ fontSize: "12px" }} />{" "}
                                        <span style={{ fontSize: "12px" }}>Parâmetros</span>
                                    </button>
                                </div>
                            </>
                        )}   

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-modal-email">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    );
}
