import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import { ValidationForm} from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";
import { convertToBase64 } from "./../../../components/Utils";
import Waveform from "../../../components/waveformer";
import Dropzone from "../../../components/dropzone";
import { AudioRecorder } from 'react-audio-voice-recorder';

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalAudioNode({ nodeId, showModal, handleCloseModal, waveFormInstance, nodeData}) {
    const [audio, setAudio]                     = useState('');
    const [description, setDescription]         = useState('')
    const [isRecording, setIsRecording]         = useState(false)
    const [timeToAction, setTimeToAction]       = useState(0)
    const reactFlowInstance                     = useReactFlow();

    const handleAudio = async(files) => {
        if (files[0] && files[0].size > 20 * 1024 * 1024) {
            ShowMessages({status : 500, message : "O arquivo não pode ser maior que 20Mb"})
            return
        }

        if (files[0]) {
            setAudio(await convertToBase64(files[0]))
        }
    };

    useEffect(() => {
        setAudio(nodeData?.audio || '');
        setDescription(nodeData.description)
        setTimeToAction(nodeData.timeToAction || 0)
    }, [nodeData]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (audio === null) {
            ShowMessages({
                status: 500,
                message: "Não há um arquivo de audio adicionado!",
            });
            return;
        }

        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node       : parseInt(nodeData.node),
                            parentId   : parseInt(nodeData.parentId), 
                            targetId   : parseInt(nodeData.targetId),
                            timeToAction,
                            objectType : 3,
                            description,
                            audio
                        },
                    };
                }

                return node;
            })
        );

        handleCloseModal();
    };

    const handleCaptureAudio = async (a) => {
        if (a !== undefined) {
            setAudio(await convertToBase64(a))
        }
    }
    
    const setLayoutMic = () => {
        let _main = document.querySelector('.audio-recorder')
        let _save = document.querySelector('.audio-recorder-mic ')
        let _img  = document.querySelectorAll('.audio-recorder img')

        _main.style.backgroundColor = 'transparent'

        if (!document.querySelector('.audio-recorder i')) {
            const _iRecord = document.createElement('i');
            const _iPause  = document.createElement('i');
            const _iStop   = document.createElement('i');

            _iRecord.addEventListener('click', function(event) {
                _img[0].click()
                _main.style.backgroundColor = '#EBEBEB'
                _iRecord.style.display      = 'none'
                _iPause.style.display       = 'block'
                _iStop.style.display        = 'block'
                setIsRecording(true)
            });

            _iPause.addEventListener('click', function(event) {
                _img[1].click()
                _main.style.backgroundColor = '#EBEBEB'

                if (_iPause.classList.length > 0) {
                    if (_iPause.classList[1] === 'fa-circle-pause') {
                        _iPause.classList.remove('fa-circle-pause')
                        _iPause.classList.add('fa-circle-play')
                    } else {
                        _iPause.classList.remove('fa-circle-play')
                        _iPause.classList.add('fa-circle-pause')
                    }
                }
            });

            _iStop.addEventListener('click', function(event) {
                _img[2].click()
                _main.style.backgroundColor = '#EBEBEB'
                _iStop.style.display        = 'none'
                _iPause.style.display       = 'none'
                _iRecord.style.display      = 'block'
                _main.style.backgroundColor = 'transparent'
                _save.click()
                setIsRecording(false)
            });

            _iRecord.className          = 'fa fa-microphone'
            _iRecord.style.fontSize     = '20px'
            _iRecord.style.color        = '#858585'
            _iRecord.style.padding      = '12px 15px'
            _iRecord.style.border       = '1px solid #cacaca'
            _iRecord.style.borderRadius = '50%'
            _iRecord.title              = 'Gravar Áudio'

            _iPause.className       = 'fa fa-circle-pause'
            _iPause.style.fontSize  = '24px'
            _iPause.style.color     = '#858585'
            _iPause.style.display   = 'none'
            _iPause.style.padding   = '5px'
            _iPause.title           = 'Pausar Gravação'

            _iStop.className      = 'fa fa-circle-stop'
            _iStop.style.fontSize = '24px'
            _iStop.style.color    = '#858585'
            _iStop.style.display  = 'none'
            _iStop.style.padding  = '5px'
            _iStop.title          = 'Finalizar Gravação'

            _main.appendChild(_iRecord)
            _main.appendChild(_iPause)
            _main.appendChild(_iStop)
            
        }

        if (_img.length > 0) {
            _img[0].style.display = 'none'
            _img[1].style.display = 'none'
            _img[2].style.display = 'none'
        }
    }

    useEffect(() => {
        if (audio === '')
            setLayoutMic()
    },[audio])

    useEffect(() => {
        setLayoutMic()
    },[])

    return (
        <Modal size="md" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width450"  animation={true}  backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-microphone"></i> Mensagem de Áudio
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box-shadow" style={{padding:"10px"}}>
                        <div>
                            <label>Descrição de Identificação</label><small style={{color:"red", marginLeft:"10px"}}> (Texto não enviado no arquivo)</small>
                            <input type="text" className="form-control" value={description} onChange={e => setDescription(e.target.value)}/>
                        </div>
                            {audio !== '' ? (
                                <div>
                                    <div className={`w-100 ${audio ? "border" : ""}`}  style={{borderRadius:"5px", marginTop:"15px", padding:"10px", display:"flex"}}>
                                        <Waveform
                                            url={audio}
                                            waveFormInstance={waveFormInstance}
                                            isModal={true}
                                        ></Waveform>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                            <button type="button" onClick={e =>setAudio('')} className="btn btn-danger"><i className="fa fa-trash"/></button>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px", marginTop: "15px" }}>
                                        <span>Enviar o status de gravando por</span>
                                            <div style={{ width: "100px" }}>
                                                <input type="number" className="form-control" min={0} max={120} value={timeToAction} onChange={e => setTimeToAction(e.target.value)} />
                                                <small style={{color:"red"}}>0 não envia o status</small>
                                            </div>
                                        <span>segundos</span>
                                    </div>
                                </div>
                            ) : (                     
                                <div style={{marginTop:"15px", border:"1px dashed #cacaca", borderRadius:"5px", padding:"5px"}}>
                                    <div style={{marginTop:"-15px", marginLeft:"5px"}}>
                                        <label style={{background:"#fff"}}>Áudio</label>
                                    </div>
                                    <div className="mb-2">
                                        <Dropzone onChange={handleAudio} multiple={false} accept= {{'audio/*': ['.ogg','.mp3','.wav']}} />
                                    </div>
                                    <div style={{display:"flex", justifyContent:`center`, padding:"10px"}}>
                                        <AudioRecorder
                                            onRecordingComplete={handleCaptureAudio}
                                            audioTrackConstraints={{
                                                noiseSuppression: true,
                                                echoCancellation: true,
                                            }}
                                            onNotAllowedOrFound={(err) => {
                                                ShowMessages({status : 500, message : "Microfone não encontrado"})
                                                if ((document.querySelectorAll('.fa.fa-circle-stop').length > 0) && (document.querySelectorAll('.fa.fa-circle-stop')[0].style.display !== 'none')) {
                                                    document.querySelectorAll('.fa.fa-circle-stop')[0].click()
                                                    setIsRecording(false)
                                                }
                                            }}
                                            downloadOnSavePress={false}
                                            downloadFileExtension="mp3"
                                            mediaRecorderOptions={{
                                                audioBitsPerSecond: 128000,
                                            }}
                                            showVisualizer={true}
                                        />  
                                    </div>
                                </div>
                            )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-audio-info">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
    </Modal>
  );
}
