import React, { useEffect, useState } from 'react'
import { IDCOMPANY, ReadDataStorage} from './../../services/auth'
import ShowMessages from './../../components/Exceptions'
import api from '../../services/api'
import Loading from '../../components/Loading';

export default function Attendance({ children }) { 
    const [settings, setSettings] = useState({})

    useEffect(() => {        
        const getSettings = async () => {
            await api.get('/company/' + ReadDataStorage(IDCOMPANY))
                     .then(async(response) => {
                        setSettings(response.data.settings)
                     }).catch((error) => {
                        ShowMessages(error)
                     })  
        }
        
        getSettings()
    }, [])

    if (Object.keys(settings).length > 0) {
        if (settings.allowSystemAttendance)
            return (
                <>
                    {children}
                </>
            )
        else 
            return (
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"80vh"}}>
                    <div className='alert alert-warning box-shadow' style={{width:"550px", height:"300px", display:"flex", padding:"25px", gap:"15px"}}>
                        <div style={{display:"flex", alignItems:"center"}}>
                            <i className='fa fa-warning' style={{fontSize:"120px", color:"#262728"}}/>
                        </div>
                        <div style={{display:"flex",gap:"15px", flexDirection:"column"}}>                            
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <p style={{fontSize:"18px", color:"#262728"}}>A opção de atendimento não está habilitada.</p>
                                <p style={{fontSize:"18px", color:"#262728"}}>Click no botão abaixo para acessar as configurações, e ative a opção</p>
                                <p style={{fontSize:"18px", color:"#262728",fontWeight:"bold"}}>"DESEJA UTILIZAR O SISTEMA DE ATENDIMENTO?"</p>
                            </div>
                            <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                                <button type='button' className='btn btn-primary' onClick={e => window.addTab(e,'Configurações','fa fa-cogs','/settings',{},1,true)}><i className='fa fa-cogs'/> Configurações</button>
                            </div>
                        </div>
                       
                    </div>
                </div>
            )    
    } else {
        return (
            <Loading loading={true} /> 
        )
    }

}
