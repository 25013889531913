import { openDB, deleteDB  } from 'idb';

export async function dbPromise(idcompany) { 
    return openDB('55Zap_' + idcompany, 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains('55Zap_'+ idcompany)) {
                db.createObjectStore('55Zap_'+ idcompany);
            }
        },
    });
}

export async function dbDelete(idcompany) {
    try {
        const db = await dbPromise(idcompany)
        db.close()
        await deleteDB('55Zap_' + idcompany);
    }catch(e){
        console.log(e)
    }
}

export async function setDataDB(key, value, updateAction, idcompany) {
    if (key === '')
        return
    const db = await dbPromise(idcompany);
    const transaction = db.transaction('55Zap_'+ idcompany, 'readwrite');
    const store = transaction.objectStore('55Zap_'+ idcompany);
    await store.put( value,key);
    if (updateAction)
        updateAction(await store.get(key))
}

export async function getDataDB(key,idcompany) {

    const db = await dbPromise(idcompany);
    const transaction = db.transaction('55Zap_'+ idcompany, 'readonly');
    const store = transaction.objectStore('55Zap_'+ idcompany);
    return await store.get(key);
}

export async function lastID(key,idcompany) {
    const db = await dbPromise(idcompany);
    const transaction = db.transaction('55Zap_'+ idcompany, 'readonly');
    const store = transaction.objectStore('55Zap_'+ idcompany);
    return await store.get(key + '_ID');
}

export async function setID(key, value, idcompany) {
    if (key === '')
        return
    const db = await dbPromise(idcompany);
    const transaction = db.transaction('55Zap_'+ idcompany, 'readwrite');
    const store = transaction.objectStore('55Zap_'+ idcompany);
    await store.put( value,key + '_ID');
}
 
export async function deleteData(key,idcompany) {
    const db = await dbPromise(idcompany);
    const transaction = db.transaction('55Zap_'+ idcompany, 'readwrite');
    const store = transaction.objectStore('55Zap_'+ idcompany);
    await store.delete(key);
}