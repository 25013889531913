import Cookies from 'universal-cookie';

export const setCookieMessage = (cookieName, cookieValue) =>{
    const cookies = new Cookies();
    cookies.set(cookieName, cookieValue, { path: '/' });
}

export const getCookieMessage = (cookieName, cookieDefault) =>{
    const cookies = new Cookies();
    return cookies.get(cookieName) ? cookies.get(cookieName) : cookieDefault;
}


