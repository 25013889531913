import React, { useState, useEffect } from 'react'
import ContentWrapper from '../../layouts/contentWrapper'
import api from '../../../services/api'
import ShowMessages from '../../../components/Exceptions'
import setStatistic from '../../../services/apiStatistic'
import Loading from '../../../components/Loading';

export default function DashboardDatabase() {
    const [loading, setLoading]                 = useState(false)

    const [totaldatabase, setTotalDatabase]     = useState(false)
    const [totaluser, setTotalUser]             = useState(false)
    const [tables, setTables]                   = useState(false)
    const [size, setSize]                       = useState(false)
    const [tablesize, setTableSize]             = useState([])

    const getData = async() => {
        setLoading(true)
        await api.get('/admin/dashboard-database')
                 .then(response => {
                    let data = response.data
                    setTotalDatabase(data.totaldatabase)
                    setTotalUser(data.totaluser)
                    setTables(data.tables)
                    setSize(data.size)
                    setTableSize(data.tablesize)
                    setLoading(false)
                 })
                 .catch((error) => {
                    ShowMessages(error)
                 })
    }

    useEffect(() => {
        getData()
        setStatistic('maintenance\\admin\\dashboard-banco-de-dados')
    },[])

    
    return (    
        <ContentWrapper>
            {loading && (
                <Loading loading={loading} />
            )}
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`} style={{padding:"5px", display:"flex", flexDirection:"column"}}>
                <div style={{display:"flex"}}>
                    <div style={{width:"100%"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0" >
                            <div className="col-4 d-flex align-items-center bg bg-primary-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-server fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 alert alert-primary rounded-right" style={{borderTopRightRadius:"7px", borderBottomRightRadius:"7px", borderBottomLeftRadius:"0px",borderTopLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h2 mt-0" style={{textAlign:"right", paddingRight:"10px"}}>{totaldatabase}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Total Banco de Dados</div>
                            </div>
                        </div>
                    </div> 
                    <div style={{width:"100%", marginLeft:"5px"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0">
                            <div className="col-4 d-flex align-items-center bg bg-success-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-users fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 alert alert-success rounded-right" style={{borderTopRightRadius:"7px", borderBottomRightRadius:"7px", borderBottomLeftRadius:"0px",borderTopLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h2 mt-0" style={{textAlign:"right", paddingRight:"10px"}}>{totaluser}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Total de Usuários</div>
                            </div>
                        </div>
                    </div>  

                    <div style={{width:"100%", marginLeft:"5px"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0">
                            <div className="col-4 d-flex align-items-center bg bg-danger-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-table fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 alert alert-danger rounded-right" style={{borderTopRightRadius:"7px", borderBottomRightRadius:"7px", borderBottomLeftRadius:"0px",borderTopLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h2 mt-0" style={{textAlign:"right", paddingRight:"10px"}}>{tables}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Tabelas 55Zap</div>
                            </div>
                        </div>
                    </div>   
                    <div style={{width:"100%", marginLeft:"5px"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0">
                            <div className="col-4 d-flex align-items-center bg bg-warning-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-database fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 alert alert-warning rounded-right" style={{borderTopRightRadius:"7px", borderBottomRightRadius:"7px", borderBottomLeftRadius:"0px",borderTopLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h2 mt-0" style={{textAlign:"right", paddingRight:"10px"}}>{size}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Tamanho 55Zap</div>
                            </div>
                        </div>
                    </div>   
                </div>
    
                <div style={{display:"flex", marginTop:"15px"}}>
                    <div className="box-shadow col-3" style={{padding:"5px"}}>
                        <div style={{padding:"5px", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}><i className="fa fa-table"></i> Maiores Tabelas</span>
                        </div>
                        <table className='table table-striped w-100' style={{height:'10px', marginTop:"5px"}}>
                            <thead className="thead-light">
                                <tr className='head-th'>
                                    <th style={{width:"80%"}}>Tabela</th>
                                    <th style={{width:"20%"}}>Tamanho</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tablesize?.map(_item => {
                                    return(
                                        <tr className="gradeX">
                                            <td>{_item.relation}</td>
                                            <td className="text-center">{_item.total_size}</td>
                                        </tr>)
                                    })}
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>                    
        </ContentWrapper>
    )
}