import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";
import api from '../../../services/api'

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalMessageNode({
    nodeId,
    showModal,
    handleCloseModal,
    nodeData,
}) {
    const [prompt, setPrompt] = useState("");
    const [idnumber, setIDNumber] = useState(0)
    const [agent, setAgent] = useState('')
    const [model, setModel] = useState('')
    const [idmodel, setIDModel] = useState(0)
    const [maxtoken, setMaxToken] = useState(0)

    const [iaModelData, setIAModelData] = useState([])
    const [iaAgentData, setIAAgentData] = useState([])
    const [closeInteraction, setCloseInteration] = useState(30)
    
    const reactFlowInstance = useReactFlow();

    useEffect(() => {
        setPrompt(nodeData.prompt);
        setIDModel(nodeData.idmodel)
        setIDNumber(nodeData.idnumber || 0)
        setAgent(nodeData.agent || 0)
        setModel(nodeData.model || 0)

    }, [nodeData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (idnumber === 0) {
            ShowMessages({ status: 500, message: "Campo agente está vazio!" });
            return;
        }

        if (idmodel === 0) {
            ShowMessages({ status: 500, message: "Campo modelo está vazio!" });
            return;
        }

        if (prompt === "") {
            ShowMessages({ status: 500, message: "Campo o roteiro de aprendizado está vazio!" });
            return;
        }

        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node: parseInt(nodeData.node),
                            parentId: parseInt(nodeData.parentId),
                            targetId: parseInt(nodeData.targetId),
                            objectType: 18,
                            closeInteraction,
                            maxtoken,
                            prompt,
                            idnumber,
                            idmodel,
                            agent,
                            model
                        },
                    };
                }

                return node;
            })
        );

        handleCloseModal();
    };

    useEffect(() => {
        const getData = async () => {
            await api.get('/getDataIA')
                .then((response) => {
                    setIAModelData(response.data.IAModel)
                    setIAAgentData(response.data.IAAgent)
                }).catch(error => {
                    ShowMessages(error)
                })
        }
        getData()
    }, [])



    return (
        <Modal
            size="lg"
            show={showModal}
            onSubmit={handleSubmit}
            onHide={handleCloseModal}
            dialogClassName="maxHeight450 width500"
            animation={true}
            backdrop="static"
            dialogAs={DraggableModalDialog}
        >
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-robot"></i> Inteligência Artificial
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box-shadow" style={{ padding: "10px" }}>
                        <div style={{ display: "flex", width: "100%", gap: "5px" }}>
                            <div style={{ width: "300px" }}>
                                <label>Agente</label>
                                <select className="form-select" value={idnumber} onChange={e => {
                                    setIDNumber(e.target.value)
                                    setAgent(e.target.options[e.target.selectedIndex].text)
                                }
                                }>
                                    <option>Selecione</option>
                                    {iaAgentData.map((_item) => {
                                        return <option value={_item.idnumber}>{_item.description}</option>
                                    })}
                                </select>
                            </div>
                            <div style={{ width: "300px" }}>
                                <label>Modelo</label>
                                <select className="form-select" value={idmodel} onChange={e => {
                                    setIDModel(e.target.value)
                                    setModel(e.target.options[e.target.selectedIndex].text)
                                }} >
                                    <option>Selecione</option>
                                    {iaModelData.map((_item) => {
                                        if (parseInt(_item.idiaagent) === parseInt(1))
                                            return <option value={_item.idiamodel}>{_item.iamodel}</option>
                                    })}
                                </select>
                            </div>
                            <div style={{ width: "135px" }}>
                                <label>Máximo de Tokens</label>
                                <input className="form-control" value={maxtoken} onChange={e => setMaxToken(e.target.value)} type="number" />
                            </div>
                        </div>
                        <div className="my-2">
                            <label>Informe o roteiro de aprendizado que o seu agende irá se basear para responder seus contatos.</label>
                            <div className="input-group">
                                <TextInput
                                    multiline={true}
                                    rows="15"
                                    cols="60"
                                    name="prompt"
                                    id="prompt"
                                    type="text"
                                    required
                                    autoFocus={true}
                                    maxLength={1500}
                                    errorMessage={{
                                        required: "Por favor, informe o roteiro de aprendizado!",
                                    }}
                                    value={prompt}
                                    className="form-control "
                                    onChange={(e) => setPrompt(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div>
                            <div style={{ display: "flex", gap: "5px", alignItems: "center", marginTop: "15px" }}>
                                <span>Continuar o fluxo após</span>
                                <div style={{ width: "80px" }}>
                                    <input value={closeInteraction} onChange={e => setCloseInteration(e.target.value)} className="form-control" type="number" />
                                </div>

                                <span> minutos(s) sem interação</span>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-ia">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button
                        type="button"
                        className="btn-ligth-gray"
                        onClick={handleCloseModal}
                    >
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    );
}
