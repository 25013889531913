import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';

import { TagsInput } from "react-tag-input-component";

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import loadingButton  from '../layouts/Buttons'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalImportInstagram({showModal, handleCloseModal}) {
    const [user, setUser]                         = useState('')
    const [password, setPassword]                 = useState('')
    const [perfil, setPerfil]                     = useState('')

    const [withoutnumber,setWithoutNumber]        = useState(true)
    const [withoutcategory, setWithoutCategory]   = useState(true)
    const [analyzefollowers, setAnalyzeFollowers] = useState(true)
    const [analyzefollowing, setAnalyzeFollowing] = useState(true)
    const [analyzehashtag, setAnalyzeHashTag]     = useState(true)

    const [hashtag, setHashTag]                   = useState([])
    const [perfils, setPerfils]                   = useState([])
    const [tags, setTags]                         = useState([])
    const [selectInstagram, setSelectInstagram]   = useState([])

    const [dataTags, setDataTags]                 = useState([])
    const [tag, setTag]                           = useState('')

    const addValidationHashTag = (_tag) => {
        if (_tag.trim() === '')
            return false
        _tag = _tag.toString().toUpperCase()
        return !hashtag.includes(_tag)
    }

    const handleHashTagInput = (value) => { 
        value = value.map(function(x){ return x.toString().toUpperCase().replace(/[^a-zA-Z0-9_]/g,''); })     
        
        if (value === '')
            return false  
        
        if (hashtag.length !== value.length)
            setHashTag(value)
    }

    const handleTagInput = (value) => { 
        value = value.map(function(x){ return x.toString().toUpperCase().replace(/[^a-zA-Z0-9_]/g,''); })     
        
        if (value === '')
            return false
        
        if (tags.length !== value.length)
            setTags(value)
    }

    const addPerfil = (e) => {
        e.preventDefault()
        if ((perfil) && (perfil !== '')) {
            if (!perfils.find(el => el.perfil === perfil)) 
                setPerfils(perfils => perfils.concat({perfil})) 
            else
               ShowMessages({status : 500, message : "Perfil já cadastrado"})
            setPerfil('')
        } else 
            ShowMessages({status : 500, message : "Perfil não informado"})
        
    }

    const removePerfil = (_perfil) => {
        const _remove = perfils.filter((t, i) => {
            if (t.perfil !== _perfil)
                return true;
        
            return false;
        });
        setPerfils(_remove)
    }

    const addInstagram = (e) => {
        e.preventDefault()
        if ((user) && (user !== '') && (password) && (password !== '')) {
            if (!selectInstagram.find(el => el.user === user)) 
                setSelectInstagram(selectInstagram => selectInstagram.concat({user, password})) 
            else
                ShowMessages({status : 500, message : "Usuário já cadastrado"})

            setUser('') 
            setPassword('') 
        } else {
            if ((!user) || (user === '')) {
                ShowMessages({status : 500, message : 'Usuário não informado'})
                return
            }

            if ((!password) || (password === '')) {
                ShowMessages({status : 500, message : 'Senha não informada'})
                return
            }
        }
 
    }

    const removeInstagram = (_user) => {
        const _remove = selectInstagram.filter((t, i) => {
            if (t.user !== _user)
                return true;
        
            return false;
        });
        setSelectInstagram(_remove)
    }

    const addValidationTag = (_tag) => {
        if (_tag.trim() === '')
            return false
        _tag = _tag.toString().toUpperCase()
        return !tags.includes(_tag)
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-import'),document.querySelector('#submit-import').children[0].classList,document.querySelector('#submit-import').lastChild.nodeValue)
        try {

            const formData = new FormData();
            let _tags = tags.map(name => name.toUpperCase());
            
            formData.append('withoutnumber',    withoutnumber    ? 1 : 0);
            formData.append('withoutcategory',  withoutcategory  ? 1 : 0);
            formData.append('analyzefollowers', analyzefollowers ? 1 : 0);
            formData.append('analyzefollowing', analyzefollowing ? 1 : 0);
            formData.append('analyzehashtag',   analyzehashtag   ? 1 : 0);
            formData.append('hashtag', hashtag);
            formData.append('selectInstagram', selectInstagram);
            formData.append('perfils', perfils);
            formData.append('tags', _tags);
            
            await api.post('/api/v1/importInstagram', formData, {headers: { "Content-Type": "multipart/form-data"}})
                      .then(response => {
                         ShowMessages(response)
                         if (response.ok) {   
          
                         }
                         loadingButton(false,document.querySelector('#submit-import'),'fa fa-save','Importar')        
                       })
                       .catch( error => { 
                           console.log(error)   
                           loadingButton(false,document.querySelector('#submit-import'),'fa fa-save','Importar')    
                           ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-import'),'fa fa-save','Importar')    
            ShowMessages(response)
        }
    }
    
    const getData = async() => {
        try {
            await api.get('/getDataCampaing')
                    .then(response => {
                        setDataTags(response.data.tags)    
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            console.log(response) 
            ShowMessages(response)
        }
    }
 
    useEffect(() => {
        getData()
        setStatistic('modals\\modal-import-instagram')
    } ,[])

    useEffect(() => {

    } ,[tags])

    return (
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogClassName="width500" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fab fa-instagram"></i> Propriedades de Importação do Instagram
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div>         
                        <div style={{display:"flex"}}> 
                            <div style={{width:"250px"}}>     
                                <div style={{display:"flex"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={withoutnumber} value={withoutnumber} onClick={e => setWithoutNumber(!withoutnumber)}  />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Importar contatos sem número?</span>
                                </div>
                                <div style={{display:"flex"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={withoutcategory} value={withoutcategory} onClick={e => setWithoutCategory(!withoutcategory)}  />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Usar categoria como tag?</span>
                                </div>
                                <div style={{display:"flex"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={analyzefollowers} value={analyzefollowers} onClick={e => setAnalyzeFollowers(!analyzefollowers)}  />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Analisar seguidores?</span>
                                </div>
                                <div style={{display:"flex"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={analyzefollowing} value={analyzefollowing} onClick={e => setAnalyzeFollowing(!analyzefollowing)}  />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Analisar seguindo?</span>
                                </div>
                                <div style={{display:"flex"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={analyzehashtag} value={analyzehashtag} onClick={e => setAnalyzeHashTag(!analyzehashtag)}  />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Analisar hashtag?</span>
                                </div>
                            </div>

                            { analyzehashtag ? 
                                <div style={{width:"300px"}}>
                                    <label>Hashtag a serem analisadas no Instagram</label>
                                    <div className="input-group">
                                        <TagsInput value={hashtag} onChange={e => handleHashTagInput(e)} beforeAddValidate={e => addValidationHashTag(e)}/>
                                    </div>
                                    <small className="text-danger">Após digitar a <span style={{fontWeight:"bold"}}>HashTag</span>, aperte <span style={{fontWeight:"bold"}}>Enter</span></small>
                                </div> 
                            : ""}

                            <div style={{marginLeft:"15px"}}>
                                <div>
                                    <div style={{display:"flex"}}>
                                        <div style={{width:"100%"}}>
                                            <label>Perfis a serem analisados</label>
                                            <div className="input-group">
                                                <TextInput  
                                                    name="perfil"
                                                    id="perfil"
                                                    type="text"
                                                    required
                                                    maxLength={150}
                                                    errorMessage={{ required: "Por favor, informe um perfil!" }}
                                                    value={perfil}
                                                    className="form-control "
                                                    onChange={e => setPerfil(e.target.value)}
                                                    autoComplete="off"/>
                                            </div>
                                        </div>   
                                        <div style={{marginLeft:"5px", display:"flex", alignItems:"end"}}>
                                            <button type="button" className="btn btn-success" title="Clique para inserir o perfil do Instagram" onClick={e => addPerfil(e)}><i className="fa fa-plus"/></button>
                                        </div>
                                    </div>

                                    <div style={{marginTop:"5px", maxHeight:"150px", overflowY:"auto"}}>
                                        <table className="table table-striped w-100">
                                            <thead className="thead-theme">
                                                <tr>
                                                    <td>Perfis</td>
                                                    <td style={{textAlign:"center"}}>...</td>
                                                </tr>
                                            </thead>  
                                            <tbody>                        
                                                {perfils.map((_actions,id) => {
                                                    return(<tr className="gradeX" key={id}>
                                                                <td style={{verticalAlign: "middle"}}>{_actions.perfil}</td>
                                                                <td style={{textAlign:"center",verticalAlign: "middle"}}>
                                                                    <button type='button' className="btn btn-danger btn-sm" onClick={e => removePerfil(_actions.perfil)}><i className="fa fa-trash"/></button>
                                                                </td>
                                                        </tr>)
                                                        }) }
                                            </tbody> 
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{borderTop:"1px dashed #cacaca", width:"100%", marginTop:"15px"}}></div>

                        <div style={{display:"flex"}}>
                            <div style={{width:"41%", minWidth:"41%"}}>
                                <div className="bg alert alert-warning" style={{padding:"10px", marginTop:"15px",borderRadius:"5px"}}>
                                    <span style={{color:"#000"}}>Quando maior a quantidade de Instagram logado, mais rápido será a análise...</span>
                                </div>
                                <div style={{display:"flex", marginTop:"5px"}}>
                                    <div style={{width:"50%"}}>
                                        <label>Usuário do Instagram</label>
                                        <div className="input-group">
                                            <TextInput  
                                                name="user"
                                                id="user"
                                                type="text"
                                                required
                                                maxLength={150}
                                                errorMessage={{ required: "Por favor, informe um usuário!" }}
                                                value={user}
                                                className="form-control "
                                                onChange={e => setUser(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                    </div>   
                                    <div style={{marginLeft:"15px",width:"50%"}}>
                                        <label>Senha do Instagram</label>
                                        <div className="input-group">
                                            <TextInput  
                                                name="password"
                                                id="password"
                                                type="password"
                                                required
                                                maxLength={150}
                                                errorMessage={{ required: "Por favor, informe uma senha!" }}
                                                value={password}
                                                className="form-control "
                                                onChange={e => setPassword(e.target.value)}
                                                autoComplete="off"/>
                                        </div>  
                                    </div> 
                                    <div style={{marginLeft:"5px", display:"flex", alignItems:"end"}}>
                                        <button type="button" className="btn btn-success" title="Clique para inserir a conta do instagram" onClick={e => addInstagram(e)}><i className="fa fa-plus"/></button>
                                    </div>
                                </div>
                                <div style={{marginTop:"5px"}}>
                                    <table className="table table-striped w-100">
                                        <thead className="thead-theme">
                                            <tr>
                                                <td>Usuário</td>
                                                <td style={{textAlign:"center"}}>...</td>
                                            </tr>
                                        </thead>  
                                        <tbody>                        
                                            {selectInstagram.map((_actions,id) => {
                                                return(<tr className="gradeX" key={id}>
                                                            <td style={{verticalAlign: "middle"}}>{_actions.user}</td>
                                                            <td style={{textAlign:"center",verticalAlign: "middle"}}>
                                                                <button type='button' className="btn btn-danger btn-sm" onClick={e => removeInstagram(_actions.user)}><i className="fa fa-trash"/></button>
                                                            </td>
                                                    </tr>)
                                                    }) }
                                        </tbody> 
                                    </table>
                                </div>
                            </div>

                            {!withoutcategory ?
                                <div style={{display:"flex", width:"57%", marginLeft:"15px"}}>
                                    <div style={{width:"300px"}}>
                                        <label>Tags</label>
                                        <div className="input-group">
                                            <TagsInput value={tags} onChange={e => handleTagInput(e)} beforeAddValidate={e => addValidationTag(e)}/>
                                        </div>
                                        <small className="text-danger">Após digitar a <span style={{fontWeight:"bold"}}>Tag</span>, aperte <span style={{fontWeight:"bold"}}>Enter</span></small>
                                    </div>     
                                    
                                    <div style={{marginLeft:"15px", marginTop:"5px"}}>
                                        <button title="Click aqui para incluir uma tag já existente" type="button" className="btn btn-success" style={{marginTop:"15px"}} onClick={e => handleTagInput(addValidationTag(tag) ? [...tags,tag] : tags)}><i className="fa fa-arrow-left"></i></button>
                                    </div>       
                        
                                    <div style={{marginLeft:"15px"}}>
                                        <div style={{width:"200px"}}>
                                            <span>Tags já existentes</span>
                                            <select className="form-select" value={tag} onChange={e => setTag(e.target.value)} style={{minHeight:"37px"}}>
                                                <option value="">Selecione</option>
                                                {dataTags.map((item) => {
                                                    return(<option value={item.tags}>{item.tags}</option>)
                                                })} 
                                            </select>
                                        </div>
                                        <small className="text-danger">Selecione uma <span style={{fontWeight:"bold"}}>Tag</span> e click no botão ao lado para inserir</small>
                                    </div>                                    
                                </div>
                            : ""}
                        </div>

                    </div>
              
                </Modal.Body>
                <Modal.Footer>  
 
                    <Button type="button" id="submit-import" onClick={e => handleSubmit(e)}>
                        <em className="fa fa-save"></em> Importar
                    </Button>          
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}