import twemoji from 'twemoji';

import PDF from "./../assets/pdf.png"
import FILE from "./../assets/file.png"
import XML from "./../assets/xml.png"
import ZIP from "./../assets/zip.png"
import OGGA from "./../assets/oggA.png"
import OGGV from "./../assets/oggV.png"
import MPEG from "./../assets/mpeg.png"
import TEXT from "./../assets/text.png"
import HTML from "./../assets/html.png"
import XLSX from "./../assets/xlsx.png"
import CSV from "./../assets/csv.png"
import EXEI from "./../assets/exeI.png"
import GZIP from "./../assets/gzip.png"
import DOCX from "./../assets/docx.png"
import DOC from "./../assets/doc.png"
import JNLP from "./../assets/jnlp.png" 
import PKCS12 from "./../assets/pkcs12.png" 
import XLS from "./../assets/xls.png" 
import MP4 from "./../assets/mp4.png" 
import MKV from "./../assets/mkv.png" 
import VCARD from "./../assets/contact.png" 
import MPEGA from "./../assets/mpegA.png" 
import _JSON from "./../assets/json.png" 
import MOV from "./../assets/mov.png" 
import EML from "./../assets/eml.png" 
import ODS from "./../assets/ods.png" 
import JS from "./../assets/js.png" 
import APK from "./../assets/apk.png" 
import JPEG from "./../assets/jpeg.png" 
import PNG from "./../assets/png.png" 

import USER from './../assets/user-gray.png'
import DOMPurify from 'dompurify';
import {format, fromUnixTime, subDays } from 'date-fns'
import CryptoJS from 'crypto-js';
import { pdfjs  } from 'react-pdf';

import { saveAs } from 'file-saver';
import ShowMessages from './../components/Exceptions'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const replaceAll = (text,str1, str2, ignore) => {
    return (text || '').toString().replace(new RegExp(str1.toString().replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,"\\$&"),(ignore?"gi":"g")),(typeof(str2)=="string")?str2.toString().replace(/\$/g,"$$$$"):str2);
} 

export const convertToBase64 = async(file) => {
    let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
    });

    return result_base64;
}

export const getIconeFile = (type,file) => {
    switch (type) {
        case 'application/vnd.android.package-archive':
            return APK  
        case 'application/pdf':
            return PDF  
        case 'text/xml' : 
            return XML  
        case 'application/x-zip-compressed' : 
            return ZIP
        case 'audio/ogg' : 
            return OGGA
        case 'video/ogg' : 
            return OGGV
        case 'video/mpeg' : 
            return MPEG
        case 'text/plain' : 
            return TEXT
        case 'text/html' : 
            return HTML
        case 'text/csv' : 
            return CSV
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' :
            return XLSX
        case 'application/x-msdownload':
            return EXEI
        case 'application/x-gzip':
            return GZIP
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return DOCX
        case 'application/vnd.ms-fontobject':
            return DOCX
        case 'application/msword':
            return DOC
        case 'application/x-java-jnlp-file':
            return JNLP  
        case 'application/x-pkcs12':
            return PKCS12    
        case 'application/vnd.ms-excel':
            return XLS   
        case 'video/mp4':
            return MP4  
        case 'video/x-matroska':
            return MKV    
        case 'text/x-vcard':
            return VCARD  
        case 'audio/mpeg':
            return MPEGA  
        case 'application/json':
            return _JSON  
        case 'video/quicktime':
            return MOV  
        case 'message/rfc822':
            return EML  
        case 'application/vnd.oasis.opendocument.spreadsheet':
            return ODS  
        case 'text/javascript':
            return JS    
        case 'image/jpeg':
             return JPEG 
        case 'image/png':
            return PNG     
        default:
            if (type !== '' )
                console.log(type, ' | ', file)
            return FILE;
    }
}

export const messageCustom = (message) => {
    const phoneLink = (value) => {
        // Expressão regular para identificar números de telefone
        const regexPhone = /(?:\+?\d{1,3}[\s-]?)?\(?\d{2}\)?[\s.-]?\d{4,5}[\s.-]?\d{4}/g;
        
        let _match = value.match(regexPhone) || []

        _match.forEach(_item => {
            let _number = _item.toString().replace(/[\W_,[a-z]+/g,"")
            value = value.replace(_item, `<a style="color:#027eb5; cursor:pointer" href='/#' onClick="window.showChat(null,null,${_number},null,null,0,0,0,'',false)">${_item}</a>`);
        });

        return value
      }

  //  message = replaceAll(message,'<','&lt;')
   // message = replaceAll(message,'>','&gt;')
    message = replaceAll(message,'/n','<br>')
    message = replaceAll(message,'&lt;br&gt;','<br>')
    message = replaceAll(message,'&lt;/br&gt;','<br>')
    let _startB = true
    let _startI = true
    let _startR = true
    for (var i = 0; i <= message.length;i++) {
        if (message[i] === '*') {
            message = message.replace('*',_startB ? '<b>' : '</b>')
            _startB = !_startB
        }

        if (message[i] === '_') {
            message = message.replace('_',_startI ? '<i>' : '</i>')
            _startI = !_startI
        }

        if (message[i] === '~') {
            message = message.replace('~',_startR ? '<s>' : '</s>')
            _startR = !_startR
        }


    }
    message = message.replace(/(https?:\/\/[^\s]+)/g, '<div style="margin-top:3px; overflow-wrap:anywhere"><a href="$1">$1</a></div>');
    message = phoneLink(message);
    //message = twemoji.parse(message, { folder: 'svg', ext: '.svg',className:"emoji-55zap" });
    //return message
    let ALLOWED_TAGS = ['b','i','br','img','a','s','span']
    let ALLOWED_ATTR = ['onclick','style']
    return DOMPurify.sanitize(message,{
        ALLOWED_TAGS,
        ALLOWED_ATTR
    })
}

export const getFields = () => {
    return [{  label : 'Selecione',
               value : '',
               inDatabase : true},
            {  label : '[NOME]',
               value : '[NOME]',
               inDatabase : true},  
            {  label : '[PHONE]',
               value : '[PHONE]',
               inDatabase : true},
            {  label : '[PHONE2]',
               value : '[PHONE2]',
               inDatabase : true},
            {  label : '[DATA_ATUAL]',
               value : '[DATA_ATUAL]',
               inDatabase : false},
            {  label : '[HORA_ATUAL]',
               value : '[HORA_ATUAL]',
               inDatabase : false},
            {  label : '[DATA_HORA_ATUAL]',
               value : '[DATA_HORA_ATUAL]',
               inDatabase : false},
            {  label : '[EMAIL]',
               value : '[EMAIL]',
               inDatabase : true},
            {  label : '[ENDEREÇO]',
               value : '[ENDEREÇO]',
               inDatabase : true},
            {  label : '[COMPLEMENTO]',
               value : '[COMPLEMENTO]',
               inDatabase : true},
            {  label : '[NÚMERO]',
               value : '[NÚMERO]',
               inDatabase : true},
            {  label : '[BAIRRO]',
               value : '[BAIRRO]',
               inDatabase : true},
            {  label : '[CIDADE]',
               value : '[CIDADE]',
               inDatabase : true},
            {  label : '[UF]',
               value : '[UF]',
               inDatabase : true},
            {  label : '[CEP]',
               value : '[CEP]',
               inDatabase : true},
            {  label : '[PAIS]',
               value : '[PAIS]',
               inDatabase : true},
            {  label : '[ENDEREÇO_COMPLETO]',
               value : '[ENDEREÇO_COMPLETO]',
               inDatabase : false},
            {  label : '[TELEGRAM]',
               value : '[TELEGRAM]',
               inDatabase : true},
            {  label : '[FACEBOOK]',
               value : '[FACEBOOK]',
               inDatabase : true},
            {  label : '[LINKEDIN]',
               value : '[LINKEDIN]',
               inDatabase : true},
            {  label : '[INSTAGRAM]',
               value : '[INSTAGRAM]',
               inDatabase : true},
            {  label : '[TAG_PERSONALIZADA]',
               value : '[TAG_PERSONALIZADA]',
               inDatabase : false},
            {  label : '[TAG_PERSONALIZADA1]',
               value : '[TAG_PERSONALIZADA1]',
               inDatabase : false},
            {  label : '[TAG_PERSONALIZADA2]',
               value : '[TAG_PERSONALIZADA2]',
               inDatabase : false}]
}

export const generateThumbnail = async(pdfBase64) => {
    try {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        console.log('ATOB DE THUMBNAIL')
        const pdfData = atob(pdfBase64);
        const loadingTask = pdfjs.getDocument({data : pdfData});

        const pdf = await loadingTask.promise;
        const _pages = pdf.numPages

        const firstPage = await pdf.getPage(1);
        const viewport = firstPage.getViewport({ scale: 0.5 }); // Set the desired scale for the thumbnail
    
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
            canvasContext: context,
            viewport,
        };

        await firstPage.render(renderContext).promise
        await new Promise((resolve) => setTimeout(resolve, 1000));

        return {URL : canvas.toDataURL(), pages : _pages}; // Obtém a imagem do canvas como base64
    } catch(e) {
        console.log(e)
    }
}

export const convertURL = (text) => {
    let _url = text.substring(text.indexOf('http'), 500)
    _url = _url.substring(0, _url.indexOf(' ') === -1 ? _url.length : _url.indexOf(' ') )
    text = text.replace(_url,`<a href="${_url}" target="_new"/>${_url}</a>`)
    return text
}

export const ConvertVCard = ({body,number}) => {
    const extrairDadosVCard = (vcardText) => {
    
        let linhas = vcardText
        try {
            linhas = JSON.parse(vcardText).caption 
        } catch (e) {

        }

        linhas = linhas?.split('\n')

        const dados = {};

        for (const linha of linhas) {
            const [chave, valor] = linha.split(':')
            if (chave && valor) {
                if (chave.indexOf('waid') >= 0) {
                    dados['waid'] = valor.toString().replace(/\D/g, '')
                } else {
                    dados[chave] = valor;
                } 
            }
        }

        return dados;
    }

    let _data = extrairDadosVCard(body) 

    return (
        <div style={{display:"flex", flexDirection:"column", width:"200px", gap:"5px"}}>
            <div style={{display:"flex",gap:"5px",background:"#f7f8f9",padding:"5px", borderRadius:"8px"}}>
                <div style={{display:"flex"}}>
                    <img src={`${USER}`} style={{width:"50px"}} alt=""/>
                </div>
                <div  style={{display:"flex", alignItems:"center"}}>
                    <span style={{fontWeight:"bold"}}>{_data['X-WA-BIZ-NAME'] || _data['FN']}</span>
                </div>
            </div>
            <div style={{display:"flex",justifyContent:"end"}}>
                <button className='btn btn-light' onClick={e => window.showChat(
                    0,
                    _data['X-WA-BIZ-NAME'] || _data['FN'],
                    '',
                    _data['waid'],
                    null,
                    null,
                    0,
                    0,
                    0,
                    number,
                    false
                )}>Conversar</button>
            </div>
        </div>
    )
}

export const MessageTypeToType = (MessageType) => {
    switch (MessageType) {
        case 1: return 'chat'
        case 2: return 'document'
        case 3: return 'ppt'
        case 4: return 'vcard'
        case 5: return 'button'
        default: return ''
            break;
    }
}

export const RepeatRemove = (_array) => {
    let _newArray = []
    _array.map(_item => {
        let _filter = _newArray.filter(_itemF => {
            return _itemF.idmessage === _item.idmessage
        })

        if (_filter.length === 0)
            _newArray.push(_item) 
        else if (_item.edited) {
            _newArray = _newArray.filter(_itemEdit => {
                return _itemEdit.idmessage !== _item.idmessage
            })

            _newArray.push(_item) 
        } else if (_item.revoked) {
            console.log(_item)
        }
    })
   
   return _newArray
}

export const gerarCorHexadecimal = (key) => {
    const hash = CryptoJS.MD5(key).toString();

    // Pega uma substring da hash para usar como cor (por exemplo, os primeiros 6 caracteres)
    const color = `#${hash.substring(0, 6)}`;
  
    return color;
}

export const getDataNameAndHour = (_date) => {
    let _new = format(new Date(_date),'dd/MM/yyyy')
    if (_new === format(new Date(),'dd/MM/yyyy'))
        return format(_date,'HH:mm:ss')
    else if (_new === format(subDays(new Date(),1),'dd/MM/yyyy'))
        return 'Ontem as ' + format(_date,'HH:mm:ss')
    else
        return format(_date,'dd/MM/yyyy HH:mm:ss')
}

export const PopulateConversation = (_msg,isreceive) => {
    let create_at = _msg.create_at ? _msg.create_at  : fromUnixTime(_msg.timestamp)
    let hasMedia  = _msg?.hasmedia === undefined ? _msg?.hasMedia : _msg?.hasmedia

    return {body             : hasMedia ? null : _msg.body,
            caption          : _msg?.caption || _msg?.body || null,
            mimetype         : _msg?.mimetype || _msg?._media?.mimetype || null,
            type             : _msg.type,
            filesize         : _msg.filesize || _msg?._media?.filesize || 0,
            filename         : _msg.filename || _msg?._media?.filename || '',
            file             : hasMedia ? _msg?.file || _msg?.body || _msg?._media?.body: null, 
            create_at_format : format(new Date(create_at),'dd/MM/yyyy HH:mm:ss'),
            hasmedia         : hasMedia,
            location         : _msg.location,
            isreceive        : isreceive,
            notifyusergroup  : _msg.notifyusergroup,
            numberusergroup  : _msg.numberusergroup,
            reaction         : _msg.reaction || null,
            create_at        : new Date(create_at),
            thumbnail        : _msg.thumbnail || null,
            pages            : _msg.pages || 0,
            ack              : _msg.ack || null,
            idmessage        : _msg?.id?.id || _msg?.idmessage || null,
            idmessagehistory : _msg.idmessagehistory || null,
            edited           : _msg.edited,
            revoked          : _msg.revoked,
            editedhistory    : _msg.editedhistory
    }
}

export const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    return result;
}

export const base64ToBlob = (base64Data) => {
    console.log('ATOB DE BLOB')
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: 'application/octet-stream' });
}

export const downloadFile = (e,media) => {
    try {
        const base64Data = media.base64 || media.file || media.src; 
        // Criar um objeto URL para o Blob
        let blobUrl = base64ToBlob(base64Data.substr(base64Data.indexOf(',') + 1,base64Data.length))
        saveAs(blobUrl, media.filename || media.name || 'arquivo.' + media.type.split('/')[1]);
    } catch(e) {
        ShowMessages({message : "Arquivo corrompido ou inválido", status : 500})
    }
}

export const sanatizeData = (value, allowSpecialChar, allowAcentuation) => {
    let sanitizedValue = value.normalize('NFD')
    
    if (!allowAcentuation)
        sanitizedValue = sanitizedValue.replace(/[\u0300-\u036f]/g, '') // Remove acentos

    if (!allowSpecialChar)
        sanitizedValue = sanitizedValue.replace(/[^\w]/gi, ''); // Remove caracteres não alfanuméricos
  
    return DOMPurify.sanitize(sanitizedValue);
}

export const removeAcento = (text) => {       
    text = text.toLowerCase();                                                         
    text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
    return text;                 
}

export const phone_format = (phone) => {
    const numeros = phone.toString().replace(/\D/g, '');
    if (numeros.length === 13) {
        return `${numeros.substring(0, 2)} (${numeros.substring(2, 4)}) ${numeros.substring(4, 9)}-${numeros.substring(9, 13)}`;
    } else if (numeros.length === 12) {
        if ([7,8,9].includes(numeros.substring(4, 5)))
            return `${numeros.substring(0, 2)} (${numeros.substring(2, 4)}) 9${numeros.substring(4, 8)}-${numeros.substring(8, 12)}`;
        else
            return `${numeros.substring(0, 2)} (${numeros.substring(2, 4)}) ${numeros.substring(4, 8)}-${numeros.substring(8, 12)}`;
    } else if (numeros.length === 11) {
        return `55 (${numeros.substring(0, 2)}) ${numeros.substring(2, 7)}-${numeros.substring(7, 12)}`;
    } else if (numeros.length === 10) {
        return `55 (${numeros.substring(0, 2)}) 9${numeros.substring(2, 6)}-${numeros.substring(6, 11)}`;
    } else
        return phone;
}


