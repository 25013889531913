import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import Swal from 'sweetalert2'
import ModalChatBotWebHook from '../modals/modal-chatbot-webhook';
import {ButtonGroup,DropdownMenu,DropdownToggle,DropdownItem,UncontrolledDropdown} from 'reactstrap'

export default function Campaing() {
    const [showModal, setShowModal]                        = useState(false)
    const [loading, setLoading]                            = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [filters, setFilters]                            = useState([])
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [data, setData]                                  = useState([])

    const getChatbot = async() => {
        await api.get('/getStatusChatbot/0')
                 .then(response => {
                     setGridParams({idautomationchat  : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                                      value : '00000'},
                                                         primaryKey   : true},  
                                    image              : {field       : 'image',
                                                          type        : 'url',
                                                          className   : "text-center width25",
                                                          order       : false},
                                    image1              : {field      : 'imagestatus',
                                                           type       : 'class',
                                                           className   : "text-center width25",
                                                          order       : false},
                                    title              : {text        : "Nome do Fluxo"},
                                    create_at          : {text        : "Último Inicio",
                                                          dataType    : "date"},
                                    finished_at        : {text        : "Último Encerramento",
                                                          dataType    : "date"},
                                    number             : {text        : "Número" },

                                    statusautomation    : {text        : "Status",
                                                         item        : {className : "text-center",
                                                         switch      : {data       : response.data,
                                                                        key        : 'idstatusautomation',
                                                                        titleField : 'errormessage'}}},
                                    Options           : {text        : "Opções",
                                                         className   : "text-center",
                                                         order       : false,
                                                         filter      : false,
                                                         item        : [{type  : "Custom",
                                                                         props : {tag            : "button",
                                                                                  title          : "Editar de Chatbot",
                                                                                  className      : 'btn btn-sm btn-warning',
                                                                                  icon           : "fa fa-edit",
                                                                                  indexcallback  : 1}},
                                                                        {type  : "Custom",
                                                                         props : {tag            : "button",
                                                                                  title          : "Exclusão de Chatbot",
                                                                                  className      : 'btn btn-sm btn-danger',
                                                                                  icon           : "fa fa-trash",
                                                                                  indexcallback  : 0}},
                                                                        {type  : "Custom",
                                                                         props : {tag            : "button",
                                                                                  title          : "Ativar ChatBot",
                                                                                  className      : 'btn btn-sm btn-success',
                                                                                  visualConditional : {idstatusautomation : {operator : 'in',
                                                                                                       values : [0]}},
                                                                                  icon           : "fa fa-check",
                                                                                  indexcallback  : 2}},
                                                                         {type  : "Custom",
                                                                          props : {tag            : "button",
                                                                                   title          : "Desativar ChatBot",
                                                                                   className      : 'btn btn-sm btn-secondary',
                                                                                   visualConditional : {idstatusautomation : {operator : 'in',
                                                                                                        values : [1]}},
                                                                                   icon           : "fa fa-ban",
                                                                                   indexcallback  : 3}},
                                                                         {type  : "Custom",
                                                                          props : {tag            : "button",
                                                                                   title          : "Webhook",
                                                                                   className      : 'btn btn-sm btn-primary padding0',
                                                                                   visualConditional : {webhook : {operator : '===',
                                                                                                        values : true}},
                                                                                   icon           : "fa fa-robot",
                                                                                   indexcallback  : 4}},
                                                                          {type  : "Custom",
                                                                           props : {tag            : "button",
                                                                                    title          : "Pausar Agendamento em Andamento",
                                                                                    className      : 'btn btn-sm btn-purple padding0',
                                                                                    visualConditional : {idstatusscheduling : {operator : '===',
                                                                                                         values : 0}},
                                                                                    icon           : "fa fa-circle-pause",
                                                                                    indexcallback  : 5}},
                                                                          {type  : "Custom",
                                                                           props : {tag            : "button",
                                                                                    title          : "Reiniciar Agendamento Pausado",
                                                                                    className      : 'btn btn-sm btn-success padding0',
                                                                                    visualConditional : {idstatusscheduling : {operator : '===',
                                                                                                        values : 4}},
                                                                                    icon           : "fa fa-circle-play",
                                                                                    indexcallback  : 6}}]}
                                             
                    })
                })
                .catch( error => { 
                   ShowMessages(error)
               })
    }

    useEffect(() => {
        getChatbot()
        setStatistic('maintenance\\chatbot')
    },[])
    
    const handleFilter            = () => {setIsOpenFilter(!isOpenFilter)}

    const handleWebHook = async(id) => {
        await api.get('/chatbot/' + id)
                 .then(response => {
                    setData(response.data.automation)
                    setShowModal(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                }) 
    }

    const RemoveChatbot = async (id) => {  
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir a automação?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/chatbot/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                        .catch( error => {
                            ShowMessages(error)
                        })    
            }
            
        })
    }

    const EditChatbot = async(id) => {
        await api.get('/chatbot/' + id)
                 .then(response => {
                    window.addTab(null, 
                        response.data.automation.title, 'fa fa-robot', '/workflow',{id},id,true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                })    
    }

    const pauseScheduling = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente parar o agendamento?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                 api.get('/scheduling/pause/' + id)
                         .then((response) => {
                            if (!response.data.auth) 
                                ShowMessages({message : response.data.message,status : response.data.status})
                            setReload(true) 
                          })
                          .catch((error) => {
                              ShowMessages(error)
                          })
            }
            
          })
    }

    const restartScheduling = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente reiniciar o agendamento pausado?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                 api.get('/scheduling/restart/' + id)
                         .then((response) => {
                            if (!response.data.auth) 
                                ShowMessages({message : response.data.message,status : response.data.status})
                            setReload(true) 
                          })
                          .catch((error) => {
                              ShowMessages(error)
                          })
            }
            
          })
    }

    const ActivateChatBot = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente ativar o chatbot?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                 api.post('/chatbot/status',{status : 1, id})
                         .then((response) => {
                            if (!response.data.auth) 
                                ShowMessages({message : response.data.message,status : response.data.status})
                            setReload(true) 
                          })
                          .catch((error) => {
                              ShowMessages(error)
                          })
            }
            
          })
    }

    const DeactivateChatBot = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente desativar o chatbot?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                 api.post('/chatbot/status',{status : 0, id})
                         .then((response) => {
                            if (!response.data.auth) 
                                ShowMessages({message : response.data.message,status : response.data.status})
                            setReload(true) 
                          })
                          .catch((error) => {
                              ShowMessages(error)
                          })
            }
            
          })
    }
    
    const handleCloseModal = () => {setShowModal(false)}

    return (    
        <ContentWrapper>
            {loading && (
                <Loading loading={loading}/>
            )}
            <ModalChatBotWebHook showModal={showModal} handleCloseModal = {handleCloseModal} handleReload={setReload} data={data}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                        <ButtonGroup>
                                <UncontrolledDropdown>
                                    <DropdownToggle caret color="success">
                                        <i className="fa fa-plus"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={e => {window.addTab(null,'Workflow de WhatsApp', 'fab fa-whatsapp', '/workflow',{type : 0},0,false)}}>
                                            <i className="fab fa-whatsapp"></i> WhatsApp
                                        </DropdownItem>
                                        <DropdownItem onClick={e => {window.addTab(null,'Workflow de Telegram', 'fab fa-telegram', '/workflow',{type : 1},0,false)}}>
                                            <i className="fab fa-telegram"></i> Telegram
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </ButtonGroup>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info"  title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/chatbot' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      filters             = {filters}
                      callbackReload      = {setReload}
                      print               = {print}
                      excel               = {excel}
                      callbackShowButtons = {setShowButtons}
                      callbackCellClick   = {EditChatbot}
                      callbackButtons     = {[RemoveChatbot, 
                                              EditChatbot,
                                              ActivateChatBot,
                                              DeactivateChatBot,
                                              handleWebHook,
                                              pauseScheduling,
                                              restartScheduling]}
                      />  
               
            </div>                    
        </ContentWrapper>
    )
}