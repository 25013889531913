import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog, Accordion } from "react-bootstrap";
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalListNode({ nodeId, showModal, handleCloseModal, nodeData }) {
    const [title, setTitle]             = useState('');
    const [description, setDescription] = useState('');
    const [body, setBody]               = useState('');
    const [footer, setFooter]           = useState('');
    const [list, setList]               = useState([])

    const [itemText, setItemText]       = useState('')

    const reactFlowInstance = useReactFlow();

    useEffect(() => {
        setTitle(nodeData.title);
        setDescription(nodeData.description);
        setBody(nodeData.body)
        setFooter(nodeData.footer)
        setList(nodeData.list)
    }, [nodeData]);

    const addItemText = () => {
        const getID = (arr, chave) => {
            arr.sort(function(a, b) { return a[chave] - b[chave]; }); // ordena o array em ordem crescente

            let proximoNumero = 3;
            for (let i = 0; i < arr.length; i++) {
              if (arr[i][chave] === proximoNumero) {
                proximoNumero++;
              } else if (arr[i][chave] > proximoNumero) {
                return proximoNumero;
              }
            }
            return proximoNumero; // se não houver sequência vazia, retorna o próximo número na sequência
        }

        const _list = list.some((item) => {
            if (item.title === itemText) {
                return true;
            }
            return false;
        });

        if (itemText === '') {
            ShowMessages({ status: 500, message: "Item não informado" });
            return;
        }

        if (list.length >= 10) {
            ShowMessages({
                status: 500,
                message: "Permitido apenas 10 itens da lista",
            });
            return;
        }

        if (_list) {
            ShowMessages({ status: 500, message: "Item já existe" });
            return;
        }

        setList([...list, { title: itemText, id : getID(list,'id')}]);
        setItemText('')
    };

    const removeItemText = (_word) => {
        let _list = list.filter((item) => {
            return item.title !== _word;
        });

        setList(_list);
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node       : parseInt(nodeData.node),
                            parentId   : parseInt(nodeData.parentId), 
                            targetId   : parseInt(nodeData.targetId),
                            body,
                            footer,
                            description,
                            title,
                            list,
                            objectType : 6
                        }
                    }
                }

                return node;
            })
        )

        handleCloseModal();
    };

    return (
        <Modal size="md" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width500" animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-list"></i> Lista
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="box-shadow" style={{padding:"10px"}}>
                            <div className="mb-1">
                                <div style={{width:"100%"}}>
                                    <span>Título da Lista</span>
                                    <label className="text-required">*</label>
                                    <div className="input-group">
                                        <TextInput
                                            name="title"
                                            id="title"
                                            type="text"
                                            autoFocus={true}
                                            maxLength={25}
                                            value={title}
                                            className="form-control "
                                            onChange={(e) => setTitle(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-1">
                                <div style={{width:"100%"}}>
                                    <span>Descrição</span>
                                    <label className="text-required">*</label>
                                    <div className="input-group">
                                        <TextInput
                                            name="description"
                                            id="description"
                                            type="text"
                                            required
                                            autoFocus={true}
                                            maxLength={25}
                                            errorMessage={{
                                                required: "Por favor, informe uma mensagem!",
                                            }}
                                            value={description}
                                            className="form-control "
                                            onChange={(e) => setDescription(e.target.value)}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-1">
                                <div style={{width:"100%"}}>
                                    <span>Corpo da Lista</span>
                                    <label className="text-required">*</label>
                                    <div className="input-group">
                                        <TextInput
                                            multiline={true}
                                            name="body"
                                            id="body"
                                            rows="6"
                                            cols="50"
                                            type="text"
                                            autoFocus={true}
                                            maxLength={200}
                                            value={body}
                                            className="form-control "
                                            onChange={(e) => setBody(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-1">
                                <div style={{width:"100%"}}>
                                    <span>Rodapé</span>
                                    <label className="text-required">*</label>
                                    <div className="input-group">
                                        <TextInput
                                            name="footer"
                                            id="footer"
                                            type="text"
                                            autoFocus={true}
                                            maxLength={25}
                                            value={footer}
                                            className="form-control "
                                            onChange={(e) => setFooter(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div>
                            </div>
                            <div>
                            <div style={{padding:"5px", border:"1px dashed #cacaca", marginTop:"5px", borderRadius:"5px"}}>
                                    <div className="my-1 d-flex flex-direction-row " style={{display:"flex", flexDirection:"column"}}>
                                        <div style={{display:"flex"}}>
                                            <div style={{width:"100%"}}>
                                                <span>Texto do item</span>
                                                <label className="text-required">*</label>
                                                <div className="input-group">
                                                    <TextInput
                                                        name="itemText"
                                                        id="itemText"
                                                        type="text"
                                                        autoFocus={true}
                                                        maxLength={50}
                                                        value={itemText}
                                                        className="form-control "
                                                        onChange={(e) => setItemText(e.target.value)}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-end justify-content-center" style={{marginLeft:"5px"}}>
                                                <button
                                                    type="button"
                                                    title="Clique para inserir a palavra"
                                                    className="btn btn-success"
                                                    onClick={(e) => addItemText()}
                                                >
                                                    <i className="fa fa-plus" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{marginTop:"15px"}}>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="own-acordion">
                                                    <span className="w-100">Itens</span>
                                                </Accordion.Header>
                                            <Accordion.Body className="p-0" style={{height:"150px", overflow:"auto"}}>
                                                    <table className="table table-striped w-100" style={{maxHeight:"150px", overflow:"auto"}}>
                                                        <tbody>
                                                            {list.map((_actions, id) => {
                                                                return (
                                                                    <tr className="gradeX" key={id}>
                                                                        <td style={{width:"100%"}}>
                                                                            {_actions.title}
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-danger btn-sm"
                                                                                onClick={(e) =>
                                                                                    removeItemText(_actions.title)
                                                                                }
                                                                            >
                                                                                <i className="fa fa-trash" />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-action-info">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
  );
}
