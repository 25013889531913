import { memo, useEffect, useState, useCallback } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import ModalEndNode from "./../../../pages/modals/workflow/modal-end-node";

const EndNode = (props) => {
    const reactFlowInstance = useReactFlow();

    const [isHuman, setIsHuman]     = useState(false)
    const [isBan, setIsBan]         = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [statistic, setStatistic] = useState(0)
    const [id, setID]               = useState(-1)

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node            : _id,
                    parentId        : -1, 
                    targetId        : -1,
                    selectedFiles   : props.data.nodeInfo.selectedFiles,
                    objectType      : 4
                }
            }
        }))
    }

    const deleteNode = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            setIsHuman(nodeInfo.isHuman);
            setIsBan(nodeInfo.isBan);
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
        };
        handleNodeProps();
    }, [props.data]);

  
    return (
        <>

            <Handle type="target" position={Position.Top} id='node-end1' />
            <Handle type="target" position={Position.Left} id='node-end2' />
            <Handle type="target" position={Position.Bottom} id='node-end3' />
            <Handle type="target" position={Position.Right} id='node-end4' />

            <ModalEndNode
                nodeId={props.id}
                nodeData={props.data.nodeInfo}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
            ></ModalEndNode>

            <div className="rfnode rfnode-end d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) => setShowModal(true)}>
                <div className="rfnode-title">#{id} Fim do Fluxo</div>

                <div className="my-2" style={{background:"rgb(220 53 69 / 29%)", padding:"5px", borderRadius:"5px", display:"flex", alignItems:"center"}}>
                    <i className="fa fa-flag-checkered" style={{fontSize:"10px", marginRight:"2px", color:"#dc3545"}}></i>
                    <p className="p-0 m-0" style={{fontSize:"6px", color:"#dc3545"}}>Fim do processo</p>
                </div>
                <div style={{display:"flex", gap:"5px", fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #dc3545"}}>
                    <div style={{display:"flex", gap:"5px", justifyContent:"start"}}>
                        <div onClick={e => copy(e)} title="Copiar Objeto" className="workflow-copy" >
                            <i className="fa fa-copy text-primary"></i>
                        </div>
                        <div onClick={() => deleteNode()} title="Excluir Objeto" className="workflow-delete" >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    </div>
                    {isHuman ? 
                        <div title="Atendimento humano ativado" >
                            <i className='fa fa-user-tie text-success' ></i>
                        </div>
                    : ''}
                    {isBan ? 
                        <div  title='Não interagir mais após o fim do fluxo'>
                            <i className='fa fa-ban text-danger' ></i>
                        </div>
                    : ''}
                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{fontSize:"10px", color:"#dc3545"}}>{statistic || ''}</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(EndNode);
