import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalCampaignGroup from '../modals/modal-campaign-group';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import Swal from 'sweetalert2'

export default function CampaingGroup() {
    const [loading, setLoading]                            = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [editState, setEditState]                        = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([])
                                                                         
    const getCampaign = async() => {
        await api.get('/statusCampaign/0')
                 .then(response => {
                     setGridParams({idcampaigngroup   : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                             value : '00000'},
                                                         primaryKey  : true},          
                                    campaign          : {text        : "Campanha"},
                                    title             : {text        : "Chatbot"},
                                    statuscampaign    : {text        : "Status",
                                                         item        : {className : "text-center",
                                                         switch      : {data       : response.data,
                                                                        key        : 'idstatuscampaign',
                                                                        titleField : 'errormessage'}}},
                                    Options           : {text        : "Opções",
                                                         className   : "text-center",
                                                         order       : false,
                                                         filter      : false,
                                                         item        : [{type  : "Custom",
                                                                         props : {tag            : "button",
                                                                                  title          : "Exclusão de campanha",
                                                                                  className      : 'btn btn-sm btn-danger',
                                                                                  icon           : "fa fa-trash",
                                                                                  message        : "Deseja realmente excluir a campanha?",
                                                                                  visualConditional : {idstatuscampaign : {operator : 'in',
                                                                                                                            values : [0,1,2,6,7,8,9]}},
                                                                                  indexcallback  : 0}},
                                                                        {type  : "Custom",
                                                                         props : {tag            : "button",
                                                                                  title          : "Editar campanha",
                                                                                  className      : 'btn btn-sm btn-warning',
                                                                                  visualConditional : {idstatuscampaign : {operator : 'in',
                                                                                                                            values : [0,2,6,7,8,9]}},

                                                                                  icon           : "fa fa-edit",
                                                                                  indexcallback  : 1}},
                                                                        {type  : "Custom",
                                                                         props : {tag            : "button",
                                                                                  title          : "Pausar Campanha",
                                                                                  className      : 'btn btn-sm btn-warning padding0',
                                                                                  icon           : "fa fa-pause",
                                                                                  visualConditional : {idstatuscampaign : {operator : 'in',
                                                                                                                           values : [4,9]}},
                                                                                  indexcallback  : 2}}, 
                                                                        {type  : "Custom",
                                                                         props : {tag            : "button",
                                                                                  title          : "Iniciar Campanha",
                                                                                  className      : 'btn btn-sm btn-success padding0',
                                                                                  icon           : "fa fa-play",
                                                                                  visualConditional : {idstatuscampaign : {operator : 'in',
                                                                                                                           values : [0,6,7]}},
                                                                                  indexcallback  : 3}}]}
                                             
                    })
                })
                .catch( error => { 
                   ShowMessages(error)
               })
    }

    useEffect(() => {
        getCampaign()
        setStatistic('maintenance\\campaignGroup')
    },[])
    
    const handleCloseModal        = () => {setShowModal(false)}
    const handleFilter            = () => {setIsOpenFilter(!isOpenFilter)}

    const RemoveCampaign = async (id) => {  
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir a campanha?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/campaign-group/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                        .catch( error => {
                            ShowMessages(error)
                        })    
            }
            
        })
    }

    const EditCampaign = async(id) => {
        await api.get('/campaign-group/' + id)
                 .then(response => {
                    setData(response.data)
                    console.log(data)
                    if ([0,2,6,7,8,9].includes(response.data.idstatuscampaign)) {
                        setEditState(true)
                        setShowModal(true)
                    }
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const PauseCampaign = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente pausar a campanha?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/campaign-group/pause/' + id)
                         .then(response => {
                            ShowMessages(response)
                            setTimeout(() => {
                                setReload(true)
                            },2000)  
                          })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
          })
    }

    const approvedCampaign = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente iniciar a campanha?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                 let _status = {status : 4}

                 api.post('/campaign-group/status/' + id,_status)
                         .then((response) => {
                            if (!response.data.auth) 
                                ShowMessages({message : response.data.message,status : response.data.status})
                            setReload(true) 
                          })
                          .catch((error) => {
                              ShowMessages(error)
                          })
            }
            
          })
    }

    return (    
        <ContentWrapper>
            {loading && (
                <Loading loading={loading}/>
            )}
            <ModalCampaignGroup showModal={showModal} handleCloseModal = {handleCloseModal} editState={editState} data={data} handleReload={setReload}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-success" title="Insere um novo registro" onClick={e => {setEditState(false) 
                                                                                                               setShowModal(true)}}><i className="fa fa-plus"></i></button>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/campaign-group' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      filters             = {filters}
                      callbackShowButtons = {setShowButtons}
                      callbackReload      = {setReload}
                      callbackCellClick   = {EditCampaign}
                      callbackButtons     = {[RemoveCampaign, 
                                              EditCampaign,
                                              PauseCampaign,
                                              approvedCampaign]}
                      />  
               
            </div>                    
        </ContentWrapper>
    )
}