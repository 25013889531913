import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ModalWhatsAppGroup from '../modals/modal-whatsapp-group';
import ModalFindGroup from '../modals/modal-find-groups';
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import Swal from 'sweetalert2'
import { Dropdown, DropdownButton } from 'react-bootstrap';

export default function WhatsappGroups() {
    const [showModal, setShowModal]                         = useState(false)
    const [showModalFind, setShowModalFind]                 = useState(false)
    const [loading, setLoading]                             = useState(false)
    const [loadingConnect, setLoadingConnect]               = useState(false)
    const [isOpenFilter, setIsOpenFilter]                   = useState(false)
    const [reload, setReload]                               = useState(false)
    const [print, setPrint]                                 = useState(false)
    const [excel, setExcel]                                 = useState(false)
    const [showButtons, setShowButtons]                     = useState(false)
    const [gridParams, setGridParams]                       = useState({})
    const [data, setData]                                   = useState({})
    const [filters, setFilters]                             = useState([])
    const [showOnlySendMessages, setShowOnlySendMessages]   = useState(false)
    const [showOnlyWaitingEntry, setShowOnlyWaitingEntry]   = useState(false)
    const [showOnlyAdministrator, setShowOnlyAdministrator] = useState(false)
    const [showOnlyOwner, setShowOnlyOwner]                 = useState(false)
    
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href="/#"
            ref={ref}
            className="mnp-name"
            onClick={(e) => {
                e.preventDefault();
            }}
        >
            {children}
        </a>
    ));

    const getWhatsappGroup = async() => {
        setGridParams({idwhatsappgroup : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                       value : '00000'},
                                          primaryKey  : true,
                                          visible     : false                          },
                       phone_format    : {text        : 'Número'} ,
                       groupname       : {text        : "Grupo",
                                          searchDefault : true},
                       description     : {text        : "Descrição",
                                          props       : {maxLength   : 100,
                                                         fieldTitle   : true}},
                       participants    : {text        : "Participantes",
                                          className   : 'text-right',
                                          item        : {className : 'text-right'}},
                       grouplink       : {text        : "Link do Grupo"}, 
                       announce        : {text        : "Pode Enviar Mensagem?",
                                          className   : "text-center",
                                          item        : {className : "text-center"}},
                       isadmin        : {text        : "Sou Admin?",
                                          className   : "text-center",
                                          item        : {className : "text-center"}}, 
                       isowner        : {text        : "Sou o Criador?",
                                          className   : "text-center",
                                          item        : {className : "text-center"}}, 
                       isprivate       : {text        : "Privado?",
                                          className   : "text-center",
                                          item        : {className : "text-center"}},
                       Options          : {text       : "Opções",
                                           className  : "text-center",
                                           order      : false,
                                           filter     : false,
                                           item       : [{type  : "Custom",
                                                          props : {tag               : "button",
                                                                   title             : "Copiar Link",
                                                                   className         : 'btn btn-sm btn-success',
                                                                   icon              : "fa fa-copy",
                                                                   visualConditional : {grouplink : {operator : '!=',
                                                                                                    values :  ''}},
                                                                   indexcallback     : 0}},
                                                         {type  : "Custom",
                                                          props : {tag               : "button",
                                                                   title             : "Sair do Grupo",
                                                                   className         : 'btn btn-sm btn-warning',
                                                                   visualConditional : {idType : {operator : 'in',
                                                                                        values : [0]}},
                                                                   icon              : "fa fa-person-walking-dashed-line-arrow-right",
                                                                   indexcallback     : 1}},
                                                          {type  : "Custom",
                                                           props : {tag               : "button",
                                                                    title             : "Excluir grupo",
                                                                    className         : 'btn btn-sm btn-danger',
                                                                    icon              : "fa fa-trash",
                                                                    indexcallback     : 2}}]}
                      })                    
        
    }

    const handleFilter            = () => {setIsOpenFilter(!isOpenFilter)}

    const DeleteGroup = async(_id) => {  
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir o grupo?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/whatsapp-groups/' + _id)
                         .then((response) => {
                            if (!response.data.data.auth) 
                                ShowMessages({message : response.data.data.message,status : 200})
                            setReload(true)
                          })
                          .catch((error) => {
                              ShowMessages(error)
                          })
            }
            
          })
    }
    
    const LeaveGroup= (_id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente sair do grupo?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/whatsapp-groups/leave/' + _id)
                         .then((response) => {
                            if (!response.data.data.auth) 
                                ShowMessages({message : response.data.data.message,status : 200})
                            setReload(true)
                          })
                          .catch((error) => {
                              ShowMessages(error)
                          })
            }
            
          })
    }

    const copyLink = async(_id) => {
        await api.get('/whatsapp-groups/' + _id)
                 .then(async(response) => {
                    await navigator.clipboard.writeText(response.data.grouplink).then(function() {
                        ShowMessages({status : 200, message : "Link copiado com sucesso!"})
                     }, function(err) {
                        ShowMessages({status : 500, message : "Erro ao copiar o Link!"})
                      });
                 })
                 .catch((error) => {
                    ShowMessages(error)
                 })
    }

    const ImportGroup = async() => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente importar | atualizar os grupos?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                setLoadingConnect(true)
                await api.post('/import-groups')
                         .then((response) => {
                            if (!response.data.data.auth) 
                                ShowMessages({message : response.data.data.message,status : 200})
                            setReload(true)
                            setLoadingConnect(false)
                          })
                          .catch((error) => {
                              setLoadingConnect(false)
                              setReload(true)
                              ShowMessages(error)
                          })
            }
            
          })
    }

    const handleSearch = async() => {
        let _filters = []

        if (showOnlySendMessages)
            _filters.push({ field      : 'announce',
                            key        : '=',  
                            value      : true,
                            value2     : null   
            })

        if (showOnlyWaitingEntry)
            _filters.push({ field      : 'idtype',
                            key        : '=',  
                            value      : 1,
                            value2     : null   
            })

        if (showOnlyAdministrator)
            _filters.push({ field      : 'isadmin',
                            key        : '=',  
                            value      : true,
                            value2     : null   
            })
        
        if (showOnlyOwner)
            _filters.push({ field      : 'isowner',
                            key        : '=',  
                            value      : true,
                            value2     : null   
            })

        setFilters(_filters)
    }

    const handleCloseModal     = async() => {setShowModal(false)}
    const handleCloseModalFind = async() => {setShowModalFind(false)}
    
    useEffect(() => {
        getWhatsappGroup()
        setStatistic('maintenance\\whatsapp-groups')
    },[])
    

    useEffect(() => {
        handleSearch()
    },[showOnlySendMessages,showOnlyWaitingEntry,showOnlyAdministrator,showOnlyOwner])
    
    return (    
        <ContentWrapper>
            {loading && (
                <Loading loading={loading}/>
            )}
            <ModalWhatsAppGroup showModal={showModal} handleCloseModal = {handleCloseModal}  data={data} handleReload={setReload}/>
            <ModalFindGroup showModal={showModalFind} handleCloseModal = {handleCloseModalFind}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <DropdownButton variant={'success'} as={CustomToggle} title={<i className="fa fa-plus"></i>}>
                                <Dropdown.Item href="#" onClick={e => setShowModal(true)}>Criar ou Entrar no Grupo</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={e => setShowModalFind(true)}>Pesquisar Grupos</Dropdown.Item>
                            </DropdownButton>

                            <button className="btn btn-primary" id="btn-refresh" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            <button className="btn btn-warning" id="btn-check" title="Importar | Atualizar Grupos" style={{marginLeft:"3px"}} onClick={e => ImportGroup()}><i className={`${loadingConnect ? 'fa fa-spinner fa-spin' : 'fa fa-tower-cell'}`}></i></button>
                            <button className="btn btn-purple " id="btn-link" title="Criar link de automação de grupos" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-link"></i></button>
                            {showButtons ? 
                                <> 
                                    <button className="btn btn-dark" id="btn-print" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info"  id="btn-export" title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" id="btn-search" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        </div>
                        <div style={{display:"flex", flexDirection:"column", borderLeft:"1px dashed #cacaca", marginLeft:"25px"}}>
                            <div style={{display:"flex",marginLeft:"30px"}}>
                                <div style={{display:"flex",background:"#fff", width:"fit-content"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={showOnlySendMessages} value={showOnlySendMessages} onClick={e => setShowOnlySendMessages(!showOnlySendMessages)} />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Somente com permissão de envio de mensagens</span>
                                </div>  
                            </div>
                            <div style={{display:"flex",marginLeft:"30px"}}>
                                <div style={{display:"flex",background:"#fff", width:"fit-content"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={showOnlyAdministrator} value={showOnlyAdministrator} onClick={e => setShowOnlyAdministrator(!showOnlyAdministrator)} />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Somente que sou adminstrador</span>
                                </div>  
                            </div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div style={{display:"flex",marginLeft:"30px"}}>
                                <div style={{display:"flex",background:"#fff", width:"fit-content"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={showOnlyOwner} value={showOnlyOwner} onClick={e => setShowOnlyOwner(!showOnlyOwner)} />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Somente que sou criador</span>
                                </div>  
                            </div>
                            <div style={{display:"flex",marginLeft:"30px"}}>
                                <div style={{display:"flex",background:"#fff", width:"fit-content"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={showOnlyWaitingEntry} value={showOnlyWaitingEntry} onClick={e => setShowOnlyWaitingEntry(!showOnlyWaitingEntry)} />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Somente que estão aguardando entrada</span>
                                </div>  
                            </div>
                        </div>                      
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/whatsapp-groups' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      filename            = "Grupos do Whatsapp"
                      callbackShowButtons = {setShowButtons}
                      callbackExcel       = {setExcel}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      //callbackCellClick   = {ConnectWhatsapp}
                      callbackButtons     = {[copyLink, 
                                              LeaveGroup,
                                              DeleteGroup]}
                      />  
            </div>                    
        </ContentWrapper>
    )
}