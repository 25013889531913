import React, { useState, useEffect } from 'react'
import ContentWrapper from '../../layouts/contentWrapper'
import Filter from '../../../components/Filter';
import Loading from '../../../components/Loading';
import Grid from '../../../components/Grid';
import api from '../../../services/api'
import ModalWhatsAppGroup from '../../modals/admin/modal-whatsapp-group';
import ShowMessages from '../../../components/Exceptions'
import setStatistic from '../../../services/apiStatistic'
import Swal from 'sweetalert2'

export default function WhatsappNumbers() {
    const [loadingConnect, setLoadingConnect]              = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [loading, setLoading]                            = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [editState, setEditState]                        = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([])
    const [numberTypeData, setNumberTypeData]              = useState([])
    const [numberType, setNumberType]                      = useState(-99)
    const [search, setSearch]                              = useState('')
    
    const getWhatsApp = async() => {
        await api.get('/mynumberstatus')
                 .then(async response => {
                    await api.get('/admin/getMyNumberSetting')
                             .then(async response1 => {     
                                 console.log(response1.data)
                                 setNumberTypeData(response1.data)                 
                                 setGridParams({idnumber        : {text        : "#ID", mask : { name  : 'leftSlice',
                                                                                                 value : '00000'},
                                                                   primaryKey  : true},
                                                image           : {url         : 'image',
                                                                   className   : "text-center",},
                                                 number          : {text        : 'Número'} ,
                                                 numberstatus    : {text        : "Status",
                                                                    className   : "text-center",
                                                                    item        : {className : "text-center",
                                                                                   switch    : {data  : response.data,
                                                                                                key   : 'idnumberstatus'}}},
                                                 fantasy         : {text        : "Cliente"},
                                                 update_at       : {text        : "Ultima Atualização",
                                                                    dataType    : "date"},
                                                 whatsappversion : {text        : "Versão"},
                                                 servername      : {text        : "Servidor"},
                                                 Options         : {text        : "Opções",
                                                                    className   : "text-center",
                                                                    order       : false,
                                                                    filter      : false,
                                                                    item        : [{type  : "Custom",
                                                                                    props : {tag               : "button",
                                                                                             title             : "Desconectar",
                                                                                             className         : 'btn btn-sm btn-danger',
                                                                                             icon              : "fa fa-circle-exclamation",
                                                                                             visualConditional : [{idnumberstatus : {operator : '==',
                                                                                                                                     values : [1]}},
                                                                                                                  {idmynumbertype : {operator : '==',
                                                                                                                                     values : [0]}}],
                                                                                                                   indexcallback     : 1}},
                                                                                   {type  : "Custom",
                                                                                    props : {tag               : "button",
                                                                                             title             : "Excluir número",
                                                                                             className         : 'btn btn-sm btn-danger',
                                                                                             icon              : "fa fa-trash",
                                                                                             message           : "Deseja realmente excluir a conexão?",
                                                                                             visualConditional : {idnumberstatus : {operator : '==',
                                                                                                                                    values : [0]}
                                                                                                                  },
                                                                                                                  indexcallback     : 0}}]}})
                              
                             }).catch((error) => {
                                ShowMessages(error);
                             });    
                 }).catch((error) => {
                    ShowMessages(error);
                 }); 
                                  
        
    }

    const DeleteWhatsapp = async(_id) => {  
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir a conexão?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/myNumbers/' + _id)
                         .then((response) => {
                            if (!response.data.data.auth) 
                                ShowMessages({message : response.data.data.message,status : 200})
                            setReload(true)
                          })
                          .catch((error) => {
                              setReload(true)
                              ShowMessages(error)
                          })
            }
            
          })
    }

    const statusNumberConnect = async() => {
        setLoadingConnect(true)
        await api.get('/admin/getAllNumbers')
                 .then(async(response) => { 
                    for (const [idx, _row] of response.data.entries()) {
                        switch (_row.idmynumbertype) {
                            case 0:
                                await api.post('/api/v1/getStatusNumber',{number : _row.number.replace(/[\W_,[a-z]+/g,""),
                                                                          hash   : _row.environment })
                                         .then(async(_status) => {  
 
                                         }).catch(error => {

                                         })
                                break;
                            case 1:
                                await api.post('/api/v1/getStatusTelegram',{token : _row.number })
                                         .then(async(_status) => {  
                                      
                                         }).catch(error => {
                              
                                        })
                                break;
                            case 5:
                                    await api.post('/api/v1/getStatusEmail',{smtp     : _row.servername,
                                                                             password : _row.password,
                                                                             user     : _row.number,
                                                                             port     : _row.port,
                                                                             tls      : _row.tls}) 
                                             .then(async(_status) => {  
                                              
                                             }).catch(error => {
                                            
                                            })
                                    break;
                            default:
                                break;
                        }
                    }
                    setLoadingConnect(false) 
                    setReload(true) 

        }).catch(error => {
           setLoadingConnect(false)
           ShowMessages(error)
        })  
    }

    const DisconnectWhatsapp =  (_id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente desconectar do WhatsApp? Ao desconectar o seu número será excluído!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.get('/mynumbers/' + _id)
                         .then(async response => {
                            await api.post('/api/v1/logout',{number : response.data.number.replace(/[\W_,[a-z]+/g,""), hash : response.data.environment})
                                     .then((response) => {
                                        if (!response.data.data.auth) 
                                            ShowMessages({message : 'Você foi desconectado com sucesso!',status : 200})
                                        setReload(true)
                                     })
                                     .catch((error) => {
                                        ShowMessages(error)
                                     })
                         })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
          })
    }  

    const callFilter = async(_id) => {
        console.log(_id)
        setNumberType(parseInt(_id))
        if (_id !== -99)
            setFilters([{ field      : 'idmynumbertype',
                         key        : '=',  
                         value      : _id,
                         value2     : null,
                         ignoretime : true}])
        else 
            setFilters([])
    }

    const handleSearch = (e) => {
        setFilters(
            {
                field      : 'number',
                key        : '%%',  
                value      : e.toString().replace(/[\W_]+/g,""),
            }
        )
        setSearch(e)
    }

    useEffect(() => {
        getWhatsApp()
        setStatistic('maintenance\\admin\\whatsapp-numbers')
    },[])
    
    const handleFilter     = () => {setIsOpenFilter(!isOpenFilter)}
    const handleCloseModal = async() => {setShowModal(false)}
    
    return (    
        <ContentWrapper>
            {loading && (
                <Loading loading={loading}/>
            )}
            <ModalWhatsAppGroup showModal={showModal} handleCloseModal = {handleCloseModal} editState={editState}  data={data} handleReload={setReload}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-primary" id="btn-refresh" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            <button className="btn btn-warning" id="btn-check" title="Checar as Conexões" style={{marginLeft:"3px"}} onClick={e => statusNumberConnect()}><i className={`${loadingConnect ? 'fa fa-spinner fa-spin' : 'fa fa-tower-cell'}`}></i></button>
                            {showButtons && ( 
                                <> 
                                    <button className="btn btn-dark" id="btn-print" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info"  id="btn-export" title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" id="btn-search" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            )}
                        </div>
                        <div style={{display:"flex", marginLeft:"15px", gap:"5px"}}>
                            <div style={{display:"flex", flexDirection:"column", marginTop:"-10px"}}>
                                <label>Tipo de Conexão</label>
                                <select className="form-select" value={numberType} onChange={e => callFilter(parseInt(e.target.value))}>
                                    <option value={-99} selected>Todos</option>
                                    {numberTypeData.map((item) => {
                                        return(<option value={item.idmynumbertype}>{item.mynumbertype}</option>)
                                    })}
                                </select>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", marginTop:"-10px"}}>
                                <label>Exibir Somente</label>
                                <select className="form-select" value={numberType} onChange={e => callFilter(parseInt(e.target.value))}>
                                    <option value={-99} selected>Todos</option>
                                    {numberTypeData.map((item) => {
                                        return(<option value={item.idmynumbertype}>{item.mynumbertype}</option>)
                                    })}
                                </select>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", marginTop:"-10px"}}>
                                <label>Pesquisar Números</label>
                                <input type="text" className='form-control' value={search} onChange={e => handleSearch(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/admin/whatsapp-number' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      filename            = "Números de WhatsApp"
                      callbackShowButtons = {setShowButtons}
                      callbackExcel       = {setExcel}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackButtons     = {[DeleteWhatsapp,
                                              DisconnectWhatsapp]}
                      />  
            </div>                    
        </ContentWrapper>
    )
}