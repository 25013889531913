import React, { memo, useCallback, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import ModalLocationNode from "../../../pages/modals/workflow/modal-location-node";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";

const LocationNode = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [latitude, setLatitude]   = useState(0);
    const [longitude, setLongitude] = useState(30);
    const [id, setID]               = useState(-1)
    const [statistic, setStatistic] = useState(0)

    const reactFlowInstance = useReactFlow();

    const constas = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            setLatitude(nodeInfo.latitude);
            setID(parseInt(nodeInfo.node))
            setLongitude(nodeInfo.longitude);
            setStatistic(nodeInfo.statistic)
        };
        handleNodeProps();
    }, [props.data]);

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node         : _id,
                    parentId     : -1, 
                    targetId     : -1,
                    latitude     : props.data.nodeInfo.latitude,
                    longitude    : props.data.nodeInfo.longitude,
                    objectType   : 19
                }
            }
        }))
    }

    const handleShowModal = (e) => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const Maps = React.memo(({ props }) => {
        const position = [props.latitude, props.longitude]

        const MapClickHandler = () => {
            useMapEvents({
                click(e) {
                    window.open(`https://www.google.com/maps?q=${props.latitude},${props.longitude}&z=17&hl=pt-BR}`, '_blank')
                },
            });
            return null;
        };

        return (<MapContainer
            scrollWheelZoom={false}
            dragging={false}
            doubleClickZoom={false}
            attributionControl={false}
            zoomControl={false}
            center={position}
            style={{ height: '100px', width: "190px" }}
            zoom={15} >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MapClickHandler />
            <Marker position={position}>
            </Marker>

        </MapContainer>)
    });

    return (
        <>
            <ModalLocationNode nodeId={props.id} showModal={showModal} handleCloseModal={handleCloseModal} nodeData={props.data.nodeInfo}></ModalLocationNode>
            <div className="rfnode rfnode-location d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) => handleShowModal(e)}>
                <div className="rfnode-title">#{id} Localização</div>

                
                {((latitude === 0) && (longitude === 0)) ? (
                    <div style={{background: "rgba(226, 7, 255, 0.1098039216)", margin:"5px 10px", padding:"5px", borderRadius:"5px", color:"#000"}}>
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <i className="fa fa-map-location"/>
                        </div>
                        <div className="w-100 d-flex " style={{alignItems:"center"}}>
                            <span style={{fontSize:"8px", textAlign:"center"}}>
                                Informe a localização desejada
                            </span>
                        </div>
                    </div>
                ) : (
                    <div style={{width:"200px", padding:"5px", height:"110px"}}>
                        <Maps props={{ latitude: latitude, longitude: longitude }} />
                    </div>
                )}

                <div style={{display:"flex", fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #e207ff"}}>
                    <div style={{display:"flex", gap:"5px",}}>
                        <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                                <i className="fa fa-copy text-primary"></i>
                        </div>
                        <div onClick={() => constas()} title="Excluir Mensagem" className="workflow-delete" >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    </div>

                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{fontSize:"10px", color:"#ffc107"}}>{statistic || ''}</span>
                    </div>
                </div>
            </div>

            <Handle type="source" position={Position.Bottom} id='node-location1'/>
            <Handle type="target" position={Position.Left} id='node-location2' />
            <Handle type="target" position={Position.Top} id='node-location3' />
            <Handle type="source" position={Position.Right} id='node-location4'/>
        </>
    );
};

export default memo(LocationNode);
