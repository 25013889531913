import React, { useEffect, useState } from 'react';

import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';
import { Button } from 'reactstrap';
import ShowMessages from '../../../components/Exceptions';

import {  ValidationForm } from 'react-bootstrap4-form-validation';

import api from '../../../services/api';
import setStatistic from '../../../services/apiStatistic';
import loadingButton from '../../layouts/Buttons';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalPlanSettings({showModal, handleCloseModal}) {
    const [intermediary, setIntermediary]         = useState(0)
    const [allowPix, setAllowPix]                 = useState(true)
    const [allowCard, setAllowCard]               = useState(false)
    const [allowBoleto, setAllowBoleto]           = useState(false)
    const [publicKey, setPublicKey]               = useState('')
    const [appId, setAppId]                       = useState('')


    const handleSubmit = async(event) => {
        event.preventDefault()

        if ((allowPix + allowCard + allowBoleto) === 0){
            ShowMessages({status : 500, message : "Informe uma forma de recebimento"})
            return
        }


        if (publicKey === '') {
            ShowMessages({status : 500, message : "Informe a chave publica"})
            return
        }

        if (appId === '') {
            ShowMessages({status : 500, message : "Informe o AppID"})
            return
        }

        loadingButton(true,document.querySelector('#submit-plan'),document.querySelector('#submit-plan').children[0].classList,document.querySelector('#submit-plan').lastChild.nodeValue)
        try {
            let _dataToSend = {
                intermediary,
                allowPix,
                allowCard,
                allowBoleto,
                publicKey,
                appId,
            }

            await api.put('/admin/plan-settings', _dataToSend)
                .then(response => {
                    ShowMessages(response)
                    if (response.ok) {
                        if (response.data.status === 200) {
                            handleCloseModal()

                        } else
                            loadingButton(false, document.querySelector('#submit-plan'), 'fa fa-save', 'Salvar')
                    } else
                        loadingButton(false, document.querySelector('#submit-plan'), 'fa fa-save', 'Salvar')
                })
                .catch(error => {
                    loadingButton(false, document.querySelector('#submit-plan'), 'fa fa-save', 'Salvar')
                    ShowMessages(error)
                })
        } catch (response) {
            loadingButton(false, document.querySelector('#submit-plan'), 'fa fa-save', 'Salvar')
            ShowMessages(response)
        }
    }

    const getData = async() => {
        await api.get('/admin/plan-settings')
                 .then((response) =>{
                    setIntermediary(response.data.intermediary)
                    setAllowPix(response.data.allowpix)
                    setAllowCard(response.data.allowcard)
                    setAllowBoleto(response.data.allowboleto)
                    setPublicKey(response.data.publickey)
                    setAppId(response.data.appid)
                 })
                 .catch((error) => {
                    ShowMessages(error)
                 })

    }


    useEffect(() => {
        setStatistic('modals\\modal-plan-settings')
    } ,[])

    useEffect(() => {
        getData()
    } ,[showModal])

    return (
        <Modal size="md" show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="width550" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content">
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-gears"></i> Configurações de Pagamento
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='box-shadow' style={{gap:"15px", display:"flex", flexDirection:"column"}}> 
                        <div style={{display:"flex", gap:"5px", flexDirection:"column", border:"1px solid #cacaca", borderRadius:"5px", padding:"5px"}}>
                            <div style={{marginTop:"-15px"}}>
                                <span style={{background:"#fff", fontWeight:"bold"}}>Intermediador de Pagamento</span>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{display:"flex",width:"100%",gap:"5px"}}>
                                    <input type="radio" className='form-check-input' checked={intermediary === 0} onClick={e => setIntermediary(0)}/>
                                    <span>Mercado Pago</span>
                                </div> 
                                <div style={{display:"flex",width:"100%",gap:"5px"}}>
                                    <input type="radio" className='form-check-input' checked={intermediary === 1} onClick={e => setIntermediary(1)}/>
                                    <span>PagSeguro</span>
                                </div> 
                            </div>

                        </div>
                        
                        <div style={{display:"flex", gap:"25px"}}>
                            <div>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={allowPix} 
                                                           value={allowPix} 
                                                           onClick={e => setAllowPix(!allowPix)} />
                                    <span ></span>
                                </label>
                                <span>Aceitar PIX</span>
                            </div>

                            <div>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={allowCard} 
                                                           value={allowCard} 
                                                           onClick={e => setAllowCard(!allowCard)} />
                                    <span ></span>
                                </label>
                                <span>Aceitar Cartão</span>
                            </div>

                            <div>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={allowBoleto} 
                                                           value={allowBoleto} 
                                                           onClick={e => setAllowBoleto(!allowBoleto)} />
                                    <span ></span>
                                </label>
                                <span>Aceitar Boleto</span>
                            </div>
                        </div>
                      
                        <div>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <span>Chave Pública</span>
                                <input className='form-control' value={publicKey} onChange={e => setPublicKey(e.target.value)} />
                            </div>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <span>AppId</span>
                                <input className='form-control' value={appId} onChange={e => setAppId(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-plan" className={`btn-theme`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    )
}