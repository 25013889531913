import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button, } from 'reactstrap';
import Draggable from 'react-draggable';


import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'
import CurrencyInput from '../../components/moneyInput';

import api from '../../services/api';

import loadingButton  from '../layouts/Buttons'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalClient({showModal, handleCloseModal, data, handleReload, dataStatus}) {
    const [loading, setLoading]                = useState(false)
    const [fantasy, setFantasy]                = useState('')
    const [start, setStart]                    = useState(null)
    const [expiration, setExpiration]          = useState(null)
    const [plan, setPlan]                      = useState(-1)
    const [value, setValue]                    = useState(0)
    const [discount, setDiscount]              = useState(0)
    const [ticket, setTicket]                  = useState(0)
    const [attendances, setAttendances]        = useState(0)
    const [numbers, setNumbers]                = useState(0)
    const [valueNumber,setValueNumber]         = useState(0)
    const [valueAttendance,setValueAttendance] = useState(0)
    const [idstatusplan, setIDStatusPlan]      = useState(0)
    const [idperiod, setIDPeriod]              = useState(0)

    const [dataPlan, setDataPlan]              = useState([])


    const getData = async() => {
        await api.get('/get-plans')
                 .then(response => {
                    setDataPlan(response.data)                                   
                }).catch(e => {
                    console.log(e)
                }) 
    }

    const setData = (obj,clear) => {
        setFantasy(clear ? '' : obj.fantasy)
        setStart(clear ? '' : obj.amendment.start.substring(0,10))
        setExpiration(clear ? '' : obj.amendment.expiration.substring(0,10))
        setPlan(clear ? -1 : obj.amendment.idplan)
        setValue(clear ? -1 : obj.amendment.value)
        setDiscount(clear ? -1 : obj.amendment.discount)
        setTicket(clear ? -1 : obj.amendment.value - obj.amendment.discount)
        setAttendances(clear ? -1 : obj.amendment.attendances)
        setNumbers(clear ? -1 : obj.amendment.numbers)
        setValueNumber(clear ? -1 : obj.amendment.valueNumber)
        setValueAttendance(clear ? -1 : obj.amendment.valueAttendance)
        setIDStatusPlan(clear ? -1 : obj.idstatusplan)
        setIDPeriod(clear ? -1 : obj.amendment.idperiod)
        setTicket(clear ? 0 : obj.amendment.ticket)
    }

    
    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-client'),document.querySelector('#submit-client').children[0].classList,document.querySelector('#submit-client').lastChild.nodeValue)
        event.preventDefault()

        let amendment = {
            start,
            expiration,
            idplan : plan,
            value,
            discount,
            attendances,
            numbers,
            valueAttendance,
            valueNumber,
            idperiod,
            ticket
        }
        try {
            let _data = {fantasy,
                         idstatusplan,
                         amendment}
                     
                     
                await api.put('/company/plans/' + data.idcompany, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-client'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-client'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-client'))
                            ShowMessages(error) 
                        })

        } catch (response) {
            loadingButton(false,document.querySelector('#submit-client'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        let _plan = dataPlan.filter(_item => {
            return _item.idplan === plan
        })
        
        if (_plan.length > 0) {
            _plan = _plan[0].discount.filter(_item => {
                return _item.month === idperiod
            })
            console.log(idperiod)
            if (_plan.length > 0) {
                let _value           = _plan[0].valueMonth.replace('R$','').replace('.','#').replace(',','.').replace('#','').trim()
                let _discount        = discount.toString().replace('R$','').replace('.','#').replace(',','.').replace('#','').trim()
                let _valueNumber     = valueNumber.toString().replace('R$','').replace('.','#').replace(',','.').replace('#','').trim()
                let _valueAttendance = valueAttendance.toString().replace('R$','').replace('.','#').replace(',','.').replace('#','').trim()
                let valuesNumber     = 0
                let valuesAttendance = 0

                if (numbers > 3) 
                    valuesNumber = _valueNumber * (numbers - 3)

                if (attendances > 3) 
                    valuesAttendance = _valueAttendance * (attendances - 3)

                setTicket(_value - _discount + valuesNumber + valuesAttendance)
                setValue(_value)
            } else 
                setTicket(0)
        } else {
            setTicket(0)
        }
    },[discount,plan,idperiod, numbers, valueNumber, attendances, valueAttendance])

    useEffect(() => {
        setStatistic('modals\\modal-client')
    },[])

    useEffect(() => {
        getData()
        if (JSON.stringify(data) !== '{}')
           setData(data,false)
    },[data,showModal])
   
    return (
      
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} dialogClassName="width500"  backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" > 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-person"></i> Propriedades do Cliente
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth330px"/>
                    )}
                    <div className={`box-shadow ${loading ? "hidden" : ""}`}>  
                        <div style={{display:"flex", gap:"5px"}}>
                            <div style={{width:"60%"}}>
                                <label>Cliente</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="fantasy"
                                        id="fantasy"
                                        type="text"
                                        required
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe o cliente!" }}
                                        value={fantasy}
                                        className="form-control "
                                        onChange={e => setFantasy(e.target.value)}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div style={{width:"40%"}}>
                                <label>Status</label>
                                <div className="input-group">
                                    <SelectGroup  
                                        name="idstatusplan"
                                        id="idsatusplan"
                                        required
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe um status para o plano!" }}
                                        value={idstatusplan}
                                        className="form-select "
                                        onChange={e => setIDStatusPlan(e.target.value)}
                                        autoComplete="off"
                                    >
                                        <option value="-1">Selecione</option>
                                        {dataStatus.map(_item => {
                                            return <option value={_item.idstatusplan}>{_item.statusplan}</option>
                                        })}
                                    </SelectGroup> 
                                </div>
                            </div>
                        </div>             
                        
                        <div style={{display:"flex", gap:"5px"}}>
                            <div style={{width:"60%"}}>
                                <label>Plano</label>
                                <div className="input-group">
                                    <SelectGroup  
                                        name="plan"
                                        id="plan"
                                        required
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe um plano!" }}
                                        value={plan}
                                        className="form-select "
                                        onChange={e => setPlan(parseInt(e.target.value))}
                                        autoComplete="off"
                                    >
                                        <option value="-1">Selecione</option>
                                        {dataPlan.map(_item => {
                                            return <option value={_item.idplan}>{_item.name}</option>
                                        })}
                                    </SelectGroup> 
                                </div>
                            </div>
                            <div style={{width:"40%"}}>
                                <label>Período</label>
                                <div className="input-group">
                                    <SelectGroup  
                                        name="idperiod"
                                        id="idperiod"
                                        required
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe um período!" }}
                                        value={idperiod}
                                        className="form-select "
                                        onChange={e => setIDPeriod(e.target.value)}
                                        autoComplete="off"
                                    >
                                        <option value={1}>Mensal</option>
                                        <option value={3}>3 Meses</option>
                                        <option value={6}>6 Meses</option>
                                        <option value={12}>12 Meses</option>
                                    </SelectGroup> 
                                </div>
                            </div>
                        </div>

                        <div style={{display:"flex", gap:"5px"}}>
                            <div style={{width:"50%"}}>
                                <label>Inicio do Contrato</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="start"
                                        id="start"
                                        type="date"
                                        required
                                        errorMessage={{ required: "Por favor, informe uma data para o contrato!" }}
                                        value={start}
                                        className="form-control "
                                        onChange={e => setStart(e.target.value)}
                                        autoComplete="off"
                                    />  
                                </div>
                            </div>
                            <div style={{width:"50%"}}>
                                <label>Vencimento do Contrato</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="expiration"
                                        id="expiration"
                                        type="date"
                                        required
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe a data de vencimento!" }}
                                        value={expiration}
                                        className="form-control "
                                        onChange={e => setExpiration(e.target.value)}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{display:"flex", gap:"5px"}}>
                            <div style={{width:"20%"}}>
                                <label>Qtd. Números</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="numbers"
                                        id="numbers"
                                        type="number"
                                        required
                                        min={1}
                                        max={50}
                                        errorMessage={{ required: "Por favor, informe a quantidade de números!", min: "A quantidade de números não pode ser menor que 1", max:"A quantidade de números não pode ser maior que 50"}}
                                        value={numbers}
                                        className="form-control "
                                        autoFocus
                                        onChange={e => setNumbers(e.target.value)}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div style={{width:"25%"}}>
                                <label>Qtd. Atendentes</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="attendances"
                                        id="attendances"
                                        type="number"
                                        min={0}
                                        max={100}
                                        errorMessage={{ required: "Por favor, informe a quantidade de atendentes!", min: "A quantidade de atendentes não pode ser menor que 0", max:"A quantidade de atendentes não pode ser maior que 100"}}
                                        value={attendances}
                                        className="form-control "
                                        autoFocus
                                        onChange={e => setAttendances(e.target.value)}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        {/* </div> */}
                        {/* <div style={{display:"flex", gap:"5px"}}> */}
                            <div style={{width:"25%"}}>
                                <label>Valor por Número</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="valuenumber"
                                        id="valuenumber"
                                        type="text"
                                        required
                                        errorMessage={{ required: "Por favor, informe um valor para o número!" }}
                                        value={valueNumber}
                                        className="form-control "
                                        autoFocus
                                        onChange={setValueNumber}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div style={{width:"30%"}}>
                                <label>Valor por Atendente</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="valueAttendance"
                                        id="valueAttendance"
                                        required
                                        errorMessage={{ required: "Por favor, informe um valor de atendimento!" }}
                                        value={valueAttendance}
                                        className="form-control "
                                        autoFocus
                                        onChange={setValueAttendance}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{display:"flex", gap:"5px"}}>
                            <div>
                                <label>Valor do Contrato</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="value"
                                        id="value"
                                        required
                                        disabled
                                        value={value}
                                        className="form-control text-right"
                                        onChange={e => setValue(e.target.value)}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div>
                                <label>Desconto</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="discount"
                                        id="discount"
                                        required
                                        errorMessage={{ required: "Por favor, informe um desconto ou deixe zerado!" }}
                                        value={discount}
                                        className="form-control text-right"
                                        onChange={setDiscount}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div>
                                <label>Valor do Ticket</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="ticket"
                                        id="ticket"
                                        type="text"
                                        disabled
                                        value={ticket}
                                        className="form-control text-right"
                                        autoFocus
                                        onChange={e => setTicket(e.target.value)}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>
     
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-client' className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}