import { memo, useCallback, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import ModalActionNode from "./../../../pages/modals/workflow/modal-action-node";

const ButtonNodeItem = ({ handleId, value, index }) => {
    return (
        <div className="custom-node__options" style={{minWidth:"100px"}} title={value}>
          <li className="list-item d-flex align-items-center" style={{minWidth:"100px"}}>
            {index === undefined && (
                <div className="list-marker me-1">
                <i className="fa-solid fa-circle-check"></i>
                </div>
            )}
            <div style={{textOverflow: "ellipsis", maxWidth:"100px",whiteSpace: "wrap",overflow: "hidden",textAlign:"center" }} >{(index !== undefined) && (<><span style={{fontWeight:"bold"}}>{index}</span> <span>-</span></>)} {value}</div>
          </li>
          <Handle type="source" position={Position.Right} id={`node-action${handleId}`} style={{marginTop:"15px"}}/>
        </div>
      );
};

const ActionNode = (props) => {
    const [showModal, setShowModal]                     = useState(false);
    const [actionType, setActionType]                   = useState(0);
    const [timeToAction, setTimeToAction]               = useState(30);
    const [id, setID]                                   = useState(-1)
    const [selectedTags, setSelectedTags]               = useState([])
    const [selectedReplaceTags, setSelectedReplaceTags] = useState([])
    const [statistic, setStatistic]                     = useState(0)
    const [optionTag, setOptionTag]                     = useState(0)

    const reactFlowInstance = useReactFlow();

    const constas = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            setActionType(nodeInfo.actionType);
            setTimeToAction(nodeInfo.timeToAction);
            setSelectedTags(nodeInfo.selectedTags)
            setSelectedReplaceTags(nodeInfo.selectedReplaceTags)
            setOptionTag(nodeInfo.optionTag)
            setStatistic(nodeInfo.statistic)
            setID(parseInt(nodeInfo.node))
        };
        handleNodeProps();
    }, [props.data]);

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node                : _id,
                    parentId            : -1, 
                    targetId            : -1,
                    timeToAction        : props.data.nodeInfo.timeToAction,
                    actionType          : props.data.nodeInfo.actionType,
                    selectedTags        : props.data.nodeInfo.selectedTags,
                    selectedReplaceTags : props.data.nodeInfo.selectedReplaceTags,
                    optionTag           : props.data.nodeInfo.optionTag,
                    options             : props.options,
                    objectType          : 8
                }
            }
        }))
    }

    const handleShowModal = (e) => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <ModalActionNode nodeId={props.id} showModal={showModal} handleCloseModal={handleCloseModal} nodeData={props.data.nodeInfo}></ModalActionNode>
            <div className="rfnode rfnode-action d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) => handleShowModal(e)}>
                <div className="rfnode-title">#{id} Ação</div>

                {(actionType === 0) && (
                    <div style={{background: "rgba(255, 193, 7, 0.1098039216)", margin:"5px 10px", padding:"5px", borderRadius:"5px", color:"#000"}}>
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <i className="fa fa-clock"/>
                        </div>
                        <div className="w-100 d-flex justify-content-center">
                            <span style={{fontSize:"8px"}}>
                                Aguardar {timeToAction} segundos
                            </span>
                        </div>
                    </div>
                )}
                {(actionType === 1) && (
                    <>
                        <div style={{ background: "rgba(255, 193, 7, 0.1098039216)", margin: "5px 10px", padding: "5px", borderRadius: "5px", color: "#000" }}>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <i className="fa fa-hourglass-end" />
                            </div>
                            <div className="w-100 d-flex justify-content-center">
                                <span style={{ fontSize: "8px" }}>
                                    Aguardar o usuário responder
                                </span>
                            </div>
                        </div>
                        {timeToAction > 0 && (
                            <div>
                                <div style={{ background: "#ffc1077c", borderRadius: "5px", padding: "5px", display: "flex", height: "27px", marginTop: "2px", fontSize: "8px", alignItems: "center", marginBottom:"5px" }}>
                                    <ButtonNodeItem value={`Continuar Automação em ${timeToAction} seg(s).`} handleId={6}></ButtonNodeItem>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {(actionType === 2) && (
                    <div style={{background: "rgba(255, 193, 7, 0.1098039216)", margin:"5px 10px", padding:"5px", borderRadius:"5px", color:"#000"}}>
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <i className="fa fa-user-tag"/>
                        </div>
                        <div className="w-100 d-flex justify-content-center" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                            <span style={{fontSize:"8px"}}>
                                {optionTag === 0 ? 'Alterar a TAG do Contato para' : 'Alterar TAG do Contato de'}
                            </span>
                            <span style={{fontSize:"8px", fontWeight:"bold", textAlign:"center"}}>
                                {selectedTags.map((_item, i) => _item + (i < selectedTags.length -1? ', ' : ''))}
                            </span>
                            {optionTag === 1 && (
                                <>
                                    <span style={{fontSize:"8px"}}>
                                        para
                                    </span>
                                    <span style={{fontSize:"8px", fontWeight:"bold", textAlign:"center"}}>
                                        {selectedReplaceTags.map((_item, i) => _item + (i < selectedReplaceTags.length -1? ', ' : ''))}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                )}
                {(actionType === 3) && (
                    <div style={{background: "rgba(255, 193, 7, 0.1098039216)", margin:"5px 10px", padding:"5px", borderRadius:"5px", color:"#000"}}>
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <i className="fa fa-microphone"/>
                        </div>
                        <div className="w-100 d-flex justify-content-center">
                            <span style={{fontSize:"8px", textAlign:"center"}}>
                                Gravando Áudio por {timeToAction} segundos
                            </span>
                        </div>
                    </div>
                )}
                {(actionType === 4) && (
                    <div style={{background: "rgba(255, 193, 7, 0.1098039216)", margin:"5px 10px", padding:"5px", borderRadius:"5px", color:"#000"}}>
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <i className="fa fa-keyboard"/>
                        </div>
                        <div className="w-100 d-flex justify-content-center">
                            <span style={{fontSize:"8px"}}>
                                Digitando por {timeToAction} segundos
                            </span>
                        </div>
                    </div>
                )}

                <div style={{display:"flex", fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #ffc107"}}>
                    <div style={{display:"flex", gap:"5px",}}>
                        <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                                <i className="fa fa-copy text-primary"></i>
                        </div>
                        <div onClick={() => constas()} title="Excluir Mensagem" className="workflow-delete" >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    </div>

                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{fontSize:"10px", color:"#ffc107"}}>{statistic || ''}</span>
                    </div>
                </div>
            </div>

            <Handle type="target" position={Position.Bottom} id='node-action1'/>
            <Handle type="target" position={Position.Left} id='node-action2' />
            <Handle type="target" position={Position.Top} id='node-action3' />
            <Handle type="source" position={Position.Right} id='node-action4' style={{marginTop:`${timeToAction > 0 && actionType === 1 ? "-25px" : "0px"}`}}/>
        </>
    );
};

export default memo(ActionNode);
