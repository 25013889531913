import React, {useState, useEffect} from 'react'

import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import { Button, Nav, NavLink, NavItem, TabPane, TabContent  } from 'reactstrap';
import Draggable from 'react-draggable';
import Loading from '../../components/Loading';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { ValidationForm, SelectGroup, TextInput } from 'react-bootstrap4-form-validation';
import api from '../../services/api'
import  loadingButton  from '../layouts/Buttons'
import { sanatizeData } from './../../components/Utils'
import { IDCOMPANY, ReadDataStorage } from '../../services/auth';
import zxcvbn from 'zxcvbn';
import PasswordStrengthBar from 'react-password-strength-bar';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalUser({showModal, handleCloseModal, handleReload}) {
    const [loading, setLoading]                         = useState(false)
    const [tabActive, setTabActive]                     = useState(0)
    const [password, setPassword]                       = useState('')
    const [confirm, setConfirm]                         = useState('')
    const [updatepassword, setUpdatePassword]           = useState(false)
    const [registration, setRegistration]               = useState('')
    const [email, setEmail]                             = useState('')
    const [whatsapp, setWhatsapp]                       = useState('')
    const [name, setName]                               = useState('')
    const [attendancewhatsapp, setAttendanceWhatsapp]   = useState('')
    const [usergroup, setUserGroup]                     = useState(-1)
    const [dataGroup, setDataGroup]                     = useState([])

    const [settings, setSettings]                       = useState([])
    const [hourCompany, setHourCompany]                 = useState(false)
    const [hours, setHours]                             = useState({})
    const [isdepartamentadmin, setIsDepartamentAdmin]   = useState(false)
    const [dataDepartament, setDataDepartament]         = useState([])
    const [departament,setDepartament]                  = useState('')

    const matchPassword = (value) => {
        return value && value === password;   
    }

    const setData = () => {
        setPassword('')
        setConfirm('')
        setRegistration('')
        setEmail('')
        setWhatsapp('')
        setName('')
        setUserGroup(-1)
        setAttendanceWhatsapp('')
        setHourCompany(false)
        setHours({})
        setTabActive(0)
    }

    useEffect(() => {
        setData()
        getData()
    },[showModal])

    async function handleSubmit(event) {
        event.preventDefault()
        const score = zxcvbn(password)

        if (whatsapp === -1) {
            ShowMessages({status : 500, message : "Informe um número de whatsapp"})   
            return
        }

        if (usergroup === -1) {
            ShowMessages({status : 500, message : "Informe o grupo do usuário"})   
            return
        }

        if (score.score < 3) {
            ShowMessages({status : 500, message : "A senha não atende os requisitos mínimos de segurança!"})   
            return
        }
        loadingButton(true,document.querySelector('#submit-user'),document.querySelector('#submit-user').children[0].classList,document.querySelector('#submit-user').lastChild.nodeValue)
        try {
            let _data = {usergroup,
                         password,
                         email,
                         whatsapp,
                         updatepassword,
                         name,
                         hours,
                         hourCompany,
                         registration,
                         isdepartamentadmin,
                         attendancewhatsapp}

            await api.post('/user', _data)
                         .then(response => {
                            ShowMessages(response)
                             if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else
                                    loadingButton(false,document.querySelector('#submit-user'))        
                             } else
                                loadingButton(false,document.querySelector('#submit-user'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-user'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-user')) 
            ShowMessages(response)
        }
    }

    const getData = async() => {
        setLoading(true)
        await api.get('/getUserGroupData')
                 .then(async response => {
                     if (response.ok) {
                        setDataGroup(response.data)
                        await api.get('/getDepartament')
                                 .then(async response => {
                                    if (response.ok) {
                                        setDataDepartament(response.data)
                                        await api.get('/company/' + ReadDataStorage(IDCOMPANY))
                                                .then(response => {
                                                    if (response.ok) {
                                                        setSettings(response.data.settings)
                                                        setLoading(false)
                                                    }
                                                })
                                                .catch( error => { 
                                                    ShowMessages(error)
                                                })
                                    }
                                 })
                                 .catch( error => { 
                                     ShowMessages(error)
                                 })
                    }
                 })
                 .catch( error => { 
                    ShowMessages(error)
                 })
    }  

    useEffect(() => {
        setStatistic('modals\\modal-user')
    } ,[])

    const handleHourCompany = async(e,field) => {
        e.preventDefault()
        let _fields = {...hours}
        _fields[field] = e.target.value
        setHours(_fields)
    }

    return (<Modal dialogClassName="width500" show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
                <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}>   
                    <Modal.Header closeButton>
                        <Modal.Title style={{marginBottom:"0px"}}>
                            <i className="fa fa-user"></i> Propriedades do Usuário
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {loading ? (
                            <Loading loading={loading} addClass="heigth430px"/>
                        ) :
                        <div className={`box-shadow${loading ? "hidden" : ""}`}> 
                            <Nav tabs>
                                <NavItem>
                                    <NavLink className={tabActive === 0 ? "active" : ""} onClick={() => { setTabActive(0)}}>
                                        <i className='fa fa-person'/> Informações
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={tabActive === 1 ? "active" : ""} onClick={() => { setTabActive(1)}}>
                                        <i className='fa fa-calendar'/> Horários Permitidos
                                    </NavLink>
                                </NavItem>
                            </Nav> 
                            <TabContent activeTab={tabActive} style={{border:"0px", padding:"10px 0px"}}>
                                <TabPane tabId={0}>  
                                    <div style={{width:"100%"}}>
                                        <label>Nome</label>
                                        <TextInput 
                                            name="name" 
                                            id="name" 
                                            type="text"
                                            required
                                            errorMessage={{ required: "Por favor, informe o nome!" }}
                                            className="form-control "
                                            value={name} 
                                            maxLength={25}
                                            autoComplete="off"
                                            onChange={e => setName(e.target.value)}/>
                                    </div>   
                                    <div style={{display:"flex", gap:"5px"}}>
                                        <div style={{width:"50%"}}>
                                            <label>Usuário | Login</label>
                                            <TextInput 
                                                type="text"
                                                required
                                                errorMessage={{ required: "Por favor, informe um login | usuário!" }}
                                                className="form-control "
                                                value={registration} 
                                                maxLength={25}
                                                autoComplete="off"
                                                onChange={e => setRegistration(sanatizeData(e.target.value,false,false).toUpperCase())}/>
                                        </div> 
                                        <div style={{width:"50%"}} title="O número informado abaixo, e destinado a envio de notificações do sistema para o atendente">
                                            <label>Whatsapp de Cadastro</label><i className="fa fa-circle-question text-primary" style={{marginLeft:"5px"}}/>
                                            <PhoneInput
                                                placeholder=""
                                                style={{display:'flex', padding:"0.295rem 0.75rem"}}
                                                value={whatsapp}
                                                initialValueFormat="national"
                                                defaultCountry="BR"
                                                className='form-control'
                                                onChange={setWhatsapp}/>
                                        </div> 
                                    </div>
                                    <div style={{display:"flex", gap:"5px"}}>
                                        <div style={{width:"50%"}}>
                                            <div style={{ width:"100%"}}>
                                                <label>Senha</label>
                                                <TextInput
                                                    type="password"
                                                    required
                                                    errorMessage={{ required: "Por favor, informe sua senha!", pattern:"A senha devem conter pelo menos 6 dígitos." }}
                                                    value={password}
                                                    style={{flex :" 0 0"}}
                                                    pattern="(?=.*[A-Z,0-9,!@#$%&*()-=+,.;:/}{\|}]).{6,}"
                                                    className="form-control "
                                                    onChange={e => setPassword(e.target.value)}
                                                    autoComplete="one-time-code"
                                                    maxLength={16}
                                                />
                                            </div>
                                            <PasswordStrengthBar password={password} shortScoreWord= "Muito curta" scoreWords={['Muito Fraca', 'Fraca', 'OK', 'Boa', 'Muito Boa']}/>
                                        </div>
                                        <div style={{width:"50%"}}>
                                            <label>Confirme a senha</label>
                                            <TextInput
                                                type="password"
                                                style={{flex :" 0 0"}}
                                                required
                                                errorMessage={{ required: "Por favor, confirme sua senha!",validator : "As senhas informadas não conferem.",pattern:"A senha devem conter pelo menos 6 dígitos." }}
                                                value={confirm}
                                                pattern="(?=.*[A-Z,0-9,!@#$%&*()-=+,.;:/}{\|}]).{6,}"
                                                validator={matchPassword}
                                                className="form-control "
                                                onChange={e => setConfirm(e.target.value)}
                                                autoComplete="one-time-code"
                                                maxLength={16}
                                            />
                                        </div>                                   
                                    </div>   
                                    <div style={{display:"flex",gap:"5px"}}>
                                        <div className=" mb-2" style={{width:"100%"}}>
                                            <label>Grupo</label>
                                            <SelectGroup 
                                                name="usergroup" 
                                                id="usergroup" 
                                                placeholder="Selecione" 
                                                required
                                                errorMessage={{ required: "Por favor, informe um grupo!" }}
                                                className="form-select"
                                                value={usergroup} 
                                                onChange={e => setUserGroup(parseInt(e.target.value))}>
                                                    <option value={-1}>Selecione</option>
                                                    {dataGroup.map((data, id) => {
                                                        return (<option key={id} value={data.value}>{data.label}</option>)
                                                    })} 
                                            </SelectGroup>  
                                        </div> 
                                    </div>   
                                    
                                    {(settings.modeType === 0) && (usergroup === 3) && (
                                        <>
                                            <div style={{display:"flex", gap:"5px"}}>
                                                <div className=" mb-2" style={{width:"100%"}}>
                                                    <label>Departamento</label>
                                                    <SelectGroup 
                                                        name="departament" 
                                                        id="departament" 
                                                        placeholder="Selecione" 
                                                        required
                                                        errorMessage={{ required: "Por favor, informe um departamento!" }}
                                                        className="form-select"
                                                        value={departament} 
                                                        onChange={e => setDepartament(parseInt(e.target.value))}>
                                                            <option value="">Selecione</option>
                                                                {dataDepartament.map((data, id) => {
                                                                    return (<option key={id} value={data.iddepartament}>{data.departament}</option>)
                                                                })} 
                                                    </SelectGroup>  
                                                </div> 
                                            </div>
                                            <div style={{display:"flex", alignItems:"center", marginTop:"-10px", marginBottom:"10px"}}>
                                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                                    <input type="checkbox" checked={isdepartamentadmin} value={isdepartamentadmin} onClick={e => setIsDepartamentAdmin(!isdepartamentadmin)}  />
                                                    <span style={{ marginTop: "5px" }}></span>
                                                </label>
                                                <label style={{marginLeft:"5px"}}>Usuário e Supervisor do Departamento Informado?</label>
                                            </div>  
                                        </>
                                                  
                                    )}  
                                    <div >
                                        <div className=" mb-2">
                                            <label>Email</label>
                                            <TextInput 
                                                autoComplete="off"
                                                type="email"
                                                required
                                                errorMessage={{ required: "Por favor, informe um email!", email:"Por favor, informe um email!", type:"Por favor, informe um email válido!" }}
                                                className="form-control"
                                                value={email} 
                                                onChange={e => setEmail(e.target.value)}/>
                                        </div> 
                                    </div>                                               
                                    <div style={{display:"flex"}}>
                                        <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                            <input type="checkbox" checked={updatepassword} value={updatepassword} onClick={e => setUpdatePassword(!updatepassword)}  />
                                            <span style={{ marginTop: "5px" }}></span>
                                        </label>
                                        <label style={{marginLeft:"5px"}}>Alterar a senha no próximo login?</label>
                                    </div> 
                                </TabPane>
                                <TabPane tabId={1}>
                                    <div style={{display:"flex", gap:"15px", flexDirection:"column"}}>
                                        <div style={{display:"flex", gap:"5px", alignItems:"center", flexDirection:"column"}}>
                                            <div style={{display:"flex", gap:"5px", alignItems:"center"}}>
                                                <label className="switch switch-sm" style={{ justifyContent: "center" }} >
                                                    <input type="checkbox" checked={hourCompany} value={hourCompany} onClick={e => setHourCompany(!hourCompany)} />
                                                    <span style={{ marginTop: "5px" }}></span>
                                                </label>
                                                <div style={{display:"flex", alignItems:"center"}}>
                                                    <span>Acessar o sistema apenas em horário de funcinamento da empresa?</span>
                                                </div>
                                            </div>
                                            {hourCompany && (
                                                <div className='alert alert-warning'>
                                                    <span>Para a identificação dos horários, acesso o menu <span style={{fontWeight:"bold"}}>{`Configurações->Ajustes`}</span> e informe o horário de funcionamento da empresa</span>
                                                </div>
                                            )}
                                        </div>
                                        {!hourCompany && (
                                            <div style={{borderTop:"1px dashed #cacaca", paddingTop:"5px", display:"flex", flexWrap:"wrap", justifyContent:"space-between", gap:"10px"}}>
                                                {['Segunda-Feira','Terça-Feira','Quarta-Feira','Quinta-Feira','Sexta-Feira','Sábado','Domingo'].map(_item => {
                                                    return (
                                                        <div style={{display:"flex", justifyContent:"space-between", flexDirection:"column", width:"200px"}}>
                                                            <div style={{display:"flex",justifyContent:"center"}}>
                                                                <span style={{fontWeight:"bold"}}>{_item}</span>
                                                            </div>
                                                            <div style={{display:"flex", gap:"5px", justifyContent:"center"}}>
                                                                <div>
                                                                    <label>Horário Inicial</label>
                                                                    <input type="time" className='form-control' value={hours[`${_item}-start`]} onChange={e => handleHourCompany(e,`${_item}-start`)}/>
                                                                </div>
                                                                <div>
                                                                    <label>Horário Final</label>
                                                                    <input type="time" className='form-control' value={hours[`${_item}-end`]} onChange={e => handleHourCompany(e,`${_item}-end`)}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>    
                        }
                    </Modal.Body>
                    <Modal.Footer>  
                        <Button type="submit"  id="submit-user" className={`btn-theme ${loading ? "hidden" : ""}`} >
                            <em className="fa fa-save"></em> Salvar
                        </Button>          
                        <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                            <em className="fa fa-door-closed"></em> Fechar
                        </Button>
                    </Modal.Footer>
                </ValidationForm>      
            </Modal>)
}