import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import setStatistic from '../../services/apiStatistic'

import { Button } from 'reactstrap';

export default function Payment(props) {
    useEffect(() => {
        setStatistic('maintenance\\payment')
    },[])

    return (    
        <ContentWrapper className="content-wrapper-bottom81 ">
            <div className={`content-box-cart-page adjust-height`} style={{alignItems:"center"}}>
             
            </div>
            <div className="modal-footer footer-fixed modal-footer-bottom-10">
                <Button className="btn-ligth-gray">
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </div>
        </ContentWrapper>
    )
}