import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalECommerce from '../modals/modal-ecommerce';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import Swal from 'sweetalert2'

export default function ECommerce() {
    const [loading, setLoading]                            = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [editState, setEditState]                        = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([])

    const getIntegration = async () => {
        await api.get('/statusIntegration')
            .then(response => {
                setGridParams({
                    idecommerce_integration: {
                        text: "#ID", mask: {
                            name: 'leftSlice',
                            value: '00000'
                        },
                        primaryKey: true,
                        visible: false
                    },
                    ecommercecompany: {
                        text: "ECommerce",
                        searchDefault: true
                    },
                    ecommerceevent: { text: "Evento" },
                    ecommerceorderstatus: { text: "Status do Evento" },
                    appkey: { text: "App Key" },
                    interval: {
                        text: "Interval",
                        dataType: "number"
                    },
                    statusintegration: {
                        text: "Status",
                        item: {
                            className: "text-center",
                            switch: {
                                data: response.data,
                                key: 'idstatusintegration'
                            }
                        }
                    },
                    Options: {
                        text: "Opções",
                        className: "text-center",
                        order: false,
                        filter: false,
                        item: [{
                            type: "Custom",
                            props: {
                                tag: "button",
                                title: "Exclusão de integração",
                                className: 'btn btn-sm btn-danger',
                                icon: "fa fa-trash",
                                indexcallback: 0
                            }
                        },
                        {
                            type: "Custom",
                            props: {
                                tag: "button",
                                title: "Editar Integração",
                                className: 'btn btn-sm btn-warning',
                                icon: "fa fa-edit",
                                indexcallback: 1
                            }
                        },
                        {
                            type: "Custom",
                            props: {
                                tag: "button",
                                title: "Ativar Integração",
                                className: 'btn btn-sm btn-success',
                                icon: "fa fa-check",
                                visualConditional: {
                                    idstatusintegration: {
                                        operator: '===',
                                        values: 0
                                    }
                                },
                                indexcallback: 2
                            }
                        },
                        {
                            type: "Custom",
                            props: {
                                tag: "button",
                                title: "Desativar Integração",
                                className: 'btn btn-sm btn-secondary',
                                visualConditional: {
                                    idstatusintegration: {
                                        operator: '===',
                                        values: 1
                                    }
                                },
                                icon: "fa fa-cancel",
                                indexcallback: 3
                            }
                        }]
                    }

                })
            })
    }

    useEffect(() => {
        getIntegration()
        setStatistic('maintenance\\e-commerce')
    },[])
    
    const handleCloseModal = () => {setShowModal(false)}
    const handleFilter     = () => {setIsOpenFilter(!isOpenFilter)}

    const ActiveIntegration = async (id) => {   
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente ativar a integração?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/ecommerce-integration/status/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                        })
                        .catch( error => {
                            ShowMessages(error)
                        })
            }
            
        }) 

    }

    const InactiveIntegration = async (id) => {   
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente inativar a integração?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/ecommerce-integration/status/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                        })
                        .catch( error => {
                            ShowMessages(error)
                        })
            }
            
        }) 

    }

    const RemoveIntegration = async (id) => {   
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir a integração?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/ecommerce-integration/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                        })
                        .catch( error => {
                            ShowMessages(error)
                        })
            }
            
        }) 

    }

    const EditIntegration = async(id) => {
        await api.get('/ecommerce-integration/' + id)
                 .then(response => {
                    setData(response.data)
                    setEditState(true)
                    setShowModal(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    return (    
        <ContentWrapper>
            {loading && (
                <Loading loading={loading}/>
            )}
            <ModalECommerce showModal={showModal} handleCloseModal = {handleCloseModal} editState={editState} data={data} handleReload={setReload}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-success" title="Insere um novo registro" onClick={e => {setEditState(false) 
                                                                                                               setShowModal(true)}}><i className="fa fa-plus"></i></button>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/ecommerce-integration' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      callbackShowButtons = {setShowButtons}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackExcel       = {setExcel}
                      filename            = "Integração de ECommerce"
                      callbackCellClick   = {EditIntegration}
                      callbackButtons     = {[RemoveIntegration, 
                                              EditIntegration,
                                              ActiveIntegration,
                                              InactiveIntegration]}
                      />  
               
            </div>                    
        </ContentWrapper>
    )
}