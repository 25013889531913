import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog, Accordion } from "react-bootstrap";
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";
import Loading from '../../../components/Loading';

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalRandomizeNode({ nodeId, showModal, handleCloseModal, nodeData }) {
    const [loading, setLoading]                  = useState(false)
    const [percents, setPercents]                = useState([])
    const [percent, setPercent]                  = useState(0)
    const reactFlowInstance                      = useReactFlow();

    useEffect(() => {  
        setPercents(nodeData.percents || [])  
    }, [nodeData]);

    const addItemText = () => {
        const getID = (arr, chave) => {
            arr.sort(function(a, b) { return a[chave] - b[chave]; }); // ordena o array em ordem crescente

            let proximoNumero = 4;
            for (let i = 0; i < arr.length; i++) {
              if (arr[i][chave] === proximoNumero) {
                proximoNumero++;
              } else if (arr[i][chave] > proximoNumero) {
                return proximoNumero;
              }
            }
            return proximoNumero; // se não houver sequência vazia, retorna o próximo número na sequência
        }

        if ((percent === '') || (percent === '0')){
            ShowMessages({ status: 500, message: "Percentual não informado" });
            return;
        }

        if (percents.length >= 10) {
            ShowMessages({
                status: 500,
                message: "Permitido apenas 10 valores",
            });
            return;
        }

        setPercents([...percents, { percent, id : getID(percents,'id'),actual : 0, interaction : 0}]);
        setPercent(0)
    };

    const removeItemText = (_id) => {
        let _list = percents.filter((item) => {
            return item.id !== _id;
        });

        setPercents(_list);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let _sum = percents.reduce((_all, _actual) => {
            return _all + _actual.percent;
        }, 0);

        if (_sum > 100) {
            ShowMessages({status : 500, message : 'A soma do percentual não pode passar de 100%'})
            return
        }

        if (_sum < 100) {
            ShowMessages({status : 500, message : 'A soma do percentual não pode ser menor que 100%'})
            return
        }


        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node       : parseInt(nodeData.node),
                            parentId   : parseInt(nodeData.parentId), 
                            targetId   : parseInt(nodeData.targetId),
                            percents,
                            objectType : 13
                        }
                    }
                }

                return node;
            })
        )

        handleCloseModal();
    };

    return (
        <Modal size="sm" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width500" animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-shuffle"></i> Randomizador
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {loading ? (
                            <Loading loading={loading} addClass="heigth390px"/> 
                        ) : (
                        <div className="box-shadow" style={{padding:"10px"}}>
                            <div className="alert alert-primary" style={{borderRadius:"5px", padding:"15px"}}>
                                <span>Toda a carga recebida será dividida para os %(percentual) configurado abaixo!</span>
                            </div>
                                <div style={{padding:"5px", border:"1px dashed #cacaca", marginTop:"5px", borderRadius:"5px"}}>
                                    <div className="my-1 d-flex flex-direction-row " style={{display:"flex", flexDirection:"column"}}>
                                        <div style={{display:"flex"}}>
                                            <div style={{width:"100%"}}>
                                                <span>Percentual</span>
                                                <label className="text-required">*</label>
                                                <div className="input-group">
                                                    <TextInput
                                                        name="itemText"
                                                        id="itemText"
                                                        type="number"
                                                        maxLength={3}
                                                        minValue={1}
                                                        maxValue={100}
                                                        value={percent}
                                                        className="form-control "
                                                        onChange={(e) => setPercent(parseFloat(e.target.value))}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex" style={{marginLeft:"5px", alignItems:"end"}}>
                                                <button
                                                    type="button"
                                                    title="Clique para inserir o percentual"
                                                    className="btn btn-success"
                                                    onClick={(e) => addItemText()}
                                                >
                                                    <i className="fa fa-plus" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{marginTop:"15px"}}>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="own-acordion">
                                                    <span className="w-100">Percentuais</span>
                                                </Accordion.Header>
                                            <Accordion.Body className="p-0" style={{height:"150px", overflow:"auto"}}>
                                                    <table className="table table-striped w-100" style={{maxHeight:"150px", overflow:"auto"}}>
                                                        <tbody>
                                                            {percents.map((_actions, id) => {
                                                                return (
                                                                    <tr className="gradeX" key={id}>
                                                                        <td style={{width:"100%"}}>
                                                                            {_actions.percent}
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-danger btn-sm"
                                                                                onClick={(e) =>
                                                                                    removeItemText(_actions.id)
                                                                                }
                                                                            >
                                                                                <i className="fa fa-trash" />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-action-info">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
  );
}
