import { memo, useCallback, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import ModalBridgeNode from "../../../pages/modals/workflow/modal-bridge-node";

const BridgeNode = (props) => {
    const [showModal, setShowModal]       = useState(false);
    const [id, setID]                     = useState(-1)
    const [idattendance, setIdAttendance] = useState(0);
    const [attendance, setAttendance]     = useState('')
    const [statistic, setStatistic]       = useState(0)

    const reactFlowInstance = useReactFlow();

    const constas = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            console.log(nodeInfo)
            setIdAttendance(nodeInfo.idattendance || 0);
            setAttendance(nodeInfo.attendance)
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
        };
        handleNodeProps();
    }, [props.data]);

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node         : _id,
                    parentId     : -1, 
                    targetId     : -1,
                    idattendance : props.data.nodeInfo.idattendance,
                    attendance   : props.data.nodeInfo.attendance,
                    isClosed     : true, 
                    isHuman      : false,
                    objectType   : 15
                }
            }
        }))
    }


    const handleShowModal = (e) => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <ModalBridgeNode nodeId={props.id} showModal={showModal} handleCloseModal={handleCloseModal} nodeData={props.data.nodeInfo}/>
            <div className="rfnode rfnode-bridge d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow" onClick={(e) => handleShowModal(e)}>
                <div className="rfnode-title">#{id} Ponte de Atendimento</div>

                <div style={{background: "rgba(47, 79, 79, 0.1098039216)", color:"#2F4F4F", margin:"5px 10px", padding:"5px", borderRadius:"5px", width:"85%", textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    {(idattendance === 0) ? (
                        <span style={{fontSize:"6px"}}>Selecione um atendente para fazer a ponte de atendimento.</span>
                    ) : (
                        <span style={{fontSize:"6px"}}>{attendance}</span>
                    )}
                </div>
                <div style={{display:"flex",fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #2F4F4F"}}>
                    <div style={{display:"flex", gap:"5px"}}>
                        <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                                <i className="fa fa-copy text-primary"></i>
                            </div>
                        <div onClick={() => constas()} title="Excluir Mensagem" className="workflow-delete" >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    </div>

                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{fontSize:"10px", color:"#2F4F4F"}}>{statistic || ''}</span>
                    </div>
                </div>
            </div>

            <Handle type="target" position={Position.Top} id='node-bridge1' />
            <Handle type="target" position={Position.Left} id='node-bridge2' />
            <Handle type="target" position={Position.Bottom} id='node-bridge3' />
            <Handle type="target" position={Position.Right} id='node-bridge4' />
        </>
    );
};

export default memo(BridgeNode);
