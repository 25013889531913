import React, { useState, useEffect } from 'react'
import ContentWrapper from '../../layouts/contentWrapper'
import api from '../../../services/api'
import ShowMessages from '../../../components/Exceptions'
import setStatistic from '../../../services/apiStatistic'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,  PieChart, Pie,ResponsiveContainer } from 'recharts';
import { format,differenceInDays }   from 'date-fns'
import Loading from '../../../components/Loading';

export default function DashboardCampaign() {
    const [loading, setLoading]                 = useState(false)

    const [campaigns, setCampaigns]             = useState(0)
    const [actives, setActives]                 = useState(0) 
    const [timemachine, setTimeMachine]         = useState(0) 
    const [leads, setLeads]                     = useState(0)
    const [sending, setSending]                 = useState(0)
    const [receive, setReceive]                 = useState(0)   

    const [campaignMonthly, setCampaignMonthly] = useState([])
    const [campaignDaily, setCampaignDaily]     = useState([])
    const [leadsMonthly, setLeadsMonthly]       = useState([])

    const [clientMinusCampaign, setClientMinusCampaign] = useState([])
    const [clientPlusCampaign, setClientPlusCampaign]   = useState([])
    const [clientMinusLeads, setClientMinusLeads]       = useState([])
    const [clientPlusLeads, setClientPlusLeads]         = useState([])

    const getData = async() => {
        setLoading(true)
        await api.get('/admin/dashboard-campaign')
                 .then(response => {
                    let data = response.data
                    setCampaigns(data.campaigns)
                    setActives(data.actives)
                    setTimeMachine(data.timemachine)
                    setLeads(data.leads)
                    setSending(data.sending)
                    setReceive(data.receive)
                    setCampaignMonthly(data.campaignMonthly)
                    setCampaignDaily(data.campaignDaily)
                    setLeadsMonthly(data.leadsMonthly)
                    setClientMinusCampaign(data.clientMinusCampaign)
                    setClientPlusCampaign(data.clientPlusCampaign)
                    setClientMinusLeads(data.clientMinusLeads)
                    setClientPlusLeads(data.clientPlusLeads)
                    setLoading(false)
                 })
                 .catch((error) => {
                    ShowMessages(error)
                 })
    }

    useEffect(() => {
        getData()
        setStatistic('maintenance\\admin\\dashboard-campaign')
    },[])

    
    return (    
        <ContentWrapper>
            {loading && (
                <Loading loading={loading} />
            )}
        <div className={`box-shadow content-box-cart ${(loading ? "hidden" : "")}`} style={{padding:"10px", display:"flex", flexDirection:"column", overflowY:"auto", height:"85vh", overflowX:"hidden"}}>
                <div style={{display:"flex"}}>
                    <div style={{width:"100%"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0" >
                            <div className="col-4 d-flex align-items-center bg bg-primary-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-filter-circle-dollar fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 alert alert-primary rounded-right" style={{borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h2 mt-0" style={{textAlign:"right", paddingRight:"10px"}}>{campaigns}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Total Campanhas</div>
                            </div>
                        </div>
                    </div> 
                    <div style={{width:"100%", marginLeft:"5px"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0">
                            <div className="col-4 d-flex align-items-center bg bg-success-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-comment fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 alert alert-success rounded-right" style={{borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h2 mt-0" style={{textAlign:"right", paddingRight:"10px"}}>{(sending/1000).toFixed(3)}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Mensagens Enviadas</div>
                            </div>
                        </div>
                    </div>  

                    <div style={{width:"100%", marginLeft:"5px"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0">
                            <div className="col-4 d-flex align-items-center bg bg bg-danger-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-person-running fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 alert alert-danger rounded-right" style={{borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h2 mt-0" style={{textAlign:"right", paddingRight:"10px"}}>{timemachine}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Time Machine</div>
                            </div>
                        </div>
                    </div>   
                    <div style={{width:"100%", marginLeft:"5px"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0">
                            <div className="col-4 d-flex align-items-center bg bg-warning-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-users fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 alert alert-warning rounded-right" style={{borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h2 mt-0" style={{textAlign:"right", paddingRight:"10px"}}>{(leads/1000).toFixed(3)}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Total de Leads</div>
                            </div>
                        </div>
                    </div>   
                </div>
                <div style={{display:"flex"}}>
                    <div style={{width:"24.8%"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0">
                            <div className="col-4 d-flex align-items-center bg bg-primary-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-bullhorn fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 alert alert-primary rounded-right" style={{borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h2 mt-0" style={{textAlign:"right", paddingRight:"10px"}}>{actives}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Campanhas Ativas</div>
                            </div>
                        </div>
                    </div>  
                    <div style={{width:"24.8%", marginLeft:"5px"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0">
                            <div className="col-4 d-flex align-items-center bg bg-success-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-comments fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 alert alert-success rounded-right" style={{borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px",  marginBottom:"0px"}}>
                                <div className="h2 mt-0" style={{textAlign:"right", paddingRight:"10px"}}>{(receive/1000).toFixed(3)}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Mensagens Recebidas</div>
                            </div>
                        </div>
                    </div>     
                </div>
                <div style={{display:"flex", width:"98%"}}>
                    <div style={{height:"350px",padding:"15px 5px"}} className="box-shadow col-4">
                        <div style={{padding:"5px", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}><i className="fa fa-chart-line"></i> Campanhas Diárias</span>
                        </div>
                        <ResponsiveContainer width="100%" height="95%">
                            <BarChart
                                width={500}
                                height={300}
                                data={campaignDaily}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                            }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <YAxis dataKey="total" domain={[0, 20]}/>
                            <XAxis dataKey="dia" />
                            <Legend />
                            <Tooltip />
                            <Bar dataKey="total" fill="#8884d8"  stroke="#8884d8"/>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <div style={{height:"350px",padding:"15px 5px", marginLeft:"10px"}} className="box-shadow col-4">
                        <div style={{padding:"5px", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}><i className="fa fa-magnifying-glass-chart"></i> Campanhas Mensal</span>
                        </div>
                        <ResponsiveContainer width="100%" height="95%">
                            <BarChart
                                width={500}
                                height={300}
                                data={campaignMonthly}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                            }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <YAxis dataKey="total" domain={[0, 60]}/>
                            <XAxis dataKey="mês" />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="total" fill="#8884d8"  />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <div style={{height:"350px",padding:"15px 5px", marginLeft:"10px"}} className="box-shadow col-4">
                        <div style={{padding:"5px", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}><i className="fa fa-users-between-lines"></i> Leads por Mês</span>
                        </div>
                        <ResponsiveContainer width="100%" height="95%">
                            <BarChart
                                width={500}
                                height={300}
                                data={leadsMonthly}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                            }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <YAxis dataKey="total" domain={[0, 150000]}/>
                            <XAxis dataKey="mês" />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="total" fill="#8884d8"  />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div style={{display:"flex", marginTop:"15px", width:"97%"}}>
                    <div className="box-shadow col-3" style={{padding:"5px"}}>
                        <div style={{padding:"5px", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}><i className="fa fa-calendar-check"></i> Clientes com Mais Campanhas</span>
                        </div>
                        <table className='table table-striped w-100' style={{height:'10px', marginTop:"5px"}}>
                            <thead className="thead-light">
                                <tr className='head-th'>
                                    <th style={{width:"80%"}}>Cliente</th>
                                    <th style={{width:"20%"}}>Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientPlusCampaign?.map(_item => {
                                    return(
                                        <tr className="gradeX">
                                            <td>{_item.company}</td>
                                            <td className="text-center">{_item.total}</td>
                                        </tr>)
                                    })}
                            </tbody>
                        </table>
                    </div>
                    <div className="box-shadow col-3" style={{padding:"5px", marginLeft:"10px"}}>
                        <div style={{padding:"5px", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}><i className="fa fa-user-plus"></i> Clientes com Menos Campanhas</span>
                        </div>
                        <table className='table table-striped w-100' style={{height:'10px', marginTop:"5px"}}>
                            <thead className="thead-light">
                                <tr className='head-th'>
                                    <th style={{width:"80%"}}>Cliente</th>
                                    <th style={{width:"20%"}}>Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientMinusCampaign?.map(_item => {
                                    return(
                                        <tr className="gradeX">
                                            <td>{_item.company}</td>
                                            <td className="text-center">{_item.total}</td>
                                        </tr>)
                                    })}
                            </tbody>
                        </table>
                    </div>
                    <div className="box-shadow col-3" style={{padding:"5px", marginLeft:"10px"}}>
                        <div style={{padding:"5px", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}><i className="fa fa-person-circle-exclamation"></i> Clientes com Mais Leads</span>
                        </div>
                        <table className='table table-striped w-100' style={{height:'10px', marginTop:"5px"}}>
                            <thead className="thead-light">
                                <tr className='head-th'>
                                    <th style={{width:"80%"}}>Cliente</th>
                                    <th style={{width:"20%"}}>Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientPlusLeads?.map(_item => {
                                    return(
                                        <tr className="gradeX">
                                            <td>{_item.company}</td>
                                            <td className="text-center">{_item.total}</td>
                                        </tr>)
                                    })}
                            </tbody>
                        </table>
                    </div>
                    <div className="box-shadow col-3" style={{padding:"5px", marginLeft:"10px"}}>
                        <div style={{padding:"5px", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>
                            <span style={{fontSize:"16px", fontWeight:"bold"}}><i className="fa fa-person-circle-exclamation"></i> Clientes com Menos Leads</span>
                        </div>
                        <table className='table table-striped w-100' style={{height:'10px', marginTop:"5px"}}>
                            <thead className="thead-light">
                                <tr className='head-th'>
                                    <th style={{width:"80%"}}>Cliente</th>
                                    <th style={{width:"20%"}}>Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientMinusLeads?.map(_item => {
                                    return(
                                        <tr className="gradeX">
                                            <td>{_item.company}</td>
                                            <td className="text-center">{_item.total}</td>
                                        </tr>)
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>                    
        </ContentWrapper>
    )
}