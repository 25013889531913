import React, { useState, useEffect, useCallback } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Loading from '../../components/Loading';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import { checkPermission } from '../../components/security'
import { format } from 'date-fns'
import Swal from 'sweetalert2'

export default function Alert(props) {
    const [loading, setLoading]         = useState(false)
    const [title, setTitle]             = useState('')
    const [description, setDescription] = useState('')
    const [createAt, setCreateAt]       = useState(null)
    const [readAt, setReadAt]          = useState(null)

    const handleSubmit = (e) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Tem certeza que deseja marcar a mensagem como lida?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/alerts',{id : props.props.id})
                         .then((response) => {  
                            ShowMessages(response)
                            props.props.setUpdate()
                            window.removeTab(`tab_${props.id}`,e)
                        }).catch(error => {
                            ShowMessages(error)
                        })  
            }
            
          })
    }

    const getData = async() => {
        setLoading(true)
        await api.get('/alerts/' + props.props.id)
                 .then((response) => { 
                    let _data = response.data
                    setTitle(_data.title)
                    setDescription(_data.description)
                    setCreateAt(_data.create_at)
                    setReadAt(_data.read_at)
                    setLoading(false)
                 }).catch(error => {
                    ShowMessages(error)
                 })  
    }

    useEffect(() => {
        getData()
        setStatistic('maintenance\\alert')
    }, [])

    return (
        <ContentWrapper>
            {loading && (
                <Loading loading={loading} />
            )}
               <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                    <div className="box-shadow" style={{display:"flex",width:"50%", padding:"15px", flexDirection:"column"}}>
                        <div>
                            <span style={{fontSize:"18px", fontWeigth:"bold"}}>{title.toString().toUpperCase()}</span>
                        </div>
                        <div style={{display:"flex", justifyContent:"space-between",marginTop:"15px"}}>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <span style={{fontWeight:"bold"}}>Data do Alerta</span>
                                <span> {format(new Date(createAt),'dd/MM/yyyy HH:mm:ss')}</span>
                            </div>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <span style={{fontWeight:"bold"}}>Data do Leitura</span>
                                <span> {readAt === null ? '' : format(new Date(readAt),'dd/MM/yyyy HH:mm:ss')}</span>
                            </div>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <span style={{fontWeight:"bold"}}>Criado por</span>
                                <span> ADMINISTRADOR DO SISTEMA</span>
                            </div>
                        </div>
                        <div style={{marginTop:"15px"}}>
                            <span style={{fontWeight:"bold"}}>Descrição</span>
                            <div style={{border:"1px dashed #cacaca", padding:"5px", borderRadius:"5px", background:"#f7f8f9"}}>
                                {description.split('\n').map(_lines => {
                                    if (_lines.indexOf('http') > 0) {
                                        return (
                                            <div dangerouslySetInnerHTML={{__html: _lines}} style={{width:"100%", overflowX:"hidden", flexWrap:"wrap", display:"flex"}}>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <pre style={{width:"100%", overflowX:"hidden", flexWrap:"wrap", display:"flex"}}>
                                                {_lines}
                                            </pre>
                                        )
                                    }
                                })}
                               
                            </div>
                        </div>
                        {readAt === null ?
                            <div style={{marginTop:"15px", display:"flex", justifyContent:"flex-end"}}>
                                <button className='btn btn-success' type="button" onClick={e => handleSubmit(e)}><i className="fa fa-check"></i> Marcar como lida</button>
                            </div>
                        : ''}
                    </div>
            </div>
        </ContentWrapper>
    )
}