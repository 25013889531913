import React, {useState, useEffect} from 'react'


import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';

import Loading from '../../components/Loading';

import setStatistic from '../../services/apiStatistic'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}


export default function ModalMyNumberHistory({showModal, handleCloseModal, data}) {
    const [loading, setLoading]                = useState(false)


    useEffect(() => {
        setStatistic('modals\\modal-mynumber-history')
    } ,[])

    return (
        <Modal size='xl' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="width550"  dialogAs={DraggableModalDialog}>
            <Modal.Header closeButton>
                <Modal.Title style={{marginBottom:"0px"}}>
                    <i className="fab fa-square-poll-vertical"></i> 50 Últimos Históricos de Conexões
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                <div>   
                    {loading && (
                        <Loading loading={loading}/>
                    )}
                    <div className={`box-shadow ${(loading ? "hidden" : "")}`} style={{padding:"15px"}}>      
                                               
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>  
                <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}