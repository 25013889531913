import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalAutomationHistory from '../modals/modal-automation-history';
import ModalUpdateLead from '../modals/modal-update-lead';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import Swal from 'sweetalert2'
import {Dropdown} from 'react-bootstrap'
import { useChatData } from './../../components/messageView/chatContext'
import { format   }        from 'date-fns'
import { TextInput, ValidationForm } from 'react-bootstrap4-form-validation';
import { PopulateConversation } from "./../../components/Utils";

export default function AutomationHistory() {
    const [loading, setLoading]                            = useState(false)
    const [loadingAllPrint, setLoadingAllPrint]            = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [showModalUpdate, setShowModalUpdate]            = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [number, setNumber]                              = useState('')
    const [interactions, setInteractions]                  = useState(0)
    const [filters, setFilters]                            = useState([{ field      : 'create_at',
                                                                         key        : 'between',  
                                                                         value      : format(new Date(),'yyyy-MM-dd'),
                                                                         value2     : format(new Date(),'yyyy-MM-dd'),
                                                                         ignoretime : true   
                                                                       }])
    const [startDate, setStartDate]                        = useState(format(new Date(),'yyyy-MM-dd'))
    const [endDate, setEndDate]                            = useState(format(new Date(),'yyyy-MM-dd'))
    const [statusMessages, setStatusMessages]              = useState(-99)
    const [data, setData]                                  = useState([])                                                                 
    const [dataStatus, setDataStatus]                      = useState([])

    const { setConversation } = useChatData()

    const handleSearch = async(e) => {
        e.preventDefault()
        let _filters = [{ field      : 'create_at',
                          key        : 'between',  
                          value      : startDate,
                          value2     : endDate,
                          ignoretime : true   
                         }]

        if (statusMessages !== -99)
            _filters.push({
                field      : 'idstatusautomationchat',
                key        : '=',  
                value      : statusMessages,
            })

        if ((interactions !== 0) && (interactions !== ''))
            _filters.push({
                field      : 'totalinteractions',
                key        : '=',  
                value      : interactions,
            })


        if (number !== '')
            _filters.push({
                field      : '"to"',
                key        : '%%',  
                value      : number.toString().replace(/[\W_]+/g,""),
            })

        setFilters(_filters)
    }

    const getAutomationHistory = async() => {
        await api.get('/getStatusAutomation/0')
                 .then(response => {
                    setDataStatus(response.data)
                    setGridParams({idautomationchatheadhistory : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                                               value : '00000'},
                                                                  primaryKey  : true                          },
                                   create_at            : {text        : "Data"},
                                   number               : {text        : "Número ChatBot"},
                                   '"to"'               : {text        : 'Número de Contato',
                                                           searchDefault : true},
                                   statusautomationchat : {text        : "Status",
                                                           item        : {className : "text-center",
                                                                          switch    : {data      : response.data,
                                                                                       key       : 'idstatusautomationchat'}}},

                                   Options    : {text        : "Opções",
                                                 className   : "text-center",
                                                 order       : false,
                                                 filter      : false,
                                                 item        : [ {type  : "Custom",
                                                                  props : {tag            : "button",
                                                                           title          : "Encerrar automação",
                                                                           className      : 'btn btn-sm btn-danger',
                                                                           icon           : "fa fa-circle-stop",
                                                                           indexcallback  : 2,
                                                                           visualConditional : {idstatusautomationchat : {operator : '===',
                                                                                                values : 0}},}},
                                                                 {type  : "Custom",
                                                                  props : {tag            : "button",
                                                                           title          : "Visualizar conversão",
                                                                           className      : 'btn btn-sm btn-warning',
                                                                           icon           : "fa fa-edit",
                                                                           indexcallback  : 0}},
                                                                {type  : "Custom",
                                                                 props : {tag            : "button",
                                                                          title          : "Impressão da conversão",
                                                                          className      : 'btn btn-sm btn-secondary',
                                                                          icon           : "fa fa-print",
                                                                          indexcallback  : 1}}]}
                                             
                    })
                 }).catch(e => {
                    console.log(e)
                 })  
    } 

    useEffect(() => {
        getAutomationHistory()
        setStatistic('maintenance\\automation-history')
    },[])
    
    const handleCloseModal       = () => {setShowModal(false)}
    const handleCloseModalUpdate = () => {setShowModalUpdate(false)}
    const handleFilter           = () => {setIsOpenFilter(!isOpenFilter)}

    const ShowChatBot = async(id, to) => {
        console.log(id,to)
        await  api.post('/showAttendance', {id})
                 .then(response => {
                    let _data = []
                    response.data.map(_item => {
                        _data.push(PopulateConversation(_item,_item.isreceive))
                    })
                    setConversation(_data)
                    setShowModal(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const StopChatBot = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente parar o chatbot para esse interação?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/automation/stop/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                        .catch( error => {
                            ShowMessages(error)
                        })    
            }
            
        })
    }

    const Printer = async(id) => {
        await api.post('/automation/print/' + id)
                 .then(response => {
                    var link = document.createElement('a');
                    link.download = id + '.pdf';
                    link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data;
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                 })
                 .catch( error => { 
                    ShowMessages(error)
                 })
    }

    const AllPrinter = async() => {
        let _filters = ''
        if ((filters !== "") && (JSON.stringify(filters) !== "[]")) 
            _filters = btoa(JSON.stringify(filters))
        setLoadingAllPrint(true)
        await api.post('/automation/allprint/' + _filters)
                 .then(response => {
                    var link = document.createElement('a');
                    link.download = 'CSAT.pdf';
                    link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data;
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                    setLoadingAllPrint(false)
                 })
                 .catch( error => { 
                    setLoadingAllPrint(false)
                    ShowMessages(error)
                 })
    }

    const showOptions = (e, _data) => {
        e.stopPropagation()
        setShowModalUpdate(true)
        setData(_data)
    }

    const restartAttendance = (id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente ativar o chatbot para esse lead?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/automation/start/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                        .catch( error => {
                            ShowMessages(error)
                        })    
            }
            
        })
    }

    const getLayout = (_data) => {
        return (<div className="box-hover box-shadow box-chatbothistory" onClick={e => ShowChatBot(_data.idautomationchatheadhistory, _data.to)} >
                    <div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
                        <div>
                            <span style={{color:"#cacaca"}}>#{_data.idautomationchatheadhistory}</span>
                        </div>
                        <div style={{display:"flex", alignItems:"center", height:"110px"}}>
                        {(_data.idstatusautomationchat === 1) && 
                            (<div title="Automação Concluída" style={{display:"flex", justifyContent:"center", alignItems:"center", borderRadius:"50%", width:"50px", height:"50px", boxShadow : "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;", color:"#fff"}} className='alert alert-primary'>
                                 <i className='fa fa-thumbs-up' style={{fontSize:"24px"}}/>
                             </div>) 
                        }
                        {(_data.idstatusautomationchat === 0) && 
                            (<div title="Automação em Andamento" style={{display:"flex", justifyContent:"center", alignItems:"center", borderRadius:"50%", width:"50px", height:"50px", boxShadow : "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;", color:"#fff"}} className='alert alert-success'>
                                 <i className='fa fa-robot' style={{fontSize:"24px"}}/>
                             </div>) 
                        }
                        {(_data.idstatusautomationchat === 2) && 
                            (<div title="Automação Finalizada por Inatividade" style={{display:"flex", justifyContent:"center", alignItems:"center", borderRadius:"50%", width:"50px", height:"50px", boxShadow : "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;", color:"#fff"}} className='alert alert-danger'>
                                 <i className='fa fa-moon' style={{fontSize:"24px"}}/>
                             </div>) 
                        }
                        {(_data.idstatusautomationchat === 3) && 
                            (<div title="Automação Finalizada Manualmente" style={{display:"flex", justifyContent:"center", alignItems:"center", borderRadius:"50%", width:"50px", height:"50px", boxShadow : "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;", color:"#fff"}} className='alert alert-warning'>
                                 <i className='fa fa-user-tie' style={{fontSize:"24px"}}/>
                             </div>) 
                        }
                        </div>
                    </div>
                    
                    <div style={{width:"100%"}}>
                       
                        <div style={{display:"flex", justifyContent:"space-between"}}>
                            <div style={{display:"flex", flexDirection:"column"}}> 
                                <small style={{fontWeight:"bold"}}>
                                    Nome
                                </small>
                                <span className='chatbot-number'>{_data.name || "Não Informado"}</span>
                            </div>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <Dropdown title='Opções' className="nav-link" style={{textAlign:"right", display:"flex", marginTop:"-1px", padding:"2px"}} onClick={e => e.stopPropagation()}>
                                    <Dropdown.Toggle  style={{padding:"7px 10px"}}>
                                        <em className="fa fa-bars"></em>      
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{padding:"0px"}}>
                                        <Dropdown.Item href="#" >
                                            <div style={{display:"flex", gap:"5px", alignItems:"center"}} onClick={e => showOptions(e,_data)}>
                                                <em className="fa fa-person"></em>  
                                                <span>Opções do Lead</span>
                                            </div>
                                        </Dropdown.Item>
                                        
                                        {(_data.isban || _data.ishuman || _data.idstatusautomationchat === 3) && (
                                            <Dropdown.Item href="#" >
                                                <div style={{display:"flex", gap:"5px", alignItems:"center"}} onClick={e => restartAttendance(_data.idautomationchatheadhistory)}>
                                                    <em className="fa fa-robot"></em>  
                                                    <span>Desbloquear Atendimento</span>
                                                </div>  
                                            </Dropdown.Item> 
                                        )}

                                        {(_data.idstatusautomationchat === 0) && (
                                                <Dropdown.Item href="#" >
                                                    <div style={{display:"flex", gap:"5px", alignItems:"center"}} onClick={e => StopChatBot(_data.idautomationchatheadhistory)}>
                                                        <em className="fa fa-circle-stop"></em>  
                                                        <span>Parar Atendimento</span>
                                                    </div>
                                                </Dropdown.Item>
                                        )}

                                    </Dropdown.Menu>
                                </Dropdown>                          
                            </div>
                        </div>

                        <div style={{display:"flex",justifyContent:"space-between"}}>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <small style={{fontWeight:"bold"}}>
                                    Remetente
                                </small>
                                <span className='chatbot-number'>{_data.number}</span>
                            </div>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <small style={{fontWeight:"bold"}}>
                                    Destinatário
                                </small>
                                <span className='chatbot-number'>{_data.to}</span>
                            </div>
                        </div>
                        <div style={{display:"flex",justifyContent:"space-between"}}>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <small style={{fontWeight:"bold"}}  className='chatbot-title'>
                                    Primeira Interação
                                </small>
                                <span className='chatbot-number'>{_data.create_at}</span>
                            </div>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <small style={{fontWeight:"bold"}} className='chatbot-title'>
                                    Última Interação
                                </small>
                                <span className='chatbot-number'>{_data.update_at}</span>
                            </div>
                        </div>
                        <div style={{display:"flex",justifyContent:"space-between"}}>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <small style={{fontWeight:"bold"}}>
                                    Interações
                                </small>
                                <div style={{display:"flex", justifyContent:"center"}}>
                                    <span style={{width:"fit-content"}} className='badge badge-primary'>{_data.totalinteractions}</span>
                                </div>                                
                            </div>
                            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
                                <small style={{fontWeight:"bold"}}>
                                    Tags
                                </small>
                                <div style={{display:"flex", gap:"2px", alignItems:"center"}}>
                                    {(_data.tags || []).map(lead => {
                                        return (
                                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                <span style={{width:"fit-content"}} className='badge badge-primary'>{lead}</span>
                                            </div>  
                                        )
                                    })}
                                </div>

                            </div>
                            <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                <small style={{fontWeight:"bold"}}>
                                    Fluxo
                                </small>
                                <div style={{display:"flex", justifyContent:"center"}}>
                                    <span  className='badge badge-success chatbot-fluxo'>{_data.title}</span>
                                </div>
                            </div>
                        </div>               
                    </div>
                </div>
       )
    }

    return (    
        <ContentWrapper>
            {loading && (
                <Loading loading={loading}/>
            )}
            <ModalAutomationHistory showModal={showModal} handleCloseModal = {handleCloseModal} data={data} handleReload={setReload}/>
            <ModalUpdateLead showModal={showModalUpdate} handleCloseModal = {handleCloseModalUpdate} data={data} handleReload={setReload}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <ValidationForm onSubmit={handleSearch} className="modal-content" setFocusOnError={true}>
                    <div className="content-heading">
                        <div className="box-maintance-buttons">
                            <div>
                                <button type="button" className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                                {showButtons ? 
                                    <>
                                        <button type="button" className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                        <button type="button" className="btn btn-info"  title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                        <button type="button" className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                        <button type="button" className="btn btn-warning" disabled={loadingAllPrint} title="Exportação Completa" style={{marginLeft:"3px"}} onClick={e => AllPrinter()}><i className={`fa ${loadingAllPrint ? 'fa-spin fa-spinner' : 'fa-file-arrow-down'}`}></i></button>
                                    </>
                                : "" }
                            </div>
                        </div>
                        <div className='box-date-history'>
                            <div style={{width:"50%"}}>
                                <span>Data Inicial</span>
                                <input type="date" className='form-control' value={startDate} onChange={e => setStartDate(e.target.value)}/>
                            </div>
                            <div style={{width:"50%"}}>
                                <span>Data Final</span>
                                <input type="date" className='form-control' value={endDate} onChange={e => setEndDate(e.target.value)}/>
                            </div>
                        </div>
                        <div style={{display:"flex", gap:"5px"}}>
                            <div style={{display:"flex", gap:"15px"}}>
                                <div style={{display:"flex", flexDirection:"column"}}>
                                    <label>Número de Contato</label>
                                    <TextInput
                                        name="number"
                                        id="number"
                                        className="form-control w-100"
                                        maxLength="25"
                                        onChange={(e) => setNumber(e.target.value)}
                                        value={number}/>
                                </div>
                            </div>
                            <div style={{display:"flex", gap:"15px"}}>
                                <div style={{display:"flex", flexDirection:"column"}}>
                                    <label>Total de Interações</label>
                                    <TextInput
                                        name="interactions"
                                        id="interactions"
                                        type="number"
                                        className="form-control w-100"
                                        maxLength="25"
                                        onChange={(e) => setInteractions(e.target.value)}
                                        value={interactions}/>
                                </div>
                            </div>
                            <div style={{display:"flex", gap:"15px"}}>
                                <div style={{display:"flex", flexDirection:"column"}}>
                                    <label>Status da Mensagem</label>
                                    <select className="form-select" value={statusMessages} onChange={e => setStatusMessages(parseInt(e.target.value))}>
                                        <option value={-99} selected>Todos</option>
                                        {dataStatus.map((item) => {
                                            return(<option value={item.idstatusautomationchat}>{item.statusautomationchat}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div style={{display:"flex",alignItems:"end"}}>
                                <button type="button" className="btn btn-success" title="Clique aqui para pesquisar" onClick={e => handleSearch(e)}><i className="fa fa-search"/></button>
                            </div>
                        </div>

                    </div>
                </ValidationForm>
            </div>
            <div className={`content-box-cart content-box-cart-grid ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/automation' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      perPage             = {12}
                      filters             = {filters}
                      filename            = "Historico de ChatBot"
                      getGridLayout       = {getLayout}
                      callbackShowButtons = {setShowButtons}
                      callbackExcel       = {setExcel}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackCellClick   = {ShowChatBot}
                      callbackButtons     = {[ShowChatBot,
                                              Printer,
                                              StopChatBot]}
                      />  
            </div>                    
        </ContentWrapper>
    )
}