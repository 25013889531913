import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import { setCookieMessage } from './Cookies';

const Redirect = (url) => {
    document.location.href = url
}

const _timout = 4000

export default function ShowMessages(error) {
    console.log(error)
    if (error.message) {
        if (error.status === 500) {
            if (error.message.name === 'error')
                NotificationManager.error('5004 - Erro ao tentar efetuar a transação. Por favor, entre em contato com o suporte técnico.', 'Erro!', _timout)
            else     
                NotificationManager.error(JSON.stringify(error.message).replace('"','').replace('"',''), 'Erro!', _timout)
        }

        if (error.status === 325) //Erro personalizado para "ATENÇÃO" 
            NotificationManager.warning(error.message, 'Atenção!', _timout);

        if (error.status === 200) 
            NotificationManager.success(error.message, 'Sucesso!', _timout);

        if (error.status === 401) 
            NotificationManager.error(error.message, 'Erro!', _timout);

        if (error.message?.indexOf('token') > 0) {
            setCookieMessage('LoginMsg',"Acesso expirado, por favor, efetue novamente o seu login.")
            Redirect('/login')
        }
        return
    }  else if (error.status === 401) {     
        if (error.error !== undefined)
            setCookieMessage('LoginMsg',error.error)
        Redirect('/login')
    } else {
        if (error.internalCode) {
            if (error === "warning")
                NotificationManager.warning(error.message, 'Atenção!', _timout);
            else
                NotificationManager.error(error.message, 'Erro!', _timout);
            return
        } else {
            if ((error.problem) && (error.problem !== "SERVER_ERROR"))
                NotificationManager.error(error.problem, 'Erro!', _timout); 
            else if (error.data) {
                if ((error.data.message !== null) && (error.data.message !== undefined)){
                    if (error.data.status === 200)
                        NotificationManager.success(error.data.message, 'Sucesso!', _timout);
                    else if (error.data.status === 401) 
                        NotificationManager.warning(error.data.message, 'Atenção!', _timout);
                    else
                        NotificationManager.error(error.data.message, 'Erro!', _timout);
                } else if (error.data.error !== undefined) {
                    if (error.data.error.message !== undefined) {
                        NotificationManager.error(error.data.error.message, 'Erro!', _timout);  
                    }
                } else {
                    console.log(error.originalError)
                    NotificationManager.error(error.originalError.toJSON().message,'Erro!',_timout)    
                }
            } else {
                console.log(error)
                NotificationManager.error('3214 - Erro ao tentar efetuar a transação. Por favor, entre em contato com o suporte técnico.', 'Erro!', _timout); 
            }
            return    
        }
    }

}