import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import { FileInput,ValidationForm} from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";
import { convertToBase64 } from "../../../components/Utils";
import Waveform from "../../../components/waveformer";

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalTriggerNode({ nodeId, showModal, handleCloseModal, waveFormInstance, nodeData,editData}) {
    const [audio, setAudio]                     = useState(null);
    const [description, setDescription]         = useState('')
    const reactFlowInstance                     = useReactFlow();

    useEffect(() => {
        setAudio(nodeData.audio);
        setDescription(nodeData.description)
    }, [nodeData]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (audio === null) {
            ShowMessages({
                status: 500,
                message: "Não há um arquivo de audio adicionado!",
            });
            return;
        }

        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    console.log(node.data)
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node       : parseInt(nodeData.node),
                            parentId   : parseInt(nodeData.parentId), 
                            targetId   : parseInt(nodeData.targetId),
                            objectType : 14,
                            description,
                            audio
                        },
                    };
                }

                return node;
            })
        );

        handleCloseModal();
    };

    return (
        <Modal size="md" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width450"  animation={true}  backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-arrows-turn-to-dots"></i> Gatilho
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="box-shadow" style={{padding:"10px"}}>                      
                        <div style={{marginTop:"5px"}}>
                            <div className="mb-3">
                                <label>Fluxo</label><small className="text-danger" style={{marginLeft:"5px"}}>Somente fluxos do tipo gatilho</small>
                                <select className="form-select">
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-audio-info">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
    </Modal>
  );
}
