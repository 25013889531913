import React, { useState, useEffect } from 'react'
import ContentWrapper from '../../layouts/contentWrapper'
import Filter from '../../../components/Filter';
import Loading from '../../../components/Loading';
import Grid from '../../../components/Grid';
import api from '../../../services/api'
import ShowMessages from '../../../components/Exceptions'
import setStatistic from '../../../services/apiStatistic'
import Swal from 'sweetalert2'
import ModalPlan from '../../modals/admin/modal-plan'
import ModalPlanSettings from '../../modals/admin/modal-plan-settings'

export default function Plains() {
    const [loading, setLoading]                            = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [showModalSettings, setShowModalSettings]        = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [filters, setFilters]                            = useState([])
    const [data, setData]                                  = useState([])
    const [editState,setEditState]                         = useState(false)

    const RemovePlan   = async (id,e) => {  
        e.stopPropagation()
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir o plano?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/plans/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
        }) 
    }

    const getPlains = async() => {
        setGridParams({idcompany : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                                               value : '00000'},
                                                                  primaryKey  : true                          },
                                   create_at            : {text        : "Data"},
                                   number               : {text        : "Número ChatBot"},
                                                           searchDefault : true}                    
                    )
    }

    useEffect(() => {
        getPlains()
        setStatistic('maintenance\\plains')
    },[])
    
    const handleFilter             = () => {setIsOpenFilter(!isOpenFilter)}
    const handleCloseModal         = () => {setShowModal(false)}
    const handleCloseModalSettings = () => {setShowModalSettings(false)}

    const ShowCompany = async(id) => {
        await api.get('/plans/' + id)
                 .then(response => {
                    setShowModal(true)
                    setEditState(true)
                   setData(response.data)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 }) 
    }

    const getLayout = (_data) => {

        return (<div className="box-shadow" onClick={e => ShowCompany(_data.idplan)} style={{padding:"0px", cursor:"pointer", width:"360px"}}>
                    <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                        <div style={{height:"auto", width:"100%", borderTopLeftRadius:"5px",borderTopRightRadius:"5px", display:"flex", alignItems:"center", justifyContent:"space-between", background:"#f7f8f9"}}>
                            <div style={{display:"flex",justifyContent:"center", width:"100%"}}>
                                <span style={{fontSize:"20px", fontWeight:"400"}}>{_data.name.toString().toUpperCase() }</span>
                            </div>

                            <div style={{padding:"5px"}}>
                                <button type="button" className='btn btn-sm btn-danger' onClick={e => RemovePlan(_data.idplan,e)}>
                                    <i className='fa fa-trash'/>
                                </button>
                            </div>
                        </div>
                        <div style={{display:"flex",flexDirection:"column",width:"100%", padding:"10px", gap:"15px"}}>
                            <div style={{display:"flex", justifyContent:"space-between", borderBottom:"1px dashed #cacaca", paddingBottom:"25px"}}>
                                <div style={{display:"flex", flexDirection:"column"}}> 
                                    <small style={{fontWeight:"bold"}}>
                                        Valor por Número
                                    </small>
                                    <span className='text-right' style={{fontSize:"20px"}}>{_data.numbervalue.toLocaleString('pt-BR',{style: 'currency', currency: 'BRL'})}</span>
                                </div>
                                <div style={{display:"flex", flexDirection:"column"}}> 
                                    <small style={{fontWeight:"bold"}}>
                                        Valor por Atendente
                                    </small>
                                    <span className='text-right' style={{fontSize:"20px"}}>{_data.attendancevalue.toLocaleString('pt-BR',{style: 'currency', currency: 'BRL'})}</span>
                                </div>
                                <div style={{display:"flex", flexDirection:"column"}}> 
                                    <small style={{fontWeight:"bold"}}>
                                        Valor Fluxo
                                    </small>
                                    <span className='text-right' style={{fontSize:"20px"}}>{_data.valuechatbot.toLocaleString('pt-BR',{style: 'currency', currency: 'BRL'})}</span>
                                </div>
                            </div>
                            <div style={{display:"flex", justifyContent:"space-between", borderBottom:"1px dashed #cacaca", flexDirection:"column", paddingBottom:"25px"}}>
                                <div style={{display:"flex", alignItems:"center", justifyContent:"center", background:"#cacaca", borderRadius:"5px"}}>
                                    <span>Itens do Plano</span>
                                </div>
                                {JSON.parse(_data.description).map(_item => {
                                    return (<div style={{marginLeft:"15px", display:"flex", alignItems:"center", gap:"5px"}}>
                                                <div style={{width:"10px", height:"10px", borderRadius:"50%", background:"#cacaca"}}></div>
                                                <span>{_item.description}</span>
                                            </div>)
                                })}
                            </div>
                            <div style={{display:"flex", justifyContent:"space-between",flexDirection:"column"}}>
                                <div style={{display:"flex", alignItems:"center", justifyContent:"center", background:"#cacaca", borderRadius:"5px"}}>
                                    <span>Planos de Desconto</span>
                                </div>
                                {_data.discount.map(_item => {
                                    return (<div style={{marginLeft:"15px", display:"flex", alignItems:"center", gap:"5px"}}>
                                                <div style={{width:"10px", height:"10px", borderRadius:"50%", background:"#cacaca"}}></div>
                                                <span>{_item.month}  Meses por </span>
                                                <span>{_item.valueMonth}</span>
                                            </div>)
                                })}
                            </div>
                        </div>


                             
                    </div>
                </div>
       )
    }

    return (    
        <ContentWrapper>
            {loading && (
                <Loading loading={loading}/>
            )}
            <ModalPlan showModal={showModal} handleCloseModal = {handleCloseModal} handleReload={setReload} data={data} editState={editState}/>
            <ModalPlanSettings showModal={showModalSettings} handleCloseModal = {handleCloseModalSettings}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                    <div className="content-heading">
                        <div className="box-maintance-buttons">
                            <div>
                                <button className="btn btn-success" title="Insere um novo registro" onClick={e => {setEditState(false) 
                                                                                                                   setShowModal(true)}}><i className="fa fa-plus"></i></button>
                                <button type="button" className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                                {showButtons && ( 
                                    <>
                                        <button type="button" className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                        <button type="button" className="btn btn-info"  title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                        <button type="button" className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                    </>
                                )}
                                <button type="button" className="btn btn-warning" title="Configurações de Pagamento" style={{marginLeft:"3px"}} onClick={e => setShowModalSettings(true)}><i className="fa fa-gears"></i></button>
                            </div>
                        </div>

                    </div>

            </div>
            <div className={`content-box-cart content-box-cart-grid ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/plans' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      perPage             = {12}
                      filters             = {filters}
                      filename            = "Historico Planos"
                      getGridLayout       = {getLayout}
                      callbackShowButtons = {setShowButtons}
                      callbackExcel       = {setExcel}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackCellClick   = {null}
                      callbackButtons     = {[]}
                      />  
            </div>                    
        </ContentWrapper>
    )
}