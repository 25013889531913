
const Parameters = ({ handleHideParams, handleTextParams }) => {
    return (
        <div className="workflow-parameters">
            <div>
                <div className="d-flex align-items-center mb-2">
                    <button
                        className=" btn btn-warning"
                        onClick={(e) => handleHideParams()}
                    >
                        <i className="fa fa-arrow-left" /> <span>Voltar</span>
                    </button>
                </div>
                <div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[NOME]</span>
                        Nome do usuário do WhatsApp
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[WHATSAPP_NAME]</span>
                        Nome do usuário no status do whatsapp
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[PHONE]</span>
                        Número do WhatsApp
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[PHONE2]</span>
                        Número de telefone
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[AGORA] </span>
                        Data e Hora do Momento do Envio
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[DATA_ATUAL] </span>
                        Data do momento do envio
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[DATA_ONTEM] </span>
                        Data de ontem
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[HORA_ATUAL]</span> Hora do momento do envio
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[DATA_HORA_ATUAL]</span> Data e Hora do momento do envio
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[EMAIL]</span> Email do usuário destinatário
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[ENDEREÇO]</span> Endereço do usuário destinatário
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[COMPLEMENTO]</span> Complemento do usuário destinatário
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[NÚMERO]</span> Número da residência do usuário destinatário
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[BAIRRO]</span> Bairro do usuário destinatário
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[CIDADE]</span> Cidade do usuário destinatário
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[UF]</span> UF do usuário destinatário
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[CEP]</span> CEP do usuário destinatário
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[PAIS]</span> País do usuário destinatário
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[ENDEREÇO_COMPLETO]</span> Endereço completo do usuário
                        destinatário
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[TELEGRAM]</span> Telegram do usuário destinatário
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[FACEBOOK]</span> Facebook do usuário destinatário
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[LINKEDIN]</span> Linkedin do usuário destinatário
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[INSTAGRAM]</span> Instagram do usuário destinatário
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[TAG]</span> TAG do Contato
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[TAG_PERSONALIZADA]</span> Utilizada para retornar um valor personalizado
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[TAG_PERSONALIZADA1]</span> Utilizada para retornar um valor personalizado
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[TAG_PERSONALIZADA2]</span> Utilizada para retornar um valor personalizado
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[NUMERO_ALEATORIO]</span> Utilizado para gerar um número aleatório na mensagem
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[CONTADOR]</span> Contador autoincremental setado no inicio do fluxo
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[ANO]</span> Obtém o ano atual com 4 dígitos
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[MES]</span> Obtém o mês atual com 1 dígito
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[MES2]</span> Obtém o mês atual com 2 dígitos
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[DIA]</span> Obtém o dia atual com 1 dígitos
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[DIA2]</span> Obtém o dia atual com 2 dígitos
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[DIA+1]</span> Obtém o próximo dia com 1 dígitos
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[DIA2+1]</span> Obtém o próximo dia com 2 dígitos
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[DIA-1]</span> Obtém o dia anterior com 1 dígitos
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>[DIA2-1]</span> Obtém o dia anterior com 2 dígitos
                    </div>
                    <div
                        className="parameters__item"
                        onClick={(e) => handleTextParams(e)}
                    >
                        <span>{'{TEXTO1;TEXTO2;TEXTO3}'} </span>
                        <div> Todo conteúdo dentro das chaves será randomizado, ex: <b>{'{'} Oi;Está ai?;Como Vai?{'}'}</b> o sistema irá obter uma das opções informadas separadas por ; </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Parameters;
