import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import {  ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import ShowMessages from "../../../components/Exceptions";

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}


export default function ModalEndNode({ nodeId, showModal, handleCloseModal, nodeData }) {
    const [isHuman, setIsHuman]                           = useState(false);
    const [isBan, setIsBan]                               = useState(false);
    const [timernextInteraction, setTimerNextInteraction] = useState(60);

    const reactFlowInstance = useReactFlow();

    useEffect(() => {
        setTimerNextInteraction(nodeData.timernextInteraction);
        setIsBan(nodeData.isBan);
        setIsHuman(nodeData.isHuman);
    }, [nodeData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isHuman && isBan) {
            ShowMessages({status : 500, message : "A opção de atendimento humano não pode ser ativada quando a opção de não interagir mais estiver marcada."})
            return false
        }

        if ((!isBan) && ((timernextInteraction === '') || (parseInt(timernextInteraction) < 0))) {
            ShowMessages({status : 500, message : "O tempo para a próxima interação não foi informado"})
            return false
        }

        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node       : parseInt(nodeData.node),
                            parentId   : parseInt(nodeData.parentId), 
                            targetId   : parseInt(nodeData.targetId),
                            objectType : 9,
                            isClosed   : true, 
                            isHuman,
                            isBan,
                            timernextInteraction,
                        }
                    }
                }

                return node;
            })
        )

        handleCloseModal();
    };

    return (
        <Modal size="md" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName=" width500" animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
             <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-flag-checkered"></i> Fim do Fluxo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="box-shadow" style={{padding:"10px"}}>
                            <div>
                                <div style={{display:"flex",background:"#fff", width:"fit-content"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={isHuman} value={isHuman} onClick={e => setIsHuman(!isHuman)} />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Ao finalizar o fluxo ativar o atendimento humano?</span>
                                </div>  

                                <div style={{display:"flex",background:"#fff", width:"fit-content"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={isBan} value={isBan} onClick={e => setIsBan(!isBan)} />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Não executar mais esse fluxo para esse contato?</span>
                                </div>  
                                {!isBan ? 
                                    <div style={{marginTop:"5px"}}>
                                        <label>Reiniciar a interação automatizada com</label>
                                        <div style={{display:"flex"}}>
                                            <div style={{width:"170px"}}>
                                                <TextInput 
                                                    name="timernextInteraction"
                                                    id="timernextInteraction"
                                                    type="number"
                                                    required
                                                    errorMessage={{ required: "Por favor, um tempo para a próxima interação" }} 
                                                    value={timernextInteraction} 
                                                    onChange={e => setTimerNextInteraction(parseInt(e.target.value))}
                                                    className="form-control "
                                                    autoComplete="off"/>
                                            </div>
                                            <div style={{ marginTop:"5px", marginLeft:"5px"}}>
                                                <span>Minutos</span>
                                            </div>                                        
                                        </div>
                                    </div>
                                : ''}
                            </div>
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-action-info">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
  );
}
