import React, {useState, useEffect} from 'react'

import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Button, } from 'reactstrap';
import Draggable from 'react-draggable';
import ModalLead from './modal-lead';

import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'

import api from '../../services/api';

import loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalPeopleAnnotation({showModal, handleCloseModal,editState, data}) {
    const [loading, setLoading]             = useState(false)
    const [tag, setTag]                     = useState('')
    const [description, setDescription]     = useState('')
    const [tags, setTags]                   = useState([])
    const [dataTag, setDataTag]             = useState([])
    const [annotations, setAnnotations]     = useState('')
    const [showModalLead, setShowModalLead] = useState(false)
    const [dataLead, setDataLead]           = useState([])
    const [idpeople,setIDPeople]            = useState(0)

    const addTag = () => {
        const tagAlreadyExistInArrayTags = tags.some((item) => {
            if(item.tag === tag) {
                return true
            }
            return false;
        })

        if(tag.length === 0){
            ShowMessages({status : 500, message : "Tag não informada"})
            return
        }

        if(tags.length >= 5){
            ShowMessages({status : 500, message : "Permitido apenas 5 tags"})
            return
        }
        
        if(tagAlreadyExistInArrayTags){
            ShowMessages({status : 500, message : "Tag já existe"})
            return
        }
        
        setTags([...tags, {tag, description}]);
    };

    const removeTag = (_tag) => {
        let arrayOfTags = tags.filter((item) => {
            return item.tag !== _tag;
        })

        setTags(arrayOfTags);
    }

    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-people-annotation'),document.querySelector('#submit-people-annotation').children[0].classList,document.querySelector('#submit-people-annotation').lastChild.nodeValue)
        event.preventDefault()

        try {                        
            await api.post('/attendance/annotation',{to       : data.to.replace(/[\W_,[a-z]+/g,""),
                                                     name     : data.name,
                                                     idpeople : data.idpeople,
                                                     tags     : tags,
                                                     idmessagehistoryheader : data.idmessagehistoryheader,
                                                     annotations })
                     .then((response) => {
                        loadingButton(false,document.querySelector('#submit-people-annotation'),'fa fa-save','Salvar')
                        handleCloseModal()
                     })
                    .catch((error) => {
                        ShowMessages(error)
                        loadingButton(false,document.querySelector('#submit-people-annotation'))
                    })  
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-people-annotation'))
            ShowMessages(response)
        }
    }

    const EditLead = async() => {
        await api.get('/leads/' + idpeople)
                 .then(response => {
                    setDataLead(response.data)
                    setShowModalLead(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const handleCloseModalLeads  = () => {setShowModalLead(false)}

    useEffect(() => {
        const getTags = async() => {
            setLoading(true)
            await api.post('/getAnnotations',{to : data.to.replace(/[\W_,[a-z]+/g,"")})
                     .then((response) => {
                        setDataTag(response.data.tags)
                        setAnnotations(response.data?.annotation?.annotations)
                        setIDPeople(response.data.annotation.idpeople)
                        let _tag = response.data?.annotation?.tags 
                        if (_tag)
                            setTags(typeof _tag === 'object' ? _tag : JSON.parse(response.data.annotation.tags))
                        else 
                            setTags([])
                        setLoading(false)
                     })
                     .catch((error) => {
                        ShowMessages(error)
                     })  
        }

        if (showModal)
            getTags()
    },[showModal])

    useEffect(() => {
        setStatistic('modals\\modal-people-annotation')
    },[])
  
    return (
        <>
            <ModalLead showModal={showModalLead} handleCloseModal={handleCloseModalLeads} editState={true} data={dataLead} copy={false} />
            <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
                <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" >
                    <Modal.Header closeButton>
                        <Modal.Title style={{ marginBottom: "0px" }}>
                            <i className="fa fa-note-sticky"></i> Anotações do Contato
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {loading && (
                            <Loading loading={loading} addClass="heigth330px" />
                        )}
                        <div className={`box-shadow ${loading ? 'hidden' : ''}`} >
                            <div style={{ display: "flex", gap: "5px" }}>
                                <div style={{ width: "350px" }}>
                                    <label>Etiquetas</label>
                                    <select className='form-select' value={tag} onChange={e => {
                                        setTag(e.target.value)
                                        setDescription(e.target.options[e.target.selectedIndex].text)
                                    }}>
                                        <option value=''>Selecione</option>
                                        {dataTag.map(_item => {
                                            return <option value={_item.idtag}>{_item.tag}</option>
                                        })}
                                    </select>
                                </div>
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <button className='btn btn-success' type="button" onClick={e => addTag(e)}><i className='fa fa-plus'></i></button>
                                </div>
                            </div>
                            <div style={{ marginTop: "5px" }}>
                                <table className="table table-striped w-100">
                                    <thead className="thead-theme">
                                        <tr>
                                            <th className='head-th'>Etiqueta</th>
                                            <th className='head-th' style={{ textAlign: "center" }}>...</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tags.map((_actions, id) => {
                                            return (<tr className="gradeX" key={id}>
                                                <td style={{ verticalAlign: "middle" }}>{_actions.description}</td>
                                                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                    <button type='button' className="btn btn-danger btn-sm" onClick={e => removeTag(_actions.tag)}><i className="fa fa-trash" /></button>
                                                </td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div>
                                    <label>Observações</label>
                                    <textarea className='form-control' rows={10} value={annotations} onChange={e => setAnnotations(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <div>
                                <Button id='submit-people-lead' className={`btn-warning ${loading ? "hidden" : ""}`} onClick={e => EditLead()}>
                                    <em className="fa fa-edit"></em> Dados do Lead
                                </Button>
                            </div>
                            <div style={{ display: "flex", gap:"5px"}}>
                                <Button type="submit" id='submit-people-annotation' className={`btn-theme ${loading ? "hidden" : ""}`} >
                                    <em className="fa fa-save"></em> Salvar
                                </Button>
                                <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                                    <em className="fa fa-door-closed"></em> Fechar
                                </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                </ValidationForm>
            </Modal>
        </>
    )
}