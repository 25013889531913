import React, { useState, useEffect } from 'react';
import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Modal, ModalDialog } from 'react-bootstrap';
import { Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ShowMessages from '../../components/Exceptions';
import MaskMoneyValidation from '../../components/moneyInput';
import Draggable from 'react-draggable';
import CurrencyInput from '../../components/moneyInput';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

const formatMoney = (value) => {
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(value);
};

export default function ModalAfiliateWithdrawal({showModal, handleCloseModal, handleReload}) {
    const [loading, setLoading]                     = useState(false)
    const [accountWithdrawal, setAccountWithdrawal] = useState(0.00);
    const [currentBalance, setCurrentBalance]       = useState(0);


    const handleSubmit = (e) => {
        e.preventDefault()
        let _accountWithdrawal = accountWithdrawal.replace('.','').replace(',','.').replace('R$ ','')
        if (_accountWithdrawal <= 0) {
            ShowMessages({status : 500, message : 'O valor solicitado não pode ser menor ou igual a 0'})
            return
        }

        if (_accountWithdrawal > currentBalance) {
            ShowMessages({status : 500, message : 'O valor solicitado não pode ser maior que o saldo disponível'})
            return
        }
    };

    useEffect(() => {
        setCurrentBalance(1000);
    }, []);

    return (
        <Modal  show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}>  
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-user-check"></i> Propriedades do Usuário
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',padding: '20px',borderRadius: '8px' }}>
                        <div className='alert alert-success' style={{padding: '10px', borderRadius: '5px', marginBottom: '14px', fontSize: '15px', textAlign:"center"}} >
                            <label style={{fontSize:"20px", fontWeight:"bold"}}>Saldo Atual</label>
                            <div>{formatMoney(currentBalance)}</div>
                        </div>
                        <div>
                            <label>Valor Socilitado</label>
                            <CurrencyInput  
                                        name="saleprice"
                                        id="saleprice"
                                        type="text"
                                        required
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe um valor!" }}
                                        value={accountWithdrawal}
                                        className="form-control text-right"
                                        onChange={setAccountWithdrawal}
                                        autoComplete="off"/>
                         </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit"  id="submit-user" className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    );
}
