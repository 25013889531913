import React, {useState, useEffect} from 'react'

import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';
import loadingButton  from '../layouts/Buttons'
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalMyNumberIA({showModal, handleCloseModal, data, handleReload,editState}) {
    const [idservice, setIDService]       = useState(0)
    const [appkey, setAppKey]             = useState('')
    const [description, setDescription]   = useState('')

    const setData = (obj,clear) => {
        setIDService(clear ? '' : obj.idservice)
        setAppKey(clear ? '' : obj.appkey)  
        setDescription(clear ? '' : obj.description) 
    }
      
    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-ia'),document.querySelector('#submit-ia').children[0].classList,document.querySelector('#submit-ia').lastChild.nodeValue)
        event.preventDefault()

        try {
            let _data = {idservice,
                         appkey,
                         description
                        }
              
            if (editState)
                await api.put('/ia/' + data.idnumber, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-ia'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-ia'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-ia'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/ia', _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-ia'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-ia')) 
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-ia'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-ia'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        if (editState)
            setData(data,false)
        else 
            setData({},true)
    },[data,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-mynumber-ia')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="width450" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-robot"></i> Conexão com IA
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div className='box-shadow'>
                        <div style={{display:"flex", gap:"5px", flexDirection:"column"}}>
                            <div style={{width:"100%"}}>
                                <label>Serviço</label>
                                <select className='form-select' value={idservice} onChange={e => {
                                        setIDService(e.target.value)
                                        setDescription(e.target.options[e.target.selectedIndex].text)
                                        }}>
                                    <option value={''}>Selecione</option>
                                    <option value={1}>ChatGPT</option>
                                </select>
                            </div>
                            <div>
                                <label>Chave</label>
                                <input type="text" className='form-control'value={appkey} onChange={e => setAppKey(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    <Button type="submit" id="submit-ia" className={`btn-theme`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button> 
                    <Button type="button" className="btn-ligth-gray" onClick={e => handleCloseModal()}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    )
}