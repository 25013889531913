import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../../components/Exceptions'
import Loading from '../../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../../services/api';
import setStatistic from '../../../services/apiStatistic'
import  loadingButton  from '../../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalWhatsAppGroup({showModal, handleCloseModal, data, handleReload, editState}) {
    const [loading, setLoading]         = useState(false)
    const [description, setDescription] = useState(0)
    const [link, setLink]               = useState('')


    const setData = (obj,clear) => {
        console.log(obj)
        setDescription(clear ? '' : obj.description)
        setLink(clear ? '' : obj.link)
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-whatsapp-group'),document.querySelector('#submit-whatsapp-group').children[0].classList,document.querySelector('#submit-whatsapp-group').lastChild.nodeValue)

        try {
            let _data = { description,
                          link}

            if (editState)
                await api.put('/admin/whatsapp-group/' + data.idwhatsappgrouplist, _data)
                         .then(response => {
                             ShowMessages(response)
                             if (response.ok) {   
                                 if (response.data.status === 200) {
                                     handleCloseModal()  
                                     handleReload(true)    
                                 } else 
                                     loadingButton(false,document.querySelector('#submit-whatsapp-group'))        
                             } else
                                 loadingButton(false,document.querySelector('#submit-whatsapp-group'))
                         })
                         .catch( error => {
                             loadingButton(false,document.querySelector('#submit-whatsapp-group'))
                            ShowMessages(error) 
                         })
            else 
                await api.post('/admin/whatsapp-group', _data)
                         .then(response => {
                             ShowMessages(response)
                             if (response.ok) {   
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-whatsapp-group'))        
                             } else
                                loadingButton(false,document.querySelector('#submit-whatsapp-group'))
                         })
                         .catch( error => {
                            loadingButton(false,document.querySelector('#submit-whatsapp-group'))
                            ShowMessages(error) 
                         })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-whatsapp-group'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        if (editState)
            setData(data,false)
        else
            setData({},true)
    },[data,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-whatsapp-group')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-user-group"></i> Propriedades de Grupos
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>  
                        <div>
                            <label>Descrição do Grupo</label>
                            <input className="form-control" type="text" value={description} onChange={e => setDescription(e.target.value)}/>
                        </div> 
                        <div>
                            <label>Link do Grupo</label>
                            <input className="form-control" type="text" value={link} onChange={e => setLink(e.target.value)}/>
                        </div>                                    
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-whatsapp-group" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}