import React, {useState, useEffect} from 'react'

import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Button, } from 'reactstrap';
import Draggable from 'react-draggable';


import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'
import { useChatData } from './../../components/messageView/chatContext'

import api from '../../services/api';
import Swal from 'sweetalert2'

import { USER_DATA, ReadDataStorage, IDUSER } from '../../services/auth';
import loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalTransferUser({showModal, handleCloseModal, data, handleReload}) {
    const [loading, setLoading]   = useState(false)
    const [user, setUser]         = useState('')
    const [userData, setUserData] = useState([])
  
    const { setTo }               = useChatData()

    const getData = async() => {    
        setLoading(true)
        await api.get('/getUserData')
                 .then((response) => { 
                    setUserData(response.data)
                    
                    setLoading(false)
                 }).catch(error => {
                    setLoading(false)
                    ShowMessages(error)
                 })  
    }
    
    const handleSubmit = async(event) => {
        event.preventDefault()
        console.log(data)
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente transferir a chamada para outro atendente?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                loadingButton(true,document.querySelector('#submit-transfer-user'),document.querySelector('#submit-transfer-user').children[0].classList,document.querySelector('#submit-transfer-user').lastChild.nodeValue)
                

                try {
                    let _data = {user,
                                 idautomationchatheadhistory : data.idAutomationChatHeadHistory}
                     
                     
                    await api.post('/attendance/transfer', _data)
                             .then(response => {
                                ShowMessages(response)
                                if (response.ok) {  
                                    if (response.data.status === 200) {
                                        handleCloseModal()  
                                        setTo('')
                                        handleReload(true)    
                                    } else 
                                        loadingButton(false,document.querySelector('#submit-transfer-user'))        
                                } else 
                                    loadingButton(false,document.querySelector('#submit-transfer-user')) 
                            })
                            .catch( error => {
                                loadingButton(false,document.querySelector('#submit-transfer-user'))
                                ShowMessages(error) 
                            })
                } catch (response) {
                    loadingButton(false,document.querySelector('#submit-transfer-user'))
                    ShowMessages(response)
                }
            }
                
          })
    }

    useEffect(() => {
        getData()
        setStatistic('modals\\modal-transfer-user')
    },[])
   
    return (
      
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" > 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-right-left"></i> Transferência de Atendente
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth330px"/>
                    )}
                    <div className={`box-shadow ${loading ? "hidden" : ""}`} style={{padding:"15px"}}>        
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <label>Atendente Atual</label>
                            <label style={{fontWeight:"bold", fontSize:"18px"}}>{JSON.parse(ReadDataStorage(USER_DATA)).name}</label>
                        </div>       
                        <div>
                            <label>Transferir atendimento para...</label>
                            <div className="input-group">
                                 <select className='form-select' style={{width:"100%"}} value={user} onChange={e => setUser(e.target.value)}>
                                    <option value="">Selecione</option>
                                    {userData.map((_item,id) => {
                                        if (_item.iduser !== parseInt(ReadDataStorage(IDUSER)))
                                            return (
                                                <option key={id} value={_item.iduser}>{_item.name}</option>
                                            )
                                    })}
                                 </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-transfer-user' className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-save"></em> Transferir
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}